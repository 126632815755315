import { SortDirection } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import { I18n } from 'react-redux-i18n'
import { config } from '../../../../../main/config'

import { dataFormatters } from '../tableFormatters'
import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { Debouncer } from '../../../../../shared/utils/components/debounce'
import store from '../../../../../main/store/store'
import { setColumnWidths, setColumns } from '../../../actions/table'
import { ITable, ITableColumn, ITableRow, ITableSearchTag, ITableSort } from '../../../models/table'
declare global {
  interface Window {
    colState: any
  }
}

const ColumnComponent = (props) => {
  return (
    <div
      className="cursor-pointer"
      onContextMenu={e => e.preventDefault()}
    >
      {props.value}
    </div>
  )
}

interface TableProps {
  tableId: string
  table: ITable
  rows: ITableRow[]
  dockId: string
  disableSorting: boolean
  columns: ITableColumn[]
  hiddenColumnNames: string[]
  onClickCell: (
    rowIndex: string,
    columnKey: string,
    row: ITableRow,
    event: any,
    type: 'left' | 'right') => void
  sorting: ITableSort[]
  paginatedDataRequest: (
    page: number,
    sorting: ITableSort[],
    type: string,
    id: string,
    tags: ITableSearchTag[]
  ) => void
  sort: (id: string, sorting: ITableSort[]) => void
}

const UiTableComponentGrid = ({
  tableId,
  dockId,
  columns,
  rows,
  hiddenColumnNames,
  onClickCell,
  paginatedDataRequest,
  table,
  sorting,
  sort
}: TableProps) => {
  const debouncer = useRef(new Debouncer())
  const gridRef = useRef<AgGridReact>(null)
  const page= useRef(0)
  const [sortingMap, setSortingMap] = useState({})
  const [sortingOrder, setSortingOrder] = useState([])

  const colsData = columns
    .filter((col) =>
      ['$index', ...hiddenColumnNames].indexOf(col.name) === -1
      && col.rowVisible
    )
    .map((col: any) => {
       const index = sortingOrder.indexOf(col.name)
        return {
          field: col.name,
          cellRenderer: ColumnComponent,
          cellClass: `${col.name}-column`,
          flex: col.width === undefined ? 1 : col.width,
          headerName: I18n.t(col.title),
          width: col.width,
          colId: col.name,
          sort: sortingMap[col.name] ? (sortingMap[col.name].direction === 1 ? 'asc' : 'desc'): null as SortDirection,
          sortIndex: index > -1 ? index : undefined,
        }
      }
    )

  const updateSorting = (sortingArray: ITableSort[]) => {
    setSortingMap(sortingArray.reduce((acc, columnSort) => {
      return {...acc, [columnSort.columnName]: columnSort }
    }, {}))
    setSortingOrder(sortingArray.map(columnSort => columnSort.columnName))
  }

  const onGridReady = () => {
    updateSorting(sorting)
  }

  const rowsData = rows.map((row: any) => {
    const mappedRow = {}
    columns.forEach((col: any) => {
      mappedRow[col.name] = dataFormatters[col.name]
        ? dataFormatters[col.name](row[col.name])
        : row[col.name]
    })

    return mappedRow
  })

  const _columnResized = (event) => {

    if (event?.columns && event.finished) {
      const parent = document.querySelector('.dockId-' + dockId) as HTMLElement
      for (let gridColumn of event.columns) {
        store.dispatch(
          setColumnWidths(
            tableId,
            gridColumn.getId(),
            gridColumn.getActualWidth()
          )
        )
      }
    }
  }

  const _columnMoved = (event) => {
    if (event.toIndex !== undefined && event.finished) {
      store.dispatch(setColumns(tableId, reorderColumns(columns, event.columnApi.getAllDisplayedColumns(), hiddenColumnNames)))
    }
  }

  const paginatedScroll = () => {
    debouncer.current.debounce(
      () => {
        const total = gridRef.current.api.getDisplayedRowCount()
        const last = gridRef.current.api.getLastDisplayedRowIndex()
        const isBottom = total - 5 < last
        if (isBottom) {
          page.current = Math.ceil(total / config.tablePageSize)
          if (table.externallyPaginated) {
            paginatedDataRequest(
              total,
              sorting,
              table.type,
              table.id,
              table.tags
            );
          }
        } else if (page.current > Math.floor(last / config.tablePageSize)) {
          page.current = Math.floor(last / config.tablePageSize) - 1
          if (table.externallyPaginated) {
            paginatedDataRequest(
              page.current * config.tablePageSize,
              sorting,
              table.type,
              table.id,
              table.tags
            );
          }
        }
      },
        200)
      };

  const onSortColumn = (event) => {
    if (event.columns) {
      const newSorting = event.api.getColumns()
        .filter(c => !!c.sort)
        .sort((c1, c2) => c1.sortIndex - c2.sortIndex)
        .map(c => {
          return {
            columnName: c.colId,
            direction: c.sort === 'asc' ? 1 : -1
          }
        })
      updateSorting(newSorting)

      sort(table.id, newSorting);
      page.current = 1
      if (table.externallyPaginated) {
        paginatedDataRequest(
          0,
          newSorting,
          table.type,
          table.id,
          table.tags
        )
      }
    }
  }
  
  return (
    <div className="orderbook">
      <div className="books-view grid-wrapper">
        <div className="grid-container">
          <div className="main-tabel-wrapper">
            <div className="ag-theme-quartz-dark table">
              <AgGridReact
                onGridReady={onGridReady}
                rowData={rowsData}
                columnDefs={colsData}
                animateRows={false}
                defaultColDef={{
                  resizable: true,
                  onCellClicked: event => onClickCell(''+event?.rowIndex, event?.column.getId(), event?.data, event.event, 'left'),
                  onCellContextMenu: event =>  onClickCell(''+event?.rowIndex, event?.column.getId(), event?.data, event.event, 'right'),
                  suppressHeaderMenuButton: true,
                }}
                ref={gridRef}
                gridOptions={{
                  onColumnResized: _columnResized,
                  onColumnMoved: _columnMoved,
                  onBodyScroll: e => paginatedScroll(),
                  onSortChanged: onSortColumn,
                  suppressContextMenu:true,
                  suppressDragLeaveHidesColumns: true
                }}
                suppressScrollOnNewData
                suppressRowClickSelection
                suppressCellFocus
                rowHeight={22}
                headerHeight={30}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(UiTableComponentGrid, propsEqual)

function propsEqual(oldProps, newProps) {
  if (oldProps?.rows?.length === 0 && newProps?.rows?.length === 0) {
    return true
  }
  return false
}

function reorderColumns(columns: ITableColumn[], newColumnOrder: any[], hiddenColumnNames: string[]) {
  const columnsReordered = [];
  let visibleIndex = 0;
  const columnMap = columns.reduce((acc, col) => {
    acc[col.name] = col
    return acc
  }, {});
  for (let oldIndex = 0; oldIndex < columns.length; oldIndex++) {
    if (hiddenColumnNames.indexOf(columns[oldIndex].name) == -1 && columns[oldIndex].rowVisible) { 
      columnsReordered.push(columnMap[newColumnOrder[visibleIndex++]?.getId()]);
    } else {
      columnsReordered.push(columns[oldIndex]);
    }
  }
  return columnsReordered.filter(c => c !== undefined);
}
