import * as React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { config } from '../../main/config';
import { isFronteggSSO } from '../keycloak/helper'
import { NotificationMessage } from './LoginForm';
import { ContextHolder } from '@frontegg/react';

interface Props {

}

interface State {
    msg: string;
    translated: string;
}

export class LogoutPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        // a status message might be included in query params
        const match: any = window.location.search.match(/msg\=([^&]+)/);
        let msg = undefined;
        if (match && match[1]) {
            msg = decodeURIComponent(match[1]);
        }

        this.state = {
            translated: !msg ? '' : I18n.t(msg),
            msg: msg
        };
    }

    componentDidMount() {
        // try to rerender after localization messages are loaded
        setTimeout(() => {
            this.setState((prevState => {
                return {
                    ...prevState,
                    translated: !prevState.msg ? '' : I18n.t(prevState.msg)
                };
            }) );
        }, 1000);

        if (isFronteggSSO(config)) {
            setTimeout(() => {
                const baseUrl = ContextHolder.getContext().baseUrl;
                window.location.href = `${baseUrl}${baseUrl[baseUrl.length - 1] === '/' ? '' : '/'}oauth/logout?post_logout_redirect_uri=${encodeURI('http://' + window.location.host + config.subfolder)}`;
            }, 1000);
        }
    }

    render() {
        const hideLoginButton = this.state.msg === 'error.sessionActive';

        return (
        <div className="login-container row justify-content-center h-100">
            <div className="login-form center-block">
                {this.state.msg ? <NotificationMessage
                      message={this.state.translated}
                      level={'danger'}
                /> : ''}
                <p hidden={hideLoginButton}>
                    <Translate value="login.loggedOut" />
                </p>
                <div className="d-flex justify-content-center mt-auto w-100">
                    <a href={config.subfolder + '/'}>
                        <input hidden={hideLoginButton}
                            type="submit"
                            className="btn btn-primary w150 login-button"
                            value={I18n.t('login.Login')}
                        />
                    </a>
                </div>
            </div>
        </div>    
        );
    }
}
