import * as React from 'react';

import { IMarket } from '../../../models/market';

import { Translate, Localize, I18n } from 'react-redux-i18n';
import { Debouncer } from '../../../../utils/components/debounce';
import {periodTypes} from "../../../../../orderbook/models/contracts";

interface ExpiryBlockProps {
  columns: any[];
  hiddenExpiryKeys: string[];
  expiryRowsLength: { [key: string]: number };
  onCheckboxChange: (name: string) => void;
  onRangeChange: (name: string, value: number) => void;
  dockId: string;
}

class ExpiryBlock extends React.Component<ExpiryBlockProps, any> {
  rangeDebouncer: Debouncer;
  constructor(props: ExpiryBlockProps) {
    super(props);
    this.toggleRange = this.toggleRange.bind(this);
    this.rangeDebouncer = new Debouncer();
  }

  toggleRange(event: any) {
    const target = event.target;
    const value = parseInt(target.value);
    const name = target.name;
    this.rangeDebouncer.debounce(() => this.props.onRangeChange(name, value), 150);
  }

  render() {
    const { columns, hiddenExpiryKeys, expiryRowsLength } = this.props;

    let inputs = [];
    for (let column of columns) {
      const periodTypeKey = column.name.replace(/\d+$/, '');
      const translatedPeriodType = periodTypes[periodTypeKey]
          ? I18n.t(periodTypes[periodTypeKey].key)
          : periodTypeKey;
      const steps: any = {
        'QUARTER_HOUR': 4, 'HALF_HOUR': 2
      };
      const uniqueId = this.props.dockId + '__' + column.name;
      inputs.push(
        <div className="col-12 pl-0" key={column.name}>
          <div className="form-group form-check pl-0">
            <input
              type="checkbox"
              id={uniqueId}
              name={uniqueId}
              defaultChecked={hiddenExpiryKeys.indexOf(column.name) === -1}
              className="form-check-input"
              onChange={(e) => this.props.onCheckboxChange(column.name)}
            />
            <label className="form-check-label" htmlFor={uniqueId}>
              {Number(column.name) ? (
                <Localize
                  value={parseInt(column.name, 10)}
                  dateFormat="date.long"
                />
              ) : (
                <span>{translatedPeriodType} {column.name.replace(periodTypeKey, '')}</span>
              )}
            </label>
          </div>
          {hiddenExpiryKeys.indexOf(column.name) === -1 ? (
            <div className="form-group form-range">
              <input
                type="range"
                min="1"
                className="w-100"
                max={column.length}
                name={column.name}
                value={expiryRowsLength[column.name] || column.length}
                onChange={this.toggleRange}
                step="1"
                list="ticks"
              />
              <div className="text-center">
                <label>
                  {expiryRowsLength[column.name]
                    ? expiryRowsLength[column.name]
                    : column.length}
                </label>
              </div>
            </div>
          ) : null}
        </div>
      );
    }

    return (
      <div className="block">
        <Translate value="marketsheet.expiry" tag={'h3'} />
        <div data-test="expiry-filter-menu-table" className="table-filter-block">{inputs}</div>
      </div>
    );
  }
}

interface InstrumentBlockProps {
  marketId: string;
  name: string;
  columns: any[];
  hiddenColumnNames: any[];
  onCheckboxChange: (name: string, value: any) => void;
}

class InstrumentBlock extends React.Component<InstrumentBlockProps, any> {
  constructor(props: InstrumentBlockProps) {
    super(props);
  }

  isChecked(groupAndColName, hiddenColumnNames): boolean {
    return hiddenColumnNames.indexOf(groupAndColName) === -1;
  }

  render() {
    const { marketId, name, columns, hiddenColumnNames } = this.props;
    let inputs = columns.map(column => {
      const groupAndColName = column.group + '__' + column.name;
      const uniqueId = marketId + '__' + groupAndColName;
      return (
        <div key={uniqueId}>
          <div className="form-group form-check">
            <input
              type="checkbox"
              id={uniqueId}
              name={uniqueId}
              className="form-check-input"
              checked={this.isChecked(groupAndColName, hiddenColumnNames)}
              onChange={(e) => this.props.onCheckboxChange(groupAndColName, e.target.checked)}
            />
            <label
              className="form-check-label"
              htmlFor={uniqueId}
              title={I18n.t(column.title)}
            >
              <Translate value={column.title} />
            </label>
          </div>
        </div>
      );
    });
    return (
      <div className="horizontal-inputs">
        <h3>{name}</h3>
        <div className="d-flex justify-content-start">{inputs}</div>
      </div>
    );
  }
}

interface MarketFilterProps {
  dockId: string;
  markets: IMarket[];
  expiries: { name: string; length: number }[];
  hiddenExpiries: string[];
  triggerColumnNames: (
    marketId: string,
    hiddenColumnNames: string[],
    dockId: string
  ) => void;
  triggerExpiries: (
    marketIds: string[],
    hiddenExpiryKeys: string[],
    allExpiryKeys: string[],
    dockId: string
  ) => void;
  triggerExpiryRows: (
    marketIds: string[],
    expiryRowsLength: {},
    dockId: string
  ) => void;
  isHeadlinesVisible: boolean;
  changeHeadlinesVisibility: (
    marketIds: string[],
    isHeadlinesVisible: boolean,
    dockId: string
  ) => void;
}
interface State {
  maxHeight: string;
}

export default class UIMarketFilter extends React.Component<
  MarketFilterProps,
  State
> {
  constructor(props: MarketFilterProps) {
    super(props);

    this.onColumnNamesChange = this.onColumnNamesChange.bind(this);
    this.onExpiryKeysChange = this.onExpiryKeysChange.bind(this);
    this.onExpiryRowsChange = this.onExpiryRowsChange.bind(this);
    this.state = {
      maxHeight: '100%'
    }
  }

  onColumnNamesChange(marketId: string, name: string, value: any) {
    const { dockId, markets } = this.props;
    const market = markets.find(m => m.id === marketId);
    if (!market) {
      return;
    }
    const hiddenColumnNames = market.hiddenColumnNames;
    const index = hiddenColumnNames.indexOf(name);

    let newHiddenColNames = [];
    if (index === -1 && !value) {
      newHiddenColNames = [...hiddenColumnNames, name];
    } else if (index >= -1 && value) {
      newHiddenColNames =  [
        ...hiddenColumnNames.slice(0, index),
        ...hiddenColumnNames.slice(index + 1)
      ];
    }
    this.props.triggerColumnNames(market.id, newHiddenColNames, dockId);
  }

  onExpiryKeysChange(name: string) {
    const { dockId, markets, expiries, hiddenExpiries } = this.props;
    const index = hiddenExpiries.indexOf(name);
    const newHiddenExpiryKeys =
      index === -1
        ? [...hiddenExpiries, name]
        : [
          ...hiddenExpiries.slice(0, index),
          ...hiddenExpiries.slice(index + 1)
        ];

    this.props.triggerExpiries(
      markets.map(m => m.id),
      newHiddenExpiryKeys,
      expiries.map(e => e.name),
      dockId
    );
  }

  onExpiryRowsChange(name: string, value: number) {
    const { dockId, markets } = this.props;
    const newExpiryRowsLength = markets.reduce((acc: any, market) => {
      return {
        ...acc,
        ...Object.assign({}, market.expiryRowsLength, {
          [name]: value
        })
      };
    }, {});

    this.props.triggerExpiryRows(
      markets.map(m => m.id),
      newExpiryRowsLength,
      dockId
    );
  }

  onHeadlinesVisibilityChange(event: any) {
    const { dockId, markets } = this.props;
    const value = event.target.checked;
    this.props.changeHeadlinesVisibility(
      markets.map(m => m.id),
      value,
      dockId
    );

  }

  render() {
    const { markets, expiries, hiddenExpiries, isHeadlinesVisible, dockId } = this.props;
    const { maxHeight } = this.state;
    const instrumentBlocks = markets.map(market => (
      <InstrumentBlock
        key={market.id}
        marketId={market.id}
        name={market.title}
        columns={market.columns}
        hiddenColumnNames={market.hiddenColumnNames}
        onCheckboxChange={(name: string, value: any) => this.onColumnNamesChange(market.id, name, value)}
      />
    ));

    const expiryRowsLength = markets.reduce((acc: {}, market) => {
      return { ...acc, ...market.expiryRowsLength };
    }, {});

    return (
      <div className="market__filter" style={{ maxHeight: maxHeight }}>
        <div className="d-flex">
          <ExpiryBlock
            columns={expiries}
            hiddenExpiryKeys={hiddenExpiries}
            expiryRowsLength={expiryRowsLength}
            onCheckboxChange={this.onExpiryKeysChange}
            onRangeChange={this.onExpiryRowsChange}
            dockId={dockId}
          />
          <div className="block block-horizontal">
            <div className="d-flex flex-column">{instrumentBlocks}</div>
          </div>
        </div>
      </div>
    );
  }
}
