import { Cell } from '../../models/market';
import { Restriction } from '../../../../orders/models/orders';
import * as React from 'react';
import { formatQuantityToDecimal } from '../../../../shared/utils/formatters';
import { CurrencySymbols } from '../../../../orderbook/models/contracts';

export const MarketQuantityFormatter = (cell: Cell, contextData: any): Cell => {
    const { contract, source } = contextData;
    let { value } = cell;
    let formattedLocalizeOptions: { [attribute: string]: any } = {
        style: 'decimal',
        useGrouping: true,
        minimumFractionDigits: contract ? contract.qtyDecimals : 0,
        maximumFractionDigits: contract ? contract.qtyDecimals : 0,
        decimalStep: contract ? contract.qtyStepSize : 0
    };
    let quantityUnit = !!contract?.qtyUnit && contract.qtyUnit !== 'NONE' ? contract.qtyUnit : '';
    if (quantityUnit === 'KW' && contract.qtyDecimals === 3) {
       quantityUnit = 'MW';
    }

    value = contract ? formatQuantityToDecimal(value, contract.qtyDecimals) : value;
    let symbol = quantityUnit;
    if (source !== undefined  && source !== null && (source.restriction === Restriction.AON || source.restriction === Restriction.AON_INT)) {
        symbol = ' *';
    }

    return { ...cell, value: value, symbol: symbol, localizeOptions: formattedLocalizeOptions };
};

export const CounterpartyFormatter = (cell: Cell, contextData: any): Cell => {
    const { anonymizeMarketDataEnabled} = contextData;
    let value = anonymizeMarketDataEnabled ? 'IM' : cell.value;
    return { ...cell, value: value};
};

export const ExecPriceFormatter = (cell: Cell, contextData: any): Cell => {
    const { entries, index} = contextData;
    let indicator = '';
    let icon: JSX.Element | undefined = undefined;
    // display indicator only for the first price, ignore self-trades
    // server sends one additional last price for calculation of increase/decrease. This last price should not be displayed
    let value = cell.value;
    if (index === 0) {
        let next = index + 1;
        if (entries && entries.length > next) {
            if (cell.value > entries[next]['execPrice']) {
                indicator = 'top green';
            } else if (cell.value < entries[next]['execPrice']) {
                indicator = 'bottom red';
            } else if (cell.value === entries[next]['execPrice']) {
                indicator = 'right gray';
            }
        }
        if (indicator) {
            icon = (
                <i className={'oi execprice-arrow oi-caret-' + indicator} />
            );
        }
    } else if (entries.length - 1 === index) {
        value = '';
    }
    return { ...cell, className: cell.className + ' ' + indicator, value: value, decorator: icon, symbol: undefined};
};

export const PriceFormatter = (cell: Cell, contextData: any): Cell => {
    const { contract } = contextData;
    let formattedLocalizeOptions: { [attribute: string]: any } = {
        style: 'decimal',
        useGrouping: true,
        minimumFractionDigits: contract ? contract.priceDecimals : 0,
        maximumFractionDigits: contract ? contract.priceDecimals : 0
    };
    const currency = CurrencySymbols[contextData?.contract?.currency]

    return { ...cell, className: cell.className, localizeOptions: formattedLocalizeOptions, symbol: currency};
};

const timeInstance = new Intl.DateTimeFormat(undefined, {
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
});

const dateInstance = new Intl.DateTimeFormat(undefined, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric'
});

const dateTimeInstance = new Intl.DateTimeFormat(undefined, {
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric'
});

export const TimestampFormatter  = (cell: Cell, contextData: any): Cell => {
    try {
        let formattedTime = undefined
        if (contextData.compactColumnsEnabled && contextData.columns?.includes('date')) {
            formattedTime = dateTimeInstance.format(new Date(cell.value));
        } else {
            formattedTime = timeInstance.format(new Date(cell.value));
        }

        return { ...cell, value: formattedTime};
    } catch (e) {
        return cell;
    }
};

export const DateFormatter  = (cell: Cell, contextData: any): Cell => {
    try {
        let formattedTime = undefined
        if (contextData.compactColumnsEnabled && contextData.columns?.includes('timestamp')) {
            formattedTime = undefined
        } else {
            formattedTime = dateInstance.format(new Date(cell.value));
        }


        return { ...cell, value: formattedTime};
    } catch (e) {
        return cell;
    }
};

const formattersForCell = (col: any) => {
    const formatterCodes = col.formatters;

    if (!!formatterCodes) {
        return formatterCodes.map((code: string) => {
            switch (code) {
                case 'quantity':
                    return MarketQuantityFormatter;
                case 'badge':
                    return PriceFormatter;
                case 'counterparty':
                    return CounterpartyFormatter;
                case 'execPrice':
                    return ExecPriceFormatter;
                case 'time':
                    return TimestampFormatter;
                case 'date':
                    return DateFormatter;
                default:
                    return null;
            }
        }).filter((formatter: any) => formatter !== null);
    }
    return [];
};

export const formatCell = (cell: Cell, contextData: any): Cell => {
    const { column } = contextData;
    return formattersForCell(column).reduce(
    (resultCell: Cell, formatter: any) => {
        const formattedCell: Cell = formatter(resultCell, contextData);
        const preparedCell: Cell = { ...resultCell, ...formattedCell };
        return preparedCell;
    }, cell);
};
