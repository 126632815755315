import * as React from "react";

const EmptyRowsComponent = ({
                                       count,
                                       latestIndex,
                                       className,
                                       onContextMenu,
                                       onClickAction,
                                       layers,
                                       compactColumnsEnabled
                                   }: {
    count: number;
    latestIndex: number;
    className?: string;
    onContextMenu: any;
    onClickAction: any;
    layers: ((cellValuePresent: boolean) => JSX.Element[]) | null;
    compactColumnsEnabled: boolean;
}) => {
    if (count < 1) {
        return null;
    }
    let rows = [];
    for (let i = 1; i <= count; i++) {
        rows.push(
            <tr key={'empty-row-instrument' + i}>
                <td
                    className={`${className ? className : ''}${compactColumnsEnabled ? ' compact' : ''}`}
                    onContextMenu={e => onContextMenu(e, latestIndex - 1 + i)}
                    onClick={e => onClickAction(e, latestIndex + i, true)}
                > <label className="entry"></label>
                    {layers === null ? [] : layers(false)}
                </td>

            </tr>
        );
    }
    return <React.Fragment>{rows}</React.Fragment>;
};

function areEqualEmptyRows (prevProps: any, nextProps: any) {
    return prevProps.count === nextProps.count;
}

export const EmptyRows = React.memo(EmptyRowsComponent, areEqualEmptyRows);