import {
  combineEpics,
  ActionsObservable,
  StateObservable,
} from 'redux-observable'
import * as Rx from 'rxjs'
import { I18n } from 'react-redux-i18n'
import { State } from '../../main/reducers/rootReducer'
import { ActionTypes as Dashboard } from '../actions/dashboard'
import { ActionTypes as Dock } from '../../shared/dock/actions/dock'
import { DockType } from '../../shared/dock/models/dock'

import {
  move as tabsMove,
  remove as tabRemove,
} from '../../shared/ui/actions/tab'
import {
  move as marketsMove,
  remove as marketRemove,
} from '../../shared/ui/actions/market'
import {
  move as chartsMove,
  remove as chartRemove,
} from '../../shared/ui/actions/chart'
import {
  getTabsForDock,
  getMarketsForDock,
  getChartsForDock,
} from '../../shared/ui/selectors/ui'
import { inactive } from '../../shared/dock/actions/dock'
import { getDockById } from '../selectors/dashboard'
import { config } from '../../main/config'
import { filter, map, debounceTime, switchMap, mergeMap } from 'rxjs/operators'
import { ComponentType } from '../../shared/ui/models/component'

export const connectDocks: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter((action) => action.type === Dashboard.CONNECT_DOCKS),
    map((action) => action.payload),
    debounceTime(1),
    switchMap((payload) => {
      const { fromId, toId, type } = payload
      switch (type) {
        case DockType.Tabs: {
          const tabIds = getTabsForDock(state.value, fromId).map(
            (tab) => tab.id
          )
          return Rx.of(tabsMove(tabIds, fromId, toId))
        }

        case DockType.Market:
        case DockType.ProductMarket:
        case DockType.MarketIntraday: {
          const fromMarkets = getMarketsForDock(state.value, fromId)
          const fromItemIds = fromMarkets.map((market) => market.itemId)

          const toItemsId = getMarketsForDock(state.value, toId).map(
            (market) => market.itemId
          )

          let connect: boolean = true
          for (let i = 0; i < fromItemIds.length; i++) {
            if (toItemsId.indexOf(fromItemIds[i]) >= 0) {
              connect = false
              break
            }
          }
          const marketIds = fromMarkets.map((market) => market.id)
          const componentType =
            fromMarkets && fromMarkets.length > 0
              ? fromMarkets[0].type
              : ComponentType.Instrument
          if (
            connect &&
            fromItemIds.length + toItemsId.length <=
              config.maxConnectedMarkets &&
            toItemsId.length > 0
          ) {
            return Rx.of(marketsMove(marketIds, fromId, toId, componentType))
          }
          if (toItemsId.length === 0) {
            return Rx.of(marketRemove(fromMarkets[0].id, fromId, componentType))
          }
          const { position, id, size } = getDockById(state.value, fromId)
          return Rx.of(inactive(id, position, size))
        }

        case DockType.ChartMarket: {
          const chartIds = getChartsForDock(state.value, fromId).map(
            (chart) => chart.id
          )
          return Rx.of(chartsMove(chartIds, fromId, toId))
        }

        default:
          return Rx.empty()
      }
    })
  )
}

export const removeDock: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter((action) => action.type === Dock.REMOVE),
    map((action) => action.payload),
    mergeMap((payload) => {
      const tabs = getTabsForDock(state.value, payload)
      const markets = getMarketsForDock(state.value, payload)
      const charts = getChartsForDock(state.value, payload)

      if (tabs.length) {
        return tabs.map((tab) => {
          return tabRemove(tab.id, payload, tab.type)
        })
      }

      if (markets.length) {
        return markets.map((market) => {
          return marketRemove(market.id, payload, market.type)
        })
      }

      if (charts.length) {
        return charts.map((chart) => {
          return chartRemove(chart.id, payload, [chart.contractId])
        })
      }

      return Rx.empty()
    })
  )
}

export const dockSizeValidation: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(
      (action) =>
        [Dashboard.RESIZE, Dashboard.LOAD_SUCCESS, Dock.INACTIVE].indexOf(
          action.type
        ) > -1
    ),
    switchMap((action) => {
      let dockId = undefined
      if (action.type === Dock.INACTIVE) {
        dockId = action.payload.id
      }
      // const isDockBellowLimit = checkDockBellowLimit(state.value, dockId)
      // if (isDockBellowLimit) {
      //   return Rx.of(warn(I18n.t('dashboard.Dock size is below minimal size')))
      // }
      return Rx.empty()
    })
  )
}

export const dashboardEpic = combineEpics(
  connectDocks,
  removeDock,
  dockSizeValidation
)
