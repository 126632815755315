import * as React from 'react';
import { PriceAlarmFormData, PriceAlarmType, PriceAlarmFormMode } from '../models/priceAlarms';
import { Translate, I18n } from 'react-redux-i18n';
import { Validation, ValidationResult } from '../../shared/validation/model';
import ValidationService from '../../shared/validation/service';
import { formatPriceAlarmFormData, getLocalizationSettings } from '../../shared/utils/formatters';
import { NumberSpinner } from '../../shared/form/components/NumberSpinner';
import { IFormComponent } from '../../shared/form/components/FormComponent';
import DatePicker from 'react-datepicker';
import { Contract } from '../../orderbook/models/contracts';
import { MemoTranslate } from '../../shared/i18n/components/memoTranslate';
import ContractSelectComponent from "../containers/contractSelectContainer";
import {createMasterDataIdString} from "../../orderbook/selectors/contracts";

interface State {
  formData: PriceAlarmFormData & {contract: Contract};
  correlationIds: string[];
  success: boolean;
  disabled: boolean;
}

interface Props {
  handleSubmit: (
    form: PriceAlarmFormData,
    correlationId: string,
    validations: Validation
  ) => void;
  matchOnlyModeEnabled: boolean;
  data: PriceAlarmFormData;
  locale: string;
  disabled: boolean;
  formatFormData: (data: PriceAlarmFormData, priceDecimals: any) => PriceAlarmFormData;
  handleInvalidInput: (result: ValidationResult) => void;
  handleNumberChange: (event: any, decimals: number) => void;
  handleChange: (event: any) => void;
  handleSuccess: (message: string) => void;
  formClear: () => void;
  additionalValidations: Validation;
}

export default class PriceAlarmFormComponent extends React.Component<Props, State>  implements IFormComponent {
  validations: Validation;
  validationService: ValidationService;

  constructor(props: Props) {
    super(props);
    const formData = formatPriceAlarmFormData(props.data);
    this.state = {
      formData: {
        ...formData
      },
      success: false,
      correlationIds: [],
      disabled: false,
    };

    this.togglePriceType = this.togglePriceType.bind(this);
    this.toggleLessMore = this.toggleLessMore.bind(this);
    this.updateContract = this.updateContract.bind(this);

    this.validations = {
      translationPrefix: 'priceAlarm.form',
      rules: {
      }
    };

    this.validationService = new ValidationService();
  }

  setFormState = (formState: any) => {
    return this.setState(prevState => {
      return {
              ...prevState,
              formData: {...prevState.formData,  ...formState}
          };
      }
    );
  }

  getFormState = () => {
      return this.state.formData;
  }

  getValidations = () => {
    return this.validations;
  }

  handleSuccess = () => {
    return this.props.handleSuccess(I18n.t('priceAlarm.form.success' + this.state.formData.mode));
  }

  handleDateChange = (date: Date | undefined) => {

    this.setState(prevState => {
        return {
            ...prevState,
            formData:  {
              ...prevState.formData, expires: date
            }
        };
    });
  }

  togglePriceType(lstPrice: boolean) {
    this.setState(prevState => {
      return {
        ...prevState,
        formData: {
          ...prevState.formData,
          type: this._isLess(prevState) ?
            (lstPrice ? PriceAlarmType.LAST_PRICE_LESS : PriceAlarmType.ASK_PRICE) :
            (lstPrice ? PriceAlarmType.LAST_PRICE_MORE : PriceAlarmType.BID_PRICE)
        }
      };
    });
  }

  toggleLessMore(less: boolean) {
    this.setState(prevState => {
      return {
        ...prevState,
        formData: {
          ...prevState.formData,
          type: less ?
            (this._isLastPrice(prevState) ? PriceAlarmType.LAST_PRICE_LESS : PriceAlarmType.ASK_PRICE) :
            (this._isLastPrice(prevState) ? PriceAlarmType.LAST_PRICE_MORE : PriceAlarmType.BID_PRICE)
        }
      };
    });
  }

  _isLastPrice = (state: State) => {
    return state.formData.type === PriceAlarmType.LAST_PRICE_LESS || state.formData.type === PriceAlarmType.LAST_PRICE_MORE;
  }

  _isLess = (state: State) => {
    return state.formData.type === PriceAlarmType.LAST_PRICE_LESS || state.formData.type === PriceAlarmType.ASK_PRICE;
  }

  changeValue = (field: string, e: any) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        formData: {...prevState.formData, [field]: e.target.value}
      };
    });
  }

  updateContract(contract: Contract, venueName: string) {
    if (contract) {
      this.setState(prevState => {
        return {
          ...prevState,
          formData: {...prevState.formData, contractId: createMasterDataIdString(contract.id), contractName: contract.name, venue: venueName, nameWithVenue: contract.nameWithVenue, contract},
        };
      });
    }
  }

  render() {
    const { formData } = this.state;
    const dateFormat = getLocalizationSettings().dateFormat + ' HH:mm';
    const formVisible = !this.state.success;
    const contractExists = !!formData.contractId;
    const disabled = formData.mode === PriceAlarmFormMode.CANCEL || !contractExists;

    return (
      <React.Fragment>
        <form style={{ display: formVisible ? 'block' : 'none' }}>

          <ContractSelectComponent handleContractSelection={this.updateContract}/>

          <div className="form-group row">
              <div className="col-sm-4 text-left">
                <label>
                  <Translate value="contract.selected" />
                </label>
              </div>
              <div className="col-sm-8 text-left">
                <label>{`${formData.nameWithVenue}`}</label>
              </div>
          </div>
          <div className="form-group row">
            <div className="btn-group btn-group-toggle col-sm-6 text-left" data-toggle="buttons">
              <div className="form-group type-toggle">
                <label
                  className={this._isLastPrice(this.state) ? 'btn active btn-approval' : 'btn'}
                  onClick={() =>
                    !contractExists ? null : this.togglePriceType(true)
                  }
                >
                  <input type="radio" name="priceType" disabled={disabled} id="priceType0" autoComplete="off" />
                  <Translate value="priceAlarm.form.lastPrice" />
                </label>
                <label
                  className={!this._isLastPrice(this.state) ? 'btn active btn-approval' : 'btn'}
                  onClick={() =>
                    !contractExists ? null : this.togglePriceType(false)
                  }
                >
                  <input type="radio" name="priceType" disabled={disabled} id="priceType1" autoComplete="off" />
                  <Translate value="priceAlarm.form.order" />
                </label>

                <label
                  className={!this._isLess(this.state) ? 'btn active btn-approval' : 'btn'}
                  onClick={() =>
                    !contractExists ? null : this.toggleLessMore(false)
                  }
                >
                  <input type="radio" name="lessMore" disabled={disabled} id="lessMore1" autoComplete="off" />
                  <Translate value={this._isLastPrice(this.state) ? 'priceAlarm.form.lastPriceMore' : 'priceAlarm.form.bidPrice'} />
                </label>
                <label
                  className={this._isLess(this.state) ? 'btn active btn-approval' : 'btn'}
                  onClick={() =>
                    !contractExists ? null : this.toggleLessMore(true)
                  }
                >
                  <input type="radio" name="lessMore" disabled={disabled} id="lessMore0" autoComplete="off" />
                  <Translate value={this._isLastPrice(this.state) ? 'priceAlarm.form.lastPriceLess' : 'priceAlarm.form.askPrice'} />
                </label>
              </div>
            </div>
            <div className="col-sm-6 text-left">
              <label>
                <Translate value="priceAlarm.form.price" />&nbsp;
              </label>
              <NumberSpinner
                className="form-control"
                name="price"
                onChange={(event: any) =>
                  this.props.handleNumberChange(
                    event,
                    contractExists ? this.state.formData.contract.priceDecimals : 0
                  )
                }
                value={formData.price}
                size={5}
                disabled={disabled}
                step={contractExists ? this.state.formData.contract.priceDecimals : 1}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-4 text-left">
              <label>
                  <Translate value="priceAlarm.form.expires" />{' '}
              </label>
            </div>
            <div className="col-sm-6 text-left">
              <DatePicker
                  name="expires"
                  className="form-control"
                  selected={this.state.formData.expires}
                  onChange={(date: any) => this.handleDateChange(date)}
                  autoComplete="off"
                  showTimeSelect={true}
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat={dateFormat}
                  timeCaption={I18n.t('priceAlarm.form.time')}
                  disabled={disabled}
              />
            </div>
            <div className="col-sm-2 text-left">
              <div
                className={`gtc-button btn
                btn ${!formData.expires ? 'btn-approval' : 'btn-light'}`}
                onClick={e => {this.handleDateChange(undefined); e.stopPropagation(); }}
              >
                <MemoTranslate value="priceAlarm.form.gtc" />
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-4 text-left">
              <label>
                  <Translate value="priceAlarm.form.customer" />{' '}
              </label>
            </div>
            <div className="col-sm-8 text-left">
              <input name="customer" size={38} type="text" onChange={e => this.changeValue('customer', e)} defaultValue={this.state.formData.customer} />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-4 text-left">
              <label>
                  <Translate value="priceAlarm.form.description" />{' '}
              </label>
            </div>
            <div className="col-sm-8 text-left">
              <input name="description" size={38} type="text" onChange={e => this.changeValue('description', e)} value={formData.description} defaultValue={this.state.formData.description} />
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}