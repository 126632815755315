import * as React from 'react';
import * as Pickr from 'pickr-widget/dist/pickr.min.js';

export interface Props {
  name: string;
  color: string;
  onSave: (colorRGBA: string) => void;
}

export interface State {}
// TODO This component crashes the app when used with StrictMode. Need to debug what's wrong with it.
export class ColorPicker extends React.Component<Props> {
  private picker: any;
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    const { name, color, onSave } = this.props;

    this.picker = new Pickr({
      el: '#pickr-' + name,
      default: color,
      position: 'middle',
      defaultRepresentation: 'RGBA',
      parent: '#desing-settings',

      components: {
        preview: true,
        opacity: true,
        hue: true,

        interaction: {
          hex: true,
          rgba: true,
          hsva: true,
          input: true,
          clear: false,
          save: true
        }
      },

      onSave(hsva: any, instance: any) {
        if (hsva && typeof hsva.toRGBA === 'function') {
          if (color !== hsva.toRGBA().toString()) {
            onSave(hsva.toRGBA().toString());
          }
        }
      }
    });
  }

  componentWillReceiveProps(newProps: Props) {
    if (this.props.color !== newProps.color) {
      this.picker.setColor(newProps.color, false);
    }
  }

  render() {
    const { name } = this.props;
    return <div id={`pickr-${name}`} />;
  }
}