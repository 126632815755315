import React, { FC, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { IFavorite } from '../models/favorite'
import { ENTER_KEY } from '../../../commonConstants'

interface FavoriteFormProps {
  onSubmit: (selectedEntity: { name: string }) => void
}

const FavoriteForm: FC<FavoriteFormProps> = ({ onSubmit }) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [selectedEntity] = useState<IFavorite | null>(null)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setInputValue(event.target.value)
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()
    if (selectedEntity) {
      const namedEntity = { ...selectedEntity, name: inputValue }
      onSubmit(namedEntity)
      setInputValue('')
    }
  }

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === ENTER_KEY) {
      handleSubmit(event)
    }
  }

  const valid = inputValue.length > 0 && !!selectedEntity

  return (
    <form>
      <div className="form-group row">
        <div className="col">
          <input
            className="form-control"
            name="price"
            onChange={handleChange}
            onKeyUp={handleKeyUp}
            type="text"
            value={inputValue}
            required
            autoFocus
          />
        </div>
      </div>
      <div className="d-flex">
        <button
          className="btn btn-dark ml-auto"
          onClick={handleSubmit}
          disabled={!valid}
        >
          <Translate value="favorite.form.save" />
        </button>
      </div>
    </form>
  )
}

export default FavoriteForm
