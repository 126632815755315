import {useRef, useEffect, useState, useMemo, useCallback} from 'react';

export default function useAutoClose<T extends HTMLElement>(locked = false) {
  const wrapperRef = useRef<T | null>(null);
  const [expanded, setExpanded] = useState(false);

  const triggerVisibility = (visible: boolean) => {
    setExpanded(visible);
  };

  const _checkAndSetLock = () => {
    if (locked) {
      setExpanded(true);
    }
  };

  const handleClickOutside = useCallback((event) => {
    if (locked || !wrapperRef.current) return;
    if (
      expanded &&
      !wrapperRef.current.contains(event.target)
    ) {
      setExpanded(false);
    } else if (
      !expanded &&
      wrapperRef.current.contains(event.target)
    ) {
      setExpanded(true);
    }
  }, [locked, expanded]);

  useEffect(() => {
    _checkAndSetLock();
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [locked, handleClickOutside]);

  return useMemo(() => ({ ref: wrapperRef, expanded, triggerVisibility }), [expanded]);
}