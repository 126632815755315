import * as PasswordActions from '../actions/passwordChange';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { ChangePasswordRequest, ChangePasswordResponse } from '../models/passwordChange';
import * as Rx from 'rxjs';
import PasswordService from '../services/passwordChange';
import { State } from '../../../trade/reducers/trade';
import { ActionTypes as Authentication } from '../../../authentication/actions/authentication';
import { filter, map, switchMap, catchError, takeUntil } from 'rxjs/operators';

const passwordService = new PasswordService();

export const resetPassword: any = (actions$: ActionsObservable<any>) => {
    return actions$.pipe(
        filter(action => action.type === Authentication.AUTHENTICATION_SUCCESS),
        map(action => action.payload),
        switchMap((action: any) => {
            if (action.passwordExpired) {
                return Rx.of(PasswordActions.resetPassword());
            }
            return Rx.empty();
        })
    );
};

export const changePassword: any = (actions$: ActionsObservable<any>) => {
    return actions$.pipe(
        filter(action => action.type === PasswordActions.ActionTypes.CHANGE_PASSWORD),
        map(action => action.payload),
        switchMap((action: ChangePasswordRequest) => {
            passwordService
                .changePassword(action);
            return Rx.empty();
        })
    );
};

export const passwordChangeListener: any = (
    actions$: ActionsObservable<any>,
    state: StateObservable<State>
) => {
    return actions$.pipe(
        filter(action => action.type === PasswordActions.ActionTypes.CHANGE_PASSWORD),
        switchMap(() => {
            return passwordService
                .subscribePasswordChanges().pipe(
                    map((content: ChangePasswordResponse) => {
                        if (content.passwordChanged) {
                            return PasswordActions.passwordChangeSuccess();
                        }
                        return PasswordActions.passwordChangeFailed(content.errorMessage);
                    }),
                    catchError((error: any) => {
                        return Rx.of(PasswordActions.passwordChangeFailed(error));
                    }),
                    takeUntil(actions$.ofType(Authentication.AUTHENTICATION_LOGOUT))
                );
        })
    );
};

export const passwordEpics = combineEpics(
    changePassword,
    passwordChangeListener,
    resetPassword
);