import React, { createRef, FC } from 'react'
import * as ReactDOM from 'react-dom';
import { ITableRow, ITableColumn } from '../../ui/models/table';
import { Translate } from 'react-redux-i18n';
import { Popover } from 'react-bootstrap';
import { config } from '../../../main/config';
import { findRootElement } from '../../utils/helper/eventUtils';

// Define max items per one scroll
const tableMaxItems = config.tablePageSize;

interface MeetTableBodyRowColumnProps {
  index: any;
  text: any;
  name: any;
  onClick: any;
  onContextMenu: any;
}

const cellsAreEqual = (prevProps, nextProps) => {
  return prevProps.index === nextProps.index &&
    prevProps.text === nextProps.text &&
    prevProps.name === nextProps.name;
}

const MeetTableBodyRowColumnComponent: FC<MeetTableBodyRowColumnProps> = (props) => {
  const { index, text, name, onClick, onContextMenu } = props;
  const indexString = '' + index;

  return (
    <td id={`data-name-${name}-${indexString}`} className={`text-nowrap ${name} row-${indexString.replace(/\//g, '-')}`} colSpan={1} onContextMenu={e => { onContextMenu(e); }} onClick={e => { onClick(e); }}>
      <span title={typeof text === 'string' ? text : ''}>{text}</span>
    </td>
  );
};

export const MeetTableBodyRowColumn =  React.memo(MeetTableBodyRowColumnComponent, cellsAreEqual);

interface MeetTableBodyRowProps {
  index: any;
  row: any;
  isSelected: boolean;
  dataFormatters: any;
  onRowSelect: any;
  type: any;
  cols: any[];
  onClickCell: any;
  onContextCell: any;
  localizeOptions: any;
  allCols: any[];
  dockTarget: any;
  updatePlacement: any;
}

interface MeetTableBodyRowState {

}

export class MeetTableBodyRowComponent extends React.Component<MeetTableBodyRowProps,
  MeetTableBodyRowState> {

shouldComponentUpdate(nextProps: MeetTableBodyRowProps, nestState: MeetTableBodyRowState) {
  return !this.rowsAreEqual(this.props, nextProps);
}

  render() {
    const {
      index,
      row,
      isSelected,
      dataFormatters,
      onRowSelect,
      type,
      cols,
      onClickCell,
      onContextCell,
      localizeOptions,
      allCols,
      dockTarget,
      updatePlacement
    } = this.props;

    const columns = cols
      .filter((k: ITableColumn) => k.name !== '$index')
      .map((col: ITableColumn) => {
        const key = col.name;
        return (
          <MeetTableBodyRowColumn
              key={key + row.$index}
              name={col.name}
              text={
                key in dataFormatters
                  ? dataFormatters[key](row[key], {
                    entry: row,
                    tableType: type,
                    localizeOptions
                  })
                  : row[key]
              }
              onClick={(e: any) => {
                e.preventDefault();
                return onClickCell(row.id, key, row, e);
              }}
              onContextMenu={(e: any) => {
                e.preventDefault();
                return onContextCell(row.id, key, row, e);
              }}
              index={row.$index}
          />);
      });

    const visibleColumns = allCols.filter((k: ITableColumn) => k.name !== '$index' && k.name !== 'contractTitle' && k.name !== 'timestamp');
    const tooltip = visibleColumns
    .filter((col: any) => !col.hiddenInTooltip)
    .map((col: any) => {
      const key = col.name;
      const value = key in dataFormatters
        ? dataFormatters[key](row[key], {
          entry: row,
          tableType: type,
          localizeOptions
        })
        : row[key];
      return (
        <tr className="meet-tooltip-element" key={`${key}_tooltip`}>
          <td className={`meet-tooltip-name ${col.name} td-${key}`} >
            {<Translate
              value={col.title}
            />}
          </td>
          <td className={`meet-tooltip-value ${col.name}`}>{value}</td>
        </tr>);
    });
    const tooltipCompo =  (
      <Popover id="meet-tooltip" className="show">
        {<table>
          <tbody>{tooltip}</tbody>
        </table>}
      </Popover>
    );

    return (
      <tr
        onClick={(e) => {
          return onRowSelect(index, e, row);
        }}
        className={`${isSelected ? 'table-active' : ''}`}
      >
        {columns}
      </tr>
    );
  }

  rowsAreEqual(prevProps: any, nextProps: any) {
    const prevKeys = Object.keys(prevProps);
    const nextKeys = Object.keys(nextProps);

    if (prevKeys.length === nextKeys.length) {
      for (let i = 0; i < prevKeys.length; i++) {
        if (prevProps[prevKeys[i]] !== nextProps[prevKeys[i]]) {
          return false;
        }
      }
      return true;
    }
    return false;
  }
}

export const MeetTableBodyRow = MeetTableBodyRowComponent;

interface MeetTableBodyProps {
  rows: ITableRow[];
  cols: ITableColumn[];
  allCols: ITableColumn[];
  columnsCount: number;
  selectedRows: number[];
  dataFormatters: { [key: string]: (value: any, contextData: any) => any };
  type: string;
  popoverComponent: JSX.Element;
  localizeOptions: any;
  page: any;
  firstTableBody: boolean;
  isPinningTable: boolean;
  onSelectRow: (rowIndex: number, e: any, row: ITableRow) => void;
  onClickCell: (id: string, key: string, row: ITableRow, event: any) => void;
  onContextCell: (id: string, key: string, row: ITableRow, event: any) => void;
  paginatedScroll: (page: number) => void;
}

interface MeetTableBodyState {
  rows: ITableRow[];
  page: number;
  dockTarget: any;
}

export class MeetTableBodyCompoment extends React.Component<
  MeetTableBodyProps,
  MeetTableBodyState
> {
  cmp: React.RefObject<any> = createRef();
  constructor(props: MeetTableBodyProps) {
    super(props);

    this.state = {
      rows: [],
      page: 1,
      dockTarget: null
    };
    this.onPaginatedSearch = this.onPaginatedSearch.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.updatePlacement = this.updatePlacement.bind(this);
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      dockTarget: findRootElement(this.cmp.current, 'scrollable')
    });
  }

  componentWillMount() {
    const { rows } = this.props;
    const { page } = this.state;

    this.setState({
      ...this.state,
      rows: rows.slice(0, page * tableMaxItems)
    });
  }

  componentWillReceiveProps(newProps: MeetTableBodyProps) {
    let { page } = this.state;
    this.setState({
      ...this.state,
      page: newProps.page,
      rows: newProps.rows.slice(0, page * tableMaxItems),
      dockTarget: findRootElement(this.cmp.current, 'scrollable')
    });
  }

  onScroll(paginationIndex: number) {
    if (paginationIndex > -1) {
      this.onPaginatedSearch(paginationIndex);
    } else {
      this.setState({
        ...this.state,
        dockTarget: findRootElement(this.cmp.current, 'scrollable')
      });
    }
  }

  onPaginatedSearch(paginationIndex: any) {
    const { rows } = this.props;
    this.props.paginatedScroll(paginationIndex === 0 ? config.tablePageSize : rows.length);
  }

  updatePlacement() {
    this.setState({
      ...this.state,
      dockTarget: findRootElement(this.cmp.current, 'scrollable')
    });
  }

  render() {
    const {
      dataFormatters,
      selectedRows,
      type,
      onSelectRow,
      cols,
      onClickCell,
      onContextCell,
      localizeOptions,
      isPinningTable,
      allCols
    } = this.props;
    const { rows } = this.state;

    return (
      <tbody key={`tbody`} ref={this.cmp} className={`scrollable ${isPinningTable ? 'pinnedTable' : 'unpinnedTable'}`}>
        {rows.map((row, $index) => {
          return (
              <MeetTableBodyRow
                key={`row${$index}`}
                cols={cols}
                row={row}
                index={row.$index}
                dataFormatters={dataFormatters}
                isSelected={selectedRows.indexOf(row.$index) !== -1}
                onRowSelect={onSelectRow}
                type={type}
                onClickCell={onClickCell}
                onContextCell={onContextCell}
                localizeOptions={localizeOptions}
                allCols={allCols}
                dockTarget={this.state.dockTarget}
                updatePlacement={this.updatePlacement}
              />
          );
        })}
      </tbody>
    );
  }
}

const debounce = (func: Function, wait: number, immediate?: boolean) => {
  var timeout: any;
  return function(this: any) {
    const context: any = this;
    const args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
};

const withInfiniteScroll = (condition: any) => (Component: any) =>
  class WithInfiniteScroll extends React.Component<any, any> {
    private node: Element | null;
    private cmp: any = createRef();
    constructor(props: any) {
      super(props);

      this._findScrollableElement = this._findScrollableElement.bind(this);
      this.onScroll = this.onScroll.bind(this);
    }

    componentDidMount() {
      this.node = this._findScrollableElement(this.cmp.current);
      this.checkUnusedSpace(this.node);
      if (this.node) {
        this.node.addEventListener(
          'wheel',
          debounce(this.onScroll, 150),
          false
        );
        this.node.addEventListener(
          'scroll',
          debounce(this.onScroll, 150),
          false
        );
      }
    }

    checkUnusedSpace = (nodeElement: any) => {
      if (!!nodeElement && nodeElement.scrollHeight === nodeElement.offsetHeight) {
        if (!!this.cmp) {
          this.cmp.onPaginatedSearch();
        }
      }
    }

    componentWillUnmount() {
      if (this.node) {
        this.node.removeEventListener('wheel', this.onScroll, false);
        this.node.removeEventListener('scroll', this.onScroll, false);
      }
    }

    onScroll(e: any) {
      return (
        this.cmp &&
        this.cmp.onScroll(condition(this.props.rows, this.cmp.state.page, this.node))
      );
    }

    _findScrollableElement(ele: Element | Text | null): Element | null {
      if (!ele) {
        return null;
      }

      if (ele instanceof Element && ele.className.search('scrollable') !== -1) {
        return ele;
      }
      return this._findScrollableElement(ele.parentElement);
    }

    render() {
      return <Component ref={this.cmp} {...this.props} />;
    }
  };

const infiniteScrollCondition = (rows: any[], page: number, node: any) => {
  const scrolled = node.scrollTop + node.offsetHeight;
  const height = node.scrollHeight;
  if (scrolled >= height) {
    return rows.length;
  } else if (node.scrollTop === 0) {
    return 0;
  }
  return -1;
};

export const MeetTableBody = withInfiniteScroll(infiniteScrollCondition)(
  MeetTableBodyCompoment
);
