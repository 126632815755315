import React from "react";
import Dragger from "js/shared/dragger/container/Dragger";
import { IFavorite } from "../models/favorite";
import { DockType } from "js/shared/dock/models/dock";
import { MemoTranslate } from "js/shared/i18n/components/memoTranslate";
import { config } from 'js/main/config';
import { isBlocked } from "../utils";

export const FavoriteList: React.FC<{
  favorites: IFavorite[];
  onSelect: (e: any, favorite: IFavorite) => void;
  activeMarketInstruments?: string[];
  activeChartInstruments?: string[];
  activeMarketProducts?: string[];
  openMore: () => void;
}> = ({ favorites, onSelect, activeMarketInstruments = [], activeChartInstruments = [], activeMarketProducts = [], openMore }) => {
  const favoritesList = favorites
    .filter(favorite => favorite.isVisible)
    .map((favorite: IFavorite) => (
      <Dragger
        tag="li"
        key={favorite.id}
        className={'dragger-item' + (isBlocked(favorite, activeMarketInstruments, activeChartInstruments, activeMarketProducts) ? ' blocked' : '')}
        onDragComplete={(e: any) => onSelect(e, favorite)}
      >
        <div className="left-container">
          {favorite.entityType === DockType.Market || favorite.entityType === DockType.MarketIntraday || favorite.entityType === DockType.ProductMarket ? (
            <span className="oi oi-list" />
          ) : (
            <span className="oi oi-graph" />
          )}
          <label className="favorite-name">{favorite.name}</label>
        </div>
      </Dragger>
    )).slice(0, config.sidebarFavoritesListLimit);

  return (
    <div className="sidebar__favorite-views__list">
      <ul data-test="favorite-markets-list">
        {favoritesList}
        <li className="more" key="favorites-more" onClick={openMore}>
          <MemoTranslate value="sidebar.more" />
        </li>
      </ul>
    </div>
  );
};