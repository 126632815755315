import { FC, MutableRefObject, useEffect, useRef, useState } from 'react'
import { v1 } from "uuid";

import { correctTooltipPosition } from '../helper/helper'

import { BuySellFormatter, DateTimeFormatter } from '../../table/tableFormatters'

import { MemoTranslate } from "../../../../i18n/components/memoTranslate";
import { formatNumber, getLocalizationSettings } from "../../../../utils/formatters";

import {createMasterDataIdString, getOrderbookContracts} from "../../../../../orderbook/selectors/contracts";
import orderBookStore from "../../../../../orderbook/store/orderbooks";

import { Price } from "../../../../../orderbook/models/orderbooks";
import React from 'react';

interface IImpliedPopoverComponentGridProps {
    data: {
        price: Price,
        x: number,
        y: number,
    };
    parent: MutableRefObject<HTMLDivElement> | null;
}

const ImpliedPopoverComponentGrid: FC<IImpliedPopoverComponentGridProps> = ({ data, parent }) => {
    const { price, x, y } = data;
    const [style, setStyle] = useState({});
    const [uniqueId, setUniqueId] = useState(v1());
    const [contracts, setContracts] = useState(getOrderbookContracts(orderBookStore.getState()));
    const element = useRef(null)
    const [show, setShow] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => setShow(true), 100);
        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        if (parent?.current && element.current) {
            const position: any = {
                x,
                y,
            };
            const { diffX, diffY } = correctTooltipPosition(element.current, position);

            const positionX = (x + (diffX < 0 ? diffX : 0));
            const positionY = (y + (diffY < 0 ? diffY : 0));
            setStyle({
                left: `${positionX}px`,
                position: "fixed",
                top: `${positionY}px`,
            });
        } else {
            setStyle({
                left: -1000 // create the element but don't display it
            })
        }
    }, [x, y, element.current, show]);

    const createLocalizeOptions = (digits: number) => ({
        ...getLocalizationSettings(),
        maximumFractionDigits: digits,
        minimumFractionDigits: digits,
        style: "decimal",
        useGrouping: true,
    });

    const priceTableRow = (prc: Price, level: number, $index: number) => {
        if (!prc?.contractId) {
            return <></>;
        }

        const contract = contracts ? contracts[createMasterDataIdString(prc.contractId)] : undefined;
        const quantityLocalizeOptions = createLocalizeOptions(contract?.qtyDecimals);
        const priceLocalizeOptions = createLocalizeOptions(contract?.priceDecimals);

        return contract ? (
          <tr key={`price-impl-${level}-${$index}`} className={`implied-table${prc.implied ? ' implied' : ''}`}>
              <td key={`contract-${level}-${$index}`}
                  className="implied-contract"
                  style={{ paddingLeft: (level * 10) + 'px' }}>
                  {contract ? contract.nameWithVenue : prc.contractId?.venue + ' ' + prc.contractId?.id
                  }</td>
              <td key={`order-id-${level}-${$index}`}
                  className="implied-orderId"
                  style={{ textAlign: 'right' }}>
                  {prc.orderId}</td>
              <td key={`bs-${level}-${$index}`} style={{ textAlign: 'right' }}>
                  {BuySellFormatter({ value: prc.buy })}
              </td>
              <td key={`prc-${level}-${$index}`} style={{ textAlign: 'right' }}>
                  {formatNumber(prc.price, priceLocalizeOptions)}
              </td>
              <td key={`qty-${level}-${$index}`}
                  style={{ textAlign: 'right' }}>
                  {formatNumber(prc.quantity, quantityLocalizeOptions)}
              </td>
              <td key={`aon-${level}-${$index}`}>{prc.aon ? 'AON' : ' '}</td>
              <td key={`time-${level}-${$index}`}>{DateTimeFormatter({ value: prc.timestamp })}</td>
          </tr>
        ) : null;
    };

    const recursivelyRenderImpliedSources = (src: Price, level: number) => {
        return src?.impliedSources
          ? (<React.Fragment key={`implied-level-${level}-wrapper`}>{src.impliedSources.map((prc, $index) => {
              return (<React.Fragment key={`implied-level-${level}-${$index}`}>{priceTableRow(prc, level, $index)}
                            {prc.impliedSources ? recursivelyRenderImpliedSources(prc, level + 1) : null}
                        </React.Fragment>
                    );
                })
                }</React.Fragment>)
            : null;
    }

    return show &&  (
        <div className="implied-tooltip" key={uniqueId} style={style} id={`ìmplied-tooltip-${uniqueId}`} ref={element}>
            <h3 key="implied-header"><MemoTranslate value="market.priceDetails" />
            </h3>
            <table key="implied-table"><thead key="implied-thead" className={`implied-table header`}>
            <tr key="implied-table-tr">
                <th key="headerContract"><MemoTranslate
                  value="columns.impliedPriceTable.contract" /></th>
                <th key="headerOrderId"><MemoTranslate
                  value="columns.impliedPriceTable.orderId" /></th>
                <th key="headerBS">
                    <MemoTranslate value="columns.impliedPriceTable.buySell" />
                </th>
                <th key="headerPrc">
                    <MemoTranslate value="columns.impliedPriceTable.price" />
                </th>
                <th key="headerQty">
                    <MemoTranslate value="columns.impliedPriceTable.quantity" />
                </th>
                <th key="headerAON">
                    <MemoTranslate value="columns.impliedPriceTable.aon" />
                </th>
                <th key="headerTime">
                    <MemoTranslate value="columns.impliedPriceTable.time" />
                </th>
            </tr>
            </thead>
                <tbody>
                {priceTableRow(price, 0, -1)}
                {recursivelyRenderImpliedSources(price, 1)}
                </tbody>
            </table>
        </div>
    );
};

export default ImpliedPopoverComponentGrid;
