import * as React from 'react';
import { ValidationResult, Validation } from '../../validation/model';
import ValidationService from '../../validation/service';
import { v1 } from 'uuid';
import Messenger from '../../messenger/components/messenger';

interface State {
    success: boolean;
    correlationIds: string[];
 }
 
interface Props {
  actionHandler: (options: any) => void;
  handleSubmit: (form: any, correlationId: string, validation: Validation) => void;
  handleClear: () => void; 
  form: any;
}

export interface IFormComponent {
  handleSuccess: () => void;
  getValidations: () => Validation;
  setFormState: (formState: any) => void;
  getFormState: (formState: any) => any;
}
 
export const formComponent = (Component: any) => 
  class FormComponent extends React.Component<Props, State> {
    validationService: ValidationService;
    private cmp: any;
    private cmpProps: any;
    constructor(props: Props) {
      super(props);
      
      this.cmpProps = {...props};
      
      this.state = {
        success: false,
        correlationIds: []
      };
      this.validationService = new ValidationService();
      this.handleNumberChange = this.handleNumberChange.bind(this);
      this.actionCallback = this.actionCallback.bind(this);
      this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
      this.props.actionHandler({ actionCallback: this.actionCallback });
    }

    actionCallback(action: string) {
      switch (action) {
        case 'submit': {
          this.submitForm();
          break;
        }
        case 'close': {
          this.clearForm();
          break;
        }
        default: {
          // do nothing
        }
      }
    }

    submitForm = () => {
      const newCorrelationId = v1();
      this.props.handleSubmit(this.cmp.getFormState(), newCorrelationId, this.cmp.getValidations());
       
      this.setState(prevState => {
        return {
          ...prevState,
          correlationIds: [...prevState.correlationIds, newCorrelationId]
        };
      });
    }

    clearForm = () => {
      this.cmp.setState((prevState: any) => {
        return {
          ...prevState,
          correlationIds: [],
          success: false,
          form: {},
          formData: {}
        };
      });
      this.props.actionHandler({ state: { error: false, success: false } });
      this.props.handleClear();
    }

    setSuccess = (success: boolean) => {
      this.setState(prevState => {
        return {
          ...prevState,
          success: success
        };
      });
    }

    handleChange(event: any) {
      event.preventDefault();
      const target = event.target;
      let value = target.value;
      const name = target.name;
  
      let validationValue: ValidationResult = this.validationService.format(
        this.cmp.getValidations().rules[name],
        value
      );
      if (!validationValue.valid) {
        this.cmp.handleInvalidInput(validationValue);
      }
      value = validationValue.value;
  
      this.cmp.setFormState({
          [name]: value
        }
      );
    }

    handleNumberChange(event: any, decimals: number) {
      event.preventDefault();
      const target = event.target;
      let value = target.value;
      const name = target.name;
      let validationValue: ValidationResult = this.validationService.formatWithDecimals(
        this.cmp.getValidations().rules[name],
        value,
        decimals
      );
      if (!validationValue.valid && !validationValue.value) {
        this.cmp.props.handleInvalidInput(validationValue);
      }
      value = validationValue.value;
  
      this.cmp.setFormState(
        { [name]: value }
      );
    }

    render() {

      const newProps = {
        ...this.props,
        handleNumberChange: this.handleNumberChange,
        handleChange: this.handleChange
      };
      return (
        <>
          <Messenger
            messageIds={this.state.correlationIds}
            onSuccessMessages={(messages: any) => {
              this.setSuccess(true);
              this.props.actionHandler({
                state: {
                  success: true
                }
              });
              this.cmp.handleSuccess();
              
            }}
            onErrorMessages={(messages: any) => {
              this.props.actionHandler({ state: { error: true } });
            }}
          />
          <Component ref={(ref: any) => (this.cmp = ref)} {...newProps} />
        </>
        );
    }
  };