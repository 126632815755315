import * as React from 'react';
import {createPortal} from "react-dom";
import { I18n } from 'react-redux-i18n';

interface MarketFilterIconProps {
  children: React.ReactNode;
  onVisible: (isVisible: boolean) => void;
  visible: boolean;
  position?: {x: number; y: number};
}

function PopupComponent(props: MarketFilterIconProps) {
    const {children, onVisible, visible, position} = props;
    
    const style = position ? {left: position.x + 'px', top: position.y + 'px', transform: 'translate(-60%, 0%)'} : {};
    return (
        <div >
            {visible && <div className='backdrop-popover' onClick={() => onVisible(false)}/>}
            {createPortal(
                <div
                    className={`market-popup ${visible? 'visible': ''} sidebar__topic`} style={style}
                >
                    <div className="popover-close"
                    > 
                        <button
                            onClick={() => onVisible(false)}
                            data-dismiss="modal"
                            aria-label={I18n.t('modal.close')}
                            title={I18n.t('modal.close')}
                        >
                            <i className="oi oi-x" />
                        </button>
                    </div>
                    {children}
                </div>, document.body)}
        </div>
    );
}
export const Popup = PopupComponent;
