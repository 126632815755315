import '../../shared/utils/wdyr';
import { connect } from 'react-redux';
import { State } from '../reducers/rootReducer';
import MainContainer from '../components/MainContainer';
import {getAuthorizedStatus, getLoginData} from '../../authentication/selectors/authetication';
import { getLocale } from '../../shared/i18n/selectors/translations';
import {
    getConnectedStatus,
    getSSOAccessToken,
    getTakeoverConfirmDisplayed
} from '../../authentication/selectors/connection';
import { sendLogToServer } from '../../shared/logger/actions/logger';

import {useAuth, useLoginWithRedirect} from '@frontegg/react';
import * as React from 'react';
import {useEffect} from 'react';
import {
    applicationComplete,
    ssoConnectionStart,
    ssoSaveAccessToken
} from '../../authentication/actions/connection';
import {historyReplace} from '../history';
import connectionStore from '../../authentication/store/connection';
import {StompClient, StompService} from '../services/stompService';
import Login from '../../authentication/containers/Login';
import { sendToServer, load as viewLoad } from '../../dashboard/actions/profile';
import { getActiveViewId } from '../../dashboard/selectors/profile';
import { logout } from 'js/authentication/actions/authentication';

export const withAuth = (Component: any) => {
    return (props: any) => {
        const  { user, isAuthenticated } = useAuth();
        const loginWithRedirect = useLoginWithRedirect();
        const stompService = new StompService(StompClient);

        useEffect(() => {
          const savedToken = getSSOAccessToken(connectionStore.getState());
          if (props.loginData) {
              // relogin (page refreshed - previous login data is available)
              connectionStore.dispatch(ssoSaveAccessToken(props.loginData.ssoToken?.accessToken));
              props.ssoConnectionStart(props.loginData);
          } else if (!isAuthenticated) {
            // previous session has to be stopped if the application logs in automatically after token expiration
            stompService.httpLogout();
            loginWithRedirect();
          } else if (!savedToken) {
            // after redirect from frontegg, when token is not saved yet
            historyReplace('/');
            props.ssoConnectionStart({
              username: user.email,
              permissions: user.permissions,
              ssoToken: {
                  accessToken: user.accessToken,
                  expiresIn: user.expiresIn,
                  refreshToken: user.refreshToken
              }
            });
          }
          if (user) {
              connectionStore.dispatch(ssoSaveAccessToken(user.accessToken));
          }
        }, [isAuthenticated, loginWithRedirect, user]);
        if (props.showTakeoverConfirm) {
            return <Login />
        }
        return <Component isFronteggAuthenticated={isAuthenticated} user={user} {...props} />;
    };
};

const mapStateToProps = (state: State) => ({
    isAuthorized: getAuthorizedStatus(state),
    isConnected: getConnectedStatus(state),
    locale: getLocale(state),
    showTakeoverConfirm: getTakeoverConfirmDisplayed(state),
    loginData: getLoginData(state),
    activeViewId: getActiveViewId(state)
});

const mapDispatchToProps = {
    ssoConnectionStart: ssoConnectionStart,
    sendLogToServer: sendLogToServer,
    viewLoad: viewLoad,
    applicationComplete: applicationComplete,
    sendProfileToServer: sendToServer,
    logout: logout
};

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(MainContainer));

