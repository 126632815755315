/* eslint-disable no-var */
import * as React from 'react'

import { Provider } from 'react-redux'
import { store } from './js/main/store/store'
import routes from './js/main/routes'
import { applicationInit } from './js/authentication/actions/connection'
import serviceWorker from './js/firebase/serviceWorker'
import { FronteggProvider } from '@frontegg/react'
import { createRoot } from 'react-dom/client'
import { LicenseManager } from 'ag-grid-enterprise'

LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-055576}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{E-Star_Trading_GmbH}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{E-Star_Webtrader}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{E-Star_Webtrader}_need_to_be_licensed___{E-Star_Webtrader}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{25_February_2025}____[v3]_[01]_MTc0MDQ0MTYwMDAwMA==26746bcc728e5cc40493670a43ff4c17")

declare var redirectUrl: string
declare var ssoClientId: string
declare var ssoEnabled: boolean

store.dispatch(applicationInit())
serviceWorker()
const container = document.getElementById('root')
const root = createRoot(container)

store.subscribe(() => {
  if (store.getState().connection?.appState !== 'started') {
    const isFrontegg =
      typeof redirectUrl !== 'undefined' &&
      typeof ssoClientId !== 'undefined' &&
      typeof ssoEnabled !== 'undefined' &&
      ssoEnabled === true
    if (isFrontegg) {
      const contextOptions = {
        baseUrl: redirectUrl,
        clientId: ssoClientId,
      }
      const authOptions = {
        keepSessionAlive: true,
      }
      root.render(
        <Provider store={store}>
          <FronteggProvider
            hostedLoginBox={true}
            contextOptions={contextOptions}
            authOptions={authOptions}
          >
            {routes(true)}
          </FronteggProvider>
        </Provider>
      )
    } else {
      root.render(<Provider store={store}>{routes(false)}</Provider>)
    }
  }
})
