import { AgGridReact } from "ag-grid-react"
import { v1 } from "uuid";

import {
    FC, memo,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { I18n } from "react-redux-i18n";
import { RecentActionsComponent } from '../../../../../dashboard/components/recentActions'
import { createMasterDataIdString } from '../../../../../orderbook/selectors/contracts'
import { Debouncer } from '../../../../utils/components/debounce'
import { ITableColumn } from '../../../models/table'

import ImpliedPopoverComponentGrid from "./uiImpliedPopoverGrid";

import {
    COLUMN_WIDTH_RESIZING,
    createColumnIdentifier,
    createModel,
    generateColumns,
    generateGroupColumn, getColumnSizes, reorderColumns,
} from '../helper/helper'

import { ComponentType } from "../../../models/component";
import { IMarket, MarketActionType } from '../../../models/market'

import { DashboardComponent } from "../../../../../dashboard/models/dashboard";
import { MasterDataId } from "../../../../../main/models/application";

import { DockType } from "../../../../dock/models/dock";

import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../styles/ag-grid-customization.css"

export interface IPrice {
    orders: { [key: string]: { [group: string]: any[] } };
    trades: { [key: string]: any[] };
}

interface IMarketInstrumentTableGridProps {
    expiryKeys: string[];
    expiries: { [key: string]: any };
    presetDepths?: { [expiryCode: string]: number };
    onExpiryClick: (expiry: any, expanded: boolean) => void;
    setOrderbookDepth: (depth: number, code: string) => void;
    onOpenOrder?: (formData: any) => void;
    onRowDragEndHandler?: (prevRowNode: any, nextRowNode: any) => void;
    onCreateChart?: (e: any, component: DashboardComponent) => void;
    onCreateTable?: (e: any, components: RecentActionsComponent[]) => void;
    handleNewPriceAlarm?: (contractId: string, group: string, _price: number) => void;
    onMarketRemove: (marketId: string) => void;
    colors?: { [key: string]: string };
    anonymizeMarketDataEnabled?: boolean;
    settings?: { [key: string]: any };
    instrumentToMarket?: Map<string, IMarket & {version: string }>;
    adjustedMatrices?: any;
    adjustedPrices?: { [key: string]: { trades: any, orders: any, version: string }};
    onGroupColumnMoved: (itemIds: MasterDataId[]) => void;
    onColumnMoved: (id: string, columns: ITableColumn[]) => void;
    onColumnResized: (id: string, columnWidths: {[colId: string]: number}) => void;
    isTableBorderActive: boolean;
    dockVisible: boolean;
}

const UiMarketInstrumentTableGrid: FC<IMarketInstrumentTableGridProps> = ({
    expiries,
    expiryKeys,
    settings,
    presetDepths,
    colors,
    onExpiryClick,
    onCreateChart,
    onCreateTable,
    setOrderbookDepth,
    onRowDragEndHandler,
    onMarketRemove,
    handleNewPriceAlarm,
    instrumentToMarket,
    adjustedMatrices,
    adjustedPrices,
    anonymizeMarketDataEnabled,
    onColumnMoved,
    onGroupColumnMoved,
    onColumnResized,
    isTableBorderActive,
}) => {
    const [rows, setRows] = useState([]);
    const [popover, setPopover] = useState(null);
    const [columns, setColumns] = useState([]);
    const [groupColumn, setGroupColumn] = useState({});

    const gridRef = useRef<AgGridReact>(null);
    const gridParent = useRef<HTMLDivElement>(null);
    const debouncer = useRef(new Debouncer())
    const clickDebouncer = useRef(new Debouncer())
    const dragFromIndex = useRef(undefined)
    const [columnSizes, setColumnSizes] = useState({ });
    const [instrumentTables, setInstrumentTables] = useState([])
    const [structureDeps, setStructureDeps] = useState({ marketVersions: '', compactColumnsEnabled: undefined, instrumentIds: []  })

    const compactColumnsEnabled = settings.compactColumns.value
    const getRowId = useMemo(() => (params: any) => { return params.data?.id ?? v1()}, []);

    const getDataPath = useMemo(() => {
        return (data) => data.orgHierarchy;
    }, []);

    const gridOptions = useMemo(() => {
        return {
          onColumnMoved: _columnMoved(instrumentToMarket),
          onColumnResized: _columnResized(instrumentToMarket),
          onColumnPinned: (event) => {},
          cellFlashDuration: 500,
          cellFadeDuration: 0,
          onDragStarted: _dragStarted,
          suppressDragLeaveHidesColumns: true
        }
    }, [])
    const defaultColDef = useMemo(() => {
        return {
            resizable: true,
            sortable: false,
            lockPinned: true,
        }
    }, []);

    const handleNewGraph = (e: any,  row: any, contractId: string, instrumentId, marketTitle) => {

        onCreateChart(e, {
            args: [{id: instrumentId}, row.periodType, contractId, []],
            dockType: DockType.ChartMarket,
            name: marketTitle,
            type: ComponentType.MarketChart,
        });

    };

    const handleNewTable = (e: any, contractId: string, mappedContractIds: string[]) => {
        onCreateTable(undefined, [{
            args: mappedContractIds ? [mappedContractIds] : [[contractId]],
            title: `${I18n.t('sidebar.trades')}`,
            type: ComponentType.Trade,
        }]);

    };

    const onDepthChange = (e, params, newDepth) => {
        clickDebouncer.current.debounce(() => {
            e.stopPropagation();
            const rowDepth = params.data?.expiryRow?.depth
            const rowCode = params.data?.expiryRow?.code
            const depth = newDepth >= 1
              ? rowDepth >= newDepth ? newDepth : rowDepth
              : 1;
            const updatedRow = {
                code: rowCode,
                depth,
            };
            if ((presetDepths[rowCode] === undefined || presetDepths[rowCode] === 1) && newDepth > 1) {
                // depth changed from 1 to higher than one => trigger expand
                onExpiryClick(updatedRow, true);
            } else if ((presetDepths[rowCode] === 0 || presetDepths[rowCode] > 1) && newDepth == 1) {
                // depth changed from higher number to 1 (top of the orderbook) => trigger collapse
                onExpiryClick(updatedRow, false);
            }
            // limit depth by desired number
            setOrderbookDepth(depth, rowCode);
        }, 200)
    };

    const onDepthExpand = (e, params) => {
        clickDebouncer.current.clear()
        e.stopPropagation();
        const row = params.data?.expiryRow;
        const collapseOrExpandDepth = presetDepths[row.code] === 0 ? 1 : 0;
        const updatedRow = {
            ...row,
            depth: collapseOrExpandDepth
        };
        onExpiryClick(updatedRow, !collapseOrExpandDepth);

        setOrderbookDepth(collapseOrExpandDepth, row.code);
    };

    const onGridReady = () => {

        const instruments = Array.from(instrumentToMarket.keys())
        setInstrumentTables(instruments)

        const rowItems = createModel({
            adjustedMatrices,
            adjustedPrices,
            instrumentTables: instruments,
            compactColumnsEnabled,
            expiries,
            expiryKeys,
            instrumentToMarket,
            presetDepths,
            colors,
            anonymizeMarketDataEnabled
        })

        const sizes = getColumnSizes(instrumentToMarket)

        const newColumns = generateColumns({
            adjustedMatrices,
            compactColumnsEnabled,
            instrumentToMarket,
            onMarketRemove,
            columnSizes: sizes,
            instrumentTables: instruments
        })
        const defaultAutoGroupSettings = generateGroupColumn({
            onDepthChange,
            onDepthExpand
        })
        setColumns(newColumns)
        setRows(rowItems)
        setGroupColumn(defaultAutoGroupSettings)
        setColumnSizes(sizes)
    };

    useEffect(() => {
        if (instrumentTables?.length && gridRef?.current?.api) {
            const marketVersions = Array.from(instrumentToMarket.keys())
              .map(instId => instrumentToMarket.get(instId).version)
              .reduce((acc, v) => acc + ',' + v, '')
            let instrumentIds = instrumentTables
            let refresh = false
            if (structureDeps.marketVersions !== marketVersions || structureDeps.compactColumnsEnabled !== compactColumnsEnabled) {
                instrumentIds = Array.from(instrumentToMarket.keys())
                setInstrumentTables(instrumentIds)

                let sizes = columnSizes
                if (instrumentIds.length !== structureDeps.instrumentIds.length) {
                    sizes = getColumnSizes(instrumentToMarket)
                }
                const defaultAutoGroupSettings: any = generateGroupColumn({
                    onDepthChange,
                    onDepthExpand
                })
                const newColumns = generateColumns({
                    adjustedMatrices,
                    compactColumnsEnabled,
                    instrumentToMarket,
                    onMarketRemove,
                    columnSizes: sizes,
                    instrumentTables: instrumentIds
                })
                setColumns(newColumns);
                setGroupColumn(defaultAutoGroupSettings);

                gridRef.current?.api?.setGridOption('onColumnMoved', _columnMoved(instrumentToMarket))
                gridRef.current?.api?.setGridOption('onColumnResized', _columnResized(instrumentToMarket))
                setStructureDeps({ marketVersions, compactColumnsEnabled, instrumentIds })
                refresh = true
            }
            const rowItems = createModel({
                compactColumnsEnabled,
                instrumentTables: instrumentIds,
                instrumentToMarket,
                adjustedMatrices,
                expiryKeys,
                expiries,
                presetDepths,
                adjustedPrices,
                colors,
                anonymizeMarketDataEnabled
            })

            setRows(rowItems)
            if (refresh) {
                gridRef?.current?.api.redrawRows()
            }
        }
    },  [instrumentToMarket, compactColumnsEnabled, colors, presetDepths, adjustedPrices]);

    useEffect(() => {
        const rowsItems = createModel({
            adjustedMatrices,
            adjustedPrices,
            instrumentTables,
            compactColumnsEnabled,
            expiries,
            expiryKeys,
            instrumentToMarket,
            presetDepths,
            colors,
            anonymizeMarketDataEnabled
        })
        setRows(rowsItems)
    }, [JSON.stringify(expiryKeys)])

    // create items for context menu
    const getContextMenuItems: any = (params) => {
        // no context menu for header column (expiry)
        if (params?.column?.colId === 'ag-Grid-AutoColumn') {
            return []
        }

        const result = [
            {
                action: (e) => {
                    const data = params.node.data;
                    if (data && params.column?.parent) {
                        const { expiryRow, marketTitle, contracts } = data;
                        const groupId = parseInt(params.column.parent.groupId)
                        const instrumentId = instrumentTables[groupId]
                        const contractId = contracts[groupId]
                        if (contractId) {
                            handleNewGraph(e, expiryRow, contractId, instrumentId, contractId)
                        }
                    }
                },
                name: I18n.t(`market.context.${MarketActionType.NEW_GRAPH}`),
            },
            {
                action: (e) => {
                    const data = params?.node?.data;
                    const colId = params?.column?.colId;
                    if (data && colId && params.column?.parent) {
                        const { contracts, rowIndex } = data;
                        const groupId = parseInt(params.column.parent.groupId)
                        const instruments = Array.from(instrumentToMarket.keys())
                        const instrumentId = instruments[groupId]
                        const contractId = contracts[groupId]

                        const market = instrumentToMarket.get(instrumentId)
                        const column = market?.columns.find(c => createColumnIdentifier(instrumentId, c.originalName) === colId)
                        const price = getPrice(instrumentId, data.expiryRow.code, column?.group, data.rowIndex)
                        if (contractId) {
                            handleNewPriceAlarm(contractId, column?.group, price?.price)
                        }
                    }
                },
                name: I18n.t(`market.context.${MarketActionType.NEW_PRICE_ALARM}`),
            },
            {
                action: (e) => {
                    const data = params.node.data;
                    if (data && params.column?.parent) {
                        const {  contracts } = data;
                        const groupId = parseInt(params.column.parent.groupId)
                        const contractId = contracts[groupId]
                        const instruments = Array.from(instrumentToMarket.keys())
                        const instrumentId = instruments[groupId]

                        if (!contractId) {
                            return;
                        }
                        if (adjustedMatrices[instrumentId]?.associatedContracts) {
                            const associatedContracts = adjustedMatrices[instrumentId]?.associatedContracts[contractId]
                            if (associatedContracts) {
                                handleNewTable(e, contractId, associatedContracts.map(id => createMasterDataIdString(id)))
                                return
                            }
                        }
                        handleNewTable(e, contractId, undefined)
                    }
                },
                name: I18n.t(`market.context.${MarketActionType.SHOW_TRADES}`),
            }
        ];
        return result;
    };

    const setReorderedColumns = (reordered, instrumentToMarketMap) => {
        setColumns(generateColumns({
            adjustedMatrices,
            compactColumnsEnabled,
            instrumentToMarket: instrumentToMarketMap,
            onMarketRemove,
            columnSizes,
            instrumentTables: reordered
        }))

        onGroupColumnMoved(reordered)
        setInstrumentTables(reordered)
    }

    function _columnMoved(instrumentMarketMap: Map<string, IMarket>) {
        return (event) => {
            if (event.finished && event?.toIndex > 0 && event.source === 'uiColumnMoved') {
                const colDefs = event.api.getColumnDefs()
                const positionRemained = colDefs
                  .map(c => c.groupId)
                  .reduce((acc, groupId, $index) => acc && (groupId === "" + $index), true)
                if (!positionRemained) {
                    const instruments: string[] = Array.from(instrumentMarketMap.keys())
                    const reordered = event.api.getColumnDefs().map(colDef => instruments[parseInt(colDef.groupId)])
                    setReorderedColumns(reordered, instrumentMarketMap)
                } else if (!event.column) {
                    if (dragFromIndex.current !== undefined) {
                        const instruments: string[] = Array.from(instrumentMarketMap.keys())
                        const fromGroup = event.columnApi.getAllDisplayedColumns()[dragFromIndex.current - 1]?.originalParent?.groupId
                        const toGroup =  event.columnApi.getAllDisplayedColumns()[event.toIndex - 1]?.originalParent?.groupId
                        const reordered = [];
                        if (fromGroup !== undefined && toGroup !== undefined) {
                            for (let group of colDefs) {
                                if (group.groupId == fromGroup) {
                                    reordered.push(instruments[toGroup])
                                }
                                else if (group.groupId == toGroup) {
                                    reordered.push(instruments[fromGroup])
                                } else {
                                    reordered.push(instruments[group.groupId])
                                }
                            }
                        }
                        setReorderedColumns(reordered, instrumentMarketMap)
                    }
                } else if (event.column) {
                    const groupId = event.column.originalParent?.groupId
                    const instrumentTables = Array.from(instrumentToMarket.keys())
                    const instrumentId = instrumentTables[parseInt(groupId)]
                    const market = instrumentMarketMap.get(instrumentId)
                    if (market) {
                        onColumnMoved(market.id, reorderColumns(
                          instrumentId,
                          market.columns,
                          event.columnApi.getAllDisplayedColumns().filter(c => c.getParent().getGroupId() === groupId),
                          market.hiddenColumnNames))
                    }
                }
            }
        }
    }

    function _columnResized(instrumentMarketMap) {
        return (event) => {
            if (event?.source === 'uiColumnResized') {
                let sizes = undefined
                if (!event.finished) {
                    sizes = Object.keys(columnSizes).reduce((acc, colId) => {
                          acc[colId] = COLUMN_WIDTH_RESIZING
                          return acc
                      }, {})

                }
                if (event.finished && event.columns) {
                    const instruments: string[] = Array.from(instrumentMarketMap.keys())
                    const sizes = { ...columnSizes }
                    for (let gridColumn of event.columns) {
                        if (!gridColumn.flex) {
                            const groupId = gridColumn.originalParent?.groupId
                            const instrumentId = instruments[parseInt(groupId)]
                            const market = instrumentMarketMap.get(instrumentId)
                            const col = market?.columns?.find(c => gridColumn.colId === createColumnIdentifier(instrumentId, c.originalName))
                            if (col) {
                                onColumnResized(market.id, { [`${col.group}__${col.name}`]: gridColumn.actualWidth })
                                sizes[gridColumn.colId] = gridColumn.actualWidth
                            }
                        }
                    }
                    setColumnSizes(sizes)
                }
                if (sizes) {
                    setColumns(generateColumns({
                        adjustedMatrices,
                        compactColumnsEnabled,
                        instrumentToMarket: instrumentMarketMap,
                        onMarketRemove,
                        columnSizes: sizes,
                        instrumentTables: Array.from(instrumentMarketMap.keys())
                    }))
                }
            }
        }
    }

    function getPrice(instrumentId: string, expiryName: string, group: string, rowIndex: number) {
        return adjustedPrices[instrumentId] && adjustedPrices[instrumentId].orders[expiryName]
          && adjustedPrices[instrumentId].orders[expiryName][group]
          && adjustedPrices[instrumentId].orders[expiryName][group][rowIndex]
    }

    // save index of moved group if columnMoved event does not contain column object
    function _dragStarted(event) {
        dragFromIndex.current =  event?.target?.ariaColIndex
    }

    return (
        <div className="grid-container">
            <div className="main-tabel-wrapper">
                <div className={`ag-theme-quartz-dark table${isTableBorderActive ? ' table-custom-border' : ''}`}
                     ref={gridParent}>
                    <AgGridReact
                        defaultColDef={defaultColDef}
                        gridOptions={gridOptions}
                        onFirstDataRendered={onGridReady}
                        suppressDragLeaveHidesColumns={true}
                        rowHeight={compactColumnsEnabled ? 60 : 22}
                        headerHeight={30}
                        onRowDragEnd={(event) => {
                            const { node, overNode } = event;

                            if (node && overNode) {
                                onRowDragEndHandler(node, overNode);
                            }
                        }}
                        onCellMouseOver={(params: any) => {
                            if (!params.value) {
                                return
                            }
                            debouncer.current.debounce(() => {
                                setPopover(null)
                                const { data, column, event } = params;

                                const instrumentTables = Array.from(instrumentToMarket.keys())
                                const instrumentId = instrumentTables[parseInt(column.parent?.groupId)]

                                const marketColumn = instrumentToMarket.get(instrumentId)?.columns.find(c => column.colId === createColumnIdentifier(instrumentId, c.originalName))

                                const price = getPrice(instrumentId, data.expiryRow.code, marketColumn?.group, data.rowIndex)
                                if (price) {
                                    setPopover({
                                                price,
                                                x: event.x + 5,
                                                y: event.y + 10,
                                            });
                                } else {
                                    setPopover(null);
                                }
                            }, 500)
                        }}
                        onCellMouseOut={
                            (params) => {
                                debouncer.current.clear()
                                setPopover(null)
                            }
                        }
                        onCellContextMenu={() => {
                            debouncer.current.clear()
                            setPopover(null)
                        }}
                        rowDragEntireRow
                        rowSelection="multiple"
                        suppressMoveWhenRowDragging
                        animateRows={false}
                        treeData
                        groupDefaultExpanded={-1}
                        suppressScrollOnNewData
                        ref={gridRef}
                        suppressAggFuncInHeader
                        allowContextMenuWithControlKey
                        getContextMenuItems={getContextMenuItems}
                        getRowId={getRowId}
                        getDataPath={getDataPath}
                        rowData={rows}
                        columnDefs={columns}
                        autoGroupColumnDef={groupColumn}
                        suppressCellFocus
                        suppressRowClickSelection
                        suppressColumnMoveAnimation
                        suppressAutoSize={true}
                        suppressGroupRowsSticky={true}
                        suppressRowHoverHighlight={true}
                    />
                    {popover?.price?.contractId && gridParent && (
                        <ImpliedPopoverComponentGrid
                            parent={gridParent}
                            data={popover}/>
                    )}
                </div>
            </div>
        </div>
    );
};

const areEqualColors = (prevProps, nextProps) => {
    if (!prevProps?.colors || !nextProps?.colors) {
        return false
    } else {
        return prevProps.colors.colorsVersion === nextProps.colors.colorsVersion
    }
}
function arePropsEqual(oldProps, newProps) {
    if (!newProps.dockVisible) {
        return true
    }
    if (Object.keys(oldProps.adjustedPrices).length !== Object.keys(newProps.adjustedPrices).length) {
        return false
    }
    for (let key in oldProps.adjustedPrices) {
        if (oldProps.adjustedPrices[key].version !== newProps.adjustedPrices[key]?.version) {
            return false;
        }
    }
    if (Object.keys(oldProps.expiries).length !== Object.keys(newProps.expiries).length) {
        return false
    }
    for (let key in oldProps.expiries) {
        if (oldProps.expiries[key].length !== newProps.expiries[key]?.length) {
            return false;
        }
        for (let i = 0; i < oldProps.expiries[key].length; i++) {
            if (oldProps.expiries[key][i].depth !== newProps.expiries[key][i].depth) {
                return false
            }
        }
    }
    if (oldProps.expiryKeys?.length !== newProps.expiryKeys?.length) {
        return false
    }
    if (JSON.stringify(oldProps.expiryKeys) !== JSON.stringify(newProps.expiryKeys)) {
        return false
    }
    if (Object.keys(oldProps.adjustedMatrices).length !== Object.keys(newProps.adjustedMatrices).length) {
        return false
    }
    if (Object.keys(oldProps.colors).length !== Object.keys(newProps.colors).length) {
        return false
    } else if (!areEqualColors(oldProps, newProps)) {
        return false
    }
    if (Object.keys(oldProps.presetDepths).length !== Object.keys(newProps.presetDepths).length) {
        return false
    }
    for (let key in oldProps.presetDepths) {
        if (oldProps.presetDepths[key] !== newProps.presetDepths[key]) {
            return false
        }
    }
    if (oldProps.instrumentToMarket.size !== newProps.instrumentToMarket.size) {
        return false
    }
    for (let [key, value] of oldProps.instrumentToMarket) {
        if (oldProps.instrumentToMarket.get(key).version !== newProps.instrumentToMarket.get(key)?.version) {
            return false
        }
    }
    return true
}
export default memo(UiMarketInstrumentTableGrid, arePropsEqual);

