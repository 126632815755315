import * as Instruments from '../actions/instruments';
import * as OrderbookInstruments from '../../orderbook/actions/instruments';
import { Instrument } from '../models/instruments';

export interface State {
    instruments: Instrument[];
    error: any;
    loaded: boolean;
    venues: string[];
}

export const initialState: State = {
    instruments: [],
    error: null,
    loaded: false,
    venues: []
};
  
export function reducer(
    state: State = initialState,
    action: Instruments.Action | OrderbookInstruments.Action
) {
    switch (action.type) {
        
        case Instruments.ActionTypes.LOAD_INSTRUMENTS_SUCCESS:
        case OrderbookInstruments.ActionTypes.PROCESS_INSTRUMENTS_SUCCESS: {
            const instrumentList: Instrument[] = Object.keys(action.payload).reduce((acc: Instrument[], id: string) => { return acc.concat([action.payload[id]]); }, []);

            const venues: Set<string> = new Set();
            for (let i = 0; i < instrumentList.length; i++) {
                venues.add(instrumentList[i].venue);
            }

            return {
                ...state,
                loaded: true,
                instruments: instrumentList,
                results: [],
                venues: Array.from(venues)
            };
        }
        
        default: 
            return state;       
    }
}