/* eslint-disable no-duplicate-case */
import * as Tab from '../actions/tab'
import * as Table from '../actions/table'
import * as Market from '../actions/market'
import * as Chart from '../actions/chart'
import * as Profile from '../../../dashboard/actions/profile'
import * as Global from '../actions/global'
import * as tabReducer from './tab'
import * as tableReducer from './table'
import * as marketReducer from './market'
import * as chartReducer from './chart'
import * as Dashboard from '../../../dashboard/actions/dashboard'

import * as Orderbook from '../../../orderbook/actions/orderbooks'

import { IGlobal } from '../models/global'

export interface State {
  tabs: tabReducer.State
  tables: tableReducer.State
  markets: marketReducer.State
  charts: chartReducer.State
  global: IGlobal
}

export const initialState: State = {
  tabs: {
    ids: [],
    entities: {},
  },
  tables: {
    ids: [],
    entities: {},
  },
  markets: {
    ids: [],
    entities: {},
    toUnsubscribeInstruments: [],
    toUnsubscribeProducts: [],
  },
  charts: {
    ids: [],
    entities: {},
    toUnsubscribe: [],
    dataVersion: 'initial',
    themes: {},
    drawings: {},
    views: {},
  },
  global: {
    modals: {},
  },
}

export function reducer(
  state: State = initialState,
  action:
    | Tab.Action
    | Table.Action
    | Market.Action
    | Chart.Action
    | Orderbook.Action
    | Profile.Action
    | Global.Action
    | Dashboard.Action
) {
  switch (action.type) {
    case Global.ActionTypes.LOAD_GLOBAL: {
      if (action.payload) {
        return {
          ...state,
          global: action.payload,
        }
      } else {
        return state
      }
    }

    case Global.ActionTypes.SET_MODAL_POSITION: {
      return {
        ...state,
        global: {
          ...state.global,
          modals: {
            ...state.global.modals,
            [action.payload.modalId]: {
              positionClass: action.payload.positionClass,
            },
          },
        },
      }
    }

    case Tab.ActionTypes.CHANGE_TAB:
    case Tab.ActionTypes.LOAD:
    case Tab.ActionTypes.CREATE:
    case Tab.ActionTypes.MOVE:
    case Tab.ActionTypes.REMOVE:
    case Tab.ActionTypes.TRIGGER_FILTER:
    case Tab.ActionTypes.TRIGGER_SEARCH: {
      return {
        ...state,
        tabs: tabReducer.reducer(state.tabs, action),
      }
    }

    case Table.ActionTypes.LOAD:
    case Table.ActionTypes.CREATE:
    case Table.ActionTypes.REMOVE:
    case Table.ActionTypes.SORT:
    case Table.ActionTypes.TRIGGER_COLUMN_NAMES:
    case Table.ActionTypes.HIDE_ROWS:
    case Table.ActionTypes.SHOW_ROWS:
    case Table.ActionTypes.PIN_ROWS:
    case Table.ActionTypes.UNPIN_ROWS:
    case Table.ActionTypes.TRIGGER_SHOW_HIDDEN:
    case Table.ActionTypes.TRIGGER_HEADLINES:
    case Table.ActionTypes.TRIGGER_TABLE_SPECIFIC_FILTER:
    case Table.ActionTypes.TRIGGER_SEARCH_TAGS:
    case Table.ActionTypes.SET_COLUMN_WIDTH: 
    case Table.ActionTypes.SET_COLUMNS: {
      return {
        ...state,
        tables: tableReducer.reducer(state.tables, action),
      }
    }

    case Market.ActionTypes.LOAD:
    case Market.ActionTypes.CREATE:
    case Market.ActionTypes.MOVE:
    case Market.ActionTypes.REMOVE:
    case Market.ActionTypes.TRIGGER_COLUMN_NAMES:
    case Market.ActionTypes.SET_COLUMNS:
    case Market.ActionTypes.SET_COLUMN_WIDTHS:
    case Market.ActionTypes.TRIGGER_EXPIRIES:
    case Market.ActionTypes.TRIGGER_EXPANDED_EXPIRY:
    case Market.ActionTypes.TRIGGER_EXPIRY_ROWS:
    case Market.ActionTypes.CHANGE_HEADLINES_VISIBLE:
    case Market.ActionTypes.SET_ORDERBOOK_DEPTH:
    case Market.ActionTypes.REORDER_MARKETS:
    case Orderbook.ActionTypes.UNSUBSCRIBE:
     {
      return {
        ...state,
        markets: marketReducer.reducer(state.markets, action),
      }
    }

    case Chart.ActionTypes.LOAD:
    case Chart.ActionTypes.CREATE:
    case Chart.ActionTypes.MOVE:
    case Chart.ActionTypes.REMOVE:
    case Chart.ActionTypes.SET_PERIOD_TYPE:
    case Chart.ActionTypes.SET_CONTRACT:
    case Chart.ActionTypes.SET_CONTRACT_ID:
    case Chart.ActionTypes.SET_GROUP_TYPES:
    case Chart.ActionTypes.SET_VIEWS:
    case Chart.ActionTypes.SET_THEMES:
    case Chart.ActionTypes.SET_CURRENT_THEME:
    case Chart.ActionTypes.SET_LAYOUT:
    case Chart.ActionTypes.SET_DRAWINGS:
    case Chart.ActionTypes.RESTORE_DRAWINGS: {
      return {
        ...state,
        charts: chartReducer.reducer(state.charts, action),
      }
    }

    case Orderbook.ActionTypes.LOAD_CONTRACT_MATRIX_SUCCESS:
    case Orderbook.ActionTypes.UPDATE_PRICES: {
      return {
        ...state,
        markets: marketReducer.reducer(state.markets, action),
        charts: chartReducer.reducer(state.charts, action),
      }
    }

    case Dashboard.ActionTypes.CLEAN: {
      const tables = state.tables.ids.reduce(
        (acc: any, id) => {
          if (
            state?.tables &&
            (state.tables.entities[id].parentId.indexOf('analytics-') >= 0 ||
              state.tables.entities[id].parentId.indexOf('recent-') >= 0)
          ) {
            acc.entities[id] = { ...state.tables.entities[id] }
            acc.ids.push(id)
          }
          return acc
        },
        { entities: {}, ids: [] }
      )
      const tabs = state.tabs.ids.reduce(
        (acc: any, id) => {
          if (
            state?.tabs &&
            (id.indexOf('analytics-') >= 0 || id.indexOf('recent-') >= 0)
          ) {
            acc.entities[id] = { ...state.tabs.entities[id] }
            acc.ids.push(id)
          }
          return acc
        },
        { entities: {}, ids: [] }
      )
      return {
        ...state,
        markets: initialState.markets,
        tables: tables,
        tabs: tabs,
      }
    }

    case Orderbook.ActionTypes.UNSUBSCRIBE: {
      return {
        ...state,
        markets: {
          ...state.markets,
          toUnsubscribe: [],
        },
        charts: {
          ...state.charts,
          toUnsubscribe: [],
        },
      }
    }

    default:
      return state
  }
}
