import React, { useState } from 'react'
import { TranslateViewName } from '../utils/profile_views'
import { I18n } from 'react-redux-i18n'
import { MemoTranslate } from 'js/shared/i18n/components/memoTranslate'
import { ConfirmationReplaceBlock } from './confirmationBlock'

export const ProfileForm = ({ viewNames, onSubmit }) => {
  const [inputValue, setInputValue] = useState('')
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const [matchingViewNames, setMatchingViewNames] = useState([])

  const reservedNames = [
    TranslateViewName('profile.views.Last Dashboard'),
    TranslateViewName('profile.views.New Dashboard'),
  ]

  const handleChange = (event) => {
    event.preventDefault()
    const value = event.target.value
    const matching = viewNames.filter((name) => {
      return (
        value.length > 0 &&
        name.toLowerCase().indexOf(value.toLowerCase()) === 0 &&
        reservedNames.indexOf(name) === -1
      )
    })
    setInputValue(value)
    setMatchingViewNames(matching)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (viewNames.find((name) => name === inputValue)) {
      setIsConfirmationOpen(true)
    } else {
      onSubmit(inputValue)
    }
  }

  const onConfirmationSuccess = () => {
    onSubmit(inputValue)
    setIsConfirmationOpen(false)
  }

  const selectMatching = (name) => {
    setInputValue(name)
    setMatchingViewNames([])
  }

  const valid =
    inputValue.length > 0 &&
    inputValue !== I18n.t('profile.views.New Dashboard') &&
    inputValue !== I18n.t('profile.views.Last Dashboard')

  const expanded = matchingViewNames.length > 0

  const dropdownItems = matchingViewNames.map((name) => (
    <li key={`profile-${name}`} onClick={(e) => selectMatching(name)}>
      <span className="oi oi-list" />
      <span className="dashboard-name">
        <label>{TranslateViewName(name)}</label>
      </span>
    </li>
  ))
  if (isConfirmationOpen) {
    return (
      <ConfirmationReplaceBlock
        viewName={inputValue}
        onSuccess={onConfirmationSuccess}
      />
    )
  }

  return (
    <form>
      <div className="form-group row">
        <div className="col">
          <input
            className="form-control"
            name="profile"
            onChange={handleChange}
            type="text"
            value={inputValue}
            required={true}
            autoFocus={true}
            autoComplete="off"
            placeholder={I18n.t('profile.modal.name')}
          />
          {!valid && inputValue.length > 0 && (
            <p className="reserved-name">
              <MemoTranslate value="profile.modal.reservedNameMessage" />
            </p>
          )}
        </div>
        <div className={'profile__dropdown ' + (expanded ? 'visible' : '')}>
          <div className="profile__results">
            <ul>{dropdownItems}</ul>
          </div>
        </div>
      </div>
      <div className="d-flex">
        <button
          className="btn btn-approval w150 ml-auto"
          onClick={handleSubmit}
          disabled={!valid}
        >
          <MemoTranslate value="profile.modal.save" />
        </button>
      </div>
    </form>
  )
}
