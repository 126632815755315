import { ITableSort } from '../../ui/models/table';

export type CompareFunctions = Record<string, (o1: any, o2: any) => number>

const processSort = (sorting: ITableSort[], cmpFunctions: CompareFunctions) => {
  return (a: any, b: any) => {
    for (let i = 0; i < sorting.length; i++) {
      const { columnName, direction } = sorting[i];
      const cmp = cmpFunctions ? cmpFunctions[columnName] : undefined;
      const compareValue = cmp ? cmp(a[columnName], b[columnName]) : a[columnName] - b[columnName];

      if (compareValue !== 0) {
        return direction * compareValue;
      }
    }
    return 0;
  };
};

export const sortTable = (tableData: any[], sorting: ITableSort[], hiddenColumnNames: string[], cmpFunctions?: CompareFunctions) => {
  if (!sorting.length) return tableData;

  const visibleSortingColumns: ITableSort[] = sorting.filter(s => hiddenColumnNames.indexOf(s.columnName) === -1);
  const sorterFn = processSort(visibleSortingColumns.length ? visibleSortingColumns : sorting, cmpFunctions);

  return tableData.sort(sorterFn);
};