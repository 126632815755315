import { config } from '../../main/config'
import { ComponentType } from '../../shared/ui/models/component'
import { ITable, ITableSort } from '../../shared/ui/models/table'
import { FilterType } from '../../shared/ui/models/tableSpecificFilters'
import { getTableEntites, getTables } from '../../shared/ui/selectors/ui'
import { sortTable } from '../../shared/utils/helper/sorter'
import { OwnTradeFetchSuccessAction, TradeFetchSuccessAction } from '../actions/trade'
import ITrade from "../models/trade";
import { v1 } from "uuid";
import { getTableData } from '../selectors/trade'

// taken from trade selectors - function which converts trade to trade table row
export function prepareTradeRows(trades, tradeTable) {
  const filtered = applyFilters(trades, tradeTable.filters);
  const tradeRows = [];
  for (let tradeIdx = 0; tradeIdx < filtered.length; tradeIdx++) {
    const trade = filtered[tradeIdx];
    const colAccumulator = { id: trade.tradeId };
    let foundTag = tradeTable.tags?.length === 0; // if no search tags are defined, allow record
    for (let colIdx = 0; colIdx < tradeTable.columns.length; colIdx++) {
      const col = tradeTable.columns[colIdx];
      if (col.name === 'date') {
        const d = new Date(trade[col.originalName]);
        d.setHours(0, 0, 0, 0);
        colAccumulator[col.name]= d.getTime();
      } else if (col.name === 'time') {
        const d = new Date(trade[col.originalName]);
        d.setFullYear(9999, 0, 0);
        colAccumulator[col.name] = d.getTime();
      }
      colAccumulator[col.name] = trade[col.originalName];
      if (tradeTable.tags?.length > 0 && col.searchable) {
        foundTag = foundTag || tradeTable.tags
          .filter(tag => ('' + colAccumulator[col.name])
            .toLowerCase()
            .indexOf(tag.name.toLowerCase()) > -1)
          .length > 0
      }
    }
    colAccumulator['product'] = trade.product
    if (foundTag) {
      tradeRows.push(colAccumulator);
    }
  }
  return tradeRows;
}

export function getDefaultSorting(type: ComponentType) {
  let defaultSorting: ITableSort[];
  switch (type) {
    case ComponentType.Trade: defaultSorting = config.ui.tradeTable.defaultSorting; break;
    case ComponentType.Owntrade: defaultSorting = config.ui.ownTradeTable.defaultSorting; break;
    case ComponentType.TradeReport: defaultSorting = config.ui.tradeReportingTable.defaultSorting; break;
    default: defaultSorting = config.ui.tradeTable.defaultSorting;
  }
  return defaultSorting
}

export function tradeRowsFn(trades: any[], scoped: {table: ITable | undefined, parentId: string}) {
  const tradeTable = scoped.table;
  if (tradeTable) {
    const _trades = prepareTradeRows(trades, tradeTable)
    const defaultSorting: ITableSort[] = getDefaultSorting(tradeTable.type)
    return sortTable(_trades, tradeTable.sorting.length === 0 ? defaultSorting : tradeTable.sorting, tradeTable.hiddenColumnNames);
  }
  return [];
}

/**
 * Adds trades received as a map to table rows
 * This can be done for one or more tables
 * @param action
 *  tables - which tables will receive trades
 *  replace - if true, replaces all data in the tables with newly received trades
 *  payload: tradeMap {[id: string]: ITable}
 * @param state - application state
 */
export function processTradesMap(action: TradeFetchSuccessAction | OwnTradeFetchSuccessAction, state: any) {
  const { tables, replace, payload, from} = action;
  const tradesMap = payload
  const newTableData = {}

  tables.filter(t => !!t)
    .forEach(table => {
      const newTradeRows = prepareTradeRows(Object.values(tradesMap), table)
      const newTradeEntities = (replace || !state.tableData || !state.tableData[table.id])
        ? {}
        : {...state.tableData[table.id]?.entities};

      // add, modify or delete existing trades with newly received
      for (let i = 0; i < newTradeRows.length; i++) {
        const row: ITrade = newTradeRows[i];
        const trade = tradesMap[row.id]
        if (trade.deleted) {
          delete newTradeEntities[trade.tradeId];
        } else {
          newTradeEntities[row.id] = row;
        }
      }
      // sort complete data set
      const defaultSorting: ITableSort[] = getDefaultSorting(table.type)
      let sorted = sortTable(Object.values(newTradeEntities), table.sorting.length === 0 ? defaultSorting : table.sorting, table.hiddenColumnNames);

      let limit
      if (table.id.startsWith('recent')) {
        // small trade table in sidebar won't be allowed to have more than one page (default 60) trades
        limit = config.tablePageSize
      } else {
        // config.tradeLimitMax is a defalt value of table limit (1000).
        // state.limit is adjustable by adding +1000 trades
        limit =  from === undefined ? state.tableData[table.id].ids.length : from + 2 * config.tablePageSize
      }

      sorted = sorted.slice(0, limit)
      // create entity map and ids array sorted in desired order
      const newTradeIds = []
      const sortedTradeEntities = {}
      for (let i = 0; i < sorted.length; i++) {
        newTradeIds.push(sorted[i].id)
        sortedTradeEntities[sorted[i].id] = sorted[i]
      }
      newTableData[table.id] = {entities: sortedTradeEntities, ids: newTradeIds}
    })

  return {
    ...state,
    tableData: {...state.tableData, ...newTableData},
    error: null,
    dataVersion: v1()
  };
}

function applyFilters(trades: ITrade[], filters: { [key: string]: boolean }) {
  const startOfDay = new Date();
  startOfDay.setHours(0, 0, 0, 0);
  const timestampStartOfDay = startOfDay.getTime();
  let filtered = [...trades];
  const activeFilterKeys = Object.keys(filters).filter(k => filters[k]);
  if (activeFilterKeys.indexOf(FilterType.TRADES_OF_THE_DAY) !== -1) {
    filtered = filtered.filter(r => r.timestamp >= timestampStartOfDay);
  }
  return filtered;
}


// get table by ID
// if ID is missing, it should return all tables of given component type
// missing ID is specific for trade subscriptions - when trades should be added to all trade tables
export const getTablesById = function(state, tableId: string, type: ComponentType) {
  if (!tableId) {
    return Object.keys(getTableData(state))
      .map(id => getTables(state)[id])
      .filter(table => table?.type === type)
  }
  return [getTableEntites(state.value)[tableId]]
}
