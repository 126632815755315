import React, {FC, useLayoutEffect, useRef, useState} from 'react';

interface AddTableIconProps {
  iconName: string;
  onAdd: (event) => void;
  title:string
}

const AddTableIcon: FC<AddTableIconProps> = ({
  iconName,
  onAdd,
  title
}) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [positioningClass, setPositioningClass] = useState('');
  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!containerRef.current) return;
    if (containerRef.current.getBoundingClientRect?.()?.left < 0) {
      setPositioningClass('left-border');
    }
  }, []);

  return (
    <div ref={containerRef} className={positioningClass}>
      <li
        onMouseEnter={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
        onClick={onAdd}
      >
        <i
          data-test={`${iconName}-icon`}
          className={`oi ${iconName} ${isMouseOver ? `active` : ``}`}
          title={title}
        />
      </li>
    </div>
  );
};

export default React.memo(AddTableIcon);