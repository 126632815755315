import * as React from 'react'
import { I18n } from 'react-redux-i18n'
import { getOrderEntities } from '../../../../orders/selectors/orders'
import { store } from '../../../../main/store/store'
import orderbooksStore from '../../../../orderbook/store/orderbooks'
import { getOrderbookProducts } from '../../../../orderbook/selectors/products'
import {
  getTradeEntities,
  getOwnTradeEntities,
} from '../../../../trade/selectors/trade'
import { LogLevel } from '../../../logger/models/logger'
import { BasketOrderStatus } from '../../../../bulkOrders/models/model'
import { Restriction } from '../../../../orders/models/orders'
import {
  getOrderbookContractByMasterDataId,
  getOrderbookContracts,
} from '../../../../orderbook/selectors/contracts'
import {
  formatNumber,
  formatQuantityToDecimal,
} from '../../../utils/formatters'
import { getUploadedBulkOrderById } from '../../../../bulkOrders/selectors/bulkOrders'
import { getRequestEntities } from '../../../../requests/selectors/requests'
import { getPriceAlarmEntities } from '../../../../priceAlarm/selectors/priceAlarms'
import { MemoTranslate } from 'js/shared/i18n/components/memoTranslate'

const timeInstance = new Intl.DateTimeFormat(undefined, {
  hour12: false,
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
})

const dateTimeInstance = new Intl.DateTimeFormat(undefined, {
  hour12: false,
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
  timeZoneName: 'short'
});

/* Time formatter */
export const TimeFormatter = ({ value }: any) => {
  try {
    const formattedTime = timeInstance.format(value)

    return <span>{formattedTime}</span>
  } catch (e) {
    return <span>{value}</span>
  }
}

/* Basket order status formatter */
export const BasketOrderDeleteFormatter = ({ value }: any) => {
  return (
    <span
      className={`bulk__icons ml-auto oi oi-minus ${BasketOrderStatus.FAILED}`}
      style={{ marginBottom: '3.75px' }}
    />
  )
}

/* Basket order status formatter */
export const BasketOrderStatusFormatter = ({ value, contextData }: any) => {
  return (
    <span
      data-toggle="tooltip"
      className={`bulk__icons ml-auto oi oi-media-record ${
        value ? value : BasketOrderStatus.OPEN
      }`}
    />
  )
}

/* Date formatter */
export const DateFormatter = ({ value }: any) => {
  const formattedTime = new Date(value).toLocaleDateString()
  return <span>{formattedTime}</span>
}

/* Date Time formatter */
export const DateTimeFormatter = ({ value }: any) => {
  const formattedTime = dateTimeInstance.format(value)
  return <span>{formattedTime}</span>
}

/* Buy/Sell formatter */
export const BuySellFormatter = ({ value }: any) => {
  return (
    <span>
      {value
        ? I18n.t('general.shortened.buy')
        : I18n.t('general.shortened.sell')}
    </span>
  )
}

/* Buy/Sell formatter */
export const PaidGivenFormatter = ({ value }: any) => {
  return (
    <span>
      {value
        ? I18n.t('general.shortened.paid')
        : I18n.t('general.shortened.given')}
    </span>
  )
}

/* Contract name formatter */
export const OrderContractFormatter = ({ value, contextData }: any) => {
  if (!contextData || !contextData.entry || !contextData.tableType) {
    return value
  }
  if (contextData.tableType.toLowerCase().indexOf('order') > -1) {
    return (
      <span title={contextData.entry.contractTitle}>
        {contextData.entry.contractTitle}
      </span>
    )
  } else {
    return <span title={value}>{value}</span>
  }
}

/**
 *  Quantity Formatter for all table types (AllTrades, MyTrades, My Orders, Requests).
 */
export const TableQuantityFormatter = ({ value, contextData }: any) => {
  if (!contextData || !contextData.entry || !contextData.tableType) {
    return value
  }
  let qtyDecimals = 0
  let qtyStep = 0
  let restrictionSign = ''
  if (contextData.tableType.toLowerCase().indexOf('bulkorder') > -1) {
    const order = getUploadedBulkOrderById(
      store.getState(),
      contextData.entry.id
    )
    if (order) {
      const contract = getOrderbookContracts(orderbooksStore.getState())[
        order.contract
      ]
      qtyDecimals = contract ? contract.qtyDecimals : 0
      qtyStep = contract ? contract.qtyStepSize : 0
    }
  } else if (contextData.tableType.toLowerCase().indexOf('order') > -1) {
    const order = getOrderEntities(store.getState())[contextData.entry.id]
    if (order) {
      const contract = getOrderbookContractByMasterDataId(
        orderbooksStore.getState(),
        order.contractId
      )
      qtyDecimals = contract ? contract.qtyDecimals : 0
      qtyStep = contract ? contract.qtyStepSize : 0
      if (order.restriction && order.restriction === Restriction.AON) {
        restrictionSign = '*'
      }
    }
  } else if (contextData.tableType.toLowerCase().indexOf('owntrade') > -1) {
    const trade = getOwnTradeEntities(store.getState())[contextData.entry.id]
    if (trade) {
      const product = getOrderbookProducts(orderbooksStore.getState())[
        trade.product
      ]
      qtyDecimals = product ? product.qtyDecimals : 0
      qtyStep = product ? product.qtyStepSize : 0
    }
  } else if (contextData.tableType.toLowerCase().indexOf('trade') > -1) {
    const trade = contextData.entry.fullData;
    if (trade) {
      const product = getOrderbookProducts(orderbooksStore.getState())[
        trade.product
      ]
      qtyDecimals = product ? product.qtyDecimals : 0
      qtyStep = product ? product.qtyStepSize : 0
    }
  } else if (contextData.tableType.toLowerCase().indexOf('request') > -1) {
    const req = getRequestEntities(store.getState())[contextData.entry.id]
    if (req) {
      const contract = getOrderbookContracts(orderbooksStore.getState())[
        req.contractId
      ]
      qtyDecimals = contract ? contract.qtyDecimals : 0
      qtyStep = contract ? contract.qtyStepSize : 0
    }
  }

  const formattedLocalizeOptions: { [attribute: string]: any } = {
    ...contextData.localizeOptions,
    minimumFractionDigits: qtyDecimals,
    maximumFractionDigits: qtyDecimals,
    decimalStep: qtyStep,
  }
  const displayValue = Number(formatQuantityToDecimal(value, qtyDecimals))
  const formatted = formatNumber(displayValue, formattedLocalizeOptions)
  return formatted + restrictionSign
}

/**
 *  Price Formatter for all table types (AllTrades, MyTrades, My Orders, Requests).
 */
export const TablePriceFormatter = ({ value, contextData }: any) => {
  if (!contextData || !contextData.entry || !contextData.tableType) {
    return value
  }

  let priceDecimals: number | undefined = 0
  if (contextData.tableType.toLowerCase().indexOf('bulkorder') > -1) {
    const order = getUploadedBulkOrderById(
      store.getState(),
      contextData.entry.id
    )
    if (order) {
      const contract = getOrderbookContracts(orderbooksStore.getState())[
        order.contract
      ]
      priceDecimals = contract ? contract.priceDecimals : 0
    }
  } else if (contextData.tableType.toLowerCase().indexOf('order') > -1) {
    const order = getOrderEntities(store.getState())[contextData.entry.id]
    if (order) {
      const contract = getOrderbookContractByMasterDataId(
        orderbooksStore.getState(),
        order.contractId
      )
      priceDecimals = contract ? contract.priceDecimals : 0
    }
  } else if (contextData.tableType.toLowerCase().indexOf('owntrade') > -1) {
    const trade = getOwnTradeEntities(store.getState())[contextData.entry.id]
    if (trade) {
      const product = getOrderbookProducts(orderbooksStore.getState())[
        trade.product
      ]
      priceDecimals = product ? product.decimals : 0
    }
  } else if (contextData.tableType.toLowerCase().indexOf('trade') > -1) {
    const trade = contextData.entry.fullData;
    if (trade) {
      const product = getOrderbookProducts(orderbooksStore.getState())[
        trade.product
      ]
      priceDecimals = product ? product.decimals : 0
    }
  } else if (contextData.tableType.toLowerCase().indexOf('request') > -1) {
    if (!value && contextData.entry.state === 'ACTIVE') {
      return '' // active quote requests don't have a price yet therefore the price should be empty
    }
    const req = getRequestEntities(store.getState())[contextData.entry.id]
    if (req) {
      const contract = getOrderbookContracts(orderbooksStore.getState())[
        req.contractId
      ]
      priceDecimals = contract ? contract.priceDecimals : 0
    }
  } else if (contextData.tableType.toLowerCase().indexOf('pricealarm') > -1) {
    const priceAlarm = getPriceAlarmEntities(store.getState())[
      contextData.entry.id
    ]
    if (priceAlarm) {
      const contract = getOrderbookContracts(orderbooksStore.getState())[
        priceAlarm.contractId
      ]
      priceDecimals = contract ? contract.priceDecimals : 0
    } else {
      priceDecimals = 0
    }
  }
  const formattedLocalizeOptions: { [attribute: string]: any } = {
    ...contextData.localizeOptions,
    minimumFractionDigits: priceDecimals,
    maximumFractionDigits: priceDecimals,
  }

  return formatNumber(value, formattedLocalizeOptions)
}

export const TableLogLevelFormatter = ({ value }: any) => {
  let index = LogLevel.DEBUG
  for (const level in LogLevel) {
    if (level !== undefined) {
      if (!parseInt(level, 10)) {
        if (value === index--) {
          return level
        }
      }
    }
  }
  return value
}

export const PriceAlarmDeleteFormatter = ({ value }: any) => {
  return (
    <span
      className={`priceAlarm__icons ml-auto oi oi-minus`}
      style={{ marginBottom: '3.75px' }}
    />
  )
}

export const PriceAlarmStatusFormatter = ({ value }: any) => {
  return (
    <span
      data-toggle="tooltip"
      title={I18n.t('priceAlarm.status.' + value)}
      className={`priceAlarm__icons ml-auto oi oi-media-record triggered-${value}`}
    />
  )
}

export const VenueFormatter = ({ value }: any) => {
  return (
    <span
      data-toggle="tooltip"
      title={I18n.t('venues.status.' + value)}
      className={`venues__icons ml-auto oi oi-media-record status-${value.toLowerCase()}`}
    />
  )
}

export const PriceAlarmLongStringFormatter = ({ value }: any) => {
  return <span title={value}>{value}</span>
}

export const dataFormatters = {
  time: (value: any) => <TimeFormatter value={value} />,
  date: (value: any) => <DateFormatter value={value} />,
  timestamp: (value: any) => <TimeFormatter value={value} />,
  validFrom: (value: any) => <DateTimeFormatter value={value} />,
  validTo: (value: any) => <DateTimeFormatter value={value} />,
  buySell: (value: any) => <BuySellFormatter value={value} />,
  paidGiven: (value: any) => <PaidGivenFormatter value={value} />,
  buy: (value: any) => <BuySellFormatter value={value} />,
  price: (value: any, contextData: any) => (
    <TablePriceFormatter value={value} contextData={contextData} />
  ),
  limit: (value: any, contextData: any) => (
    <TablePriceFormatter value={value} contextData={contextData} />
  ),
  quantity: (value: any, contextData: any) => (
    <TableQuantityFormatter value={value} contextData={contextData} />
  ),
  remainingQuantity: (value: any, contextData: any) => (
    <TableQuantityFormatter value={value} contextData={contextData} />
  ),
  orderedQuantity: (value: any, contextData: any) => (
    <TableQuantityFormatter value={value} contextData={contextData} />
  ),
  level: (value: any, contextData: any) => (
    <TableLogLevelFormatter value={value} contextData={contextData} />
  ),
  status: (value: any, contextData: any) => (
    <BasketOrderStatusFormatter value={value} contextData={contextData} />
  ),
  delete: (value: any) => <BasketOrderDeleteFormatter value={value} />,
  contract: (value: any, contextData: any) => (
    <OrderContractFormatter value={value} contextData={contextData} />
  ),
  statusMessage: (value: any, contextData: any) => (
    <MemoTranslate value={value} />
  ),
  meetState: (value: any, contextData: any) => (
    <MemoTranslate value={`quoteRequest.state.${value}`} />
  ),
  priceAlarmDelete: (value: any, contextData: any) => (
    <PriceAlarmDeleteFormatter value={value} />
  ),
  priceAlarmStatus: (value: any, contextData: any) => (
    <PriceAlarmStatusFormatter value={value} />
  ),
  expiry: (value: any, contextData: any) => (
    <span>{value ? value.name : ''}</span>
  ),
  expires: (value: any, contextData: any) => {
    return (
      <span>
        {!value ? (
          <MemoTranslate value="priceAlarm.gtc" />
        ) : (
          <DateFormatter value={new Date(value)} />
        )}
      </span>
    )
  },
  priceAlarmType: (value: any, contextData: any) => {
    return <MemoTranslate value={`priceAlarm.priceAlarmType.${value}`} />
  },
  customer: (value: any) => {
    return <PriceAlarmLongStringFormatter value={value} />
  },
  description: (value: any) => {
    return <PriceAlarmLongStringFormatter value={value} />
  },
}
