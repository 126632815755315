import { State } from '../../main/reducers/rootReducer';
import { connect } from 'react-redux';
import {getVenues} from "../../instruments/selectors/instruments";
import withAutoClose from "../../shared/autoclose/components/Autoclose";
import ContractSelectComponent from "../components/ContractSelect";

const mapStateToProps = (state: State) => {
    return {
        venues: getVenues(state)
    };
};

export default connect<any, null, any>(
    mapStateToProps,
    null
)(withAutoClose(ContractSelectComponent));