import {
  ActionsObservable,
  StateObservable,
  combineEpics,
} from 'redux-observable'
import { successLoadVenues } from '../actions/venues'
import * as Authentication from '../../../authentication/actions/authentication'
import { filter, map, switchMap, catchError } from 'rxjs/operators'
import { ActionTypes as ContractActionsTypes } from '../../../orderbook/actions/contracts'

import { of } from 'rxjs'
import { receiveMessage } from '../../messenger/actions/messenger'
import { VenueStatus } from '../models/venue'
import { State } from 'js/main/reducers/rootReducer'
import { getVenues } from 'js/instruments/selectors/instruments'

export const loadVenues: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(
      (action) =>
        action.type === Authentication.ActionTypes.AUTHENTICATION_SUCCESS ||
        action.type === ContractActionsTypes.LOAD_CONTRACTS_MAP_SUCCESS
    ),
    map((action) => action.payload),
    switchMap(() => {
      // service should be called - subscribe to venues
      const venues = getVenues(state.value)
        .filter((v) => v.trim() !== '*')
        .map((v) => {
          return {
            venue: v,
            status: VenueStatus.CONNECTED,
          }
        })
      return of(successLoadVenues(venues))
    }),

    catchError((error) => {
      return of(receiveMessage('', error, true))
    })
  )
}

export const venueEpics = combineEpics(loadVenues)
