import React, { FC, useCallback } from 'react';
import { connect } from 'react-redux';
import { setOwnTradeLimit, setAllTradeLimit } from '../actions/trade';
import TradesTable from '../containers/TradesTable';
import { ComponentType } from '../../shared/ui/models/component';
import { getLimitOwnTrades, getLimitAllTrades } from '../selectors/trade';
import { State } from '../../main/reducers/rootReducer';
import { config } from 'js/main/config';
import OwnTradesTable from '../containers/OwnTradesTable';

interface TradeTableEnhancedProps {
  limitOwnTrades: (limit: number, days: number) => void;
  limitAllTrades: (limit: number, days: number) => void;
  type: ComponentType;
  ownTradesLimits: {limit: number, days: number};
  allTradesLimits: {limit: number, days: number};
  parentId: string;
}

const dayLimits = [
  { days: 365, label: '365 days' },
  { days: 30, label: '30 days' },
  { days: 0, label: 'Max' },
];

export const TradeTableEnhancedComponent: FC<TradeTableEnhancedProps> = (props) => {
  const {
    limitOwnTrades,
    limitAllTrades,
    allTradesLimits,
    ownTradesLimits,
    type,
  } = props;

  const getActiveDaysAndCurrentLimit = (props) => {
    let activeDays = 0;
    let currentLimit = 0;
    if (props.type === ComponentType.Trade) {
      activeDays = props.allTradesLimits.days;
      currentLimit = props.allTradesLimits.limit;
    } else if (props.type === ComponentType.Owntrade) {
      activeDays = props.ownTradesLimits.days;
      currentLimit = props.ownTradesLimits.limit;
    }
    return { activeDays, currentLimit };
  }

  const isRecentActionsTable = props.parentId?.startsWith('recent-');

  const { activeDays, currentLimit } = getActiveDaysAndCurrentLimit(props);


  const setLimitCount = useCallback((count: number) => {
    if (type === ComponentType.Trade) {
      limitAllTrades(count, allTradesLimits.days);
    } else if (type === ComponentType.Owntrade) {
      limitOwnTrades(count, ownTradesLimits.days);
    }
  }, [limitOwnTrades, limitAllTrades, allTradesLimits, ownTradesLimits, type]);

  const setLimitDays = useCallback((days: number) => {
    const isUninitializedLimit = allTradesLimits.limit === 0;
    const ignoreValue = 0;

    if (type === ComponentType.Trade) {
      limitAllTrades(isUninitializedLimit ? config.tradeLimit : ignoreValue, days);
    } else if (type === ComponentType.Owntrade) {
      limitOwnTrades(isUninitializedLimit ? config.tradeLimit : ignoreValue, days);
    }
  }, [limitOwnTrades, limitAllTrades, allTradesLimits, ownTradesLimits, type]);

  const clearLimits = useCallback(() => {
    const resetLimit = -1;

    if (type === ComponentType.Trade) {
      limitAllTrades(resetLimit, 0);
    } else if (type === ComponentType.Owntrade) {
      limitOwnTrades(resetLimit, 0);
    }
  }, [type, limitOwnTrades, limitAllTrades]);
  const properties = {...props, disableSorting: isRecentActionsTable}
  return (
    <div className="trade-db-controls">
      {!isRecentActionsTable ? <div>
        <ul className="trade-limit-actions">
          <li className="trade-limit-setting" onClick={clearLimits}>
            <i className={`oi oi-delete`} />
          </li>
          <li className="trade-limit-setting" onClick={() => setLimitCount(1000)}>
            + {config.tradeLimit} ({currentLimit})
          </li>
        </ul>
      </div> : null}

      {props.type === ComponentType.Trade ? <TradesTable {...properties}  /> : <OwnTradesTable {...properties} />}
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  ownTradesLimits: getLimitOwnTrades(state),
  allTradesLimits: getLimitAllTrades(state)
});

const mapDispatchToProps = {
  limitOwnTrades: setOwnTradeLimit,
  limitAllTrades: setAllTradeLimit
};

export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(TradeTableEnhancedComponent);
