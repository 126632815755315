import { MasterDataId } from '../../main/models/application';
import { Instrument } from '../../instruments/models/instruments';

export interface InquireContractsRequest {

}

export interface InquireContractsResponse {
    contracts: Contracts;
    last: boolean;
}

export interface Contracts {
    contract: Contract[];
}

export interface Contract {
    id: {venue: string; id: string};
    idString: string;
    name: string;
    expiry: Expiry;
    expiry2: Expiry;
    lastTradingDay: Date;
    product: Product;
    unit: number;
    autoGenerated: boolean;
    productId: MasterDataId;
    state: ContractState;
    instrumentId: MasterDataId;
    qtyUnit: string;
    qtyDecimals: number;
    priceDecimals: number;
    qtyStepSize: number;
    mappedContract: string;
    mapped: boolean;
    currency: Currency;
    userPermission: any;
    nameWithVenue: string;
}

export interface SyntheticContract {
    idString: string;
    productId: string;
    offset: number;
}

export interface Expiry {
    code: string;
    name: string;
    type: PeriodType;
    efa: boolean;
    periodStart: Date;
    periodEnd: Date;
    group: NamedExpiryGroup;
    originalName?: string;
}

export enum PeriodType {
    PROMPT = 'PROMPT',
    QUARTER_HOUR = 'QUARTER_HOUR',
    HALF_HOUR = 'HALF_HOUR',
    HOUR = 'HOUR',
    BLOCK_2_H = 'BLOCK_2_H',
    BLOCK_4_H = 'BLOCK_4_H',
    DAY = 'DAY',
    WEEKEND = 'WEEKEND',
    WORKING_DAYS = 'WORKING_DAYS',
    BALANCE_OF_WEEK = 'BALANCE_OF_WEEK',
    WEEK = 'WEEK',
    SPLIT = 'SPLIT',
    BALANCE_OF_MONTH = 'BALANCE_OF_MONTH',
    MONTH = 'MONTH',
    QUARTER = 'QUARTER',
    SUM_WIN_SEASON = 'SUM_WIN_SEASON',
    SUM_WIN_7_5_GAS_SEASON = 'SUM_WIN_7_5_GAS_SEASON',
    YEAR = 'YEAR',
    GAS_YEAR = 'GAS_YEAR',
    EMISSION = 'EMISSION',
    NAMED = 'NAMED',
    EMISSION_SPOT = 'EMISSION_SPOT',
    EMISSION_FUTURE = 'EMISSION_FUTURE',
    HOLIDAY = 'HOLIDAY'
}

export const periodTypes: { [key: string]: any } = {
    QUARTER_HOUR: { index: 0, label: 'Quarter Hour', key: 'periodTypes.quarterHour' },
    HALF_HOUR: { index: 1, label: 'Half Hour', key: 'periodTypes.halfHour' },
    HOUR: { index: 2, label: 'Hour', key: 'periodTypes.hour' },
    BLOCK_2_H: {index: 3, label: '2 Hour Block', key: 'periodTypes.block2H'},
    BLOCK_4_H: {index: 4, label: '4 Hour Block', key: 'periodTypes.block4H'},
    PROMPT: { index: 5, label: 'Prompt', key: 'periodTypes.prompt' },
    DAY: { index: 6, label: 'Day', key: 'periodTypes.day' },
    BALANCE_OF_WEEK: { index: 7, label: 'Balance of Week', key: 'periodTypes.balanceOfWeek' },
    WEEKEND: { index: 8, label: 'Weekend', key: 'periodTypes.weekend' },
    WORKING_DAYS: { index: 9, label: 'Working Days', key: 'periodTypes.workingDays' },
    WEEK: { index: 10, label: 'Week', key: 'periodTypes.week' },
    SPLIT: { index: 11, label: 'Split', key: 'periodTypes.split' },
    BALANCE_OF_MONTH: { index: 12, label: 'Balance of Month', key: 'periodTypes.balanceOfMonth' },
    MONTH: { index: 13, label: 'Month', key: 'periodTypes.month' },
    QUARTER: { index: 14, label: 'Quarter', key: 'periodTypes.quarter' },
    SUM_WIN_SEASON: { index: 15, label: 'Season', key: 'periodTypes.sumWinSeason' },
    SUM_WIN_7_5_GAS_SEASON: { index: 16, label: 'Season 7/5', key: 'periodTypes.sumWinSeason75' },
    EMISSION: { index: 17, label: 'Emission', key: 'periodTypes.emission' },
    EMISSION_SPOT: { index: 18, label: 'Emission Spot', key: 'periodTypes.emissionSpot' },
    EMISSION_FUTURE: {index: 19, label: 'Emission Future', key: 'periodTypes.emissionFuture'},
    YEAR: { index: 20, label: 'Year', key: 'periodTypes.year' },
    GAS_YEAR: { index: 21, label: 'Gas Year', key: 'periodTypes.gasYear'},
    HOLIDAY: { index: 22, label: 'Holiday', key: 'periodTypes.holiday'},
    NAMED: { index: 23, label: 'Named', key: 'periodTypes.named' }
};

export enum NamedExpiryGroup {
    SPOT,
    PROMPT
}

export interface Product {
    id: MasterDataId;
    name: string;
    offset: number;
    periodType: PeriodType;
    currency: Currency;
    instrument: Instrument;
    spread: number;
    emaId: string;
    externalProduct: string;
    decimals: number;
    qtyDecimals: number;
    minQty: number;
    qtyStepSize: number;
    tradingDayStartHour: number;
    tradingDayStartMinute: number;
    tradingDayEndHour: number;
    tradingDayEndMinute: number;
    lastTradingDayEndHour: number;
    lastTradingDayEndMinute: number;
    timezone: string;
    leadTimeMinutes: number;
    autoContractGenerationDepth: number;
    preventAutomaticExecution: boolean;
    collaborativeRouting: boolean;
    interProductSpread: boolean;
    efa: boolean;
    leg1Product: Product;
    leg1Quantifier: number;
    leg1QuantityToMinQty: boolean;
    leg2Product: Product;
    leg2Quantifier: number;
    leg2QuantityToMinQty: boolean;
    sendOffsetTrade: boolean;
    productDisplayCode: string;
    instrumentId: MasterDataId;
    leg1ProductId: string;
    leg2ProductId: string;
    portfolioClassId: number;
    cooperativeTrading: boolean;
    qtyTradeFactor: number;
    qtyUnit: string;
    contracts: Contract[];
    contractId: string;
    venue: string;
    nameWithVenue: string;
    periodStarts: number[];
}

export enum Currency {
    EUR,
    GBP,
    USD,
    DKK,
    NOK,
    SEK,
    GBX,
    PER,
    CZK
}

export enum ContractState {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE'
}

export enum CurrencySymbols {
    EUR = '€',
    GBP = '£',
    USD = '$',
    DKK = 'kr',
    NOK = 'kr',
    SEK = 'kr',
    GBX = '',
    PER = '',
    CZK = 'Kč'
}

export interface ContractVenueMap {
    [venue: string]: {[id: string]: Contract};
}
