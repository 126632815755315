import { ITable, TableAction, ITableColumn, ITableSort, ITableSearchTag, ITableRow, prepareColumns } from '../../shared/ui/models/table';
import { ComponentType } from '../../shared/ui/models/component';
import { config } from '../../main/config';
import { PriceAlarmTablePopover } from '../components/priceAlarmTablePopover';
import store from '../../main/store/store';
import { openPriceAlarmForm } from '../actions/priceAlarms';
import { createCancelPriceAlarmFormData } from '../helper/priceAlarms';
import { getPriceAlarmEntities } from '../selectors/priceAlarms';
export class PriceAlarmTable implements ITable {
  static index: number = 1;

  id: string;
  parentId: string;
  type: ComponentType;
  actions: TableAction[] = [
    TableAction.HIDDING_ROWS,
    TableAction.PINNING_ROWS
  ];
  columns: ITableColumn[] = [];
  sorting: ITableSort[];
  hiddenColumnNames: string[];
  hiddenRows: any[] = [];
  tags: ITableSearchTag[] = [];
  showHeadlines: boolean = true;
  showingHidden: boolean = false;
  rowPopoverComponent: any = null;
  cellActionMapping?: { [key: string]: string; };
  cellActions?: { [key: string]: (rowIndex: string, columnKey: string, tableComponent: any, row: ITableRow, event: any) => void; } = {};
  cellActionClick?: string;
  filters: { [key: string]: boolean; } = {};
  pinnedRows: number[] = [];
  externallyPaginated?: boolean = false;
  cmpFunctions?: {[columnName: string]: (o1: any, o2: any) => number};

  constructor(parentId: string, customId?: string) {
    this.id = customId
      ? customId
      : new Date().getUTCMilliseconds().toString() + PriceAlarmTable.index++;
    this.parentId = parentId;
    this.columns = config.ui.priceAlarmTable.columns.map(c => { return {...c}; });

    if (customId) {
      this.hiddenColumnNames = prepareColumns(config.ui.priceAlarmTable, this.columns, true);
      this.sorting = config.ui.priceAlarmTable.defaultSorting;
    } else {
      this.hiddenColumnNames = prepareColumns(config.ui.priceAlarmTable, this.columns, false);
      this.sorting = config.ui.priceAlarmTable.defaultSorting;
    }

    this.cellActionMapping = config.ui.priceAlarmTable.cellActions;
    this.cellActions = {
      showPriceAlarmTablePopover: (rowIndex: string, columnKey: string, tableComponent: any, row: ITableRow, event: any, type?: string) => {
        if (type === 'right') {
          tableComponent.hidePopover();
          tableComponent.showPopover(PriceAlarmTablePopover, row, event);
        }
      },
      deletePriceAlarm: (rowIndex: string, columnKey: string, tableComponent: any, row: ITableRow, event: any, type?: string) => {
        if (type === 'left') {
          const priceAlarm = getPriceAlarmEntities(store.getState())[row.id];
          if (priceAlarm) {
            store.dispatch(openPriceAlarmForm(createCancelPriceAlarmFormData(priceAlarm)));
          }
          event.stopPropagation();
        }
      },
    };
    this.type = ComponentType.PriceAlarm;
    this.tags = [];
    this.cellActionClick = config.ui.priceAlarmTable.cellActionClick;
    this.cmpFunctions = {
      expiry: (e1, e2) => {
        if (!e1.periodStart) {
          return 1;
        }
        if (!e2.periodStart) {
          return -1;
        }
        return e1.periodStart < e2.periodStart ? -1 : (e1.periodStart > e2.periodStart ? 1 : 0);
      }, 
      expires: (d1, d2) => {
        if (!d1) {
          return -1;
        }
        if (!d2) {
          return 1;
        }
        return d1.getTime() < d2.getTime() ? -1 : (d1.getTime() > d2.getTime() ? 1 : 0);
      }  
    };
  }
}