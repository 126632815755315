import React from 'react'
import { I18n } from 'react-redux-i18n'

export const ProfileChangeIcons = ({
  currentDashboardSaved,
  saveDashboard,
  lastDashboardActive,
  viewLoad,
  lastViewId,
  emptyViewId,
}) => {
  const Color = (active) => {
    return active ? 'active' : 'white'
  }

  return (
    <div className="profile-views__icons">
      <span>&nbsp;&nbsp;</span>
      <span
        // save current Dashboard
        data-toggle="tooltip"
        title={I18n.t('profile.modal.title')}
        className={`ml-auto oi oi-check ${Color(currentDashboardSaved)}`}
        onClick={() => saveDashboard()}
      />
      <span
        // load "Last Dashboard" view
        data-toggle="tooltip"
        data-test="last-dashboard-icon"
        title={I18n.t('profile.views.Last Dashboard')}
        className={`ml-auto oi oi-clock ${Color(lastDashboardActive)}`}
        onClick={() => viewLoad(lastViewId)}
      />
      <span
        // open "New Dashboard" view
        data-toggle="tooltip"
        data-test="create-dashboard"
        title={I18n.t('profile.views.New Dashboard')}
        className="ml-auto oi oi-plus white"
        onClick={() => viewLoad(emptyViewId)}
      />
    </div>
  )
}
