import { IComponent, ComponentType } from './component';
import { ITableSearchTag } from './table'

interface TabMeta {
  displayFilter: boolean;
  displaySearch: boolean;
  isActive: boolean;
  ordering: number;
  tags?: ITableSearchTag[];
};

export interface ITab extends IComponent {
  dockId: string;
  displayActions: boolean;
  meta: TabMeta;
}

export class Tab implements ITab {
  static index = 1;

  id: string;
  dockId: string;
  title: string;
  type: ComponentType;
  displayActions = false;
  meta: TabMeta = {
    displayFilter: false,
    displaySearch: false,
    isActive: false,
    ordering: 0,
    tags: []
  };

  constructor(
    dockId: string,
    title: string,
    type: ComponentType,
    displayActions?: boolean,
    customId?: string,
    meta?: TabMeta
  ) {
    this.id = customId
      ? customId
      : new Date().getUTCMilliseconds().toString() + Tab.index++;
    this.dockId = dockId;
    this.title = title;
    this.type = type;
    
    this.displayActions = displayActions !== undefined ? displayActions : this.displayActions;
    this.meta = meta ? meta : this.meta;
  }
}
