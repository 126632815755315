import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { State } from '../../../../../main/reducers/rootReducer';
import { getChartDrawings, getChartsForDock } from '../../../selectors/ui';
import UIChartBookView from './uiChartBookComponent';
import { DockScrollState, DockType } from '../../../../dock/models/dock';
import { getDockFavoriteName, getDockScrollState } from '../../../../../dashboard/selectors/dashboard';
import { isProfileModificationAllowed } from '../../../../../authentication/selectors/authetication';
import { getAvailableFavorites } from '../../../../favorite/selectors/favorite';
import { IFavorite } from '../../../../favorite/models/favorite';
import { Chart } from '../../../models/chart';
import { scrollContent } from 'js/shared/dock/actions/dock';

interface UITradesChartViewProps {
  dockId: string;
  dockType: DockType;
  instrumentId: string;
  expiryType: number;
  charts: Chart[];
  favoriteName: string;
  activity: boolean;
  orderbookLoaded: boolean;
  modificationAllowed: boolean;
  favorites: IFavorite[];
  drawings: {[contractName: string]: any};
  dockScroll: DockScrollState;
  scrollContent: (dockId: string, scrollInfo: {width: number; height: number; scrollTop: number; scrollLeft: number}) => void;
}

export const UITradesChartView: FC<UITradesChartViewProps> = ({ dockId, dockType, charts, favoriteName, modificationAllowed, favorites, drawings }) => {
  const [dockSize, setDockSize] = useState({ width: 0, height: 0, top: 0, left: 0, tabTop: 0, tabLeft: 0 });

  useEffect(() => {
    const parentElement = document.querySelector('.dockId-' + dockId) as HTMLElement;

    setDockSize({
      width: parentElement.clientWidth,
      height: parentElement.clientHeight,
      tabTop: parseInt(parentElement.style.top),
      tabLeft: parseInt(parentElement.style.left),
      top: 0,
      left: 0,
    });
  }, [dockId]);

  const instrumentIds = charts.reduce((ids, chart) => {
    return ids.concat(chart.instrumentId);
  }, []);

  return (
    <UIChartBookView
      dockId={dockId}
      dockType={dockType}
      charts={charts}
      instrumentIds={instrumentIds}
      favoriteName={favoriteName}
      modificationAllowed={modificationAllowed}
      favorites={favorites}
      drawings={drawings}
      dockSize={dockSize}
    />
  );
};

const mapStateToProps = (state: State, props: UITradesChartViewProps) => ({
  charts: getChartsForDock(state, props.dockId),
  favoriteName: getDockFavoriteName(state, props.dockId),
  modificationAllowed: isProfileModificationAllowed(state),
  favorites: getAvailableFavorites(state),
  drawings: getChartDrawings(state),
  dockScroll: getDockScrollState(state, props.dockId)
});

const mapDispatchToProps = {};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(UITradesChartView);
