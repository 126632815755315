import { connect } from 'react-redux'
import { State } from '../../main/reducers/rootReducer'
import { connectDocks } from '../actions/dashboard'
import { load as loadProfile } from '../actions/profile'

import { create } from '../../shared/dock/actions/dock'
import { create as tabCreate } from '../../shared/ui/actions/tab'
import { create as marketCreate } from '../../shared/ui/actions/market'
import { create as chartCreate } from '../../shared/ui/actions/chart'
import { getAll, getDockTitlesMap } from '../selectors/dashboard'

import { getConnectedStatus } from '../../authentication/selectors/connection'
import { getSidebarIsLocked } from '../../shared/settings/selectors/selector'
import { warn } from '../../shared/logger/actions/logger'

import DashboardPanel from '../components/dashboardPanel'

import { setFlexLayout } from '../actions/profile'
import { remove } from '../../shared/dock/actions/dock'
import { createNotification } from '../../shared/notifications/actions/notifications'

const mapStateToProps = (state: State) => {
  return {
    docks: getAll(state),
    enabled: getConnectedStatus(state),
    isSidebarLocked: getSidebarIsLocked(state),
    dashboardLoading: state.dashboard.dashboadrLoading,
    flexLayout: state.profile.flexLayout,
    dockTitles: getDockTitlesMap(state),
  }
}

const mapDispatchToProps = {
  loadProfile: loadProfile,
  dockCreate: create,
  connectDocks: connectDocks,
  tabCreate: tabCreate,
  marketCreate: marketCreate,
  chartCreate: chartCreate,
  warn: warn,
  flexLayoutSave: setFlexLayout,
  removeDock: remove,
  createNotification: createNotification
}

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(DashboardPanel)
