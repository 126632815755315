import React, { useState, useEffect } from 'react'
import { ComponentType } from 'js/shared/ui/models/component'
import { I18n, Translate } from 'react-redux-i18n'
import { getDockType } from 'js/shared/dock/models/dock'
import Dragger from 'js/shared/dragger/container/Dragger'
import { MemoTranslate } from 'js/shared/i18n/components/memoTranslate'
import { Result } from '../models/results'
import { createMasterDataIdString } from 'js/orderbook/selectors/contracts'
import useClickOutside from 'js/hooks/useOnClickOutside'
import RadioOption from './radioOption'
import VenueDropdownItem from './venueDropdownItem'

let selectedComponent = null
const displayLimitDefault = 100
enum menuTypes {
  instrument = 'instrument',
  chart = 'chart',
  product = 'product',
}

const optionTypes = [menuTypes.instrument, menuTypes.chart, menuTypes.product]
const MarketSheetSelectComponent = ({
                                      instruments,
                                      products,
                                      loaded,
                                      onSelectComponent,
                                      modificationEnabled,
                                      venues,
                                      customId,
                                      hideRadioButtons,
                                      triggerOnClick,
                                      preselectedType,
                                      expanded
                                    }) => {
  const [results, setResults] = useState([])
  const [phrase, setPhrase] = useState('')
  const [listType, setListType] = useState(
    preselectedType || menuTypes.instrument
  )
  const [displayLimit, setDisplayLimit] = useState(displayLimitDefault)
  const [venueFilter, setVenueFilter] = useState(true)
  const [selectedVenues, setSelectedVenues] = useState([])
  const [showContextMenu, setShowContextMenu] = useState(expanded)
  const menuRef = React.useRef()

  const onClickOutside = () => {
    if (!expanded) {
      setShowContextMenu(false)
    }
  }

  useClickOutside(menuRef, onClickOutside)

  useEffect(() => {
    setListType(preselectedType || listType)
    if (expanded) {
      showDropdown()
    }
  }, [listType])

  useEffect(() => {
    if (
      phrase === '' &&
      instruments.length > 0 &&
      results.length === 0 &&
      instruments.length > 0
    ) {
      setResults(_getFilteredResults(menuTypes.instrument, [], ''))
    }
    if (!!preselectedType && listType !== preselectedType) {
      setListType(preselectedType || listType)
    }
  }, [phrase, instruments, results, listType, preselectedType])

  const _getFilteredResults = (type, venues, phrase) => {
    let filteredResults = []
    if (type === menuTypes.instrument || type === menuTypes.chart) {
      filteredResults = instruments.map((i) => {
        return {
          name: i.nameWithVenue,
          id: createMasterDataIdString(i.id),
          instrument: i,
          venue: i.venue,
          product: null,
          type: menuTypes.instrument,
        }
      })
    } else if (type === menuTypes.product) {
      filteredResults = products.map((p) => {
        return {
          name: p.nameWithVenue,
          instrument: null,
          id: createMasterDataIdString(p.id),
          venue: p.venue,
          product: p,
          type: menuTypes.product,
        }
      })
    }
    return _filterResults(phrase, venues, filteredResults)
  }

  const onSelect = (e) => {
    if (selectedComponent) {
      const componentType = _getComponentType(selectedComponent)
      onSelectComponent(e, {
        type: componentType,
        dockType: getDockType(componentType),
        name: selectedComponent.name,
        args: [selectedComponent],
      })
    }
  }

  const search = (event) => {
    const value = event.target.value
    showDropdown()
    setResults(_getFilteredResults(listType, selectedVenues, value))
    setPhrase(value)
  }

  const _filterResults = (phrase, venues, results) => {
    if (!phrase && venues.length === 0) {
      return [...results]
    }
    const phraseLowerCase = phrase?.toLowerCase()
    const res = []
    for (let i = 0; i < results.length; i++) {
      const venueCondition =
        venues.length === 0 || venues.indexOf(results[i].venue) > -1
      if (
        venueCondition &&
        (!phraseLowerCase ||
          results[i].name.toLowerCase().indexOf(phraseLowerCase) > -1)
      ) {
        res.push(results[i])
      }
    }
    return res
  }

  const onTypeChanged = (e) => {
    const type = e.target.name.replace('list-type-', '')
    setListType(type)
    setResults(_getFilteredResults(type, selectedVenues, phrase))
  }

  const showDropdown = () => {
    setResults(_getFilteredResults(listType, selectedVenues, phrase))
  }

  const _getComponentType = (result) => {
    if (listType === menuTypes.instrument && result.instrument) {
      return result.instrument.isIntraday
        ? ComponentType.InstrumentIntraday
        : ComponentType.Instrument
    } else if (listType === 'product' && result.product) {
      return ComponentType.Product
    } else {
      return ComponentType.MarketChart
    }
  }

  const getDropdownItems = (listItems) => {
    const _listItems = listItems.sort((a: Result, b: Result) => {
      const highNumber = 9999
      const compareByPhrase = () =>
        phrase
          ? (a.name?.toLowerCase().indexOf(phrase.toLowerCase()) ??
            highNumber) -
          (b.name?.toLowerCase().indexOf(phrase.toLowerCase()) ?? highNumber)
          : 0
      const compareByVenue = () => a.venue.localeCompare(b.venue)
      const compareByName = () => a.name.localeCompare(b.name)
      return compareByPhrase() || compareByVenue() || compareByName()
    })
    const iconClass =
      listType === menuTypes.instrument || listType === menuTypes.product
        ? 'oi-list'
        : 'oi-graph'
    return _listItems.slice(0, displayLimit).map((result) => {
      if (triggerOnClick) {
        return (
          <li
            className={`pointer-cursor`}
            onClick={(e) => {
              selectedComponent = result
              return onSelect(e)
            }}
            key={result.id}
          >
            <span className={`oi ${iconClass}`} />
            &nbsp;
            {`${result.name}`}
          </li>
        )
      }
      return (
        <Dragger
          tag="li"
          onDragComplete={(e) => {
            selectedComponent = result
            return onSelect(e)
          }}
          key={result.id}
        >
          <span className={`oi ${iconClass}`} />
          &nbsp;
          {`${result.name}`}
        </Dragger>
      )
    })
  }

  const _increaseLimit = (e) => {
    setDisplayLimit(displayLimit + displayLimitDefault)
  }

  const _showVenuesFilter = (show) => {
    setVenueFilter(show)
  }

  const _changeVenue = (e, venue) => {
    let venues = [...selectedVenues]
    if (e) {
      if (e.target.checked && venues.indexOf(venue) === -1) {
        venues.push(venue)
      } else if (!e.target.checked && venues.indexOf(venue) > -1) {
        venues = venues.filter((v) => v !== venue)
      }
      setDisplayLimit(displayLimitDefault)
      setSelectedVenues(venues)
      setResults(_getFilteredResults(listType, venues, phrase))
    }
  }

  const placeholder = loaded
    ? I18n.t(
      listType === menuTypes.product ? 'product.select' : 'instrument.select'
    )
    : I18n.t('instrument.loading')

  return modificationEnabled ? (
    <div className="instruments" ref={menuRef}>
      <div className="subtitle">
        <MemoTranslate
          value={
            listType === menuTypes.product
              ? 'product.title'
              : 'instrument.title'
          }
          tag={'h2'}
        />
      </div>

      <div className="instruments__search">
        <input
          data-test="instruments-search-input"
          disabled={!loaded}
          className={`form-control ${showContextMenu ? `active` : ``}`}
          placeholder={placeholder}
          type="text"
          value={phrase}
          onChange={search}
          onFocus={() => setShowContextMenu(true)}
        />
      </div>
      <div
        className={
          'instruments__dropdown ' + (showContextMenu ? 'visible' : '')
        }
      >
        <div className="content-switch">
          <div
            className={`content-switch__item${venueFilter ? ' active' : ''}`}
            onClick={() => _showVenuesFilter(true)}
          >
            <MemoTranslate value="instrument.venues" />
          </div>
          <div
            className={`content-switch__item${!venueFilter ? ' active' : ''}`}
            onClick={() => _showVenuesFilter(false)}
          >
            <MemoTranslate
              value={`instrument.show${
                listType[0].toUpperCase() + listType.slice(1)
              }s`}
            />
          </div>
        </div>
        <div className="instruments__results" hidden={venueFilter}>
          <ul data-test="instruments-search-results">
            {getDropdownItems(results)}
            {results.length > displayLimit ? <li onClick={_increaseLimit}><Translate value={"general.dropdown.showMore"} /></li> : null}
          </ul>
        </div>
        <div className="venues__results" hidden={!venueFilter}>
          <ul data-test="venues-search-results">
            {venues.map((venue) => (
              <VenueDropdownItem
                key={venue}
                venue={venue}
                selectedVenues={selectedVenues}
                customId={customId}
                onChangeVenue={_changeVenue}
              />
            ))}
          </ul>
        </div>
        {!hideRadioButtons ? (
          <div className={'type-trigger'}>
            {optionTypes.map((type) => (
              <RadioOption
                key={type}
                onTypeChanged={onTypeChanged}
                customId={customId}
                type={type}
                listType={listType}
              />
            ))}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  ) : (
    <div />
  )
}
export default MarketSheetSelectComponent
