import React, { FC, useState, useCallback } from 'react';

import { connect } from 'react-redux';
import { State } from '../../main/reducers/rootReducer';
import { I18n } from 'react-redux-i18n';
import ModalWindow from '../../shared/modalWindow/components/modalWindow';
import { QuoteRequestFormData, QuoteRequestMode } from '../models/request';
import { getQuoteRequestFormData, isQuoteRequestFormVisible, getSelectedContractId } from '../selectors/requests';
import QuoteRequestForm from '../containers/quoteRequestForm';
import { openQuoteRequest, hideQuoteRequest } from '../actions/requests';
import { FormState } from '../reducers/quoteRequestForm';
import { getModeData } from '../helper/helper';
import { ENTER_KEY } from '../../commonConstants';

export interface QuoteRequestModalProps {
    sideBarVisible: boolean;
    modalVisible: boolean;
    showModal: (formData: QuoteRequestFormData) => void;
    hideModal: () => void;
    selectedContractId: string;
    formData: FormState;
}

interface QuoteRequestModalState {
    modalVisible: boolean;
    submitted: boolean;
    success: boolean;
    error: boolean;
}

const QuoteRequestModalComponent: FC<QuoteRequestModalProps> = (props) => {
  const [state, setState] = useState<QuoteRequestModalState>({
    modalVisible: props.modalVisible,
    submitted: false,
    success: false,
    error: false,
  });
  const [actionCallback, setActionCallback] = useState<(action: string) => void>(() => {});
  const { sideBarVisible, modalVisible } = props;
  const { title, button } = getModeData(props);

  const hideModal = useCallback(() => {
    actionCallback('close');
    props.hideModal();
    setState(prevState => ({ ...prevState, submitted: false }));
  }, [actionCallback, props]);

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === ENTER_KEY && !state.submitted) {
        actionCallback('submit');
        setState(prevState => ({ ...prevState, submitted: true }));
      }
    },
    [state.submitted, actionCallback]
  );

  const handleAction = useCallback(
    (options) => {
      if (options.actionCallback) {
        setActionCallback(() => options.actionCallback);
      }
      if (options.state) {
        const {error, success, modalVisible } = options.state;
        setState((prevState) => ({
          ...prevState,
          error,
          success,
          modalVisible,
          submitted: state.submitted && !error,
        }));
        if (success) {
          hideModal();
        }
      }
    },
    [state.submitted, hideModal]
  );

  const handleSubmitClick = () => {
    if (state.submitted) return;
      actionCallback('submit');
      setState(prevState => ({ ...prevState, submitted: true }));
  };

  return (
    <div
      id="quoteRequestForm"
      style={{ position: 'absolute', bottom: '3.75px', right: sideBarVisible ? '330px' : '7.5px'}}
      onKeyUp={handleKeyPress}
    >
      <ModalWindow
        id="quoteRequestModal"
        onModalClose={hideModal}
        isOpen={modalVisible}
        title={`${I18n.t(title)} `}
      >
        <QuoteRequestForm actionHandler={handleAction} />
        <div className="modal-footer">
          {!state.success && (
            <button
              type="submit"
              className="btn btn-approval w150"
              onClick={handleSubmitClick}
              disabled={state.submitted}
              autoFocus={!state.submitted && props.formData.data.mode === QuoteRequestMode.CANCEL}
            >
              {I18n.t(button)}
            </button>
          )}
        </div>
      </ModalWindow>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  modalVisible: isQuoteRequestFormVisible(state),
  selectedContractId: getSelectedContractId(state),
  formData: getQuoteRequestFormData(state)
});

const mapDispatchToProps = {
  showModal: openQuoteRequest,
  hideModal: hideQuoteRequest
};

export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(QuoteRequestModalComponent);