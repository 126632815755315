import React from 'react'
import { TranslateViewName } from '../utils/profile_views'
import { MemoTranslate } from 'js/shared/i18n/components/memoTranslate'

export const ProfileList = ({
  views,
  activeViewId,
  onItemClick,
  openMore,
  modificationAllowed,
}) => {
  const profilesList = views.map((view) => {
    const label = (
      <label className="dashboard-name">{TranslateViewName(view.name)}</label>
    )
    return (
      <li
        key={view.viewId}
        className="select-item"
        onClick={() => onItemClick(view.viewId)}
      >
        <div className="left-container">
          <span className="oi oi-list" /> {label}
        </div>
        <span
          className={`oi oi-star float-right ${
            view.viewId === activeViewId ? `active` : ``
          }`}
        />
      </li>
    )
  })
  if (modificationAllowed) {
    profilesList.push(
      <li key="profiles-more" className="more" onClick={(e) => openMore()}>
        <MemoTranslate value="sidebar.more" />
      </li>
    )
  }
  return (
    <div className="sidebar__profile-views__list">
      <ul>{profilesList}</ul>
    </div>
  )
}

export default ProfileList
