import sum from 'hash-sum'
import {
  FC,
  Fragment, useCallback,
  useEffect,
  useState,
} from 'react'
import { useSelector } from "react-redux";
import { RecentActionsComponent } from '../../../../../dashboard/components/recentActions'
import { setDockExpiryKeysOrder } from '../../../../dock/actions/dock'
import { ITableColumn } from '../../../models/table'

import MarketActions from "./MarketAction";
import UiMarketInstrumentTableGrid from "./uiMarketInstrumentTableGrid";

import { remove, reorderMarkets, setColumnWidths, setColumns, setOrderbookDepth, triggerExpandedExpiry } from '../../../actions/market'
import { IMarket } from "../../../models/market";

import { store } from "../../../../../main/store/store";
import orderbooksStore from "../../../../../orderbook/store/orderbooks";

import { DashboardComponent } from "../../../../../dashboard/models/dashboard";
import { PeriodType } from "../../../../../orderbook/models/contracts";
import {
  createMasterDataIdString, getOrderbookContractById,
  venueFromContractWithVenue,
} from '../../../../../orderbook/selectors/contracts'
import {
    getContractMatrixesForDockId,
    getExpiriesByDockId,
    getOrderbookPricesForDockId,
} from "../../../../../orderbook/selectors/orderbooks";
import { openPriceAlarmForm } from "../../../../../priceAlarm/actions/priceAlarms";
import { createPriceAlarmFormData } from "../../../../../priceAlarm/helper/priceAlarms";
import { PriceAlarmFormData } from "../../../../../priceAlarm/models/priceAlarms";

import { MasterDataId } from "../../../../../main/models/application";
import { IFavorite } from "../../../../../shared/favorite/models/favorite";

import { ComponentType } from "../../../models/component";
import { DockScrollState } from "../../../../../shared/dock/models/dock";

interface IBookViewProps {
    dockId: string;
    markets?: IMarket[];
    hiddenExpiries?: string[];
    expiryRowsLength?: { [key: string]: number };
    favoriteName?: string;
    isTableBorderActive?: boolean;
    anonymizeMarketDataEnabled?: boolean;
    isSeparateCells?: boolean;
    colors?: { [key: string]: string };
    triggerExpandedExpiry?: (
      marketIds: string[],
      expandedExpiry: any,
      dockId: string,
    ) => void;
    onCreateChart?: (e: any, component: DashboardComponent) => void;
    onCreateTable?: (e: any, components: RecentActionsComponent[]) => void;
    isHeadlinesVisible?: boolean;
    presetDepths?: { [expiryCode: string]: number };
    modificationAllowed?: boolean;
    favorites?: IFavorite[];
    settings?: { [key: string]: any };
    dockSize: DockScrollState;
    onAddMarket: (event, dockId) => void;
    dockExpiryOrder: string[];
    dockVisible: boolean;
}

const UiBookComponentGrid: FC<IBookViewProps> = ({
    dockId,
    markets,
    hiddenExpiries,
    favoriteName,
    isHeadlinesVisible,
    modificationAllowed,
    anonymizeMarketDataEnabled,
    favorites,
    presetDepths,
    settings,
    expiryRowsLength,
    onCreateChart,
    onCreateTable,
    dockSize,
    colors,
    onAddMarket,
    isTableBorderActive,
    dockExpiryOrder,
    dockVisible
}) => {

    const matrixes = useSelector(getContractMatrixesForDockId(dockId));
    const prices = useSelector(getOrderbookPricesForDockId(dockId));
    const expiries = useSelector(getExpiriesByDockId(dockId));
    const [expiryKeys, setExpiryKeys] = useState<string[]>([]);
    const sortedPeriodTypes = Object.keys(PeriodType);
    const visibleExpiries = Object.keys(expiries).filter(
      (e) => hiddenExpiries.indexOf(e) === -1,
    );

    const isIntraday = visibleExpiries.reduce(
      (acc: boolean, key: string) => acc && !isNaN(Number(key)),
      true,
    );
    const expiryLengthCounter = { ...expiryRowsLength };

    const whichPeriodType = (s: string) => sortedPeriodTypes.indexOf(s.replace(/\d+$/, ""));

    const onRowDragEndHandler = useCallback((prevRowNode: any, nextDataNode: any) => {
      const previousdata = prevRowNode?.data;
      const nextData = nextDataNode?.data;
      const copyExpiriesKeys = [...expiryKeys];
      if (previousdata && nextData) {
        const previousIndex = copyExpiriesKeys
          .findIndex((key: string) => key === previousdata.expiryKey);
        const nextIndex = copyExpiriesKeys
          .findIndex((key: string) => key === nextData.expiryKey);
        [copyExpiriesKeys[previousIndex], copyExpiriesKeys[nextIndex]] = [copyExpiriesKeys[nextIndex], copyExpiriesKeys[previousIndex]];

        setExpiryKeys(copyExpiriesKeys);
        store.dispatch(setDockExpiryKeysOrder(dockId, copyExpiriesKeys));
      }
    }, [expiryKeys, dockExpiryOrder]);

    const onOpenPriceAlarmForm = (formData: PriceAlarmFormData | null) => {
      if (formData) {
        store.dispatch(openPriceAlarmForm(formData));
      }
    };

    const handleNewPriceAlarm = (contractId: string, group: string, price: number) => {
      const contract = getOrderbookContractById(orderbooksStore.getState(), contractId);

      onOpenPriceAlarmForm(
        createPriceAlarmFormData(
          contractId,
          contract?.name,
          contract?.nameWithVenue,
          venueFromContractWithVenue(contract?.nameWithVenue),
          price,
          group,
        ));
    };

    const onAddMarketCallback = useCallback((event) => {
      onAddMarket(event, dockId)
    }, [])



    const transformUiMarketInstrumentParameters = () => {
      const marketInstrument: Map<string, IMarket & {version: string}> = new Map();
      const matrices = { ...matrixes };
      const currentPrices = { ...prices };

      for (let i = 0; i < markets.length; i++) {
        const alreadyCheckedMarket = marketInstrument.get(markets[i].itemId);
        if (!alreadyCheckedMarket) {
          marketInstrument.set(markets[i].itemId, { ...markets[i], version: '' });
        } else {
          if (matrixes[markets[i].itemId]) {
            matrices[alreadyCheckedMarket.itemId] = {
              ...matrixes[alreadyCheckedMarket.itemId],
              ...{
                  expiries: {
                    ...matrices[alreadyCheckedMarket.itemId].expiries,
                    ...matrixes[markets[i].itemId].expiries
                  },
                  expiryToContract: {
                    ...matrices[alreadyCheckedMarket.itemId]?.expiryToContract,
                    ...matrixes[markets[i].itemId]?.expiryToContract,
                  },
                  name: matrixes[alreadyCheckedMarket.itemId].instrumentName,
                },
            };
            matrices[markets[i].itemId] = undefined;
            currentPrices[alreadyCheckedMarket.itemId].orders = {
              ...currentPrices[alreadyCheckedMarket.itemId].orders,
              ...prices[markets[i].itemId].orders,
            };
            currentPrices[alreadyCheckedMarket.itemId].trades = {
              ...currentPrices[alreadyCheckedMarket.itemId].trades,
              ...prices[markets[i].itemId].trades,
            };
            currentPrices[alreadyCheckedMarket.itemId].version = sum(JSON.stringify(currentPrices[alreadyCheckedMarket.itemId]))
            marketInstrument.set(markets[i].itemId, {
              ...alreadyCheckedMarket,
              ...markets[i],
              itemId: alreadyCheckedMarket.itemId,
            })
          }
        }
        marketInstrument.get(markets[i].itemId).version = sum(JSON.stringify(markets[i]))
        if (currentPrices[markets[i].itemId]) {
          currentPrices[markets[i].itemId].version = sum(JSON.stringify(currentPrices[markets[i].itemId]));
        }

      }
      const expiryMap = {}
      for (let i = 0; i < visibleExpiries.length; i++) {
        let expiryItems = expiries[visibleExpiries[i]];
        if (isIntraday) {
          expiryItems = expiries[visibleExpiries[i]].filter((expiry: any) => {
            let inLengthLimit = false;
            if (expiryLengthCounter[expiry.periodType] === undefined) {
              inLengthLimit = true;
            }
            if (expiryLengthCounter[expiry.periodType] > 0) {
              inLengthLimit = true;
              expiryLengthCounter[expiry.periodType]--;
            }

            return (
              inLengthLimit && hiddenExpiries.indexOf(expiry.periodType) === -1
            );
          });
        }
        expiryMap[visibleExpiries[i]] = expiryRowsLength[
          visibleExpiries[i]
          ]
          ? expiryItems.slice(0, expiryRowsLength[visibleExpiries[i]])
          : expiryItems;
      }
      return { instrumentToMarket: marketInstrument, adjustedPrices: currentPrices, adjustedMatrices: matrices, filteredExpiries: expiryMap };
    };

    const { instrumentToMarket, adjustedPrices, adjustedMatrices, filteredExpiries } = transformUiMarketInstrumentParameters();
    const instrumentTables = Object.keys(instrumentToMarket);

    useEffect(() => {
      const filteredExpiries = Object.keys(expiries)
        .filter((key: any) => expiries[key].length > 0)
        .filter(key => hiddenExpiries.indexOf(key) === -1)

      if (!expiryKeys.length
          || expiryKeys.length !== filteredExpiries.length
          || (dockExpiryOrder && JSON.stringify(expiryKeys) !== JSON.stringify(dockExpiryOrder))) {
        const keys = filteredExpiries
            .sort((e1: string, e2: string) => {
              if (dockExpiryOrder) {
                const index1 = dockExpiryOrder.indexOf(e1)
                const index2 = dockExpiryOrder.indexOf(e2)
                if (index1 === -1 || index2 === -1) {
                  return whichPeriodType(e1) - whichPeriodType(e2)
                } else {
                  return index1 - index2
                }
              }
              return whichPeriodType(e1) - whichPeriodType(e2)
            });

        setExpiryKeys(keys)
      }
    }, [expiries, Object.keys(expiries).length, dockExpiryOrder]);

    useEffect(() => {
      const allExpiryKeys = Object.keys(expiries);
      const updatedKeys = allExpiryKeys.filter((key: string) => !hiddenExpiries.includes(key));
      setExpiryKeys([...updatedKeys]);
    }, [hiddenExpiries.length]);
    return (
        <div data-test="orderbook" key="marketsheet" className="orderbook">
            <Fragment>
            <div
              className="market__actions ml-auto"
              style={{
                left: `${dockSize.tabLeft + dockSize.width - 145}px`,
                top: `${dockSize.tabTop - 32}px`,
              }}>
                <MarketActions
                  dockId={dockId}
                  markets={markets}
                  expiries={expiries}
                  hiddenExpiries={hiddenExpiries}
                  favoriteName={favoriteName}
                  isHeadlinesVisible={isHeadlinesVisible}
                  modificationAllowed={modificationAllowed}
                  favorites={favorites}
                  onAddMarket={onAddMarketCallback}
                />
              </div>
              <div className="books-view grid-wrapper"
                   style={{ fontSize: `var(--font-size-${dockId})` }}>
                    <UiMarketInstrumentTableGrid
                      anonymizeMarketDataEnabled={anonymizeMarketDataEnabled}
                      colors={colors}
                      onRowDragEndHandler={onRowDragEndHandler}
                      expiryKeys={expiryKeys}
                      presetDepths={presetDepths}
                      settings={settings}
                      onCreateChart={onCreateChart}
                      onCreateTable={onCreateTable}
                      handleNewPriceAlarm={handleNewPriceAlarm}
                      instrumentToMarket={instrumentToMarket}
                      adjustedMatrices={adjustedMatrices}
                      adjustedPrices={adjustedPrices}
                      expiries={filteredExpiries}
                      isTableBorderActive={isTableBorderActive}
                      onExpiryClick={(expiryRow: any, expanded: boolean) =>
                        store.dispatch(
                          triggerExpandedExpiry(
                            expiryRow,
                            dockId,
                            expanded
                          ),
                        )
                      }
                      onMarketRemove={(marketId: string) => store.dispatch(
                        remove(marketId, dockId, ComponentType.Instrument),
                      )}
                      setOrderbookDepth={(depth, code) =>
                        store.dispatch(setOrderbookDepth(depth, code, dockId))
                      }
                      onGroupColumnMoved={(itemIds: MasterDataId[]) => {
                        store.dispatch(reorderMarkets(itemIds.map(id => createMasterDataIdString(id)), dockId))
                      }}
                      onColumnMoved={(id: string, columns: ITableColumn[]) => {
                        store.dispatch(setColumns(id, columns))
                      }}
                      onColumnResized={(id: string, columnWidths: {[colId: string]: number}) => {
                        store.dispatch(setColumnWidths(id, columnWidths))
                      }}
                      dockVisible={dockVisible}
                    />
              </div>
            </Fragment>
        </div>
    );
};

export default UiBookComponentGrid;
