import { ITable, ITableSort, ITableSearchTag, IColumnName, ITableColumn } from '../models/table';
import { LogAction } from '../../../main/middlewares/logger';
import { LogLevel } from '../../logger/models/logger';
import { I18n } from 'react-redux-i18n';
import { FilterType } from '../models/tableSpecificFilters';
import { ComponentType } from '../models/component';

export enum ActionTypes {
  LOAD = '[UI][Table] Load',
  CREATE = '[UI][Table] Create',
  SORT = '[UI][Table] Sort',
  TRIGGER_COLUMN_NAMES = '[UI][Table] Show/hide column names',
  HIDE_ROWS = '[UI][Table] Hide Rows',
  SHOW_ROWS = '[UI][Table] Show Rows',
  PIN_ROWS = '[UI][Table] Pin Rows',
  UNPIN_ROWS = '[UI][Table] Unpin Rows',
  TRIGGER_HEADLINES = '[UI][Table] Show/hide headlines',
  TRIGGER_SHOW_HIDDEN = '[UI][Table] Show/hide hidden rows',
  TRIGGER_SEARCH_TAGS = '[UI][Table] Trigger search tags',
  REMOVE = '[UI][Table] Remove',
  PAGINATED_REQUEST = '[Table] Paginated request',
  TRIGGER_TABLE_SPECIFIC_FILTER = '[Table] Trigger table specific action',
  PAGINATED_RESULT = '[Table] Paginated result',
  TABLE_LOAD_FAILURE = '[Table] Table load data failure',
  TRIGGER_DOWNLOAD_CSV = '[Table] Trigger csv download',
  SUCCESS_DOWNLOAD_CSV = '[Table] Successful csv download',
  FAILED_DOWNLOAD_CSV = '[Table] Failes csv download',
  SET_COLUMN_WIDTH = '[UI][Table] Set column width',
  SET_COLUMNS = '[UI][Table] Set columns'
}

export interface TableLoadAction {
  type: ActionTypes.LOAD;
  payload: ITable[];
}

export interface TableCreateAction {
  type: ActionTypes.CREATE;
  payload: ITable[];
}

export interface TableSortAction {
  type: ActionTypes.SORT;
  payload: { id: string; sorting: ITableSort[] };
}

export interface TableTriggerColumnNamesAction {
  type: ActionTypes.TRIGGER_COLUMN_NAMES;
  payload: { id: string; hiddenColumnNames: string[] };
}

export interface TableTriggerHeadlinesAction {
  type: ActionTypes.TRIGGER_HEADLINES;
  payload: { id: string; showHeadlines: boolean };
}

export interface TableSpecificTriggerFilterAction {
  type: ActionTypes.TRIGGER_TABLE_SPECIFIC_FILTER;
  payload: { id: string; filter: FilterType, enable: boolean };
}

export interface TableTriggerShowHiddenAction {
  type: ActionTypes.TRIGGER_SHOW_HIDDEN;
  payload: { id: string; showingHidden: boolean };
}

export interface TableHideRowsAction {
  type: ActionTypes.HIDE_ROWS;
  payload: { id: string; hiddenRows: number[] };
}

export interface TableShowRowsAction {
  type: ActionTypes.SHOW_ROWS;
  payload: { id: string; rows: number[] };
}

export interface TablePinRowsAction {
  type: ActionTypes.PIN_ROWS;
  payload: { id: string; pinnedRows: number[] };
}

export interface TableUnpinRowsAction {
  type: ActionTypes.UNPIN_ROWS;
  payload: { id: string; rows: number[] };
}

export interface TableTriggerSearchTagAction extends LogAction {
  type: ActionTypes.TRIGGER_SEARCH_TAGS;
  payload: { id: string; tags: ITableSearchTag[] };
}

export interface TableRemoveAction {
  type: ActionTypes.REMOVE;
  payload: string;
}

export interface TablePaginatedRequestAction {
  type: ActionTypes.PAGINATED_REQUEST;
  payload: {from: number; sorting: ITableSort[]; type: ComponentType, id: string, tags: ITableSearchTag[]};
}

export interface TablePaginatedResultAction {
  type: ActionTypes.PAGINATED_RESULT;
  payload: any;
  componentType: ComponentType;
  replace: boolean;
}

export interface TableLoadFailureAction {
  type: ActionTypes.TABLE_LOAD_FAILURE;
  error: any;
}

export interface TableDownloadCsvAction {
  type: ActionTypes.TRIGGER_DOWNLOAD_CSV;
  payload: {visibleColumns: IColumnName[], hidden: number[], sorting: ITableSort[], type: ComponentType, id: string, tags: ITableSearchTag[], filter: {[key: string]: boolean} };
}

export interface SuccessTableDownloadCsvAction extends LogAction {
  type: ActionTypes.SUCCESS_DOWNLOAD_CSV;
  componentType: ComponentType;
}

export interface FailedTableDownloadCsvAction extends LogAction {
  type: ActionTypes.FAILED_DOWNLOAD_CSV;
  componentType: ComponentType;
}

export interface SetColumnWidthsAction {
  type: ActionTypes.SET_COLUMN_WIDTH;
  payload: {
    tableId: string;
    name: string;
    width: number;
  };
}

export interface SetColumnsAction {
  type: ActionTypes.SET_COLUMNS;
  payload: {
    tableId: string;
    columns: ITableColumn[];
  };
}

export function load(tables: ITable[]): TableLoadAction {
  return {
    type: ActionTypes.LOAD,
    payload: tables
  };
}

export function create(tables: ITable[]): TableCreateAction {
  return {
    type: ActionTypes.CREATE,
    payload: tables
  };
}

export function sort(id: string, sorting: ITableSort[]): TableSortAction {
  return {
    type: ActionTypes.SORT,
    payload: { id: id, sorting: sorting }
  };
}

export function triggerColumnNames(
  id: string,
  hiddenColumnNames: string[]
): TableTriggerColumnNamesAction {
  return {
    type: ActionTypes.TRIGGER_COLUMN_NAMES,
    payload: { id: id, hiddenColumnNames: hiddenColumnNames }
  };
}

export function triggerHeadlines(
  id: string,
  showHeadlines: boolean
): TableTriggerHeadlinesAction {
  return {
    type: ActionTypes.TRIGGER_HEADLINES,
    payload: { id: id, showHeadlines: showHeadlines }
  };
}

export function triggerTableSpecificAction(
  id: string,
  filter: FilterType,
  enable: boolean
): TableSpecificTriggerFilterAction {
  return {
    type: ActionTypes.TRIGGER_TABLE_SPECIFIC_FILTER,
    payload: { id: id, filter: filter, enable: enable }
  };
}

export function triggerShowHidden(
  id: string,
  showingHidden: boolean
): TableTriggerShowHiddenAction {
  return {
    type: ActionTypes.TRIGGER_SHOW_HIDDEN,
    payload: { id, showingHidden }
  };
}

export function hideRows(
  id: string,
  hiddenRows: number[]
): TableHideRowsAction {
  return {
    type: ActionTypes.HIDE_ROWS,
    payload: { id: id, hiddenRows: hiddenRows }
  };
}

export function showRows(id: string, rows: number[]): TableShowRowsAction {
  return {
    type: ActionTypes.SHOW_ROWS,
    payload: { id: id, rows: rows }
  };
}

export function pinRows(
  id: string,
  rows: number[]
): TablePinRowsAction {
  return {
    type: ActionTypes.PIN_ROWS,
    payload: { id: id, pinnedRows: rows }
  };
}

export function unpinRows(id: string, rows: number[]): TableUnpinRowsAction {
  return {
    type: ActionTypes.UNPIN_ROWS,
    payload: { id: id, rows: rows }
  };
}

export function triggerSearchTags(id: string, tags: ITableSearchTag[]): TableTriggerSearchTagAction {
  return {
    type: ActionTypes.TRIGGER_SEARCH_TAGS,
    payload: { id: id, tags: tags },
    logLevel: LogLevel.DEBUG,
    logMessage: I18n.t('table.search', {tags: tags.map(tag => tag.name).join(',')})
  };
}

export function remove(id: string): TableRemoveAction {
  return {
    type: ActionTypes.REMOVE,
    payload: id
  };
}

export function paginatedRequest(from: number, sorting: ITableSort[], type: ComponentType, id: string, tags: ITableSearchTag[]): TablePaginatedRequestAction {
  return {
    type: ActionTypes.PAGINATED_REQUEST,
    payload: {
      from: from,
      sorting: sorting,
      type: type,
      id: id,
      tags: tags
    }
  };
}

export function paginatedResult(payload: any, type: ComponentType, replace: boolean): TablePaginatedResultAction {
  return {
    type: ActionTypes.PAGINATED_RESULT,
    payload: payload,
    componentType: type,
    replace: replace
  };
}

export function tableLoadFailure(error: any): TableLoadFailureAction {
  return {
    type: ActionTypes.TABLE_LOAD_FAILURE,
    error: error
  };
}

export function triggerDownloadCsv
(visibleColumns: IColumnName[], hidden: number[], sorting: ITableSort[], type: ComponentType, id: string, tags: ITableSearchTag[], filters: {[key: string]: boolean}): TableDownloadCsvAction {
  return {
    type: ActionTypes.TRIGGER_DOWNLOAD_CSV,
    payload: {
      hidden: hidden,
      sorting: sorting,
      type: type,
      id: id,
      tags: tags,
      filter: filters,
      visibleColumns
    }
  };
}

export function successDownloadCsv(component: ComponentType): SuccessTableDownloadCsvAction {
  const comp = I18n.t(`recentAction.${component}`);
  return {
    type: ActionTypes.SUCCESS_DOWNLOAD_CSV,
    componentType: component,
    logLevel: LogLevel.INFO,
    logMessage: I18n.t('csv.success', { component: comp})
  };
}

export function failedDownloadCsv(component: ComponentType): FailedTableDownloadCsvAction {
  const comp = I18n.t('recentAction.' + component);
  return {
    type: ActionTypes.FAILED_DOWNLOAD_CSV,
    componentType: component,
    logLevel: LogLevel.ERROR,
    logMessage: I18n.t('csv.failed', { component: comp })
  };
}

export function setColumnWidths(tableId: string, name: string, width: number): SetColumnWidthsAction {
  return {
    type: ActionTypes.SET_COLUMN_WIDTH,
    payload: {
      tableId,
      name,
      width
    }
  };
}

export function setColumns(tableId: string, columns: ITableColumn[]): SetColumnsAction {
  return {
    type: ActionTypes.SET_COLUMNS,
    payload: {
      tableId,
      columns
    }
  };
}

export type Action =
  | TableLoadAction
  | TableCreateAction
  | TableSortAction
  | TableTriggerColumnNamesAction
  | TableTriggerHeadlinesAction
  | TableTriggerShowHiddenAction
  | TableHideRowsAction
  | TablePinRowsAction
  | TableUnpinRowsAction
  | TableShowRowsAction
  | TableTriggerSearchTagAction
  | TableRemoveAction
  | TablePaginatedRequestAction
  | TableSpecificTriggerFilterAction
  | TablePaginatedResultAction
  | TableLoadFailureAction
  | TableDownloadCsvAction
  | SuccessTableDownloadCsvAction
  | FailedTableDownloadCsvAction
  | SetColumnWidthsAction
  | SetColumnsAction;
