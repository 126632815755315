import * as React from 'react';
import { State } from '../../../main/reducers/rootReducer';
import { connect } from 'react-redux';
import { saveConfig } from '../actions/notifications';
import ModalWindow from '../../modalWindow/components/modalWindow';
import { getVersion } from '../../logger/selectors/logger';
import { config } from '../../../main/config';
import { I18n, Translate } from 'react-redux-i18n';
import {getInstanceId} from "../../../requests/selectors/requests";

export interface VersionInfoProps {
    version: string;
    instanceId: string;
    modalVisible: boolean;
    closeModal: () => void;
}

export interface VersionInfoState {
    modalVisible: boolean;
}

export class VersionInfoModalComponent extends React.Component<
    VersionInfoProps,
    VersionInfoState
    > {
    constructor(props: VersionInfoProps) {
        super(props);
        this.state = {
            modalVisible: props.modalVisible
        };
    }

    render() {
        const { modalVisible, version, instanceId } = this.props;
        const copyright = config.branding.company.name;
        const year = new Date().getFullYear();
        const linkToWebsite = config.branding.company.link;
        return (
            <div className="meet-settings__modal">
                <ModalWindow
                    id="version-info"
                    onModalClose={() =>
                        this.props.closeModal()
                    }
                    isOpen={modalVisible}
                    title={'info.title'}
                >
                    <div>
                        <h4><Translate value="sidebar.version.server" /></h4>
                        <p>
                            {config.backendVersion}
                        </p>
                        <h4><Translate value="sidebar.version.client" /></h4>
                        <p>
                            {version}
                        </p>
                        <h4><Translate value="sidebar.version.instance" /></h4>
                        <p>
                            {instanceId}
                        </p>
                        <label hidden={linkToWebsite !== null}>
                            {/* <Translate value="info.copyright" /> */}
                            &copy; {year} {copyright}
                        </label>
                        <label hidden={linkToWebsite === null}>
                            {/* <Translate value="info.copyright" /> */}
                            <a href={linkToWebsite === null ? '#' : linkToWebsite}>&copy; {year} {copyright}</a>
                        </label>
                    </div>
                    <div className="modal-footer">
                        <button
                            className="btn btn-approval w150"
                            data-dismiss="modal"
                            onClick={() => this.props.closeModal()}
                            autoFocus={true}
                            >
                            {I18n.t('modal.ok')}
                        </button>
                    </div>
                </ModalWindow>
            </div>
        );
    }
}

const mapStateToProps = (state: State) => ({
    version: getVersion(state),
    instanceId: getInstanceId(state)
});

const mapDispatchToProps = {
    saveConfig: saveConfig
};

export default connect<any, any, any>(
    mapStateToProps,
    mapDispatchToProps
)(VersionInfoModalComponent);
