import { State } from '../../main/reducers/rootReducer'
import { createSelector } from 'reselect'
import { argsSelectorCreator } from '../../shared/utils/selectors/agrSelector'

export const getProfileState = (state: State) => state.profile

export const getActiveViewId = argsSelectorCreator(
  getProfileState,
  (s) => s.activeViewId
)
export const getAvailableViews = createSelector(getProfileState, (s) =>
  s.views.sort((a: any, b: any) => a.name.localeCompare(b.name))
)

export const getVisibleViewIds = createSelector(
  getProfileState,
  (s) => s.visibleViewIds
)

export const getViewStatus = createSelector(
  getProfileState,
  (s) => s.viewStatus
)

export const getServerProfileVersion = createSelector(
  getProfileState,
  (s) => s.serverProfileVersion
)

export const getFlexLayout = createSelector(
  getProfileState,
  (s) => s.flexLayout
)
