import React, { FC } from 'react';
import { BasketOrder } from '../models/model';
import { Translate, I18n } from 'react-redux-i18n';

export interface BulkProps {
    errors: { [row: number]: string[] };
    bulkOrders: BasketOrder[];
    parsingRunning: boolean;
}

export const BulkErrorsTable: FC<BulkProps> = ({
  errors,
  bulkOrders,
  parsingRunning,
}) => {
  const createRow = (index: number, errorNumber: number, error: string) => (
    <tr key={index + '__' + errorNumber}>
      <td colSpan={1} className="bulkRow">{index}</td>
      <td colSpan={1} className="error">
        <label>{error}</label>
      </td>
    </tr>
  );

  const getRowsForRowNumber = (index: number) => {
    const errorsForRow = errors[index];
    const rows = [];
    for (let i = 0; i < errorsForRow.length; i++) {
      rows.push(createRow(index, i, I18n.t('bulk.error.' + errorsForRow[i])));
    }
    return rows;
  };

  const getParsingRunningRow = () => getEmptyWithText('bulk.parsing');

  const getEmptyRow = () => {
    let displayText = bulkOrders.length === 0 ? 'bulk.noUpload' : 'bulk.noErrors';
    return getEmptyWithText(displayText);
  };

  const getEmptyWithText = (displayText: string) => (
    <tr><td key={'empty'} colSpan={1} className="text-center"><Translate value={displayText} /></td></tr>
  );

  const errorKeys = Object.keys(errors);
  const empty = getEmptyRow();
  const table = errorKeys.map(key => getRowsForRowNumber(parseInt(key, 0)));

  const colgroup = (
    <colgroup>
      <col key="bulkRow" className="bulkRow" />
      <col key="error" className="error" />
    </colgroup>
  );

  return (
    parsingRunning
      ? (
        <table>
          <tbody>
          {getParsingRunningRow()}
          </tbody>
        </table>
      )
      : (
        <div className="sidebar__bulk-views">
          <table className="table BulkError">
            {colgroup}
            <thead>
            <tr>
              <th className="bulkRow" scope={'col'}>
                <Translate value="bulk.row" />
              </th>
              <th className="error" scope={'col'}>
                <Translate value="bulk.errors" />
              </th>
            </tr>
            </thead>
            <tbody className="scrollable unpinnedTable">
            {errorKeys.length === 0 ? empty : table}
            </tbody>
          </table>
        </div>
      )
  );
};