import React from 'react'
import { Translate } from 'react-redux-i18n'
import { TranslateViewName } from '../utils/profile_views'
import { MemoTranslate } from 'js/shared/i18n/components/memoTranslate'

export const ConfirmationReplaceBlock = ({ viewName, onSuccess }) => {
  return (
    <div className="confirmation-block">
      <div>
        <p>
          <Translate
            value="profile.modal.replaceQuestion"
            name={TranslateViewName(viewName)}
          />
        </p>
      </div>
      <div className="modal-footer d-flex">
        <button
          className="btn btn-approval w150 ml-auto"
          onClick={onSuccess}
          autoFocus={true}
        >
          <MemoTranslate value="profile.modal.replace" />
        </button>
      </div>
    </div>
  )
}
