import React, { useState } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { DockType } from 'js/shared/dock/models/dock';
import { IFavorite } from '../models/favorite';
import { config } from 'js/main/config';
import Dragger from 'js/shared/dragger/container/Dragger';
import { isBlocked } from '../utils';


interface FavoriteItem {
  favorite: IFavorite;
  blocked: boolean;
  visibleBefore: boolean;
  visibleAfter: boolean;
  deleteAfter: boolean;
}

interface FavoriteSelectableListProps {
  favorites: IFavorite[];
  activeMarketInstruments?: string[];
  activeChartInstruments?: string[];
  activeMarketProducts?: string[];
  onSelect: (e: any, favorite: IFavorite) => void;
  onClose: () => void;
  onFavoriteRemove: (favorite: IFavorite) => void;
  onFavoriteChangeVisibility: (favoriteId: string, isVisible: boolean) => void;
}

export const FavoriteSelectableList: React.FC<FavoriteSelectableListProps> = ({
  favorites,
  activeMarketInstruments = [],
  activeChartInstruments = [],
  activeMarketProducts = [],
  onSelect,
  onClose,
  onFavoriteRemove,
  onFavoriteChangeVisibility
}) => {
  const [items, setItems] = useState<FavoriteItem[]>(favorites.map(favorite => ({
    favorite,
    blocked: isBlocked(favorite, activeMarketInstruments, activeMarketProducts, activeChartInstruments),
    visibleBefore: favorite.isVisible,
    visibleAfter: favorite.isVisible,
    deleteAfter: false
  })));

  const handleToggleVisible = (favoriteId: string) => {
    setItems(prevItems => prevItems.map(item => item.favorite.id === favoriteId ? {
      ...item,
      visibleAfter: !item.visibleAfter
    } : item));
  };

  const handleDelete = (favoriteId: string) => {
    setItems(prevItems => prevItems.map(item => item.favorite.id === favoriteId ? {
      ...item,
      deleteAfter: true
    } : item));
  };

  const handleSave = () => {
    items.forEach(item => {
      if (item.deleteAfter) {
        onFavoriteRemove(item.favorite);
      } else if (item.visibleBefore !== item.visibleAfter) {
        onFavoriteChangeVisibility(item.favorite.id, item.visibleAfter);
      }
    });
    onClose();
  };

  const tableBody = items
    .filter(item => !item.deleteAfter)
    .map(item => (
      <tr key={item.favorite.id}>
        <td>
          <Dragger
            tag="div"
            className={'dragger-item' + (item.blocked ? ' blocked' : '')}
            onDragComplete={(e: any) => onSelect(e, item.favorite)}
          >
            <div className="left-container">
              {item.favorite.entityType === DockType.Market || item.favorite.entityType === DockType.MarketIntraday || item.favorite.entityType === DockType.ProductMarket ? (
                <span className="oi oi-list" />
              ) : (
                <span className="oi oi-graph" />
              )}
              <span className="favorite-name">
                <label>{item.favorite.name}</label>
              </span>
            </div>
          </Dragger>
        </td>
        <td className="text-center">
          <input
            type="checkbox"
            id={item.favorite.id}
            checked={item.visibleAfter}
            disabled={!item.visibleAfter && items.filter(i => i.visibleAfter && !i.deleteAfter).length >= config.sidebarFavoritesListLimit}
            onChange={() => handleToggleVisible(item.favorite.id)}
          />
          <label htmlFor={item.favorite.id}>&nbsp;</label>
        </td>
        <td className="text-center">
          <i
            className="oi oi-trash ml-auto failed"
            onClick={() => handleDelete(item.favorite.id)}
            title={I18n.t('modal.favoriteRemove')}
          />
        </td>
      </tr>
    ));

  return (
    <div className="sidebar__favorite-views__borderless-list">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>
              <Translate value="favorite.form.name" />
            </th>
            <th className="text-center">
              <Translate value="favorite.listed" />
            </th>
            <th className="text-center">
              <Translate value="modal.favoriteRemove" />
            </th>
          </tr>
        </thead>
        <tbody>
          {tableBody}
        </tbody>
      </table>
      <div className="d-flex">
        <button
          className="btn btn-approval w150 ml-auto"
          onClick={handleSave}
        >
          <Translate value="favorite.form.save" />
        </button>
      </div>
    </div>
  );
};


