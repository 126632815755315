import * as React from 'react'
import { gedPeriodTypesForCharts } from '../../../../../orderbook/selectors/orderbooks'
import { Chart } from '../../../models/chart'
import {
  remove,
  setPeriodType,
  setContractId,
  setGroupTypes,
} from '../../../actions/chart'
import { DockScrollState, DockType } from '../../../../dock/models/dock'
import store from '../../../../../main/store/store'
import ChartActions from './chartActions'
import {
  createMasterDataIdString,
  getOrderbookContracts,
} from '../../../../../orderbook/selectors/contracts'
import orderBookStore from '../../../../../orderbook/store/orderbooks'
import { I18n, Translate } from 'react-redux-i18n'
import { LogLevel } from '../../../../logger/models/logger'
import { log } from '../../../../logger/actions/logger'

import { IFavorite } from '../../../../../shared/favorite/models/favorite'
import { OhlcPeriod } from '../../../../../orderbook/models/charts'
import { Contract } from '../../../../../orderbook/models/contracts'
import { MasterDataId } from '../../../../../main/models/application'
import { Provider } from 'react-redux'
import MarketChart from '../../../../../shared/chart/containers/MarketChart'

interface ChartBlockProps {
  chart: Chart
  periodTypes: string[]
  siblings: number
  onChartSetPeriodType: (chartId: string, periodType: string) => void
  onChartSetContract: (
    chartId: string,
    contractId: string,
    dockId?: string,
    ohlcPeriod?: OhlcPeriod
  ) => void
  onChartSetGroupTypes: (chartId: string, groupTypes: string[]) => void
  drawings: { [contractName: string]: any }
}
interface ChartBlockState {
  contracts: { [key: string]: Contract }
}

class ChartBlock extends React.Component<ChartBlockProps, ChartBlockState> {
  constructor(props: ChartBlockProps) {
    super(props)
    this.onContractNoLongerActive = this.onContractNoLongerActive.bind(this)
    const contracts = getOrderbookContracts(orderBookStore.getState())
    this.state = {
      contracts,
    }
  }

  componentWillMount() {
    const { chart, periodTypes } = this.props
    if (chart.selectedPeriodType === '' && periodTypes) {
      const flatPeriodTypes = periodTypes.flatMap((p) => p)
      this.props.onChartSetPeriodType(chart.id, flatPeriodTypes[0])
    }
  }

  componentWillReceiveProps(newProps: ChartBlockProps) {
    const { chart } = this.props
    if (chart.selectedPeriodType === '' && newProps.periodTypes) {
      const flatPeriodTypes = newProps.periodTypes.flatMap((p) => p)
      this.props.onChartSetPeriodType(chart.id, flatPeriodTypes[0])
    }
  }

  informUser(notification: string, level: LogLevel) {
    store.dispatch(log(notification, level))
  }

  onContractNoLongerActive(
    contracts: { contractId: MasterDataId; expiryName: string }[]
  ) {
    const { chart } = this.props
    this.informUser(I18n.t('charts.noLongerActive'), LogLevel.WARN)
    return this.props.onChartSetContract(
      chart.id,
      createMasterDataIdString(contracts[0].contractId)
    )
  }

  render() {
    const { chart, siblings, drawings, onChartSetContract } = this.props

    return (
      <div className="m_chart">
        <div className="m_chart__content">
          <Provider store={orderBookStore}>
            <MarketChart
              dockId={chart.dockId}
              groupTypes={chart.groupTypes}
              siblings={siblings}
              chart={chart}
              drawings={drawings}
              onChartSetContract={onChartSetContract}
            />
          </Provider>
        </div>
      </div>
    )
  }
}

interface ChartBookViewProps {
  dockId: string
  dockType: DockType
  charts: Chart[]
  instrumentIds: string[]
  favoriteName: string
  modificationAllowed: boolean
  favorites: IFavorite[]
  drawings: any
  dockSize: DockScrollState
}
interface ChartBookViewState {}

export default class UIChartBookView extends React.Component<
  ChartBookViewProps,
  ChartBookViewState
> {
  updateInterval = undefined
  constructor(props: ChartBookViewProps) {
    super(props)

    this._setPeriodType = this._setPeriodType.bind(this)
    this._remove = this._remove.bind(this)
  }

  componentWillUnmount() {
    clearInterval(this.updateInterval)
  }

  _setPeriodType(chartId: string, periodType: string, dockId: string) {
    store.dispatch(setPeriodType(chartId, periodType, dockId))
  }

  _setContractId(
    chartId: string,
    contractId: string,
    dockId?: string,
    ohlcPeriod?: OhlcPeriod
  ) {
    store.dispatch(setContractId(chartId, contractId, dockId, ohlcPeriod))
  }

  _setContractGroupTypes(
    chartId: string,
    groupTypes: string[],
    dockId: string
  ) {
    store.dispatch(setGroupTypes(chartId, groupTypes, dockId))
  }

  _remove(chartId: string, dockId: string) {
    const contracts = this.props.charts
      .filter((c) => c.id === chartId)
      .map((c) => c.contractId)
    store.dispatch(remove(chartId, dockId, contracts))
  }

  render() {
    const { dockId, charts, drawings } = this.props

    const periodTypes = gedPeriodTypesForCharts(
      orderBookStore.getState(),
      dockId
    )

    const tradeCharts = charts.reduce(
      (acc: any, chart: Chart, index: number) => {
        return acc.concat(
          <ChartBlock
            key={chart.id + '-' + index}
            chart={chart}
            siblings={charts.length}
            periodTypes={periodTypes[chart.instrumentId]}
            onChartSetPeriodType={(chartId: string, periodType: string) =>
              this._setPeriodType(chartId, periodType, dockId)
            }
            onChartSetContract={(
              chartId: string,
              contractId: string,
              dockId: string,
              ohlcPeriod?: OhlcPeriod
            ) => this._setContractId(chartId, contractId, dockId, ohlcPeriod)}
            onChartSetGroupTypes={(chartId: string, groupTypes: string[]) => {
              this._setContractGroupTypes(chartId, groupTypes, dockId)
            }}
            drawings={drawings}
          />
        )
      },
      []
    )
    return (
      <React.Fragment>
        <div key="trades-charts" className="chartbook h-100">
          {tradeCharts}
        </div>
      </React.Fragment>
    )
  }
}
