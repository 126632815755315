export const initialState = {
  pathname: '/',
  search: '',
  hash: '',
};

export type State = typeof initialState;

export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';

export function reducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return action.payload.location;
    default:
      return state;
  }
}