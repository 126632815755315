import { createSelector } from 'reselect';
import { OrderbooksState } from '../reducers/combinedReducer';
import { MasterDataId } from '../../main/models/application';

const CONTRACT_VENUE_SEPERATOR = ' | ';

const getOrderbookContractsState = (state: OrderbooksState) => state.orderbook.contracts;
const getOrderbookSyntheticContractsState = (state: OrderbooksState) => state.orderbook.syntheticContracts;

export const getOrderbookContractIds = createSelector(
  [getOrderbookContractsState],
  s => {
    return s.ids;
  }
);

export const getOrderbookContracts = createSelector(
  [getOrderbookContractsState],
  s => {
    return s.entities;
  }
);

export const getOrderbookSyntheticContracts = createSelector(
  [getOrderbookSyntheticContractsState],
  s => {
    return s.entities;
  }
);

export const getOrderbookSyntheticContractIds = createSelector(
  [getOrderbookSyntheticContractsState],
  s => {
    return s.ids;
  }
);


export const getOrderbookContractsFromState = createSelector(
  getOrderbookContractsState,
  ({ entities }) => entities,
);

export const getOrderbookContractByMasterDataId = createSelector([getOrderbookContracts, (state: OrderbooksState, id: MasterDataId) => id],
(entities, id) => {
  const idString = createMasterDataIdString(id);
  if (idString) {
    return entities[idString];
  }
  return undefined;
});

export const getOrderbookContractById = createSelector([getOrderbookContracts, (state: OrderbooksState, idString: string) => idString],
  (entities, idString) => {
    return entities[idString];
  });
export const getOrderbookSyntheticContractById = createSelector([getOrderbookSyntheticContracts, (state: OrderbooksState, idString: string) => idString],
(entities, idString) => {
  return entities[idString];
});

export const createMasterDataIdString = (id: MasterDataId | string) => {
    if (id) {
        return typeof id === 'object' ? ((id.venue || '') + '___' + id.id) : id
    }
    return undefined;
}

export const createMasterDataSyntheticIdString = (id: MasterDataId) => {
  if (id) {
      return 'SYNTHETIC___' + (typeof id === 'object' ? ((id.venue || '') + '___' + id.id) : id)
  }
  return undefined;
}

export const createSyntheticMasterDataId = (id: MasterDataId, offset: number) => {
  return {
    id: id.id + "/" + offset,
    venue: id.venue
  }
}

export const combineContractVenue = (contractName: string, venue: string) => {
    return contractName + CONTRACT_VENUE_SEPERATOR + venue;
}

export const venueFromContractWithVenue = (nameWithVenue: string) => {
    if(nameWithVenue.indexOf(CONTRACT_VENUE_SEPERATOR) == -1)
        return undefined;
    return nameWithVenue.split(CONTRACT_VENUE_SEPERATOR)[1];
}

export const getOrderbookContractEntities = createSelector(
  [getOrderbookContractIds, getOrderbookContracts],
  (ids, entities) => ids.map(id => entities[id])
);

export const getContractNameToIdMap = createSelector(
  [getOrderbookContractIds, getOrderbookContracts],
  (ids, entities) => ids.reduce((acc: any, id: string) => { acc[entities[id].nameWithVenue] = id; return acc;}, {})
);

export const getSearchResults = createSelector(
  [getOrderbookContracts, (state: OrderbooksState, phrase: string) => phrase],
  (entities, phrase) => {
    if (!phrase || phrase.length < 3) {
      return [];
    }
    return Object.keys(entities).reduce((acc: any[], id: string) => {
      return entities[id].name.toLowerCase().indexOf(phrase.toLowerCase()) >
        -1 || entities[id].id?.id === phrase
        ? acc.concat(entities[id])
        : acc;
    }, []);
  }
);
