import OrderFormData, { OrderFormMode } from '../models/formData';
import { Action, ActionTypes } from '../actions/orderForm';
import * as Contracts from '../../orderbook/actions/contracts';
import { Restriction } from '../models/orders';
import { ContractState } from '../../orderbook/models/contracts';
import { SanityCheck } from '../models/sanityChecks';
import { MasterDataId } from '../../main/models/application';

export interface State {
    phrase: string;
    selectedContractId: MasterDataId;
    preselectedContractId: MasterDataId;
    visible: boolean;
    data: OrderFormData;
    preview: {orders: {[contractId: string]: {
            bidPrices: any[],
            askPrices: any[]
        }}, trades: {
            [contractId: string]: any[]
        }
    };
    sanityChecks: SanityCheck[];
    disabled: boolean;
}

export const initialState: State = {
    phrase: '',
    selectedContractId: {venue: '', id: ''},
    preselectedContractId: {venue: '', id: ''},
    visible: false,
    data: <OrderFormData> {
        price: 0,
        quantity: 0,
        restriction: Restriction.NONE,
        contractId: {venue: '', id: ''},
        orderId: 0,
        buy: true,
        mode: OrderFormMode.ENTER
    },
    preview: {
        orders: {},
        trades: {}
    },
    sanityChecks: [],
    disabled: false
};

export function reducer(state: State = initialState, action: Action | Contracts.Action) {
    switch (action.type) {
        case Contracts.ActionTypes.SEARCH_CONTRACTS: {
            return {
                ...state,
                phrase: action.payload
            };
        }
        case Contracts.ActionTypes.SELECT_CONTRACT: {
            return {
                ...state,
                phrase: action.payload.name,
                selectedContractId: action.payload.id,
                disabled: false
            };
        }
        case Contracts.ActionTypes.CONTRACTS_STATE_CHANGED: {
            const inactive = action.contracts.filter(c => c.state === ContractState.INACTIVE).map(c => c.id);
            if (inactive.indexOf(state.selectedContractId) !== -1) {
                return {
                    ...state,
                    disabled: true
                };
            }
            return state;
        }
        case ActionTypes.ORDER_FORM_SHOW: {
            return {
                ...state,
                data: { 
                    ...action.payload,
                    seenOrderToBeModified: action.payload.seenOrderToBeModified
                },
                selectedContractId: action.payload.contractId,
                preselectedContractId: action.payload.contractId,
                phrase: action.payload.contractId?.id, 
                visible: true,
                disabled: false
            };
        }
        case ActionTypes.ORDER_FORM_CLEAR: {
            return {
                ...initialState
            };
        }
        case ActionTypes.ORDER_FORM_HIDE: {
            return {
                ...state,
                data: { ...initialState.data},
                visible: false
            };
        }
        case ActionTypes.ORDERBOOK_PREVIEW_LOAD_SUCCESS: {
            if (!action.payload.contract) {
                return {
                    ...state,
                    preview: {
                        orders: {},
                        trades: {}
                    }
                }; 
            }
            return {
                ...state,
                preview: { 
                    orders: {[action.payload.contract.expiry.code] : {
                        'bidPrices': action.payload.orderbook.orderbook.bidPrices,
                        'askPrices': action.payload.orderbook.orderbook.askPrices
                        }
                    },
                    trades: {[action.payload.contract.expiry.code] : action.payload.orderbook.trades === null
                        ? {} 
                        : action.payload.orderbook.trades
                    }
                }
            };
        }
        case ActionTypes.ORDER_FORM_SANITY_CHECK: {
            return {
                ...state,
               sanityChecks: action.payload
            };
        }
        default: {
            return state;
        }
    }
}