import {Action, ActionTypes} from '../actions/market';
import {IMarket, Market} from '../models/market';
import * as OrderbookActions from '../../../orderbook/actions/orderbooks';
import * as TradeActions from '../../../trade/actions/trade';
import {ComponentType} from "../models/component";
import { getInstrumentType } from '../components/market/helper/helper';
import { config } from '../../../main/config';

export interface State {
  ids: string[];
  entities: { [id: string]: IMarket };
  toUnsubscribeInstruments: string[];
  toUnsubscribeProducts: string[];
}

export const initialState: State = {
  ids: [],
  entities: {},
  toUnsubscribeInstruments: [],
  toUnsubscribeProducts: []
};

export function reducer(
  state: State = initialState,
  action: Action | OrderbookActions.Action | TradeActions.Action
) {
  switch (action.type) {
    case ActionTypes.LOAD: {
      const markets = action.payload;
      const newMarketEntities = markets.reduce(
        (entities: { [id: string]: IMarket }, market: IMarket) => {
          const newMarketObject = new Market(
            market.dockId,
            market.title,
            market.type,
            market.itemId,
            market.instrumentId
          );

          market = {
            ...newMarketObject,
            visibleColumnNames: market.visibleColumnNames,
            ...market
          };
          if (market.customColumnOrder) {
            market.columns.sort((c1, c2) =>
              market.customColumnOrder.indexOf(`${c1.group}__${c1.name}`) - market.customColumnOrder.indexOf(`${c2.group}__${c2.name}`)
            )
          }
          const visibleColumns = market.visibleColumnNames === undefined ? [] : market.visibleColumnNames;
          // In case there are new columns added to marketsheet, this should hide them in existing sheets
          // when: column was not previously saved as visible
          // && column was not saved as hidden
          // && column is hidden in default marketsheet
          const newHidCols = market.columns.filter(c =>
              !visibleColumns.includes(`${c.group}__${c.name}`)
              && !market.hiddenColumnNames.includes(`${c.group}__${c.name}`)
              && newMarketObject.hiddenColumnNames.includes(`${c.group}__${c.name}`)
          );
          newHidCols.forEach(c => market.hiddenColumnNames.push(`${c.group}__${c.name}`));
          return Object.assign(entities, {
            [market.id]: market
          });
        },
        {}
      );

      return {
        ...state,
        ids: Object.keys(newMarketEntities),
        entities: newMarketEntities
      };
    }

    case ActionTypes.CREATE: {
      const book: IMarket = action.payload;

      return {
        ...state,
        ids: [...state.ids, book.id],
        entities: {
          ...state.entities,
          [book.id]: book
        }
      };
    }

    // add instrument to dock
    case ActionTypes.MOVE: {
      const { ids, toDockId } = action.payload;
      const toDockMarkets = state.ids
        .map(id => state.entities[id])
        .filter(market => market.dockId === toDockId);

      const expandedExpiries = toDockMarkets[0] ? toDockMarkets[0].expandedExpiries : [];
      const depths = toDockMarkets[0] ? toDockMarkets[0].depths : [];
      let lowestPrio = Math.max(...toDockMarkets.map(m => m.priority));

      const newState = ids.reduce(
        (entities: { [id: string]: IMarket }, id: string) => {
          lowestPrio++;
          return Object.assign(entities, {
            [id]: Object.assign({}, state.entities[id], {
              dockId: toDockId,
              priority: lowestPrio,
              expandedExpiries: expandedExpiries,
              depths: depths
            })
          });
        },
        {}
      );
      return {
        ...state,
        entities: {
          ...state.entities,
          ...newState
        }
      };
    }

    case ActionTypes.REORDER_MARKETS: {
      const { ids, dockId } = action.payload;
      // ids array describes how the items are sorted in a dock
      const newState = state.ids
      .map(id => state.entities[id])
      .reduce(
        (entities: { [id: string]: IMarket }, m: IMarket) => {
          return Object.assign(entities, {
            [m.id]: Object.assign({}, m, {
              priority: m.dockId === dockId ? ids.indexOf(m.itemId) : m.priority
            })
          });
        },
        {}
      );
      return {
        ...state,
        entities: newState
      };
    }

    case ActionTypes.REMOVE: {
      const { id } = action.payload;
      const newMarketIds = state.ids.filter(marketId => marketId !== id);
      const removed = state.entities[id];
      const newMarketEntities = newMarketIds.reduce(
        (entities: { [id: string]: IMarket }, marketId: string) => {
          return Object.assign(entities, {
            [marketId]: state.entities[marketId]
          });
        },
        {}
      );
      if (removed.type === ComponentType.Instrument || removed.type === ComponentType.InstrumentIntraday) {
        return {
          ...state,
          ids: newMarketIds,
          entities: newMarketEntities,
          toUnsubscribeInstruments: removed ? state.toUnsubscribeInstruments.concat([removed.itemId]) : state.toUnsubscribeInstruments
        };
      } else if (removed.type === ComponentType.Product) {
        return {
          ...state,
          ids: newMarketIds,
          entities: newMarketEntities,
          toUnsubscribeProducts: removed ? state.toUnsubscribeProducts.concat([removed.itemId]) : state.toUnsubscribeProducts
        };
      }
      return {...state};
    }

    case ActionTypes.TRIGGER_COLUMN_NAMES: {
      const { id, hiddenColumnNames } = action.payload;
      const marketsWithSameInstrument = Object.keys(state.entities)
        .map(k => state.entities[k])
        .filter(m =>
          m.instrumentId === state.entities[id].instrumentId
          && m.dockId === state.entities[id].dockId
        );
      
      const newState = Object.assign({}, state);
      for (let i = 0; i < marketsWithSameInstrument.length; i++) {
        newState.entities = Object.assign({}, newState.entities, {
          [marketsWithSameInstrument[i].id]: Object.assign({}, state.entities[marketsWithSameInstrument[i].id], {
            hiddenColumnNames: hiddenColumnNames
          })
        });
      }
      return { ...state, ...newState };
    }

    case ActionTypes.SET_COLUMNS: {
      const { id, columns } = action.payload;

      const newState = Object.assign({}, state);

      newState.entities = Object.assign({}, newState.entities, {
        [id]: Object.assign({}, state.entities[id], {
          columns, customColumnOrder: columns.map(c => `${c.group}__${c.name}`)
        })
      });

      return { ...state, ...newState };
    }

    case ActionTypes.SET_COLUMN_WIDTHS: {
      const { id, columnWidths } = action.payload;

      const newState = Object.assign({}, state);

      newState.entities = Object.assign(
        {},
        newState.entities,
        {[id]: Object.assign(
          {},
            state.entities[id],
            {customWidths: Object.assign(
              {},
                state.entities[id].customWidths,
                columnWidths
              )
            }
          )
        }
      );

      return { ...state, ...newState };
    }

    case ActionTypes.TRIGGER_EXPANDED_EXPIRY: {
      const { expiryRow, dockId, expanded } = action.payload;
      const markets = state.ids.map(id => state.entities[id])
        .filter(m => m.dockId === dockId);

        const marketsExpandedExpiries = markets.map(m => m.expandedExpiries)
        .reduce((acc: any, expiry: any) => {
          return {
            ...acc,
            ...Object.keys(expiry).reduce((inAcc: any, key: string) => {
              return {
                ...inAcc,
                [key]: expiry[key]
              };
            }, {})
          };
        }, {});

      const newState = markets.reduce(
        (entities: { [id: string]: IMarket }, m: IMarket) => {
          const id = m.id;
          return {
            ...entities,
            [id]: {
              ...state.entities[id],
              expandedExpiries:
                !expanded
                  ? Object.keys(marketsExpandedExpiries).reduce(
                      (accumulator, key) => {
                        return marketsExpandedExpiries[key].code === expiryRow.code
                          ? accumulator
                          : {
                              ...accumulator,
                              [key]: marketsExpandedExpiries[key]
                            };
                      },
                      {}
                    ) 
                  : {
                      ...marketsExpandedExpiries,
                      [expiryRow.code]: expiryRow
                    }
            }
          };
        },
        {}
      );

      return {
        ...state,
        entities: {
          ...state.entities,
          ...newState
        }
      };
    }

    case ActionTypes.TRIGGER_EXPIRIES: {
      const { ids, hiddenExpiryKeys, allExpiryKeys } = action.payload;

      const newState = ids.reduce(
        (entities: { [id: string]: IMarket }, id: string) => {
          return Object.assign(entities, {
            [id]: Object.assign({}, state.entities[id], {
              hiddenExpiryKeys: hiddenExpiryKeys,
              allExpiryKeys: allExpiryKeys
            })
          });
        },
        {}
      );

      return {
        ...state,
        entities: {
          ...state.entities,
          ...newState
        }
      };
    }

    case ActionTypes.CHANGE_HEADLINES_VISIBLE: {
      const { ids, isHeadlinesVisible } = action.payload;

      const newState = ids.reduce(
        (entities: { [id: string]: IMarket }, id: string) => {
          return Object.assign(entities, {
            [id]: Object.assign({}, state.entities[id], {
              isHeadlinesVisible: isHeadlinesVisible
            })
          });
        },
        {}
      );

      return {
        ...state,
        entities: {
          ...state.entities,
          ...newState
        }
      };
    }

    case OrderbookActions.ActionTypes.LOAD_CONTRACT_MATRIX_SUCCESS: {
      const { componentId, contractMatrixItem } = action.payload;
      const expiryKeys = Object.keys(contractMatrixItem)
        .map((key: any) => contractMatrixItem[key])
        .map((item: any) => Object.keys(item.expiries))
        .filter((value, index, arr) => {
          return arr.indexOf(value) === index;
        });
      const targetComponents = state.ids
        .filter(id => state.entities[id].dockId === componentId)
        .reduce((entities: { [id: string]: IMarket }, id: string) => {
          const market = state.entities[id];
          
          if (market?.allExpiryKeys?.length === 0) {
            const instrumentType = getInstrumentType(market.instType);
            const expiryConfig: {[key: string]: number} = config.expiry[instrumentType];
            let hiddenExpiryKeys = market.hiddenExpiryKeys;
            const expiries = contractMatrixItem[market.id]?.expiries || [];
            if (expiryConfig && (!hiddenExpiryKeys || market.hiddenExpiryKeys.length === 0)) {
              hiddenExpiryKeys = Object.keys(expiries).map(k => expiries[k]).filter(e => !expiryConfig[e]);
            }
            let expiryRowsLength = market.expiryRowsLength;
            if (expiryConfig && (!expiryRowsLength || Object.keys(expiryRowsLength).length === 0)) {
              expiryRowsLength = expiryConfig;
            }
            return Object.assign(entities, {
              [id]: Object.assign({}, market, {
                allExpiryKeys: expiryKeys,
                hiddenExpiryKeys,
                expiryRowsLength
              })
            });
          } else {
            return Object.assign(entities, {
              [id]: Object.assign({}, market)
            });
          }
        }, {});
      return {
        ...state,
        entities: {
          ...state.entities,
          ...targetComponents
        }
      };
    }

    case ActionTypes.TRIGGER_EXPIRY_ROWS: {
      const { ids, expiryRowsLength } = action.payload;
      const newState = ids.reduce(
        (entities: { [id: string]: IMarket }, id: string) => {
          return Object.assign(entities, {
            [id]: Object.assign({}, state.entities[id], {
              expiryRowsLength: expiryRowsLength
            })
          });
        },
        {}
      );

      return {
        ...state,
        entities: {
          ...state.entities,
          ...newState
        }
      };
    }

    case ActionTypes.SET_ORDERBOOK_DEPTH: {
      const { depth, expiryCode, dockId } = action.payload;
      const newState = {...state};
      for (let i = 0; i < newState.ids.length; i++) {
        if (newState.entities[newState.ids[i]].dockId === dockId) {
          newState.entities[newState.ids[i]].depths[expiryCode] = depth;
        }
      }
      return {
        ...state,
        ...newState
      };
    }

    case OrderbookActions.ActionTypes.UNSUBSCRIBE: {
      return {
        ...state,
        toUnsubscribeProducts: [],
        toUnsubscribeInstruments: []
      }
    }

    default:
      return state;
  }
}
