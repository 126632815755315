import * as React from 'react';
import { State } from '../../../main/reducers/rootReducer';
import { connect } from 'react-redux';
import SettingConfigForm from './ColorConfigForm';
import { getSettingsColors, getGeneralSettings } from '../selectors/selector';
import { saveClient, saveColor } from '../actions/settings';
import ModalWindow from '../../modalWindow/components/modalWindow';
import { Tabs, Tab } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import GeneralConfigForm from './GeneralConfigForm';
import { config } from '../../../main/config';
import { log } from '../../logger/actions/logger';
import { LogLevel } from '../../logger/models/logger';
import { isProfileModificationAllowed, isColorConfigurationAllowed } from '../../../authentication/selectors/authetication';

export interface SettignsConfigProps {
  colors: { [key: string]: { [x: string]: string } };
  modalVisible: boolean;
  saveClient: (settingns: any) => void;
  saveColor: (settingns: any) => void;
  closeModal: () => void;
  general: { [key: string]: { [x: string]: string } };
  informUser: (notification: string, level: LogLevel) => void;
  profileModificationAllowed: boolean;
  colorConfigurationAllowed: boolean;
}

export interface SettingsConfigState {
  modalVisible: boolean;
  unsavedSettings: any;
}

export class SettingsConfigModalComponent extends React.Component<
  SettignsConfigProps,
  SettingsConfigState
  > {
  constructor(props: SettignsConfigProps) {
    super(props);
    this.state = {
      modalVisible: props.modalVisible,
      unsavedSettings: {}
    };
    this.hideModal = this.hideModal.bind(this);
    this.save = this.save.bind(this);
  }

  hideModal() {
    this.props.closeModal();
  }

  save(settings: any) {
    const settingsToSave = {...this.state.unsavedSettings, ...settings};
    if (settingsToSave.general) {
      this.props.saveClient(settingsToSave);
    }
    if (settingsToSave.colors) {
      this.props.saveColor(settingsToSave);
    }
    this.hideModal();
  }

  handleChange = (settings: any) => {
    this.setState((prevState) => {
      return {
        unsavedSettings: {
          ...prevState.unsavedSettings, ...settings
        }
      };
    });
  }

  render() {
    const { modalVisible, colors, general, profileModificationAllowed, colorConfigurationAllowed } = this.props;
    const defaultTab = colorConfigurationAllowed ? 'designSettingsMarket' : 'general';
    return (
      <div className="meet-settings__modal">
        <ModalWindow
          id="settings"
          onModalClose={this.hideModal}
          isOpen={modalVisible}
          title={'settings.title'}
        >
          <Tabs id="clientConfig" defaultActiveKey={defaultTab}>
          { colorConfigurationAllowed ?
            <Tab title={I18n.t('settings.designSettingsMarket')} eventKey="designSettingsMarket" className="config-tab">{
              <SettingConfigForm
                colors={colors}
                onSubmit={this.save}
                onChange={this.handleChange}
              />
            }
            </Tab> : null
          }
            {
              profileModificationAllowed ?
                <Tab title={I18n.t('settings.general.title')} eventKey="general" className="config-tab">{
                  <GeneralConfigForm
                    formId={'settings.general'}
                    settings={general}
                    defaultSettings={config.settings.general}
                    onSubmit={this.save}
                    informUser={this.props.informUser}
                    onChange={this.handleChange}
                  />
                }
                </Tab> : null
            }
          </Tabs>

        </ModalWindow>
      </div>
    );
  }
}

const mapStateToProps = (state: State) => ({
  colors: getSettingsColors(state),
  general: getGeneralSettings(state),
  profileModificationAllowed: isProfileModificationAllowed(state),
  colorConfigurationAllowed: isColorConfigurationAllowed(state)
});

const mapDispatchToProps = {
  saveColor: saveColor,
  saveClient: saveClient,
  informUser: log
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(SettingsConfigModalComponent);