import { v1 } from 'uuid';

import * as ContractActions from '../actions/contracts';
import * as OrderbookActions from '../actions/orderbooks';
import * as InstrumentsActions from '../../instruments/actions/instruments';
import * as TradeActions from '../../trade/actions/trade';
import * as ConnectionActions from '../../authentication/actions/connection';
import * as ProductActions from '../actions/products';
import * as DockActions from '../../shared/dock/actions/dock';
import { Contract, Product, ContractState, SyntheticContract } from '../models/contracts';
import { Instrument } from '../../instruments/models/instruments';
import {
  Price,
  Orderbook,
  LoadedData
} from '../models/orderbooks';
import {createMasterDataIdString, createMasterDataSyntheticIdString, createSyntheticMasterDataId} from "../selectors/contracts";

export interface State {
  contracts: {
    ids: string[];
    entities: { [key: string]: Contract };
  };
  instruments: { [key: string]: Instrument };
  products: {
    ids: string[];
    entities: { [id: string]: Product };
  };
  orderbooks: Orderbook[];
  processing: boolean;
  isLoaded: number;
  prices: { [contractId: string]: { bidPrices: Price[]; askPrices: Price[], realDepth: number } };
  contractMatrixes: {
    ids: string[];
    entities: { [id: string]: any };
  };
  syntheticContracts: {
    ids: string[];
    entities: {[id: string]: SyntheticContract}
  };
  tradesVersion: string;
  pricesVersion: string;
}

export const initialState: State = {
  contracts: {
    ids: [],
    entities: {}
  },
  instruments: {},
  products: {
    ids: [],
    entities: {}
  },
  orderbooks: [],
  processing: false,
  isLoaded: 1,
  prices: {},
  contractMatrixes: {
    ids: [],
    entities: {}
  },
  syntheticContracts: {
    ids: [],
    entities: {}
  },
  tradesVersion: 'init',
  pricesVersion: 'init'
};

export function createSyntheticContracts(productMap, ids) {
  const syntheticContracts: {[id: string]: SyntheticContract} = {};
  for (let i = 0; i < ids.length; i++) {
    const product: Product = productMap[ids[i]];
    if (product.periodStarts?.length > 0) {
        product.periodStarts.forEach((periodStartTimestamp: number, idx: number) => {
        const masterDataId = createSyntheticMasterDataId(product.id, idx + 1)
        const symId = createMasterDataSyntheticIdString(masterDataId)
        syntheticContracts[symId] = <SyntheticContract> {
          idString: symId,
          productId: createMasterDataIdString(product.id),
          offset: idx + 1
        }
      })
    }
  }
  return {
    entities: syntheticContracts,
    ids: Object.keys(syntheticContracts)
  }
}

export function reducer(
  state: State = initialState,
  action:
    | ContractActions.Action
    | OrderbookActions.Action
    | InstrumentsActions.Action
    | TradeActions.Action
    | ConnectionActions.Action
    | ProductActions.Action
    | DockActions.Action
) {
  switch (action.type) {
    case ConnectionActions.ActionTypes.CONNECTION_START: {
      return initialState;
    }

    case ContractActions.ActionTypes.LOAD_CONTRACTS: {
      const isLoaded = (category: number) => state.isLoaded > 1 && state.isLoaded % category === 0;
      return {
        ...state,
        processing: true,
        contracts: isLoaded(LoadedData.CONTRACTS) ? {...state.contracts} : {
          ids: [],
          entities: {}
        },
        instruments: isLoaded(LoadedData.INSTRUMENTS) ? {...state.instruments} : {},
        products: isLoaded(LoadedData.PRODUCTS) ? {...state.products} : {
          ids: [],
          entities: {}
        },
        orderbooks: [],
        prices: {}
      };
    }

    case InstrumentsActions.ActionTypes.LOAD_INSTRUMENTS_SUCCESS: {
      return {
        ...state,
        instruments: action.payload,
        isLoaded: state.isLoaded * LoadedData.INSTRUMENTS
      };
    }

    case ContractActions.ActionTypes.LOAD_CONTRACTS_MAP_SUCCESS: {
      return {
        ...state,
        contracts: {
          entities: action.payload,
          ids: Object.keys(action.payload).sort()
        },
        processing: false,
        isLoaded: state.isLoaded * LoadedData.CONTRACTS
      };
    }

    case ContractActions.ActionTypes.CONTRACTS_STATE_CHANGED: {
      const current = { ...state.contracts };
      const newEntities = current.entities;

      for (let i = 0; i < action.contracts.length; i++) {
        let contract = action.contracts[i];
        if (contract.state === ContractState.INACTIVE) {
          delete newEntities[contract.id];
        } else {
          newEntities[contract.id] = contract;
        }
      }

      return {
        ...state,
        contracts: {
          entities: newEntities,
          ids: Object.keys(newEntities).sort()
        },
        dataVersion: v1()
      };
    }

    case ProductActions.ActionTypes.LOAD_PRODUCTS_SUCCESS: {
      const productIds = Object.keys(action.payload)
      const {entities, ids} = createSyntheticContracts(action.payload, productIds)

      return {
        ...state,
        products: {
          entities: action.payload,
          ids: productIds
        },
        syntheticContracts: {
          entities,
          ids
        },
        isLoaded: state.isLoaded * LoadedData.PRODUCTS
      };
    }

    case TradeActions.ActionTypes.LAST_PRICES_SUCCESS: {
      return {
        ...state,
        tradesVersion: v1()
      };
    }

    case TradeActions.ActionTypes.SETTLEMENT_PRICES_SUCCESS: {
      return {
        ...state,
        tradesVersion: v1()
      };
    }

    case OrderbookActions.ActionTypes.LOAD_ORDERBOOKS_SUCCESS: {
      const prices = { ...state.prices };
      for (let i = 0; i < action.payload.length; i++) {
        const orderbook = action.payload[i];
        const realDepth = orderbook.realDepth;
        if (prices[createMasterDataIdString(orderbook.contractId)] === undefined) {
          prices[createMasterDataIdString(orderbook.contractId)] = { bidPrices: [], askPrices: [], realDepth: realDepth };
        }
        const contractPrices = prices[createMasterDataIdString(orderbook.contractId)];

        if (contractPrices) {
          if (orderbook.askPrices) {
            contractPrices.askPrices = orderbook.askPrices;
          } else {
            contractPrices.askPrices = [];
          }

          if (orderbook.bidPrices) {
            contractPrices.bidPrices = orderbook.bidPrices;
          } else {
            contractPrices.bidPrices = [];
          }
        }
        prices[createMasterDataIdString(orderbook.contractId)].realDepth = realDepth;
      }
      
      return {
        ...state,
        prices: prices,
        pricesVersion: v1()
      };
    }

    case DockActions.ActionTypes.REMOVE: {
      const dockId = action.payload;
      let editedMatrix = {...state.contractMatrixes};
      editedMatrix.entities[dockId] = undefined;
      editedMatrix.ids = Object.keys(editedMatrix.entities);

      return {
        ...state,
        contractMatrixes: editedMatrix
      };
    }

    case OrderbookActions.ActionTypes.LOAD_CONTRACT_MATRIX_SUCCESS: {
      const { componentId, contractMatrixItem } = action.payload;
      const { ids, entities } = state.contractMatrixes;
      let targetComponents = {...entities};
      if (ids.find(id => id === componentId)) {
        targetComponents[componentId] = contractMatrixItem;
        
        return {
          ...state,
          contractMatrixes: {
            ...state.contractMatrixes,
            entities: targetComponents
          }
        };
      } else {
        return {
          ...state,
          contractMatrixes: {
            ids: [...ids, componentId],
            entities: {
              ...entities,
              [componentId]: contractMatrixItem
            }
          }
        };
      }
    }

    case ProductActions.ActionTypes.SUBSCRIBE_PRODUCTS_RESPONSE: {
      return {
        ...state,
        isLoaded: state.isLoaded / LoadedData.CONTRACTS
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
}
