import { connect } from 'react-redux'
import { getConnectedStatus } from 'js/authentication/selectors/connection'
import { State } from 'js/main/reducers/rootReducer'
import {
  getAllInstruments,
  getVenues,
  isLoaded,
} from 'js/instruments/selectors/instruments'
import { instrumentsLoadSuccess } from 'js/instruments/actions/instruments'

import { getLocale } from 'js/shared/i18n/selectors/translations'
import { isProfileModificationAllowed } from 'js/authentication/selectors/authetication'
import orderBookStore from 'js/orderbook/store/orderbooks'
import { getOrderbookProductEntities } from 'js/orderbook/selectors/products'
import MarketSheetSelectComponent from '../components/marketsheetSelect'

const mapStateToProps = (state: State) => ({
  isConnected: getConnectedStatus(state),
  instruments: getAllInstruments(state),
  products: getOrderbookProductEntities(orderBookStore.getState()),
  venues: getVenues(state),
  loaded: isLoaded(state),
  locale: getLocale(state),
  modificationEnabled: isProfileModificationAllowed(state),
})

const mapDispatchToProps = {
  loadInstrumentsSuccess: instrumentsLoadSuccess,
}

export const MarketsheetSelect = connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(MarketSheetSelectComponent)

export const MarketsheetSelectForDock = connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(MarketSheetSelectComponent)
