import { Chart } from '../../../models/chart';
import {Contract, Expiry, PeriodType, Product, SyntheticContract} from '../../../../../orderbook/models/contracts';
import {
  createMasterDataIdString,
  createMasterDataSyntheticIdString, createSyntheticMasterDataId, getOrderbookContractById, getOrderbookContractIds,
  getOrderbookContracts, getOrderbookSyntheticContractById,
} from '../../../../../orderbook/selectors/contracts'
import { MatrixItem} from "../../../../../orderbook/models/orderbooks";
import {MasterDataId} from "../../../../../main/models/application";
import {
  getOrderbookProductById,
  getOrderbookProductEntities,
} from '../../../../../orderbook/selectors/products'
import orderBookStore from '../../../../../orderbook/store/orderbooks';


export function convertToSyntheticContractId(periodType: PeriodType, instrumentId: string): string {
  const products: Product[] = getOrderbookProductEntities(orderBookStore.getState())
  const product = products.find(p => createMasterDataIdString(p.instrumentId) === createMasterDataIdString(instrumentId) && p.periodType == periodType)
  return product ? createMasterDataSyntheticIdString({id: product.id.id + "/1", venue: product.id.venue}) : ''
}

export function isSynthetic(id: string) {
  return id?.startsWith('SYNTHETIC___')
}

export function convertSyntheticIdToConcreteId(syntheticContractId: string) {
  return convertSyntheticToConcrete(syntheticContractId)?.idString || ''
}

export function getSyntheticContractIdFromContract(contract: Contract) {
  let synId = undefined
  if (!contract) {
    return synId
  }
  const product: Product = getOrderbookProductById(orderBookStore.getState(), createMasterDataIdString(contract.productId))
  if (product?.periodStarts) {
    const offset = product.periodStarts.indexOf(new Date(contract.expiry?.periodStart).getTime())
    if (offset < 0) {
      return synId
    }
    const masterDataId = createSyntheticMasterDataId(product.id, offset + 1)
    synId = createMasterDataSyntheticIdString(masterDataId)
  }
  return synId
}

export function convertSyntheticToConcrete(syntheticContractId: string) {
  const contractIds = getOrderbookContractIds(orderBookStore.getState())
  const syntheticContract: SyntheticContract = getOrderbookSyntheticContractById(orderBookStore.getState(), syntheticContractId)
  if (!syntheticContract) {
    return undefined
  }
  const product: Product = getOrderbookProductById(orderBookStore.getState(), syntheticContract.productId)
  if (!product || !product.periodStarts) {
    return undefined
  }
  const periodStart = product.periodStarts.length > syntheticContract.offset
    ? product.periodStarts[syntheticContract.offset - 1]
    : product.periodStarts[product.periodStarts.length - 1] // if product has fewer contracts than at the time synthetic contract was created
  for (let i = 0; i < contractIds.length; i++) {
    const contract = getOrderbookContractById(orderBookStore.getState(), contractIds[i])
    if (createMasterDataIdString(contract.productId) === syntheticContract.productId) {
      if (new Date(contract.expiry.periodStart).getTime() == periodStart) {
        return contract
      }
    }
  }
  return undefined
}

export function findAllContractsForPeriodType(
  chart: Chart,
  periodType: string
): { contractId: MasterDataId; expiryName: string }[] {
  const { contractMatrix, instrumentId } = chart;
  if (!contractMatrix) {
    return [];
  }
  const contractMatrixForInstrument: MatrixItem = contractMatrix[instrumentId];
  
  if (contractMatrixForInstrument.intraday) {
    let result: { contractId: MasterDataId; expiryName: string }[] = [];

    for (const periodTypeKey of Object.keys(
      contractMatrixForInstrument.expiries
    )) {
      const expiries: Expiry[] =
        contractMatrixForInstrument.expiries[periodTypeKey];
      const contractsWithNames = expiries
        .filter(expiry => expiry.type === periodType)
        .map(expiry => ({
          contractId: contractMatrixForInstrument.expiryToContract[expiry.code],
          expiryName: expiry.originalName
        }));
      result = [...result, ...contractsWithNames];
    }

    return result;
  } else {
    const expiries: any[] = contractMatrixForInstrument.expiries[periodType];
    const contracts = expiries.map(expiry => ({
      contractId: contractMatrixForInstrument.expiryToContract[expiry.code],
      expiryName: expiry.name
    }));
    return contracts;
  }
}
