import { PriceAlarmFormData, PriceAlarmFormMode, PriceAlarmType, PriceAlarm } from '../models/priceAlarms';
import { PriceAlarmModalProps } from '../components/PriceAlarmModalRefactored';

export function createPriceAlarmFormData(contract: string, contractName: string, nameWithVenue: string, venue: string, price: number, columnGroup: string): PriceAlarmFormData {
  const formData: PriceAlarmFormData = {
      contractId: contract,
      contractName: contractName,
      venue: venue,
      mode: PriceAlarmFormMode.CREATE,
      type: columnGroup === 'bidPrices' ? PriceAlarmType.BID_PRICE : (columnGroup === 'askPrices' ? PriceAlarmType.ASK_PRICE : PriceAlarmType.LAST_PRICE_LESS),
      price: price,
      priceAlarmId: '',
      expires: undefined,
      customer: '',
      description: '',
      nameWithVenue: nameWithVenue
  };

  return formData;
}

export function createModifyPriceAlarmFormData(priceAlarm: PriceAlarm): PriceAlarmFormData {
  const formData: PriceAlarmFormData = {
    contractId: priceAlarm.contractId,
      contractName: priceAlarm.contractName,
    venue: priceAlarm.venue,
    mode: PriceAlarmFormMode.MODIFY,
    type: priceAlarm.priceAlarmType,
    price: priceAlarm.priceLevel,
    priceAlarmId: priceAlarm.id,
    expires: priceAlarm.expires ? new Date(priceAlarm.expires) : undefined,
    customer: priceAlarm.customer,
    description: priceAlarm.description,
    nameWithVenue: priceAlarm.nameWithVenue
  };

  return formData;
}

export function createCancelPriceAlarmFormData(priceAlarm: PriceAlarm): PriceAlarmFormData {
  const formData: PriceAlarmFormData = {
    contractId: priceAlarm.contractId,
      contractName: priceAlarm.contractName,
    venue: priceAlarm.venue,
    mode: PriceAlarmFormMode.CANCEL,
    type: priceAlarm.priceAlarmType,
    price: priceAlarm.priceLevel,
    priceAlarmId: priceAlarm.id,
    expires: priceAlarm.expires ? new Date(priceAlarm.expires) : undefined,
    customer: priceAlarm.customer,
    description: priceAlarm.description,
    nameWithVenue: priceAlarm.nameWithVenue
  };
  return formData;
}

export const getModeData = (props: PriceAlarmModalProps) => {
  const modeMapping = {
    [PriceAlarmFormMode.MODIFY]: {
      title: 'priceAlarm.modify',
      button: 'priceAlarm.form.modifySubmit',
    },
    [PriceAlarmFormMode.CANCEL]: {
      title: 'priceAlarm.delete',
      button: 'priceAlarm.form.deleteSubmit',
    },
    [PriceAlarmFormMode.CREATE]: {
      title: 'priceAlarm.enter',
      button: 'priceAlarm.form.createSubmit',
    },
  };

  return modeMapping[props.formData.mode] || modeMapping[PriceAlarmFormMode.CREATE];
};
