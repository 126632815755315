import {
  ActionsObservable,
  combineEpics,
  StateObservable
} from 'redux-observable';
import { interval } from 'rxjs'
import * as Rx from 'rxjs';
import { v1 } from 'uuid';

import { ActionTypes as Tabs } from '../actions/tab';
import { ActionTypes as Markets } from '../actions/market';
import { ActionTypes as Charts } from '../actions/chart';
import { State } from '../../../main/reducers/rootReducer';
import {
  getTabsCountInDocks,
  getTableIdByParentId,
  getMarketsCountInDocks,
  getChartsCountInDocks,
  getMarketsForDock,
  getContractDepthForDocks, getContractDepthForAllDocks,
} from '../selectors/ui'
import { remove as dockRemove } from '../../dock/actions/dock'
import { ITab } from '../models/tab';
import { create as tableCreate, remove as tableRemove } from '../actions/table';
import { ComponentType } from '../models/component';
import { TradeTable } from '../../../trade/models/tradesTable';
import { OwnTradeTable } from '../../../trade/models/ownTradesTable';
import { OrderTable } from '../../../orders/models/orderTable';
import { RequestTable } from '../../../requests/models/requestTable';
import { LogsTable } from '../../logger/models/logger';
import { filter, map, switchMap, mergeMap, catchError, debounce } from 'rxjs/operators'
import { IMarket } from '../models/market';
import { orderbooksLoad, ActionTypes as OrderbookActionTypes } from '../../../orderbook/actions/orderbooks';
import { Chart } from '../models/chart';
import orderBookStore from '../../../orderbook/store/orderbooks';
import { TradeReportingTable } from '../../../trade/models/tradeReportingTable';
import OrderbookService from '../../../orderbook/services/orderbooks';
import { getOrderbookInstruments, getSubscribedDocksToInstruments } from '../../../orderbook/selectors/orderbooks';
import { PriceAlarmTable } from '../../../priceAlarm/models/priceAlarmTable';
import { MasterDataId } from 'js/main/models/application';
import { getOrderbookProducts } from '../../../orderbook/selectors/products';
import { VenueTable } from '../../venues/models/venueTable';
const orderbookService = new OrderbookService();

// TABS
export const tabRemoveDock: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Tabs.REMOVE || action.type === Tabs.MOVE),
    map(actions => actions.payload),
    switchMap(data => {
      const { dockId } = data;
      const tabsInDocks: { [id: string]: any } = getTabsCountInDocks(
        state.value
      );
      if (!tabsInDocks[dockId]) {
        return Rx.of(dockRemove(dockId));
      }
      return Rx.EMPTY;
    })
  );
};

// Markets
export const marketRemoveDock: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(
      action => action.type === Markets.REMOVE || action.type === Markets.MOVE
    ),
    map(actions => actions.payload),
    mergeMap(data => {
      const { dockId } = data;
      const marketsInDocks: { [id: string]: any } = getMarketsCountInDocks(
        state.value
      );

      if (!marketsInDocks[dockId]) {
        return [dockRemove(dockId),  orderBookStore.dispatch(dockRemove(dockId))];
      } else {
        const markets = getMarketsForDock(state.value, dockId);
        const masterDataObjects: any = getMasterDataEntities(data.componentType);
        const itemIds: MasterDataId[] = markets.map(market => {
          return masterDataObjects[market.itemId]?.id;
        });

        return [orderbooksLoad(itemIds, dockId, markets ? markets[0].type : ComponentType.Instrument, v1())];
      }
    })
  );
};

// Charts
export const chartRemoveDock: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(
      action => action.type === Charts.REMOVE || action.type === Charts.MOVE
    ),
    map(actions => actions.payload),
    mergeMap(data => {
      const { dockId } = data;
      const chartsInDocks: { [id: string]: any } = getChartsCountInDocks(
        state.value
      );
      if (!chartsInDocks[dockId]) {
        return [dockRemove(dockId), orderBookStore.dispatch(dockRemove(dockId))];
      }

      return Rx.EMPTY;
    })
  );
};

export const tabCreate: any = (actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === Tabs.CREATE),
    map(actions => actions.payload),
    switchMap((tab: ITab) => {
      const { id, type, dockId } = tab;
      const customId =
        dockId === 'recentActions' ? 'recent-' + type : undefined;

      switch (type) {
        case ComponentType.Trade:
          const table = new TradeTable(id, customId)
          if (tab.meta.tags) {
            table.tags = tab.meta.tags
          }
          return Rx.of(tableCreate([table]));
        case ComponentType.Order:
          return Rx.of(tableCreate([new OrderTable(id, customId)]));
        case ComponentType.Owntrade:
          return Rx.of(tableCreate([new OwnTradeTable(id, customId)]));
        case ComponentType.Request:
          return Rx.of(tableCreate([new RequestTable(id, customId)]));
        case ComponentType.Log:
          return Rx.of(tableCreate([new LogsTable(id, customId)]));
        case ComponentType.TradeReport:
          return Rx.of(tableCreate([new TradeReportingTable(id, customId)]));
        case ComponentType.PriceAlarm:
          return Rx.of(tableCreate([new PriceAlarmTable(id, customId)]));
        case ComponentType.Venues:
          return Rx.of(tableCreate([new VenueTable(id, customId)]));
        default:
          return Rx.EMPTY;
      }
    })
  );
};

// TABLES
export const tabRemoveTable: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Tabs.REMOVE),
    map(actions => actions.payload),
    switchMap(data => {
      const { id } = data;
      const tableId: string | null = getTableIdByParentId(state.value, id);
      if (tableId) {
        return Rx.of(tableRemove(tableId));
      }
      return Rx.EMPTY;
    })
  );
};

// Markets & Orderbooks

const marketCreate: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(
      action => action.type === Markets.CREATE || action.type === Charts.CREATE
    ),
    map(actions => actions.payload),
    switchMap((data: IMarket | Chart) => {
      if (data.type !== ComponentType.MarketChart) {
        const markets = getMarketsForDock(state.value, data.dockId);
        const masterDataObjects = getMasterDataEntities(data.type);
        let itemIds: string[] = [data.itemId];
        if (markets.length) {
          itemIds = markets.map(market => {
            return market.itemId;
          }).concat(itemIds)
          .filter((value: string, index: number, array: string[]) => array.indexOf(value) === index);
        }
        const masterDataIds = itemIds.map(id => masterDataObjects[id]?.id);
        return Rx.of(orderbooksLoad(masterDataIds, data.dockId, data.type, v1()));
      } else {
        return Rx.EMPTY;
      }
    })
  );
};


const marketLoad: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Markets.LOAD),
    map(actions => actions.payload),
    mergeMap((data: IMarket[]) => {
      const dockToItemIds: {[dockId:string]: MasterDataId[]} = data.reduce((acc: any, market: IMarket) => {
        const itemIdProperty = (!market.itemId && [ComponentType.InstrumentIntraday, ComponentType.Instrument].indexOf(market.type) > -1) ? 'instrumentId' : 'itemId';
        let itemId: MasterDataId = {venue: '', id: ''};
        if (market.type === ComponentType.Instrument || market.type === ComponentType.InstrumentIntraday) {
          const instruments = getOrderbookInstruments(orderBookStore.getState());
          itemId = instruments[market[itemIdProperty]]?.id;
        } else if (market.type === ComponentType.Product) {
          const products = getOrderbookProducts(orderBookStore.getState());
          itemId = products[market[itemIdProperty]]?.id;
        }
        return {
          ...acc,
          [market.dockId]:
            market.dockId in acc
              ? [...acc[market.dockId], itemId]
              : [itemId]
        };
      }, {});
      const dockToComponentType = data.reduce((acc: any, market: IMarket) => {
        return {
          ...acc,
          [market.dockId]: market.type
        };
      }, {});
      return Rx.concat(
        Object.keys(dockToItemIds).map(key => {
          return orderbooksLoad(dockToItemIds[key], key, dockToComponentType[key], v1());
        })
      );
    })
  );
};


const getMasterDataEntities = (componentType: ComponentType) => {
  if (componentType === ComponentType.Instrument || componentType === ComponentType.InstrumentIntraday) {
    const instruments = getOrderbookInstruments(orderBookStore.getState());
    return instruments;
  } else if (componentType === ComponentType.Product) {
    const products = getOrderbookProducts(orderBookStore.getState());
    return products;
  }
  return {};
}

const marketMove: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Markets.MOVE),
    map(actions => actions.payload),
    switchMap((data: { dockId: string; ids: string[]; toDockId: string, componentType: ComponentType }) => {
      const masterDataObjects: any = getMasterDataEntities(data.componentType);

      const movedItemIds = state.value.ui.markets.entities ?
        Object.keys(state.value.ui.markets.entities)
          .filter(k => data.ids.indexOf(k) !== -1)
          .map(k => masterDataObjects[state.value.ui.markets.entities[k].itemId]?.id) || []
          : [];

      const items = orderBookStore.getState().orderbook.contractMatrixes.entities[data.toDockId];
      const itemIdStrings = items ? Object.keys(items) : [];
      let itemIds: MasterDataId[] = [];
      if (data.componentType === ComponentType.Instrument || data.componentType === ComponentType.InstrumentIntraday) {
        const instruments = getOrderbookInstruments(orderBookStore.getState());
        itemIds = itemIdStrings.map(i => instruments[i]?.id);
      } else if (data.componentType === ComponentType.Product) {
        const products = getOrderbookProducts(orderBookStore.getState());
        itemIds = itemIdStrings.map(p => products[p]?.id);
      }
      return Rx.of(
        orderbooksLoad(
          movedItemIds.concat(itemIds),
          data.toDockId,
          data.componentType,
          v1()
        )
      );
    })
  );
};

const chartsMove: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Charts.MOVE),
    map(actions => actions.payload),
    switchMap((data: { dockId: string; ids: string[]; toDockId: string }) => {
      const instruments = getOrderbookInstruments(orderBookStore.getState());

      const movedInstrumentIds: MasterDataId[] =
        Object.keys(state.value.ui.charts.entities)
          .filter(
            k =>
              data.ids.indexOf(k) !== -1
          )
          .map(
            k => instruments[(state.value.ui.charts.entities[k] as Chart).instrumentId]?.id
          ) || [];

      if (movedInstrumentIds.length) {
        const dockEntity = orderBookStore.getState().orderbook?.contractMatrixes?.entities[
          data.toDockId
        ];
        if (dockEntity) {
          const instrumentIds: MasterDataId[] = Object.keys(
            orderBookStore.getState().orderbook.contractMatrixes.entities[
              data.toDockId
            ]
          ).map(id => instruments[id]?.id);

          return Rx.of(
            orderbooksLoad(
              movedInstrumentIds.concat(instrumentIds),
              data.toDockId,
              ComponentType.MarketChart,
              v1()
            )
          );
        }
        return Rx.EMPTY;
      } else {
        return Rx.EMPTY;
      }
    })
  );
};

const triggerMarketChange: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Markets.TRIGGER_EXPIRIES 
      // || action.type === Markets.TRIGGER_EXPANDED_EXPIRY
      || action.type === Markets.TRIGGER_EXPIRY_ROWS
      || action.type === Markets.SET_ORDERBOOK_DEPTH
      || action.type === Markets.REMOVE
      || action.type === Markets.MOVE),
    map(actions => actions.payload),
    switchMap((data: any) => {
        const depths = getContractDepthForAllDocks(state.value);
        return orderbookService.sendMarketsheetContractDepthRequest(depths)
        .pipe(
          switchMap((ack: any) => Rx.EMPTY),
          catchError((error: Error) => Rx.EMPTY)
        );
    }));
};

const updateAfterLayoutChange: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Markets.UPDATE_SUBSCRIPTIONS),
    map(actions => actions.payload),
    switchMap((data: { [dockId: string]: boolean }) => {
      let depths = getContractDepthForDocks(data)

      if (depths) {
        return orderbookService.sendMarketsheetContractDepthRequest(depths).pipe(
           switchMap((ack: any) => Rx.EMPTY),
           catchError((error: Error) => Rx.EMPTY)
        )
      }
      return Rx.EMPTY
    }));
};

const triggerMarketCreated: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === OrderbookActionTypes.LOAD_CONTRACT_MATRIX_SUCCESS ),
    map(actions => actions.payload),
    switchMap((data: any) => {
        return orderbookService.sendMarketsheetContractDepthRequest(
            getContractDepthForAllDocks(state.value),
            getSubscribedDocksToInstruments(state.value)[data.componentId], data.componentId
        )
        .pipe(
          switchMap((ack: any) => Rx.EMPTY),
          catchError((error: Error) => Rx.EMPTY)
        );
    }));
};

export const uiEpics = combineEpics(
  tabRemoveTable,
  tabRemoveDock,
  tabCreate,
  marketRemoveDock,
  chartRemoveDock,
  marketLoad,
  marketCreate,
  marketMove,
  chartsMove,
  triggerMarketChange,
  triggerMarketCreated,
  updateAfterLayoutChange
);
