import { createSelector } from 'reselect'
import { State } from '../../main/reducers/rootReducer'
import { ComponentType } from '../../shared/ui/models/component'
import { ITable, ITableSort } from '../../shared/ui/models/table'
import { tableVersionSelectorCreator } from '../../shared/utils/selectors/agrSelector'
import { tradeRowsFn } from '../reducers/tradeHelper'

const getTradesState = (state: State) => state.trades;
const getOwnTradesState = (state: State) => state.ownTrades;

export const getTradeEntities = createSelector(
  getTradesState,
  s => s.entities
);
export const getTradeDataVersion = createSelector(
  getTradesState,
  s => ({ version: s.dataVersion })
);
export const getTradeIds = createSelector(
  getTradesState,
  s => s.ids
);
export const getOwnTradeEntities = createSelector(
  getOwnTradesState,
  s => s.entities
);
export const getOwnTradeDataVersion = createSelector(
  getOwnTradesState,
  s => ({ version: s.dataVersion })
);
export const getOwnTradeIds = createSelector(
  getOwnTradesState,
  s => s.ids
);
export const getAllTrades = createSelector(
  getTradeEntities,
  getTradeIds,
  (entities, ids) => {
    return ids.map(id => entities[id]);
  }
);

export const getOwnTrades = createSelector(
  getOwnTradeEntities,
  getOwnTradeIds,
  (entities, ids) => ids.map((id: string) => entities[id])
);

export const getLastPrices = createSelector(
  getTradesState,
  (state) => state.lastPrices
);

export const getSettlementPrices = createSelector(
  getTradesState,
  (state) => state.settlementPrices
);

export const getTableData = createSelector(
  getTradesState, (state) => state.tableData
);

// return trade array for the table
export const getTableTrades = createSelector(
  [getTradesState, (state: State, table: ITable | undefined) => table],
    (s, table) => {
      const tableId = table.id;
      if (!s.tableData || !s.tableData[tableId] || !(table.type === ComponentType.Trade || table.type === ComponentType.TradeReport)) {
        return [];
      }
      return s.tableData[table.id].ids.map(id => s.tableData[table.id].entities[id]);
    }
);

export const getTableTradeIds = createSelector(
  [getTradesState, (state: State, tableId: string | undefined) => tableId],
  (s, id) => {
    if (!s.tableData || !s.tableData[id]) {
      return [];
    }
    return s.tableData[id].ids;
  }
);

export const getTradeRows = createSelector(
  [
    getAllTrades,
    (state: State, table: ITable | undefined, parentId: string) => ({table, parentId}),
    getTradeDataVersion
  ],
  tradeRowsFn
);

export const getOwnTradeRows = createSelector(
  [
    getOwnTrades,
    (state: State, table: ITable | undefined, parentId: string) => ({table, parentId}),
    getOwnTradeDataVersion
  ],
  tradeRowsFn
);

export const getTradeReportRows = tableVersionSelectorCreator(
  [
    getAllTrades,
    (state: State, table: ITable | undefined, parentId: string) => ({table, parentId}),
    getTradeDataVersion
  ],
  tradeRowsFn
);

export const getLimitOwnTrades = createSelector(
  getOwnTradesState,
  (state) => {
     return {
       limit: state.limit, 
       days: state.days
     };
    }
);

export const getLimitAllTrades = createSelector(
  getTradesState,
  (state) => {
     return {
       limit: state.limit, 
       days: state.days
     };
    }
);
