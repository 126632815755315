import * as React from 'react';

import { formatNumber } from '../../../../utils/formatters';
import { ITableColumn } from '../../../models/table';
import {withTooltip} from "./withTooltip";

interface ColumnEntryProps {
  value: any;
  layers: ((cellValuePresent: boolean) => JSX.Element[]) | null;
  onContextMenu: any;
  onTooltipAction: (e: any, visible: boolean) => void;
  onClick: any;
  cells: any[];
  combinedColumns?: ITableColumn[];
  onMouseMove: (e) => void;
  onMouseOut: (e) => void;
  onMouseOver: (e) => void;
  cancelTooltip: (e) => void;
}
interface ColumnEntryState {
  animate: boolean;
  oldValue: any;
}

class ColumnEntryComponent extends React.Component<
  ColumnEntryProps,
  ColumnEntryState
> {
  timeout: any;
  timestamp: number;

  constructor(props: ColumnEntryProps) {
    super(props);
    this.state = {
      animate: false,
      oldValue: undefined
    };
  }

  componentDidUpdate(prevProps: Readonly<ColumnEntryProps>, prevState: Readonly<ColumnEntryState>, snapshot?: any): void {
    if (prevProps.value !== this.props.value && !this.state.animate) {
      this.setState({
          animate: true,
          oldValue: this.props.value
        });
      requestAnimationFrame((time) => {
        this.timestamp = time;
        this._endAnimation(time);
      });
    }
  }

  _endAnimation = (time) => {
    if (time > this.timestamp + 500) {
      this.setState({
        animate: false,
        oldValue: undefined
      });
    } else {
      requestAnimationFrame(this._endAnimation);
    }
  };

  _onClick = (e) => {
    this.props.cancelTooltip(e);
    this.props.onClick(e);
  }

  _onContextMenu = (e) => {
    this.props.cancelTooltip(e);
    this.props.onContextMenu(e);
  }

  render() {
    const { value, layers, onContextMenu, onClick, cells, combinedColumns } = this.props;
    let result = null;
    let cell = cells[0];
    const className = `${cell.className} ${this.state.animate ? 'entry-update' : ''}`
    if (value !== undefined && value !== null) {
      let valueElement = <></>;
      if (!!combinedColumns) {
        let cellRows = [];
        for (let i = 0; i < combinedColumns.length; i++) {
          cell = cells[i];
          cellRows.push((
            <span style={{display: 'block'}} className={`col-${combinedColumns[i].originalName}`} key={`col-${combinedColumns[i].originalName}`}>
              <span className="cell-decorator">{cell.decorator}</span>
                {isNaN(cell.value) || cell.value === ''
                ? cell.value
                : formatNumber(cell.value,
                  cell.localizeOptions
                )}
              <span className="cell-symbol">{cell.symbol}</span>
            </span>)
          );
        }
        valueElement = <>{cellRows}</>;
      } else {
        cell = cells[0];
        valueElement = (<><span className="cell-decorator">{cell.decorator}</span>
         {isNaN(cell.value) || cell.value === ''
           ? cell.value
           : formatNumber(cell.value,
            cell.localizeOptions
          )}
          <span className="cell-symbol">{cell.symbol}</span></>);
      }
      result = (
        <React.Fragment>
          <label
            className={cell.className + ' ' + className}
            onContextMenu={this._onContextMenu}
            onClick={this._onClick}
            onMouseOver={this.props.onMouseOver}
            onMouseOut={this.props.onMouseOut}
            onMouseMove={this.props.onMouseMove}
            style={cell.style}
          > 
            {valueElement}
          </label> 
          {layers === null ? [] : layers(true)}
        </React.Fragment>
      );
    } else {
      result = (
        <React.Fragment>
          <label
            onClick={onClick}
            className={className}
            onContextMenu={onContextMenu}
            style={cell.style}
          />
          {layers === null ? [] : layers(false)}
        </React.Fragment>);
    }
    return result;
  }
}
export const ColumnEntry = withTooltip(ColumnEntryComponent);