import { ITable, TableAction, ITableColumn, ITableSort, ITableSearchTag, ITableRow, prepareColumns } from '../../../shared/ui/models/table';

import { config } from '../../../main/config';
import { ComponentType } from '../../ui/models/component';

export class VenueTable implements ITable {
  static index: number = 1;

  id: string;
  parentId: string;
  type: ComponentType.Venues;
  actions: TableAction[] = [
    TableAction.HIDDING_ROWS,
    TableAction.PINNING_ROWS
  ];
  columns: ITableColumn[] = [];
  sorting: ITableSort[];
  hiddenColumnNames: string[];
  hiddenRows: any[] = [];
  tags: ITableSearchTag[] = [];
  showHeadlines: boolean = true;
  showingHidden: boolean = false;
  rowPopoverComponent: any = null;
  cellActionMapping?: { [key: string]: string; };
  cellActions?: { [key: string]: (rowIndex: string, columnKey: string, tableComponent: any, row: ITableRow, event: any) => void; } = {};
  cellActionClick?: string;
  filters: { [key: string]: boolean; } = {};
  pinnedRows: number[] = [];
  externallyPaginated?: boolean = false;
  cmpFunctions?: {[columnName: string]: (o1: any, o2: any) => number};

  constructor(parentId: string, customId?: string) {
    this.id = customId
      ? customId
      : new Date().getUTCMilliseconds().toString() + VenueTable.index++;
    this.parentId = parentId;
    this.columns = config.ui.venueTable.columns.map(c => { return {...c}; });

    if (customId) {
      this.hiddenColumnNames = prepareColumns(config.ui.venueTable, this.columns, true);
      this.sorting = config.ui.venueTable.defaultSorting;
    } else {
      this.hiddenColumnNames = prepareColumns(config.ui.venueTable, this.columns, false);
      this.sorting = config.ui.venueTable.defaultSorting;
    }

    this.cellActionMapping = config.ui.venueTable.cellActions;
    this.cellActions = {
    };
    this.type = ComponentType.Venues;
    this.tags = [];
    this.cellActionClick = config.ui.venueTable.cellActionClick;
    
  }
}