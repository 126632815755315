import { createMasterDataIdString, getOrderbookContracts } from '../../../../../orderbook/selectors/contracts'
import orderBookStore from '../../../../../orderbook/store/orderbooks'
import { Restriction } from '../../../../../orders/models/orders'
import { formatNumber, formatQuantityToDecimal, getLocalizationSettings } from '../../../../utils/formatters'
import { findRootElement } from '../../../../utils/helper/eventUtils';
import { Cell, IMarket } from '../../../models/market'
import {ITableColumn} from "../../../models/table";
import { CurrencySymbols } from '../../../../../orderbook/models/contracts'
import * as React from "react";
import {Position} from "../../../../utils/models/grid";
import { I18n } from "react-redux-i18n";

import { CSSProperties, MutableRefObject } from 'react'
import { MatrixItem } from "js/orderbook/models/orderbooks";

const DEFAULT = 'default';
export const COLUMN_WIDTH_RESIZING = -1


type InstrumentType = 'power' | 'gas' |  'emissions' | 'oil' | typeof DEFAULT;

const defaultLocale = getLocalizationSettings()

export const getMarketContextMenuPosition = (e: any, logFatal: any, actionData: any, dockId: string) => {
    return {x: e.clientX, y: e.clientY};
};

export const getWidthFromColumnWidthCode = (code: string) => {
    try {
        return code === 'instrument-w-default' ? 0 : parseInt(code.replace('instrument-w-', ''));
    } catch (e) {
        return 0;
    }
}

export const getColumns = (market: IMarket, compactColumnsEnabled: boolean) => {
    const order = ['price', 'quantity', 'counterparty']

    const columnMap = market?.columns.reduce((combined, c) => {
        const colId = c.group + '__' + c.name
        if (market.hiddenColumnNames.indexOf(colId) === -1) {
            const column = {
                ...c,
                identifier: createColumnIdentifier(market.itemId, c.originalName)
            }
            if (market.customWidths && market.customWidths[colId]) {
                column.width = market.customWidths[colId]
            }
            if (c.combine && compactColumnsEnabled) {
                combined.set(c.group, combined.get(c.group)
                  ? [...combined.get(c.group), column]
                        .sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name))
                  : [column])
            } else {

                combined.set(c.title, [column])
            }
        }
        return combined;
    },
    new Map())

    return columnMap || new Map()
}

const getColorOpacity = (color: string): number => {
    try {
        const a = color.substring(5, color.length - 1).split(',');
        return Number(a[a.length - 1]);
    } catch (e) {
        return 0;
    }
};

export const createColumnClass = (
    column: ITableColumn,
    hasValue: boolean | number,
    periodType?: string,
): string => {
    const { group, name, originalName, insideInfo } = column;

    let result = '';
    result +=
        group === 'trades'
            ? 'trade'
            : group === 'askPrices'
                ? 'order ask'
                : 'order bid';
    result += (' ' + originalName + ' ' + name)
    if (periodType) {
        result += (' ' + periodType);
    }
    result += hasValue || hasValue === 0 ? ' with-value' : '';

    if (insideInfo) {
        result += insideInfo ? ' inside-info' : '';
    }

    return result;
};
/**
 * Sets font color to a cell
 * Does not combine colors of different types but set the color according to hierarchy
 * @param column - column object
 * @param anonymizeMarketDataEnabled - configuration flag
 * @param colors - font colors from config.ts
 * @param isQuoteRequest - cell displayes value from quote request
 * @param isOwnOrder - cell displayes value from own order
 * @param isReadOnly - cell displayes value from readonly order
 * @param isSuspended - cell displayes value from suspended order
 * @param isPreview - fictional order displayed in order preview
 * @param isImplied - implied price
 * @return style object with color attribute
 */
export const createColumnStyle = (
    column: ITableColumn,
    anonymizeMarketDataEnabled: boolean,
    colors: { [key: string]: string },
    isQuoteRequest: boolean,
    isOwnOrder?: boolean,
    isReadOnly?: boolean,
    isSuspended?: boolean,
    isPreview?: boolean,
    isImplied?: boolean,
    isRouted?: boolean,
    counterparty?: string
): { color: string } => {

    let result = {color: colors['order']};
    if (isOwnOrder && getColorOpacity(colors['orderOwn']) >= 0.5) {
        result.color = colors['orderOwn'];
    }

    if (isReadOnly  && !anonymizeMarketDataEnabled && getColorOpacity(colors['orderReadonly']) >= 0.5) {
        result.color = colors['orderReadonly'];
    }

    if (isSuspended && getColorOpacity(colors['orderSuspended']) >= 0.5) {
        result.color = colors['orderSuspended'];
    }

    if (isQuoteRequest && getColorOpacity(colors['quoteRequest']) >= 0.5) {
        result.color = colors['quoteRequest'];
    }

    if (isPreview && getColorOpacity(colors['orderPreview']) >= 0.5) {
        result.color = colors['orderPreview'];
    }

    if (isImplied && getColorOpacity(colors['implied']) >= 0.5) {
        result.color = colors['implied'];
    }

    if (isRouted && getColorOpacity(colors['routed']) >= 0.5) {
        result.color = colors['routed'];
    }

    if (counterparty && colors['counterparty-' + counterparty] && getColorOpacity(colors['counterparty-' + counterparty]) > 0.5) {
        result.color = colors['counterparty-' + counterparty];
    }

    return result;
};
/**
 * Creates colored background for a cell
 * Result is an array of div elements with different bg colors
 * These divs are layered and lower opacities of bg colors make colors visually combine
 * @param column - column object
 * @param anonymizeMarketDataEnabled - configuration flag
 * @param isQuoteRequest - cell displayes value from quote request
 * @param isOwnOrder - cell displayes value from own order
 * @param isReadOnly - cell displayes value from read only order
 * @param isSuspended - cell displayes value from suspended order
 * @param isAon - cell displayes value from  aon order
 * @param isPreview - fictional order displayed in orderbook preview
 * @return array of div elements with assigned css classes
 */

export const createColumnLayers = (
    column: ITableColumn,
    anonymizeMarketDataEnabled: boolean,
    isQuoteRequest: boolean,
    isOwnOrder?: boolean,
    isReadOnly?: boolean,
    isSuspended?: boolean,
    isAon?: boolean,
    isPreview?: boolean,
    isImplied?: boolean,
    isRouted?: boolean,
    periodType?: string,
    counterparty?: string
): (cellValuePresent: boolean) => JSX.Element[] => {
    const { name } = column;
    let resultIfCellHasValue: JSX.Element[] = [];
    let resultAlways: JSX.Element[] = [];

    if (isOwnOrder !== undefined && !isQuoteRequest) {
        // preview order is always own order therefore its applied with own order style
        resultIfCellHasValue.push(
            <div
                key="order"
                className={`layer ${isOwnOrder ? `own-order` : `non-own-order`}${isPreview ? ` preview-order` : ``}`}
            />
        );
    }

    if (isReadOnly !== undefined && isReadOnly && !anonymizeMarketDataEnabled) {
        resultIfCellHasValue.push(<div key="readonly" className="layer readonly" />);
    }

    if (!!isSuspended) {
        resultIfCellHasValue.push(<div key="suspended" className="layer suspended" />);
    }

    if (!!isAon && name === 'quantity') {
        resultIfCellHasValue.push(<div key="aon-order" className="layer aon-order" />);
    }

    if (!!isQuoteRequest) {
        resultIfCellHasValue.push(<div key="quoteRequest" className="layer quote-request" />);
    }

    if (!!isImplied) {
        resultIfCellHasValue.push(<div key="implied" className="layer implied" />);
    }

    if (!!isRouted) {
        resultIfCellHasValue.push(<div key="routed" className="layer routed" />);
    }

    if (!!periodType) {
        resultAlways.push(<div key="periodType" className={`layer periodType-${periodType.toLowerCase()}`} />);
    }

    if (!!counterparty) {
        resultAlways.push(<div key="counterparty" className={`layer counterparty-${counterparty}`} style={{backgroundColor: 'var(--counterparty-' + counterparty + '-background)'}} />);
    }

    return (cellValuePresent: boolean) => {
        if (cellValuePresent) {
            return [...resultIfCellHasValue, ...resultAlways];
        } else {
            return resultAlways;
        }
    };
};

export function createColumnBackground (
  column: ITableColumn,
  value,
  groupId: number,
  anonymizeMarketDataEnabled: boolean,
  computedStyle: any,
  isQuoteRequest: boolean,
  isOwnOrder?: boolean,
  isReadOnly?: boolean,
  isSuspended?: boolean,
  isAon?: boolean,
  isPreview?: boolean,
  isImplied?: boolean,
  isRouted?: boolean,
  periodType?: string,
  counterparty?: string
) {
    const cellValuePresent = value !== undefined && value !== null
    const { name } = column;
    let resultIfCellHasValue = undefined
    let resultAlways = undefined
    let otherStyles: CSSProperties = {}

    if (cellValuePresent &&  (groupId + 1) % 2 === 0) {
        const evenColor = getRgbaFromString(computedStyle.getPropertyValue('--table-even-background'))
        resultIfCellHasValue = painterAlgorithm(resultIfCellHasValue, evenColor)
    } else if (cellValuePresent) {
        const oddColor = getRgbaFromString(computedStyle.getPropertyValue('--table-odd-background'))
        resultIfCellHasValue = painterAlgorithm(resultIfCellHasValue, oddColor)
    }

    if (column.insideInfo) {
        const insideColor = getRgbaFromString(computedStyle.getPropertyValue('--inside-informations-background'))
        resultIfCellHasValue = painterAlgorithm(resultIfCellHasValue, insideColor)
        resultAlways = painterAlgorithm(resultAlways, insideColor)
    }

    if (column.name === 'price') {
        const variable = column.group === 'askPrices' ? '--ask-overlay-background' : '--bid-overlay-background'
        const overlayColor = getRgbaFromString(computedStyle.getPropertyValue(variable))
        resultIfCellHasValue = painterAlgorithm(resultIfCellHasValue, overlayColor)
        resultAlways = painterAlgorithm(resultAlways, overlayColor)
    }

    if (cellValuePresent && !isQuoteRequest) {
        const ownOrderColor = getRgbaFromString(computedStyle.getPropertyValue((!!isOwnOrder ? '--order-own' : '--order') + '-background'))
        resultIfCellHasValue = painterAlgorithm(resultIfCellHasValue, ownOrderColor)
    }

    if (cellValuePresent &&  isPreview !== undefined && isPreview) {
        const previewColor = getRgbaFromString('--order-preview-background')
        resultIfCellHasValue = painterAlgorithm(resultIfCellHasValue, previewColor)
    }

    if (cellValuePresent && isReadOnly !== undefined && isReadOnly && !anonymizeMarketDataEnabled) {
        const readonlyColor = getRgbaFromString(computedStyle.getPropertyValue('--order-readonly-background'))
        resultIfCellHasValue = painterAlgorithm(resultIfCellHasValue, readonlyColor)
    }

    if (cellValuePresent && !!isSuspended) {
        const suspendedColor = getRgbaFromString(computedStyle.getPropertyValue('--order-suspended-background'))
        resultIfCellHasValue = painterAlgorithm(resultIfCellHasValue, suspendedColor)
    }

    if (cellValuePresent && !!isAon && name === 'quantity') {
        otherStyles.paddingRight = '7.5px'
    }

    if (cellValuePresent && !!isQuoteRequest) {
        const qrColor = getRgbaFromString(computedStyle.getPropertyValue('--quote-request-background'))
        resultIfCellHasValue = painterAlgorithm(resultIfCellHasValue, qrColor)
    }

    if (cellValuePresent && !!isImplied) {
        const impliedColor = getRgbaFromString(computedStyle.getPropertyValue('--implied-background'))
        resultIfCellHasValue = painterAlgorithm(resultIfCellHasValue, impliedColor)
    }

    if (cellValuePresent && !!isRouted) {
        const routedColor = getRgbaFromString(computedStyle.getPropertyValue('--routed-background'))
        resultIfCellHasValue = painterAlgorithm(resultIfCellHasValue, routedColor)
    }

    if (!!periodType) {
        const ptColor = getRgbaFromString(computedStyle.getPropertyValue(`--${periodType.toLowerCase()}-background`))
        resultAlways = painterAlgorithm(resultAlways, ptColor)

    }

    if (!!counterparty) {
        const cpyColor = computedStyle.getPropertyValue('--counterparty-' + counterparty + '-background')
        resultAlways = painterAlgorithm(resultAlways, cpyColor)
    }
    if (cellValuePresent) {
        const resultBG = painterAlgorithm(resultAlways, resultIfCellHasValue)
        return {...otherStyles, background: rgbaToString(resultBG)}
    } else {
        return {...otherStyles, background: rgbaToString(resultAlways)}
    };
};

function getRgbaFromString(colorCode: string) {
    if (!colorCode) {
        return undefined
    }
    let r,g,b,a
    if (colorCode.startsWith('#')) {
        r = parseInt(colorCode.slice(1, 3), 16)
        g = parseInt(colorCode.slice(3, 5), 16)
        b = parseInt(colorCode.slice(5, 7), 16)
        a = 1
    } else if (colorCode.startsWith('rgba')) {
        const numValues = colorCode.replace(/[\(\)a-z\s]/g, '').split(',')
        r = parseInt(numValues[0])
        g = parseInt(numValues[1])
        b = parseInt(numValues[2])
        a = parseFloat(numValues[3])

    } else if (colorCode.startsWith('rgb')) {
        const numValues = colorCode.replace(/[\(\)a-z\s]/g, '').split(',')
        r = parseInt(numValues[0])
        g = parseInt(numValues[1])
        b = parseInt(numValues[2])
        a = 1
    }
    return {r, g ,b, a}
}

function painterAlgorithm(bg, fg) {
    if (!fg && !bg) return undefined
    if (!fg) return bg
    if (!bg) return fg
    const a = fg.a + bg.a * (1 - fg.a)
    const r = (fg.r * fg.a + bg.r * bg.a * (1 - fg.a)) / a
    const g = (fg.g * fg.a + bg.g * bg.a * (1 - fg.a)) / a
    const b = (fg.b * fg.a + bg.b * bg.a * (1 - fg.a)) / a

    return {r, g, b, a}
}

function rgbaToString(color: {r: number, g: number, b: number, a: number}) {
    if (!color) return undefined
    const {r,g,b,a} = color
    return 'rgba('+Math.round(r)+','+Math.round(g)+','+Math.round(b)+','+a.toFixed(1)+')'
}

/**
 *
 * @param parent underlying element from with the menu or popover is displayed
 * @param element popover or menu which position is adjusted
 * @param position initial position of menu or popover
 */
export function adjustToMousePosition(parent: HTMLElement, element: HTMLElement, position: Position) {
    const parentHeight = parent.clientHeight || 0;
    const bottomPosition = element.clientHeight + position.y;
    let parentTop = parent.offsetTop;
    let parentLeft = parent.offsetLeft;
    const context = findRootElement(element, 'sidebar__recent-actions');
    if (context && parent.classList.contains('tab-content')) {
        // recent actions position is different

        if (context) {
            parentTop = 0;
        }
    }
    let diffY = parentHeight - bottomPosition + parentTop;

    const parentWidth = parent.clientWidth || 0;
    const rightPosition = element.clientWidth + position.x;
    const diffX = parentWidth - rightPosition + parentLeft;

    return {diffX, diffY};
}

export function correctTooltipPosition(element: HTMLElement, position: Position) {
    let diffY = 0, diffX = 0
    const bottomPosition = element.clientHeight + position.y
    const rightPosition = element.clientWidth + position.x

    if (bottomPosition > window.innerHeight) {
        diffY = window.innerHeight - bottomPosition
    }
    if (rightPosition > window.innerWidth) {
        diffX = window.innerWidth - rightPosition
    }
    return {diffY, diffX}
}

/**
 * Function returns type of instrument (power, gas, oil, emissions) based on given arguments
 */
export function getInstrumentType(instrumentGroupId: string): InstrumentType {
  const typeMap: { [key: string]: InstrumentType } = {
    '1': 'power',
    '2': 'gas',
    '3': DEFAULT, // placeholder for coal
    '4': DEFAULT, // placeholder for coal physical
    '5': 'emissions',
    '6': 'gas', // LNG
    '7': 'oil'
  };

  return instrumentGroupId ? (typeMap[instrumentGroupId[0]] || DEFAULT) : DEFAULT;
}

export const formatter = (column) => {
    switch (column.name) {
        case 'price': return priceFormatter
        case 'quantity': return (params) => quantityFormatter(params, column)
        case 'timestamp': return timestampFormatter
        case 'date': return dateFormatter
        default: return undefined
    }
}

function priceFormatter(params) {
    const contractId = params.data?.contracts
      ? params.data.contracts[Number(params.column?.parent?.groupId)]
      : undefined
    if (contractId && params.value !== null) {
        const contract = getOrderbookContracts(orderBookStore.getState())[contractId]

        let formattedLocalizeOptions: { [attribute: string]: any } = {
            ...defaultLocale,
            style: 'decimal',
            useGrouping: true,
            minimumFractionDigits: contract ? contract.priceDecimals : 0,
            maximumFractionDigits: contract ? contract.priceDecimals : 0
        }
        return formatNumber(params.value, formattedLocalizeOptions)
    }
    return params.value
}

function quantityFormatter(params, column) {
    const contractId = params.data?.contracts
      ? params.data.contracts[Number(params.column?.parent?.groupId)]
      : undefined
    if (contractId && params.value !== null) {
        const contract = getOrderbookContracts(orderBookStore.getState())[contractId]

        let formattedLocalizeOptions: { [attribute: string]: any } = {
            ...defaultLocale,
            style: 'decimal',
            useGrouping: true,
            minimumFractionDigits: contract ? contract.qtyDecimals : 0,
            maximumFractionDigits: contract ? contract.qtyDecimals : 0,
            decimalStep: contract ? contract.qtyStepSize : 0
        };
        let quantityUnit = !!contract?.qtyUnit && contract.qtyUnit !== 'NONE' ? contract.qtyUnit : '';
        if (quantityUnit === 'KW' && contract.qtyDecimals === 3) {
            quantityUnit = 'MW';
        }

        let symbol = quantityUnit;
        const restriction = params.colDef.field.replace('-' + column.originalName, '-' + column.group + '-restriction')
        if (restriction === Restriction.AON || restriction === Restriction.AON_INT) {
            symbol = ' *';
        }
        if (symbol) {
            return formatNumber(params.value, formattedLocalizeOptions) + symbol
        }
        return formatNumber(params.value, formattedLocalizeOptions)
    }
    return params.value
}

const timeInstance = new Intl.DateTimeFormat(undefined, {
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short'
});

const dateInstance = new Intl.DateTimeFormat(undefined, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric'
});

const dateTimeInstance = new Intl.DateTimeFormat(undefined, {
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    timeZoneName: 'short'
});

function timestampFormatter(params){
    if (!params.value) {
        return null
    }
    try {
        const formattedTime = timeInstance.format(new Date(params.value))
        return formattedTime;
    } catch (e) {
        return params.value
    }
};

function dateFormatter (params) {
    if (!params.value) {
        return null
    }
    try {
        const formattedTime = dateInstance.format(new Date(params.value));
        return formattedTime
    } catch (e) {
        return params.value
    }
};

function dateTimeFormatter (params) {
    if (!params.value) {
        return null
    }
    try {
        const formattedTime = dateTimeInstance.format(new Date(params.value));
        return formattedTime
    } catch (e) {
        return params.value
    }
};

export const getIcon = (entries, cellValue, index) => {
    let icon: JSX.Element | undefined;
    let indicator = "";
    if (index === 0) {
        const next = index + 1;
        if (entries?.length > next) {
            if (cellValue > entries[next]['execPrice']) {
                indicator = "top green";
            } else if (cellValue < entries[next]["execPrice"]) {
                indicator = "bottom red";
            } else if (cellValue === entries[next]["execPrice"]) {
                indicator = "right gray";
            }
        }
        if (indicator) {
            icon = (
                <i style={{ marginRight: "5px" }} className={`oi execprice-arrow oi-caret-${indicator}`} />
            );
        }
    }
    return icon;
};

export function generateCellFontClass(
    colors,
    isImplied: boolean,
    counterparty?: string,
    anonymizeMarketDataEnabled?: boolean,
    isQuoteRequest?: boolean,
    isOwnOrder?: boolean,
    isReadOnly?: boolean,
    isSuspended?: boolean,
    isRouted?: boolean,
) {
    let colorClass;

    if (!isQuoteRequest) {
        colorClass = "order-font";
    }

    if (isOwnOrder && getColorOpacity(colors['orderOwn']) >= 0.5) {
        colorClass = "order-own-font";
    }

    if (isReadOnly && !anonymizeMarketDataEnabled && getColorOpacity(colors['orderReadonly']) >= 0.5) {
        colorClass = "read-only-font";
    }

    if (isSuspended && getColorOpacity(colors['orderSuspended']) >= 0.5) {
        colorClass = "order-suspended-font";
    }

    if (isQuoteRequest && getColorOpacity(colors['quoteRequest']) >= 0.5) {
        colorClass = "quote-request-font";
    }

    if (isImplied && getColorOpacity(colors['implied']) >= 0.5) {
        colorClass = "implied-font";
    }

    if (isRouted && getColorOpacity(colors['routed']) >= 0.5) {
        colorClass = "routed-font";
    }

    if (
        counterparty &&
        colors[`counterparty-${counterparty}`] &&
        getColorOpacity(colors[`counterparty-${counterparty}`]) > 0.5
    ) {
        colorClass = `counterparty-${counterparty}-font` ;
    }

    return colorClass;
}

function compactColumnsCellRenderer(col, columnMap) {
    return (params) => {
        let columns = columnMap[col.group].filter(c => c.combine)
        const joinDateAndTime = columns.filter(c => c.group === 'trades' && c.name === 'timestamp' || c.name === 'date').length === 2

        return columns.length !== 0 ? (
          <div className="ag-cell-container">
              <div className="label combined">
                  {
                    columns.map((column: any) => {
                        if (!params.data[column.identifier]) {
                            return null
                        }
                        let value = params.data[column.identifier]
                        let colFormatter = undefined
                        if (joinDateAndTime) {
                            if (column.name === 'date') {
                                return undefined
                            } else if (column.name === 'timestamp') {
                                value = dateTimeFormatter({...params, value})
                            }
                        } else {
                            colFormatter = formatter(column)
                        }

                        const cellRenderer = getCellRenderer(column, columnMap, false)
                        if (colFormatter) {
                            value = colFormatter(params)
                            if (cellRenderer) {
                                value = cellRenderer({...params, value})
                            }
                        } else if (cellRenderer) {
                            value = cellRenderer({...params, value})
                        }
                        return value
                    }).filter(v => !!v )
                      .map((value: any, index: number) => (<span key={`${col.group}${index}-value`}
                              style={{ lineHeight: "90% ", height: "fit-content" }}>
                            {value}
                        </span>)
                    )
                  }
              </div>
          </div>
        ) : null;
    }
}

function getCellRenderer(col: any, columnMap, compactColumnsEnabled: boolean) {
    if (compactColumnsEnabled) {
        return compactColumnsCellRenderer(col, columnMap)
    } else if (col.name === 'execPrice') {
        return execPriceCellRenderer
    }
    return undefined
}

const execPriceCellRenderer = (params) => {
    const prevPrice = params.data[params.colDef.field?.replace('lp', 'prevPrice')]
    let indicator = undefined
    if (params.value > prevPrice) {
        indicator = 'top green'
    } else if (params.value < prevPrice) {
        indicator = 'bottom red'
    } else if (params.value === prevPrice) {
        indicator = 'right gray'
    }
    const value = priceFormatter(params)
    if (indicator && params.value && params.data.rowIndex === 0) {
        let icon = 'oi execprice-arrow oi-caret-' + indicator
        return <span><i style={{ marginRight: '5px' }}
                                       className={icon} />{value}</span>
    }
    return value
}

export const MARKET_PREFIX = "columns.market."

interface IGenerateColumnsData {
    compactColumnsEnabled: boolean;
    instrumentToMarket: Map<string, IMarket & {version: string }>;
    adjustedMatrices?: any;
    onMarketRemove: (marketId: string) => void;
    columnSizes: {[colId: string]: number | undefined};
    instrumentTables: string[];
}

export function generateColumns({
    compactColumnsEnabled,
    instrumentTables,
    instrumentToMarket,
    adjustedMatrices,
    onMarketRemove,
    columnSizes
}: IGenerateColumnsData) {
    let items = []
    for (const instrumentId of instrumentTables) {
        const market = instrumentToMarket.get(instrumentId)
        const columnMap = getColumns(market, compactColumnsEnabled)
        const matrix: MatrixItem = adjustedMatrices[market.itemId]
        const columnCount = columnMap.size

        const children = Array.from(columnMap.keys()).map((columnTitle, $index) => {
          const allColumns = columnMap.get(columnTitle)
          const col = allColumns[0]
          const prefix = compactColumnsEnabled && col.combine ? MARKET_PREFIX : ""
          const colId = createColumnIdentifier(instrumentId, col.originalName)
          return {
            cellClass: (params) => {
                const groupId = Number(params.column?.parent?.groupId)
                const contractId = params.data?.contracts
                  ? params.data.contracts[groupId]
                  : undefined

                const insideInfoClass = col.insideInfo ? "inside-info" : ""
                const interactiveClass = contractId ? " " : "not-interactive"
                const cellBackground = (groupId + 1) % 2 === 0 ? "even-table" : "odd-table"

                let borderClass = ""
                const fontClass = params.data[params.column?.colId + '-class']
                if (params.data.rowIndex > 0 && params.data.rowIndex === params.data.totalRenderedRows-1) {
                    borderClass = "default-border"
                }
                if (params.data.firstRowInGroup && params.data.rowIndex === 0) {
                    borderClass += " group-border"
                }

                return `ag-cell ${borderClass} ${interactiveClass} ${cellBackground} ${groupId} ${$index == columnCount - 1 ? 'block-border' : ''} ${insideInfoClass} ${fontClass || ''} ${col.name}-column`
            },
            cellStyle: (params) => {
              return params.data[params.column?.colId + '-style']
            },
            cellRenderer: getCellRenderer(col, Object.fromEntries(columnMap), compactColumnsEnabled),
            enableCellChangeFlash: true,
            field: colId,
            headerName:I18n.t(`${prefix}${columnTitle}`),
            minWidth: 10,
            width: columnSizes[colId] < 0 ? undefined : columnSizes[colId],
            suppressHeaderMenuButton: true,
            valueFormatter: formatter(col)
          }
        })

        items.push({
            children,
            headerClass: ["column-header"],
            headerGroupComponent: (params) => {
                const market = instrumentToMarket.get(instrumentId)
                const marketId = market.id
                return (
                    <div className="ag-header-group-cell-label header">
                        <div className="customHeaderLabel">{params.displayName}</div>

                        {marketId && instrumentTables.length > 1 && (
                          <button onClick={() => {
                              const marketIndex = instrumentTables.findIndex((id: string) => id === marketId)
                              instrumentTables.splice(marketIndex, 1)
                              onMarketRemove(marketId)
                          }}
                          className="remove-table-button">
                              <i className="oi oi-x"></i>
                          </button>
                        )}
                    </div>
                )
            },
            headerName: matrix?.name || market.title,
            marryChildren: true,
            colId: items.length
        })
    }

    return items;
}

interface IGenerateRowsData {
    instrumentTables: string[];
    compactColumnsEnabled: boolean;
    instrumentToMarket: Map<string, IMarket>;
    adjustedMatrices?: any;
    expiryKeys: string [];
    expiries: { [key: string]: any };
    presetDepths?: { [expiryCode: string]: number };
    adjustedPrices: any;
    colors: {[id: string]: any};
    anonymizeMarketDataEnabled: boolean;
}

export function createModel({
                                compactColumnsEnabled,
                                instrumentTables,
                                instrumentToMarket,
                                adjustedMatrices,
                                expiryKeys,
                                expiries,
                                presetDepths,
                                adjustedPrices,
                                colors,
                                anonymizeMarketDataEnabled
                            }: IGenerateRowsData) {
    const computedStyle = window.getComputedStyle(document.getElementById('root'))
    const rows = []
    for (let expKeyIdx = 0; expKeyIdx < expiryKeys.length; expKeyIdx++) {
        const expiryKey = expiryKeys[expKeyIdx]
        for (let expiryIdx = 0; expiryIdx < expiries[expiryKey]?.length; expiryIdx++) {
            const expiryRow = expiries[expiryKey][expiryIdx]
            let rowCount = 0
            if (presetDepths[expiryRow.code] === undefined) {
                rowCount = 1
            } else {
                rowCount = presetDepths[expiryRow.code] > 0
                  ? Math.min(expiryRow.depth, presetDepths[expiryRow.code])
                  : expiryRow.depth
            }

            for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
                const row = {}
                const colValues = []
                const contractIds = []

                const orgHierarchy = [expiryRow.name]
                if (rowIndex > 0) {
                    orgHierarchy.push(rowIndex)
                }

                for (let instrumentIdx = 0; instrumentIdx < instrumentTables.length; instrumentIdx++) {
                    const instrumentId: string = instrumentTables[instrumentIdx]
                    const market = instrumentToMarket.get(instrumentId)
                    const columnMap = getColumns(market, compactColumnsEnabled);
                    const colTitles = Array.from(columnMap.keys())
                    const columns = Object.fromEntries(columnMap)
                    const matrix = adjustedMatrices[market.itemId]
                    const contractId = !!matrix?.expiryToContract[expiryRow.code]
                      ? matrix?.expiryToContract[expiryRow.code]
                      : ""
                    contractIds.push(createMasterDataIdString(contractId))
                    const prices = adjustedPrices[market.itemId]

                    const askPrices = prices?.orders[expiryRow.code] ? prices.orders[expiryRow.code].askPrices : undefined
                    const bidPrices = prices?.orders[expiryRow.code] ? prices.orders[expiryRow.code].bidPrices : undefined
                    const trades = prices?.trades[expiryRow.code] ? prices.trades[expiryRow.code] : [];
                    const settlement = prices?.insideMarket.get(expiryRow.code) ? prices.insideMarket.get(expiryRow.code) : [];
                    const orders: any = { askPrices: askPrices ? askPrices : [], bidPrices: bidPrices ? bidPrices : [] }

                    for (let colIdx = 0; colIdx < colTitles.length; colIdx++) {
                        const colTitle = colTitles[colIdx]
                        const colAggregator = columns[colTitle]
                        for (let colSubIdx = 0; colSubIdx < colAggregator.length; colSubIdx++) {
                            const col = colAggregator[colSubIdx]
                            let pricesInRow = []
                            let entry: any = {}
                            switch (col.group) {
                                case "askPrices":
                                case "bidPrices":
                                    pricesInRow = orders ? [...orders[col.group]] : []
                                    entry = pricesInRow[rowIndex]
                                    row[createColumnIdentifier(instrumentId, col.group + '-restriction')] = entry?.restriction
                                    const fontClass = generateCellFontClass(
                                      colors,
                                      !!entry?.implied,
                                      entry?.counterparty,
                                      anonymizeMarketDataEnabled,
                                      entry?.quoteRequest,
                                      entry?.order?.ownOrder,
                                      entry?.readonly,
                                      entry?.order?.suspended,
                                      entry?.order?.routed,
                                    ) + ' ' + createColumnClass(col, entry && !!entry[col.name], expiryKey)
                                    if (fontClass) {
                                        row[createColumnIdentifier(instrumentId, col.originalName + '-class')] = fontClass
                                    }
                                    break

                                case  "trades": {
                                        pricesInRow = trades ? trades : [];
                                        const lastPricesArray = pricesInRow.length - 1 > rowIndex ? pricesInRow[rowIndex] : [null, null, null]
                                        entry = lastPricesArray.length === 3 ? {
                                            execPrice: lastPricesArray[0],
                                            timestamp: lastPricesArray[1],
                                            date: lastPricesArray[1],
                                            counterparty: lastPricesArray[2],
                                        } : {}
                                        const prevPrice = rowIndex == 0 && pricesInRow.length > 1 ? pricesInRow[1][0] : null
                                        row[createColumnIdentifier(instrumentId, 'prevPrice')] = prevPrice

                                        const cssClass = createColumnClass(col, entry && !!entry[col.name], expiryKey)
                                        if (cssClass) {
                                            row[createColumnIdentifier(instrumentId, col.originalName + '-class')] = cssClass
                                        }
                                    }
                                    break
                                case  "settlement": {
                                        pricesInRow = settlement ? settlement : [];
                                        const settlementArray = pricesInRow.length > rowIndex ? pricesInRow[rowIndex] : [null, null]
                                        entry = settlementArray.length === 2 ? {
                                            setlPrice: settlementArray[0],
                                            counterparty: settlementArray[1],
                                        } : {}

                                        const cssClass = createColumnClass(col, entry && !!entry[col.name], expiryKey)
                                        if (cssClass) {
                                            row[createColumnIdentifier(instrumentId, col.originalName + '-class')] = cssClass
                                        }
                                    }
                                    break
                            }

                            const colValue = entry ? entry[col.name] : null
                            const style = createColumnBackground(col,
                              colValue,
                              instrumentIdx,
                              anonymizeMarketDataEnabled,
                              computedStyle,
                              entry?.quoteRequest,
                              entry?.order?.ownOrder,
                              entry?.readonly,
                              entry?.order?.suspended,
                              entry?.aon,
                              false,
                              !!entry?.implied,
                              entry?.order?.routed,
                              expiryKey,
                              entry?.counterparty)
                            if (style) {
                                row[createColumnIdentifier(instrumentId, col.originalName + '-style')] = style
                            }

                            row[createColumnIdentifier(instrumentId, col.originalName)] = colValue
                            colValues.push(colValue)
                        }
                    }
                }
                Object.assign(row,  {
                    orgHierarchy,
                    id: `${expiryRow.name.replace(/\s/g, '-')}-${rowIndex}`,
                    contracts: contractIds,
                    totalRenderedRows: rowCount,
                    colValues: colValues,
                    rowIndex: rowIndex,
                    expiryRow: expiryRow,
                    expiryKey: expiryKey,
                    firstRowInGroup: expiryIdx === 0,
                    currentDepth: presetDepths ? presetDepths[expiryRow.code] : 1
                })
                rows.push(row)
            }
        }
    }
    return rows
}

export function createColumnIdentifier(instrumentId: string, colName: string): string {
    return `${instrumentId.replace(/\s/g, '_')}-${colName}`
}

/**
 * Method for fast comparison
 * Currently not used, but it can be utilized for calculating row updates for rowNode.updateData
 * @param newRows
 * @param oldRows
 */
export function compareModels(newRows, oldRows) {
    const changed = {structure: false, cells: [], removed: [], added: []}
    let oldModelRowIdx = 0
    for (let rowIdx = 0; rowIdx < newRows; rowIdx++) {
        if (oldRows.length === 0) {
            changed.structure = true
            break
        }
        if (oldRows.length <= oldModelRowIdx) {
            for (let newRow = rowIdx; newRow < newRows.length; newRow++) changed.added.push(newRow)
            break
        }
        if (newRows[rowIdx]['rowCode'] === oldRows[oldModelRowIdx]['rowCode']) {
            for (let colIdx = 0; colIdx < newRows[rowIdx]['colValues'].length; colIdx++) {

                if (oldRows[oldModelRowIdx]['colValues'].length <= colIdx) {
                    changed.structure = true
                    break
                }
                if (newRows[rowIdx]['colValues'][colIdx] !== oldRows[oldModelRowIdx]['colValues'][colIdx]) {
                    changed.cells.push({row: rowIdx, col: colIdx})
                }
            }
        } else {
            if (newRows[rowIdx].rowIndex === 0
              && oldRows[oldModelRowIdx].rowIndex > 0) {
                changed.removed.push(oldModelRowIdx)
                rowIdx--
            }
            else if (newRows[rowIdx].rowIndex > 0
              && oldRows[oldModelRowIdx].rowIndex === 0) {
                changed.added.push(rowIdx)
                oldModelRowIdx--
            }
        }
        oldModelRowIdx++
    }
    return changed
}
interface IGenerateGroupColumn {
    onDepthChange: (e, params, currentDepth) => void;
    onDepthExpand: (e, params) => void;
}

export function generateGroupColumn({
    onDepthChange,
    onDepthExpand
}: IGenerateGroupColumn) {
    const defaultAutoGroupSettings = {
        cellClass: (params) => {
            const { data } = params.node;
            const rowName = data?.row?.name || "";
            let borderClass = "";
            if (data.firstRowInGroup && data.rowIndex === 0) {
                borderClass = "group-border"
            }

            return `group-wrapper ${borderClass} expiry-column`;
        },
        cellRenderer: (params) => {
            const depth = params?.data?.expiryRow?.depth
            const currentDepth = params?.data?.currentDepth || 1
            if (params.node.level === 0) {
                if (currentDepth > 1 && !params.node.expanded) {
                    params.api.setRowNodeExpanded(params.node, true)
                }
                if (depth > 0 && depth > params.data?.totalRenderedRows) {
                    return (
                            <button className="market-instrument-column-expiry"
                                    onClick={(e) => {
                                        onDepthChange(e, params, currentDepth + 1)
                                    }}
                                    onDoubleClick={(e) => {
                                        onDepthExpand(e, params)
                                    }}>
                                <span>{params.value}</span>
                                {I18n.t("marketsheet.depth.plus")}
                            </button>
                    );
                } else if (depth > 1 && depth <= params.data?.totalRenderedRows) {
                    return (
                            <button className="market-instrument-column-expiry" onClick={(e) => {
                                onDepthChange(e, params, params.data?.totalRenderedRows - 1)
                            }}
                                    onDoubleClick={(e) => {
                                        onDepthExpand(e, params)
                                    }}>
                                <span>{params.value}</span>
                                {I18n.t("marketsheet.depth.minus")}
                            </button>
                    );
                } else {
                    return (
                            <button className="market-instrument-column-expiry">
                                <span>{params.value}</span>
                            </button>
                    );
                }
            } else {
                return (
                    <div className="market-instrument-column-expiry minus">
                        {depth > 0 && (
                            <button
                                onClick={(e) => {
                                    const newDepth = params.node?.data ? params.node.data.rowIndex : currentDepth - 1
                                    onDepthChange(e, params, newDepth);
                                }}>
                                {I18n.t("marketsheet.depth.minus")}
                            </button>
                        )}
                    </div>
                );
            }
        },
        headerClass: ["column-header"],
        headerName: I18n.t("marketsheet.expiry"),
        minWidth: 150,
        pinned: "left",
        rowDrag: (params) => params.data?.firstRowInGroup && params.data.rowIndex === 0,
        suppressHeaderMenuButton: true,
        suppressSizeToFit: false,
        suppressMovable: true,
        lockPinned: true,
    };

    return defaultAutoGroupSettings;
}

export function reorderColumns(instrumentId: string, columns: any[], newColumnOrder: any[], hiddenColumnNames: string[]) {
    const columnsReordered = []
    let visibleIndex = 0
    const columnMap = columns.reduce((acc, col) => {
        acc[createColumnIdentifier(instrumentId, col.originalName)] = col
        return acc
    }, {})

    for (let oldIndex = 0; oldIndex < columns.length; oldIndex++) {
        if (hiddenColumnNames.indexOf(columns[oldIndex].group + '__' + columns[oldIndex].name) == -1
          && columns[oldIndex].rowVisible) {
            columnsReordered.push(columnMap[newColumnOrder[visibleIndex++]?.getId()])
        } else {
            columnsReordered.push(columns[oldIndex])
        }
    }
    return columnsReordered.filter(c => c !== undefined)
}

export function getColumnSizes(instrumentToMarket: Map<string, IMarket>) {
    const sizes = {}
    const markets = Array.from(instrumentToMarket.values())
    for (let market of markets) {
        for (let col of market.columns) {
            sizes[createColumnIdentifier(market.itemId, col.originalName)] =
              (market.customWidths && market.customWidths[col.group + '__' + col.name]) || col.width
        }
    }
    return sizes
}
