import React, { FC } from 'react';
import { Translate } from 'react-redux-i18n';

interface ToggleSwitchProps {
    value: boolean;
    trueTitle: string;
    falseTitle: string;
    disabled: boolean;
    toggle: (value: boolean) => void;
}

export const ToggleSwitch: FC<ToggleSwitchProps> = ({toggle, value, disabled, trueTitle, falseTitle}) => {
    return (
        <div className="btn-group btn-group-toggle" data-toggle="buttons">
            <label
                className={`'btn ${value ? 'active' : ''} bid'`}
                onClick={() => disabled ? null : toggle(true)}
            >
                <input
                    type="radio"
                    name="options"
                    id="option1"
                    autoComplete="off"
                />
                <Translate value={trueTitle} />
            </label>
            <label
                className={`btn ${value ? '' : 'active'} ask`}
                onClick={() => disabled ? null : toggle(false)}
            >
                <input type="radio" name="buy" id="buy0" autoComplete="off" />
                <Translate value={falseTitle} />
            </label>
        </div>
    );
};