import { Orderbooks, Orderbook, MarketsheetResponse } from '../models/orderbooks';
import { LogLevel } from '../../shared/logger/models/logger';
import { I18n } from 'react-redux-i18n';
import { LogAction } from '../../main/middlewares/logger';
import { ComponentType } from '../../shared/ui/models/component';
import { MasterDataId } from 'js/main/models/application';

export enum ActionTypes {
  LOAD_ORDERBOOKS = '[Orderbooks] Load',
  LOAD_ORDERBOOKS_SUCCESS = '[Orderbooks] Load success',
  LOAD_ORDERBOOKS_FAILURE = '[Orderbooks] Load failure',
  LOAD_CONTRACT_MATRIX_SUCCESS = '[Orderbooks] Load contract matrix success',
  UPDATE_PRICES = '[Orderbooks] Update prices',
  UPDATE_TRADES = '[Orderbooks] Update trades',
  LOAD_CONTRACT_MATRIX_FAILURE = '[Orderbooks] Load contract matrix failure',
  UNSUBSCRIBE = '[Orderbooks] Unsubscribe'
}

export interface OrderbooksLoadAction {
  type: ActionTypes.LOAD_ORDERBOOKS;
  payload: {itemIds: MasterDataId[], dockId: string};
  itemType: ComponentType;
  correlationId: string;
}

export interface OrderbooksLoadActionSuccess {
  type: ActionTypes.LOAD_ORDERBOOKS_SUCCESS;
  payload: Orderbook[];
}

export interface OrderbooksLoadActionFailure extends LogAction {
  type: ActionTypes.LOAD_ORDERBOOKS_FAILURE;
  payload: string;
}

export interface LoadContractMatrixSuccess extends LogAction {
  type: ActionTypes.LOAD_CONTRACT_MATRIX_SUCCESS;
  payload: MarketsheetResponse;
}

export interface LoadContractMatrixFailure extends LogAction {
  type: ActionTypes.LOAD_CONTRACT_MATRIX_FAILURE;
  payload: string;
}

export interface UpdatePricesAction {
  type: ActionTypes.UPDATE_PRICES;
  payload: string[];
}

export interface UpdateTradesAction {
  type: ActionTypes.UPDATE_TRADES;
  payload: any;
}

export interface OrderbookUnsubscribeAction {
  type: ActionTypes.UNSUBSCRIBE;
}

export type Action =
  | OrderbooksLoadAction
  | OrderbooksLoadActionSuccess
  | OrderbooksLoadActionFailure
  | LoadContractMatrixSuccess
  | LoadContractMatrixFailure
  | UpdatePricesAction
  | UpdateTradesAction
  | OrderbookUnsubscribeAction
  ;

export function orderbooksLoad(itemIds: MasterDataId[], dockId: string, itemType: ComponentType,  correlationId: string): OrderbooksLoadAction {
  return {
    correlationId: correlationId,
    type: ActionTypes.LOAD_ORDERBOOKS,
    payload: {itemIds: itemIds, dockId: dockId},
    itemType: itemType
  };
}

export function contractMatrixLoadSuccess(contractMatrix: any): LoadContractMatrixSuccess {
  return {
    type: ActionTypes.LOAD_CONTRACT_MATRIX_SUCCESS,
    payload: contractMatrix,
    logLevel: LogLevel.DEBUG,
    logMessage: I18n.t('contract.log.loadContractMatrixSuccess')
  };
}

export function contractMatrixLoadFailure(payload: Error
  ): LoadContractMatrixFailure {
    return {
      type: ActionTypes.LOAD_CONTRACT_MATRIX_FAILURE,
      payload: payload.message,
      logLevel: LogLevel.ERROR,
      logMessage: I18n.t('contract.log.loadContractMatrixFailure')
    };
  }

export function orderbooksLoadFailure(payload: Error
  ): OrderbooksLoadActionFailure {
    return {
      type: ActionTypes.LOAD_ORDERBOOKS_FAILURE,
      payload: payload.message,
      logLevel: LogLevel.ERROR,
      logMessage: I18n.t('orderbook.log.loadFailure')
    };
  }

export function orderbooksLoadSuccess(
  payload: Orderbook[],
): OrderbooksLoadActionSuccess {
  return {
    type: ActionTypes.LOAD_ORDERBOOKS_SUCCESS,
    payload: payload
  };
}

export function updatePrices(payload: string[]): UpdatePricesAction {
  return {
    type: ActionTypes.UPDATE_PRICES,
    payload: payload
  };
}

export function updateTrades(payload: any): UpdateTradesAction {
  return {
    type: ActionTypes.UPDATE_TRADES,
    payload: payload
  };
}

export function orderbookUnsubscribed(): OrderbookUnsubscribeAction {
  return {
    type: ActionTypes.UNSUBSCRIBE
  };
}