import * as React from 'react';
import OrderFormComponent from '../containers/orderFormComponent';
import { connect } from 'react-redux';
import { isOrderFormVisible, getSelectedContractId, getOrderFormData, isOrderSubmitDisabled } from '../selectors/orders';
import OrderFormData, { OrderFormMode } from '../models/formData';
import { Restriction } from '../models/orders';
import { openOrderForm, closeOrderForm } from '../actions/orderForm';
import { State } from '../../main/reducers/rootReducer';
import { I18n, Translate } from 'react-redux-i18n';
import { MemoTranslate } from '../../shared/i18n/components/memoTranslate';
import { MasterDataId } from '../../main/models/application';

interface OrderSidebarProps {
    modalVisible: boolean;
    showModal: (formData: OrderFormData) => void;
    hideModal: () => void;
    selectedContractId: MasterDataId;
    formData: OrderFormData;
    disabled: boolean;
}

interface OrderSidebarState {
    modalVisible: boolean;
    submitted: boolean;
    success: boolean;
    error: boolean;
    submitConfirmation: boolean;
    showConfirmationMessage: boolean;
    confirmationMessages: string[];
}

class OrderSidebarComponent extends React.Component<OrderSidebarProps, OrderSidebarState> {
    actionCallback: (action: string) => void;

    constructor(props: OrderSidebarProps) {
        super(props);
        this.state = {
            modalVisible: props.modalVisible,
            submitted: false,
            success: false,
            error: false,
            submitConfirmation: false,
            showConfirmationMessage: false,
            confirmationMessages: []
        };
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleAction = this.handleAction.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.onSanityCheck = this.onSanityCheck.bind(this);
    }

    showModal(event: any) {
        this.props.showModal({ 
            price: 0, quantity: 0, restriction: Restriction.NONE, buy: true, contractId: {id: '', venue: ''}, mode: OrderFormMode.ENTER, orderId: 0, revisionNo: 0, seenOrderToBeModified: null, 
            associatedContracts: [] 
        });
    }

    hideModal() {
        this.actionCallback('close');
        this.props.hideModal();
        this.setState(prevState => {
            return {
                ...prevState,
                submitted: false,
                showConfirmationMessage: false,
                confirmationMessages: [],
                submitConfirmation: false
            };
        });
    }
    onSubmit(event: any) {
        this.props.hideModal();
    }

    handleKeyPress(target: any) {
        if (target.charCode === 13) {
            if (!this.state.submitted && !this.props.disabled) {
               this.onSubmitAction();
            }
        }
    }

    handleAction(options: any) {
        if (options.actionCallback) {
            this.actionCallback = options.actionCallback;
            this.actionCallback = this.actionCallback.bind(this);
        }
        if (options.state) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    error: options.state.error,
                    success: options.state.success,
                    modalVisible: options.state.modalVisible,
                    submitted: this.state.submitted && !options.state.error
                };
            });
            if (options.state.success) {
                this.hideModal();
            }
        }
    }

    getModeData(props: OrderSidebarProps): any {
        let title = '';
        let button = '';
        switch (props.formData.mode) {
            case OrderFormMode.MODIFY: {
                title = 'order.modify';
                button = 'order.form.modifySubmit';
                break;
            }
            case OrderFormMode.MATCH: {
                title = 'order.match';
                button = 'order.form.matchSubmit';
                break;
            }
            case OrderFormMode.CANCEL: {
                title = 'order.delete';
                button = 'order.form.deleteSubmit';
                break;
            }
            case OrderFormMode.ENTER:
            default: {
                title = 'order.enter';
                button = 'order.form.createSubmit';
            }
        }
        return { title: title, button: button };
    }

    onSanityCheck(activated: boolean, warnings: string[]): void {
        // not yet submitted
        if (!this.state.showConfirmationMessage) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    submitConfirmation: activated,
                    confirmationMessages: warnings
                };
            });
        }
    }

    onSubmitAction = () => {
        if (!this.state.submitConfirmation) {
            this.actionCallback('submit');
            this.setState((prevState) => {
                return {
                    ...prevState,
                    submitted: true
                };
            });
        } else {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    showConfirmationMessage: true,
                    submitConfirmation: false
                };
            });
        }
    }

    onConfirmationCancel = () => {
        this.setState((prevState) => {
            return {
                ...prevState,
                showConfirmationMessage: false,
                submitConfirmation: true
            };
        });
    }

    render() {
        const { modalVisible } = this.props;
        const { title, button } = this.getModeData(this.props);
        const submitDisabled = this.state.submitted || this.props.disabled;
        let warnings = (<></>);
        if (this.state.showConfirmationMessage) {
            warnings = (
                <ul className="sanitycheck-warnings" key="order-form-warnings">
                    <MemoTranslate value="sanityChecks.warning" />
                    {this.state.confirmationMessages.map(msg => <li key={`warning-${msg}`}>{msg}</li>)}
                    <MemoTranslate value="sanityChecks.question" />
                </ul>
            );
        }

        return (
        <div className={`order-sidebar${modalVisible ? '' : ' hidden'}`} style={{ width: `370.5px` }}>
            {modalVisible ? (<div
                className="threebar cross"
                title={I18n.t('order.close')}
                onClick={() => this.hideModal()}
            >
                <div className="bar" />
                <div className="bar" />
                <div className="bar" />
            </div>) : null}
            <div
                id="orderForm"
                onKeyPress={this.handleKeyPress}
            >
                <Translate value={title} tag="h3"/>
                {warnings}
                <div style={{display: this.state.showConfirmationMessage ? 'none' : 'block'}}>
                    {modalVisible ? <OrderFormComponent onSanityCheck={this.onSanityCheck} actionHandler={this.handleAction} /> : <></>}
                </div>

                <div className="modal-footer">
                    <button 
                        hidden={!this.state.showConfirmationMessage}
                        className="btn btn-secondary w150"
                        onClick={
                            (e) => {
                                this.onConfirmationCancel();
                            }
                        }
                    >
                        {I18n.t('order.form.confirmationCancel')}
                    </button>
                    <button
                        hidden={this.state.showConfirmationMessage}
                        className="btn btn-cancellation w150 modal-close"
                        data-dismiss="modal"
                        onClick={() => this.hideModal()}
                    >
                        {I18n.t('modal.cancel')}
                    </button>
                    <button
                        style={{ display: this.state.success ? 'none' : 'block' }}
                        type="submit"
                        className="btn btn-approval w150"
                        onClick={
                            (e) => {
                                if (!submitDisabled) {
                                    this.onSubmitAction();
                                }
                            }
                        }
                        disabled={submitDisabled}
                    >{I18n.t(button)}</button>
                </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: State, props: OrderSidebarProps) => ({
    modalVisible: isOrderFormVisible(state),
    selectedContractId: getSelectedContractId(state),
    formData: getOrderFormData(state),
    disabled: isOrderSubmitDisabled(state)
});

const mapDispatchToProps = {
    showModal: openOrderForm,
    hideModal: closeOrderForm
};

export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(OrderSidebarComponent);
