import * as React from 'react'

interface DraggerProps {
  tag: string
  className?: string
  onDragComplete: (e: any) => void
  modificationEnabled: boolean
  children: React.ReactElement
}
interface DraggerState {}

export class DraggerComp extends React.Component<DraggerProps, DraggerState> {
  private initialPosition: any = { x: 0, y: 0, enabled: false }
  private ref: any
  constructor(props: DraggerProps) {
    super(props)

    this.ref = React.createRef()
    this.onMouseDown = this.onMouseDown.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.handleMouseUp = this.handleMouseUp.bind(this)
    this.onTouchStart = this.onTouchStart.bind(this)
    this.handleTouchEnd = this.handleTouchEnd.bind(this)
    this.handleTouchMove = this.handleTouchMove.bind(this)
  }

  onMouseDown(e: any) {
    if (this.props.modificationEnabled) {
      this.initialPosition = { x: e.clientX, y: e.clientY, enabled: true }
      document.addEventListener('mousemove', this.handleMouseMove, false)
      document.addEventListener('mouseup', this.handleMouseUp, false)
    }
  }

  onTouchStart(e: any) {
    if (this.props.modificationEnabled) {
      const { x, y } = this._getCoordinatesFromTouch(e)

      this.initialPosition = { x, y, enabled: true }
      document.addEventListener('touchmove', this.handleTouchMove, false)
      document.addEventListener('touchend', this.handleTouchEnd, false)
    }
  }

  handleMouseMove(e: any) {
    if (this.props.modificationEnabled) {
      const diffX = Math.abs(e.clientX - this.initialPosition.x)
      const diffY = Math.abs(e.clientY - this.initialPosition.y)
      if (this.initialPosition.enabled && (diffX > 10 || diffY > 10)) {
        document.removeEventListener('mousemove', this.handleMouseMove)
        document.removeEventListener('mouseup', this.handleMouseUp)
        this.props.onDragComplete(e)
      }
    }
  }

  handleTouchMove(e: any) {
    this.initialPosition = undefined
    document.removeEventListener('touchmove', this.handleTouchMove)
    document.removeEventListener('touchend', this.handleTouchEnd)
  }

  handleMouseUp(e: any) {
    if (this.props.modificationEnabled) {
      document.removeEventListener('mousemove', this.handleMouseMove)
      document.removeEventListener('mouseup', this.handleMouseUp)
    }
  }

  handleTouchEnd(e: any) {
    if (this.props.modificationEnabled) {
      if (this.initialPosition) {
        this.props.onDragComplete(e)
      }
      document.removeEventListener('touchmove', this.handleTouchMove)
      document.removeEventListener('mouseup', this.handleTouchEnd)
      this.initialPosition = undefined
    }
  }

  _getCoordinatesFromTouch(e: any) {
    for (let i = 0; i < e.touches.length; i++) {
      return {
        x: e.touches[i].clientX,
        y: e.touches[i].clientY,
      }
    }
    return { x: 0, y: 0 }
  }

  render() {
    const { children, tag, className, modificationEnabled } = this.props
    return React.createElement(tag, {
      ref: this.ref,
      className:
        className && modificationEnabled ? className : 'dragger-disabled',
      onMouseDown: this.onMouseDown,
      onTouchStart: this.onTouchStart,
      children: children,
    })
  }
}
