import * as Rx from 'rxjs';
import { v1 } from 'uuid';
import { AuthorizationCodeRequest } from '../models/sso';

const CODE_CHALLENGE_PARAM = 'cc';

export function redirectAndReadData(config: any) {
    if (isFronteggSSO(config)) {
         redirectAndReadDataFrontegg(config.redirectUrl, config.ssoClientId);
    } else {
        redirectAndReadDataKeycloak(config.redirectUrl);
    }
}

// this function can only get authorization code after redirect, which must be sent to frontegg to get token
export function redirectAndReadDataFrontegg(redirectUrl: string, clientId: string) {
    // read current URL
    var callback = parseCallbackUrl(window.location.href);

    if (callback) {
        // if it's redirected from frontegg
        window.history.replaceState(window.history.state, '', callback.newUrl);
    } else {
        // redirect to frontegg
        const codeVerifier = createRandomString();
        const codeChallenge: Promise<String> = generateCodeChallenge(codeVerifier);

        codeChallenge.then((codeChallenge) =>
            window.location.replace(redirectUrl +
            '?response_type=code'
            + '&scope=openId'
            + '&code_challenge=' + codeChallenge
            + '&client_id=' + clientId
            // code challenge added to redirect URL so the parameter will be recognized by application after redirect
            + '&redirect_uri=' + getLocationHrefWithLoginPath() + encodeURI('?' + CODE_CHALLENGE_PARAM + '=' + codeChallenge))
        );
    }
    return Rx.empty();
}

// for confidential client - uses fragments of keycloak library
// this function can only get authorization code after redirect, which must be sent to keycloak to get token
export function redirectAndReadDataKeycloak(redirectUrl: string) {
    // read current URL
    var callback = parseCallbackUrl(window.location.href);

    if (callback) {
        // if it's redirected from keycloak
        window.history.replaceState(window.history.state, '', callback.newUrl);
    } else {
        // redirect to keycloak
        window.location.replace(redirectUrl + '&response_type=code&redirect_uri=' + getLocationHrefWithLoginPath());
    }
    return Rx.empty();
}


/** create code challenge as a promise
 *  taken from frontegg docs */
export async function generateCodeChallenge(codeVerifier: string): Promise<string> {
const digest = await crypto.subtle.digest('SHA-256',
    new TextEncoder().encode(codeVerifier));

    // @ts-ignore
    return btoa(String.fromCharCode(...new Uint8Array(digest)))
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
}

export function createRandomString(length: number = 16): string {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
}

/**
 * creates request with available data from frame object returned by stompService connect
 * @param frame
 */
export function createRequest(frame: any): AuthorizationCodeRequest | any {
    if (!frame.code && !frame.ssoToken) {
        return {username: frame.username, correlationId: v1()};
    } else if (!frame.code && frame.ssoToken) {
        return {username: frame.username, correlationId: v1(), token: frame.ssoToken, accessToken: frame.ssoToken.accessToken};
    } else {
        return <AuthorizationCodeRequest>{code: frame.code, codeChallenge: frame.codeChallenge, redirectUri: getLocationHrefWithLoginPath()};
    }
}

function getLocationHrefWithLoginPath() {
    const locationHref = window.location.href;
    if (!locationHref.match(/(.+)\/login\/?$/)) {
        return locationHref.replace(/\/?$/, '/login');
    }
    return locationHref;
}

export function readSSOData(config) {
    const oauthParams = parseCallbackUrl(window.location.href);
    if (isFronteggSSO(config)) {
        if (oauthParams?.code && oauthParams[CODE_CHALLENGE_PARAM]) {
            return oauthParams;
        }
    } else {
        if ((oauthParams?.code && oauthParams?.session_state) || oauthParams?.error) {
            return oauthParams;
        }
    }
}

/**
 * Parses URL and retrieves data from callback URL after redirect from keycloak
 * URL can look like host?state=XXX&code=YYY or host#error=XXX
 * @param url
 * @return object with parsed parameters
 */
function parseCallbackUrl(url: string) {
    const supportedParams = ['code', 'state', 'session_state', 'error', 'error_description', 'error_uri', CODE_CHALLENGE_PARAM];

    const queryIndex = url.indexOf('?');
    const fragmentIndex = url.indexOf('#');

    let newUrl;
    let parsed;
    newUrl = url.substring(0, queryIndex);
    if (queryIndex < 0 && fragmentIndex < 0) {
        parsed = {paramsString: ''};
    } else if (queryIndex < 0 && fragmentIndex > -1) {
        parsed = parseCallbackParams(url.substring(fragmentIndex + 1), supportedParams);
    } else {
        parsed = parseCallbackParams(url.substring(queryIndex + 1, fragmentIndex !== -1 ? fragmentIndex : url.length), supportedParams);
    }
    if (parsed.paramsString !== '') {
        newUrl += '?' + parsed.paramsString;
    }
    if (fragmentIndex !== -1) {
        newUrl += url.substring(fragmentIndex);
    }
    if (fragmentIndex > 0 || queryIndex > 0) {
        window.history.replaceState(window.history.state, '', newUrl);
    }

    if (parsed && parsed.oauthParams) {
        parsed.oauthParams.newUrl = newUrl;
        return parsed.oauthParams;
    }
}

/**
 * Split param string param1=value&param2=value into object
 * @param paramsString
 * @param supportedParams
 */
function parseCallbackParams(paramsString: string, supportedParams: string[]): any {
    let p = paramsString.split('&');
    let result: {
        [key: string]: any
    } = {
        paramsString: '',
        oauthParams: {}
    };
    for (var i = 0; i < p.length; i++) {
        var t = p[i].split('=');
        if (supportedParams.indexOf(t[0]) !== -1) {
            result.oauthParams[t[0]] = decodeURI(t[1]);
        } else {
            if (result.paramsString !== '') {
                result.paramsString += '&';
            }
            result.paramsString += p[i];
        }
    }
    return result;
}

/**
 * Decode SSO token to object
 * @param str
 */
export function decodeToken(str: string): any {
    str = str.split('.')[1];

    str = str.replace('/-/g', '+');
    str = str.replace('/_/g', '/');
    switch (str.length % 4) {
        case 0:
            break;
        case 2:
            str += '==';
            break;
        case 3:
            str += '=';
            break;
        default:
            throw 'Invalid token';
    }

    str = (str + '===').slice(0, str.length + (str.length % 4));
    str = str.replace(/-/g, '+').replace(/_/g, '/');

    str = decodeURIComponent(escape(atob(str)));

    const parsed = JSON.parse(str);
    return parsed;
}

export function isFronteggSSO(config) {
    return !!config.ssoClientId;
}