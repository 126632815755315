import { I18n } from 'react-redux-i18n'

const lastViewName = 'profile.views.Last Dashboard'
const emptyViewName = 'profile.views.New Dashboard'

export const TranslateViewName = (viewName) => {
  return viewName === lastViewName || viewName === emptyViewName
    ? I18n.t(viewName)
    : viewName
}
