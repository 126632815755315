import { Action, ActionTypes } from '../actions/trade';
import ITrade from '../models/trade';
import { processTradesMap } from './tradeHelper';

export interface State {
  ids: string[];
  entities: { [id: string]: ITrade };
  tableData: {
    [tableId: string]:  {
      ids: string[];
      entities: {[id: string]: ITrade }
    }
  };
  dataVersion: string;
  error: any;
  lastPrices: {[contract: string]: number[]};
  limit: number;
  days: number;
}

export const initialState: State = {
  ids: [],
  entities: {},
  tableData: {},
  dataVersion: 'init',
  error: null,
  lastPrices: {},
  limit: 0,
  days: 0
};

export function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case ActionTypes.TRADE_REMOVE_ALL: {
      return {
        ...state,
        ...initialState,
        dataVersion: 'init'
      };
    }
    case ActionTypes.OWN_TRADE_FETCH_SUCCESS: {
      return processTradesMap(action, state);
    }

    case ActionTypes.TRADE_LIMIT: {
      if (action.own) {
        return {
          ...state,
          limit: action.limit === -1 ? 0 : action.limit + state.limit,
          days: action.days
        };
      }
      return state;
    }

    default:
      return state;
  }
}
