import React, { useState, useEffect, useCallback } from 'react'
import { I18n } from 'react-redux-i18n'
import { Tab } from 'js/shared/ui/models/tab'
import { ComponentType } from 'js/shared/ui/models/component'
import UITabView from 'js/shared/ui/components/table/components/uiTabComponent'
import Dragger from 'js/shared/dragger/container/Dragger'
import { MemoTranslate } from 'js/shared/i18n/components/memoTranslate'
import { config } from 'js/main/config'

export interface RecentActionsComponent {
  type: ComponentType;
  title: string;
  args?: any[];
}

const recentComponents: RecentActionsComponent[] = [
  {
    type: ComponentType.Order,
    title: 'sidebar.orders',
  },
  {
    type: ComponentType.Trade,
    title: 'sidebar.trades',
  },
  {
    type: ComponentType.Owntrade,
    title: 'sidebar.owntrades',
  },
  {
    type: ComponentType.TradeReport,
    title: 'sidebar.tradereports',
  },
  {
    type: ComponentType.Request,
    title: 'sidebar.requests',
  },
  {
    type: ComponentType.Log,
    title: 'sidebar.logs',
  },
  {
    type: ComponentType.PriceAlarm,
    title: 'sidebar.priceAlarms',
  },
  {
    type: ComponentType.Venues,
    title: 'sidebar.venues',
  },
]

const RecentActions = ({
  quoteRequestsEnabled,
  isTradeReportingUser,
  tabCreate,
  onDragActions,
}) => {
  const [filteredTabTypes, setFilteredTabTypes] = useState([])

  useEffect(() => {
    const liveDataPortalMode = config.liveDataPortalMode
    const filtered = recentComponents
      .map((c) => c.type)
      .filter((comp) => comp !== ComponentType.Request || quoteRequestsEnabled)
      .filter((c) => c !== ComponentType.TradeReport || isTradeReportingUser)
      .filter(
        (c) =>
          c !== ComponentType.Owntrade ||
          (!isTradeReportingUser && !liveDataPortalMode)
      )
      .filter((c) => c !== ComponentType.Order || !liveDataPortalMode)
      .filter((c) => c !== ComponentType.Venues || config.venueStatusEnabled)

    setFilteredTabTypes(filtered)
  }, [quoteRequestsEnabled, isTradeReportingUser])

  useEffect(() => {
    recentComponents.forEach((component) => {
      if (
        !config.priceAlarmsEnabled &&
        component.type === ComponentType.PriceAlarm
      ) {
        return
      }

      const meta = {
        displayFilter: false,
        displaySearch: false,
        isActive: component.type === ComponentType.Order,
        ordering: 0,
      }
      const newTab = new Tab(
        'recentActions',
        component.title,
        component.type,
        false,
        'recent-' + component.type,
        meta
      )

      tabCreate(newTab)
    })
  }, [])

  const onDragSelectedComponent = (e, title) => {
    const selectedComponent = recentComponents.filter(
      (comp) => I18n.t(comp.title).localeCompare(title) === 0
    )
    onDragActions(e, selectedComponent)
  }

  const onDragComplete = useCallback((e: any) => {
    // only drag filtered tabs
    onDragActions(
      e,
      recentComponents.filter((tab) => filteredTabTypes.indexOf(tab.type) > -1)
    )
  }, [recentComponents])

  return (
    <div>
      <div className="subtitle">
        <MemoTranslate value="sidebar.recent" tag={'h2'} />
      </div>
      <div className={'sidebar__recent-actions'}>
        <Dragger
          tag="div"
          className="dragger"
          onDragComplete={onDragComplete}
        />
        <UITabView
          dockId={'recentActions'}
          filteredTabTypes={filteredTabTypes}
          onTabDragComplete={(e, title) => onDragSelectedComponent(e, title)}
        />
      </div>
    </div>
  )
}

export default RecentActions
