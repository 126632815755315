import { connect } from 'react-redux';
import ChartIQComponent from '../components/chartIQComponent';
import { OrderbooksState } from '../../../orderbook/reducers/combinedReducer';
import { getContractNameToIdMap, getOrderbookContractIds, getOrderbookContracts, /*getOrderbookSyntheticContractIds, getOrderbookSyntheticContracts, getSyntheticContractNameToIdMap*/ } from '../../../orderbook/selectors/contracts';
import { getOrderbookProducts } from '../../../orderbook/selectors/products';

const mapStateToProps = (state: OrderbooksState, props: any) => {
  return {
    contracts: getOrderbookContracts(state),
    contractIds: getOrderbookContractIds(state),
    contractNameToIdMap: getContractNameToIdMap(state),
    products: getOrderbookProducts(state)
  };
};

const mapDispatchToProps = {
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(ChartIQComponent);
