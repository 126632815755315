import React, {useState, useRef, MouseEventHandler} from 'react';
import { State } from '../../../../../main/reducers/rootReducer';
import { connect } from 'react-redux';
import {
  TableAction,
  ITable,
  ITableSearchTag,
  ITableSort,
  IColumnName
} from '../../../models/table';
import { CSVLink } from 'react-csv';
import { I18n } from 'react-redux-i18n';
import { ComponentType } from '../../../models/component';
import { getTableForTab } from '../../../selectors/ui';
import store from '../../../../../main/store/store';
import { LogLevel } from '../../../../logger/models/logger';
import { log } from '../../../../logger/actions/logger';
import { triggerDownloadCsv } from '../../../actions/table';
import { config } from '../../../../../main/config';
import {SyncClickHandler} from 'react-csv/components/CommonPropTypes';

interface DownloadLinkProps {
  parentId: string;
  componentType: ComponentType;
  name: string;
  table: ITable | null;
  informUser: (notification: string, level: LogLevel) => void;
  triggerDownloadCsv: (visibleColumns: IColumnName[], hidden: number[], sorting: ITableSort[], type: ComponentType, id: string, tags: ITableSearchTag[], filter: {[key: string]: boolean}) => void;
}

type DownloadLinkState = any[];

const configKeys = {
  [ComponentType.Trade]: 'tradeTable',
  [ComponentType.Owntrade]: 'ownTradeTable',
  [ComponentType.TradeReport]: 'tradeReportingTable',
  [ComponentType.Order]: 'orderTable',
};

const DownloadLink: React.FC<DownloadLinkProps> = ({ name, table, componentType, parentId, triggerDownloadCsv, informUser }) => {
  const csvLink = useRef();
  const [data, setData] = useState<DownloadLinkState>([]);

  const handleStartDownload = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    const state = store.getState();
    const table = getTableForTab(state, parentId, componentType)!;
    const visibleColumns = table.columns.filter(
      (c) => table.hiddenColumnNames.indexOf(c.name) === -1
    );
    // trigger BE download
    const visibleColumnNames: IColumnName[] = visibleColumns.filter(col => col.name !== '$index').map(c => ({
        name: c.name,
        title: I18n.t(c.title)
    }));
    const configKey = configKeys[componentType];

    triggerDownloadCsv(visibleColumnNames, table.hiddenRows, table.sorting.length === 0 && configKey ? config.ui[configKey].defaultSorting : table.sorting, componentType, parentId, table.tags, table.filters);
  };

  const downloadCsv = (e) => {
    e.stopPropagation();
    const comp = I18n.t(`recentAction.${name}`)
    informUser(I18n.t('table.downloadingCsv', { content: I18n.t(comp) }), LogLevel.INFO);
  };

  if (!table || !table.actions.includes(TableAction.DOWNLOAD_CSV)) {
    return null;
  }

  return (
    <div>
      <i
        className={`oi oi-cloud-download hover-primary`}
        title={I18n.t('modal.download')}
        onClick={handleStartDownload}
      />
      <CSVLink
        ref={csvLink}
        data={data}
        separator={';'}
        onClick={downloadCsv}
        filename={name + '.csv'}
      />
    </div>
  );
};

const mapStateToProps = (state: State, props: DownloadLinkProps) => ({
  table: getTableForTab(state, props.parentId, props.componentType)
});

const mapDispatchToProps = {
  informUser: log,
  triggerDownloadCsv: triggerDownloadCsv
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(DownloadLink);