import { QuoteRequestMode, QuoteRequestFormData, EnterQuoteRequestRequest, QuoteRequestProfile, AnswerQuoteRequestRequest, IRequest } from '../models/request';
import { QuoteRequestModalProps } from '../components/QuoteRequestModalRefactored';

export function openEnterQuoteRequestFormFromQuoteRequest(quoteRequest: any, associatedContracts: string[]): QuoteRequestFormData {
    return openEnterQuoteRequestForm(quoteRequest.contractId, quoteRequest.isBuy, quoteRequest.quantity, associatedContracts);
}

export function openEnterQuoteRequestForm(contractId: string, isBuy: boolean, quantity: number, associatedContracts: string[]): QuoteRequestFormData {
    return {
        buy: isBuy,
        contractId: contractId,
        mode: QuoteRequestMode.ENTER,
        quantity: quantity ? quantity : 0,
        quoteRequestId: 0,
        reference: '',
        comment: '',
        validFrom: null,
        validTo: null,
        quote: 0,
        expiry: 0,
        businessUnit: null,
        portfolio: null,
        associatedContracts: associatedContracts
    };
}

export function createEnterQuoteRequest(data: QuoteRequestFormData, correlationId: string): EnterQuoteRequestRequest {
    const profileEntry: QuoteRequestProfile = {
        quoteRequestProfileEntry: [{
          contractId: data.contractId,
          quantity: data.quantity
        }
    ]};
    const request: EnterQuoteRequestRequest = {
        correlationId: correlationId,
        quoteRequestProfile: profileEntry,
        buy: data.buy,
        validFrom: data.validFrom,
        validTo: data.validTo,
        comment: data.comment,
        reference: data.reference,
        portfolioId: data.portfolio ? data.portfolio.toString() : undefined,
        businessUnitId: data.businessUnit ? data.businessUnit : undefined
    };

    return request;
}

export function createAnswerQuoteRequest(data: QuoteRequestFormData, correlationId: string): AnswerQuoteRequestRequest {
    const request: AnswerQuoteRequestRequest = {
        correlationId: correlationId,
        id: data.quoteRequestId,
        quote: data.quote,
        riskPremium: 0,
        expiry: data.validTo ? data.validTo.getTime() : data.expiry,
        traderPortfolioId: data.portfolio ? data.portfolio.toString() : undefined,
        traderBusinessUnitId: data.businessUnit ? data.businessUnit : undefined
    };

    return request;
}

export function createAnswerFormDataFromQuoteRequest(quoteRequest: IRequest): QuoteRequestFormData {
    return {
        buy: quoteRequest.buy,
        contractId: quoteRequest.contractId,
        mode: QuoteRequestMode.ANSWER,
        quantity: quoteRequest.quantity,
        quoteRequestId: quoteRequest.id,
        reference: quoteRequest.reference,
        comment: quoteRequest.comment,
        validFrom: new Date(quoteRequest.validFrom),
        validTo: new Date(quoteRequest.validTo),
        quote: 0,
        expiry: new Date(quoteRequest.validTo).getTime(),
        businessUnit: quoteRequest.traderBusinessUnitId,
        portfolio: parseInt(quoteRequest.traderPortfolioId, 10),
        associatedContracts: []
    };
}

export function createCancelFormDataFromQuoteRequest(quoteRequest: IRequest): QuoteRequestFormData {
    return {
        buy: quoteRequest.buy,
        contractId: quoteRequest.contractId,
        mode: QuoteRequestMode.CANCEL,
        quantity: quoteRequest.quantity,
        quoteRequestId: quoteRequest.id,
        reference: quoteRequest.reference,
        comment: quoteRequest.comment,
        validFrom: new Date(quoteRequest.validFrom),
        validTo: new Date(quoteRequest.validTo),
        quote: 0,
        expiry: 0,
        businessUnit: null,
        portfolio: null,
        associatedContracts: []
    };
}

export const getModeData = (props: QuoteRequestModalProps) => {
  const modeMapping = {
    [QuoteRequestMode.ANSWER]: {
      title: 'quoteRequest.answer',
      button: 'quoteRequest.form.answerSubmit',
    },
    [QuoteRequestMode.CANCEL]: {
      title: 'quoteRequest.cancel',
      button: 'quoteRequest.form.cancelSubmit',
    },
    [QuoteRequestMode.ENTER]: {
      title: 'quoteRequest.enter',
      button: 'quoteRequest.form.createSubmit',
    },
  };

  return modeMapping[props.formData.data.mode] || modeMapping[QuoteRequestMode.ENTER];
};