import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import {getOrderbookContractEntities, venueFromContractWithVenue} from '../../orderbook/selectors/contracts';
import orderBookStore from '../../orderbook/store/orderbooks';
import { Contract } from '../../orderbook/models/contracts';
import { MemoTranslate } from '../../shared/i18n/components/memoTranslate';
import {Result} from "../../shared/marketsheetSelect/models/results";

interface State {
    venueFilter: boolean;
    selectedVenues: string[];
    filteredContracts: any[];
    phrase: string;
    displayLimit: number;
    expanded: boolean;
}

interface Props {
    venues: string[];
    expanded: boolean; // From withAutoclose
    handleContractSelection: (contract: Contract, venueName: string) => void;
    handleTriggerVisibility: (visible: boolean) => void;
}

export default class ContractSelectComponent extends React.Component<Props, State> {
    private displayLimitDefault = 10;

    constructor(props: Props) {
        super(props);
        this.state = {
            venueFilter: false,
            selectedVenues: [],
            filteredContracts: [],
            phrase: '',
            displayLimit: this.displayLimitDefault,
            expanded: this.props.expanded
        };

        this.search = this.search.bind(this);
        this.showDropdown = this.showDropdown.bind(this);
        this._filterContracts = this._filterContracts.bind(this);
        this._getFilteredContracts = this._getFilteredContracts.bind(this);
        this._changeVenue = this._changeVenue.bind(this);
    }

    componentDidMount() {
        this.setState((prevState) => {
            return {
                ...prevState,
                expanded: false,
                phrase: '',
                filteredContracts: this._getFilteredContracts(prevState.selectedVenues, '')
            };
        });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if(prevProps.expanded !== this.props.expanded){
            this.setState(prevState => {
                return {
                    ...prevState,
                    expanded: this.props.expanded
                };
            });
        }
    }

    search(event: any) {
        const target = event.target;
        const value = target.value;
        this.showDropdown();
        this.setState(prevState => {
            return {
                ...prevState,
                filteredContracts: this._getFilteredContracts(this.state.selectedVenues, value),
                phrase: value
            };
        });
    }

    showDropdown() {
        this.setState(prevState => {
            return {
                ...prevState,
                expanded: true,
                filteredContracts: this._getFilteredContracts(prevState.selectedVenues, prevState.phrase)
            };
        });
    }

    getDropdownItems(listItems: Contract[]) {
        const iconClass = 'oi-list';
        return listItems.slice(0, this.state.displayLimit).map(result => {
            return (
                <li className="p-2" key={result.nameWithVenue} onClick={e => this._selectContract(result)}>
                    <span className={`oi ${iconClass}`} />
                    &nbsp;
                    {`${result.nameWithVenue}`}
                </li>
            );
        });
    }

    _increaseLimit = (e: any) => {
        this.setState(prevState => {
            return {
                ...prevState,
                displayLimit: prevState.displayLimit + this.displayLimitDefault
            };
        });
    }

    _selectContract = (contract: Contract) => {
        if (!contract) {
            return;
        }
        this.props.handleContractSelection(contract, venueFromContractWithVenue(contract.nameWithVenue));
        this.setState((prevState) => {
            return {
                ...prevState,
                expanded: false,
                phrase: '',
                filteredContracts: this._getFilteredContracts(prevState.selectedVenues, '')
            };
        });
    }

    _contractInSelectedVenues = (name: string, venues: string[]) => {
        for (let i = 0; i < venues.length; i++) {
            // check that the name ends with the venue name
            if(name.indexOf(venues[i], name.length - venues[i].length) !== -1){
                return true;
            }
        }
        return false;
    }

    _filterContracts(phrase: string, venues: string[], contracts: Contract[]) {
        if (!phrase && venues.length === 0) {
            return [...contracts];
        }
        let phraseLowerCase = phrase?.toLowerCase();
        let res = [];
        for (let i = 0; i < contracts.length; i++) {
            const venueCondition = venues.length === 0 || this._contractInSelectedVenues(contracts[i].nameWithVenue, venues);
            if (venueCondition && (!phraseLowerCase || (contracts[i].nameWithVenue).toLowerCase().indexOf(phraseLowerCase) > -1)) {
                res.push(contracts[i]);
            }
        }
        return res;
    }
    _getFilteredContracts(venues: string[], phrase: string): Result[] {
        let contracts = getOrderbookContractEntities(orderBookStore.getState());
        return this._filterContracts(phrase, venues, contracts);
    }

    _changeVenue = (e: any, venue: string) => {
        let venues = [...this.state.selectedVenues];
        if (e) {
            if (e.target.checked && venues.indexOf(venue) === -1) {
                venues.push(venue);
            } else if (!e.target.checked && venues.indexOf(venue) > -1) {
                venues = venues.filter(v => v !== venue);
            }
            this.setState((prevState) => {
                return {
                    ...prevState,
                    selectedVenues: venues,
                    filteredContracts: this._getFilteredContracts(venues, this.state.phrase),
                };
            });
        }
    }

    _showVenuesFilter = (show: boolean) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                venueFilter: show
            };
        });
    }

    render() {
        const { expanded } = this.state;
        const contracts = getOrderbookContractEntities(orderBookStore.getState());
        const listItems = this.state.filteredContracts.sort((a: Result, b: Result) => {
            if (a.venue < b.venue) {
                return -1
            }
            if (a.venue > b.venue) {
                return 1;
            }
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
        const placeholder: string = contracts.length > 0
            ? I18n.t('contract.select')
            : I18n.t('contract.loading');

        let dropdownItems: any = [];
        if (listItems.length === 0) {
            dropdownItems = (
                <li className="p-2">
                    <MemoTranslate value={'contract.empty'} />
                </li>
            );
        } else {
            dropdownItems = this.getDropdownItems(listItems);
            if (dropdownItems.length < listItems.length) {
                dropdownItems.push((
                    <li className="p-2" key="marketsheetselect-showmore"  onClick={e => this._increaseLimit(e)}>
                        <MemoTranslate value="general.dropdown.showMore" />
                    </li>));
            }
        }

        let venueDropdownItems: any = this.props.venues.map(venue => {
            return (<li className="p-2" key={`checkbox-venue-${venue}`}  onChange={e => this._changeVenue(e, venue)}>
                <input type="checkbox" className="form-check-input" id={`contract-venue-${venue}`} defaultChecked={this.state.selectedVenues.indexOf(venue) > -1} />
                <label className="form-check-label" htmlFor={`contract-venue-${venue}`}>{venue}</label>
            </li>);
        });

        return (
            <React.Fragment>
                    <div className="contracts">
                        <div className="subtitle">
                            <MemoTranslate value={'contract.title'} tag={'h2'} />
                        </div>
                        <div className="contracts__search">
                            <input
                                data-test="contracts-search-input"
                                disabled={contracts.length == 0}
                                className={`form-control ${expanded ? `active` : ``}`}
                                placeholder={placeholder}
                                type="text"
                                value={this.state.phrase}
                                onChange={this.search}
                                onClick={this.showDropdown}
                            />
                        </div>
                        <div className={'contracts__dropdown ' + (expanded ? 'visible' : '')}>
                            <div className="content-switch">
                                <div className={`content-switch__item${!this.state.venueFilter ? ' active' : ''}`} onClick={(e) => this._showVenuesFilter(false)}><MemoTranslate value='contract.title' /></div>
                                <div className={`content-switch__item${this.state.venueFilter ? ' active' : ''}`} onClick={(e) => this._showVenuesFilter(true)}><MemoTranslate value="instrument.venues"/></div>
                            </div>
                            <div className="contracts__results" hidden={this.state.venueFilter}>
                                <ul data-test="contracts-search-results">{dropdownItems}</ul>
                            </div>
                            <div className="venues__results" hidden={!this.state.venueFilter}>
                                <ul data-test="venues-search-results">{venueDropdownItems}</ul>
                            </div>
                        </div>
                    </div>
            </React.Fragment>
        );
    }
}
