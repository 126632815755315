import * as React from "react";
import "pickr-widget/dist/pickr.min.css";
import "../../../styles/app.css";
import {
  Consumer as ThemeConsumer,
  Provider as ThemeProvider,
} from "../../shared/context/themeContext";
import Dashboard from "../../dashboard/containers/Dashboard";
import history, { historyReplace } from "../history";
import Notifications from "../../shared/notifications/components/Notifications";
import { config } from "../config";
import { LogLevel } from "../../shared/logger/models/logger";
import { ErrorBoundary } from "react-error-boundary";
import fallbackRender from "./ErrorComponent";

interface Props {
  isAuthorized: boolean;
  isConnected: boolean;
  isFronteggAuthenticated: boolean;
  user: any;
  sendLogToServer: (log: { message: string; level: LogLevel }) => void;
  viewLoad: (viewId: string, grid: boolean) => void;
  applicationComplete: () => void;
  showTakeoverConfirm: true;
  sendProfileToServer: () => void;
  logout: (error: any) => void;
  activeViewId: string;
}

interface State {
  themeContextValue: {
    setTheme: any;
    selectedTheme: string;
  };
  authenticated: boolean;
}

export default class MainContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      themeContextValue: {
        setTheme: this.setTheme,
        selectedTheme: config.theme,
      },
      authenticated: props.isAuthorized || props.isFronteggAuthenticated,
    };
  }

  beforeUnloadHandler = (event) => {
    if (!this.props.activeViewId && this.props.isConnected) {
      event.preventDefault();
      this.props.sendProfileToServer();
    }
    return undefined;
  };

  componentDidMount() {
    this._checkAuthorization();
    this.state.themeContextValue.setTheme(config.theme);
    this.props.applicationComplete();

    window.addEventListener("beforeunload", this.beforeUnloadHandler);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.beforeUnloadHandler);
  }

  componentDidUpdate() {
    this._checkAuthorization();
  }

  componentDidCatch(error: Error) {
    this.props.sendLogToServer({
      message: "" + error.stack,
      level: LogLevel.FATAL,
    });
  }

  _checkAuthorization() {
    const notConnected = !this.state.authenticated;
    if (
      (notConnected &&
        history.location?.pathname.indexOf(config.subfolder + "/login") !==
          0) ||
      this.props.showTakeoverConfirm
    ) {
      historyReplace("/login");
    }
  }

  _checkConnectionAndAuthorization() {
    const notConnected = !this.state.authenticated || !this.props.isConnected;
    if (notConnected) {
      historyReplace("/login");
    }
  }

  setTheme = (theme: string) => {
    this.setState({
      themeContextValue: {
        ...this.state.themeContextValue,
        selectedTheme: theme,
      },
    });
  };

  render() {
    return (
      <ErrorBoundary
        fallbackRender={fallbackRender}
        onReset={(details) => {
          this.props.viewLoad("emptyView", true);
        }}
      >
        <ThemeProvider value={this.state.themeContextValue}>
          <ThemeConsumer>
            {({ selectedTheme }) => (
              <div
                className={
                  selectedTheme ? selectedTheme + " " + "h-100" : "h-100"
                }
              >
                <div className="container-fluid h-100">
                  <Dashboard />
                  <Notifications />
                </div>
              </div>
            )}
          </ThemeConsumer>
        </ThemeProvider>
      </ErrorBoundary>
    );
  }
}
