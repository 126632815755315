import React, { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import App from './containers/App';

import Login from '../authentication/containers/Login';
import { Translate } from 'react-redux-i18n';
import { config } from './config';
import { LogoutPage } from '../authentication/components/LogoutPage';
import FronteggApp from "./containers/FronteggApp";

const NotFound: FC = () => (
  <div>
    <h1><Translate value="error.Access denied" /></h1>
    <p><Translate value="error.page.proceed" /> <a href={config.subfolder + '/'}><Translate value="error.page.login" /></a></p>
  </div>
);

const BrowserError: FC = () => (
  <div className="h-100">
    <div className="login-container row justify-content-center h-100">
      <div className="login-box center-block" style={{ width: '60%' }}>
        <Translate value="error.Browser not supported" />
        <br/>
        <a
            href={
              'https://techcommunity.microsoft.com/t5/Windows-IT-Pro-Blog/The-perils-of-using-Internet-Explorer-as-your-default-browser/ba-p/331732'
            }
        >
          <Translate value="error.Click here for more information"/>
        </a>
      </div>
    </div>
  </div>
);

export default function AppRoutes(fronteggSSO: boolean) {
  return (
      <Routes>
        <Route path="/" element={fronteggSSO ? <FronteggApp /> : <App user={undefined} isFronteggAuthenticated={false} />} />
        <Route path="/login" element={fronteggSSO ? <FronteggApp /> : <Login />} />
        <Route path="/applogout" element={<LogoutPage />} />
        <Route path="/error" element={<NotFound />} />
        <Route path="/browserError" element={<BrowserError />} />
        <Route path="/oauth/callback" element={<FronteggApp />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
  );
}
