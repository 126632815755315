import React, { useState } from 'react';
import ModalWindow from 'js/shared/modalWindow/components/modalWindow';
import FavoriteForm from './favoriteForm';
import { FavoriteSelectableList } from './favoriteSelectableList';
import { MemoTranslate } from 'js/shared/i18n/components/memoTranslate';
import { FavoriteList } from './favoriteList';
import { IFavorite } from '../models/favorite';

interface FavoriteSelectProps {
  selectEntities: IFavorite[];
  favorites: { [id: string]: IFavorite };
  activeMarketInstruments: string[];
  activeChartInstruments: string[];
  activeMarketProducts: string[];
  onSelectFavorite: (e: any, favorite: IFavorite) => void;
  toggleFavorite: (favorite: IFavorite, isFavorite: boolean) => void;
  onFavoriteChangeVisibility: (favoriteId: string, isVisible: boolean) => void;
  modificationEnabled: boolean;
}

const FavoriteSelect: React.FC<FavoriteSelectProps> = ({
  favorites,
  onSelectFavorite,
  toggleFavorite,
  onFavoriteChangeVisibility,
  modificationEnabled
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMoreOpen, setIsMoreOpen] = useState(false);

  const handleFormSubmit = (selectedEntity: IFavorite) => {
    setIsModalOpen(false);
    toggleFavorite(selectedEntity, true);
  };

  const favoritesSorted = Object.keys(favorites).sort((a: string, b: string) =>
    a.localeCompare(b)
  ).map(key => favorites[key]);

  let favoritesArray: IFavorite[] = Object.keys(favoritesSorted).map((key: any) => favoritesSorted[key]);

  for (let i = 0; i < favoritesArray.length; i++) {
    if (favoritesArray[i].isNew) {
      const newOne = favoritesArray.splice(i, 1);
      favoritesArray = newOne.concat(favoritesArray);
      break;
    }
  }

  const handleSelect = ( e: any, favorite: IFavorite) => {
    setIsMoreOpen(false);
    return onSelectFavorite(e, favorite);
  }
  return modificationEnabled ? (
    <div className="sidebar__favorite-views">
      <div className="subtitle">
        <MemoTranslate value="favorite.title" tag={'h2'} />
      </div>
      <ModalWindow
        id="favorite"
        onModalClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        title={'favorite.title'}
      >
        <FavoriteForm onSubmit={handleFormSubmit} />
      </ModalWindow>
      <ModalWindow
        id="favorite-more"
        onModalClose={() => setIsMoreOpen(false)}
        isOpen={isMoreOpen}
        title={'favorite.title'}
      >
        <FavoriteSelectableList
          key="fav-list-modal"
          favorites={favoritesArray}
          onSelect={handleSelect}
          onClose={() => setIsMoreOpen(false)}
          onFavoriteRemove={favorite => toggleFavorite(favorite, false)}
          onFavoriteChangeVisibility={onFavoriteChangeVisibility}
        />
      </ModalWindow>
      <FavoriteList
        key="fav-list"
        favorites={favoritesArray}
        onSelect={(e: any, favorite: IFavorite) => onSelectFavorite(e, favorite)}
        openMore={() => setIsMoreOpen(true)}
      />
    </div>
  ) : <div />;
};

export default FavoriteSelect;
