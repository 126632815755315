import { ComponentType } from './component';
import { I18n } from 'react-redux-i18n';
import {CompareFunctions} from '../../utils/helper/sorter';

export enum TableAction {
  SEARCH = 'SEARCH',
  HIDDING_ROWS = 'HIDDING_ROWS',
  DOWNLOAD_CSV = 'DOWNLOAD_CSV',
  PINNING_ROWS = 'PINNING_ROWS',
  LIMIT_TRADES = 'LIMIT_TRADES' // only for trade tables
}

export interface ITableSearchTag {
  id: string | number;
  name: string;
}

export interface ITableColumn {
  name: string;
  title: string;
  originalName: string;
  group?: string;
  rowVisible: boolean;
  formatters: any[];
  searchable?: boolean;
  insideInfo?: boolean;
  sorting?: string;
  sortWith?: string;
  combine?: boolean;
  width?: number;
}

export interface ITableRow {
  [x: string]: any;
  name: string;
  value: any;
  formattedValue: string;
}

export interface ITableSort {
  columnName: string;
  direction: -1 | 1;
}

export interface ITable {
  id: string;
  parentId: string;
  type: ComponentType;
  actions: TableAction[];
  columns: ITableColumn[];
  sorting: ITableSort[];
  hiddenColumnNames: string[];
  hiddenRows: number[];
  tags: ITableSearchTag[];
  showHeadlines: boolean;
  showingHidden: boolean;
  rowPopoverComponent?: ((params: any) => JSX.Element) | null;
  cellActionMapping?: { [key: string]: string };
  cellActions?: { [key: string]: (rowIndex: string, columnKey: string, tableComponent: any, row: ITableRow, event: any, type?: string) => void };
  cellActionClick?: string;
  filters: {[key: string]: boolean};
  pinnedRows: number[];
  externallyPaginated?: boolean;
  cmpFunctions?: CompareFunctions;
}

export interface TablePaginatedRequest {
  from: number;
  columns: {
      column: string;
      sort: ('ASC' | 'DESC')
  }[];
  type: string;
  id: string;
  tags?: string[];
  limit: number;
  days: number;
}

export interface TradeTableRequest extends TablePaginatedRequest {
  days: number;
  limit: number;
}

export interface CreateCsvRequest {
  columnSorting: {
    column: string;
    sort: ('ASC' | 'DESC')
  }[];
  type: string;
  id: string;
  hidden: number[];
  visibleColumns: IColumnName[];
  tags?: string[];
}
export interface IColumnName {
  name: string;
  title: string;
}

const formatRow = (name: string, value: any, row: ITableRow) => {
  switch (name) {
    // should I18n be used for price and time as well?
    case 'price':
      return value && value.toLocaleString();
    case 'time':
      return value && new Date(value).toLocaleTimeString(undefined, { hour12: false });
    case 'date':
      return value && new Date(value).toLocaleDateString();
    case 'buy':
    case 'buySell':
      return value ? I18n.t('general.shortened.buy') : I18n.t('general.shortened.sell');
    case 'paidGiven':
      return value ? I18n.t('general.shortened.paid') : I18n.t('general.shortened.given');
    default:
      return value;
  }
};

export const prepareColumns = (configTable: any, tableColumns: ITableColumn[], recent: boolean) => {
  for (let i = 0; i < tableColumns.length; i++) {
    tableColumns[i].title = configTable.columns.filter((c: any) => c.name === tableColumns[i].name)[0].title + (recent ? 'Short' : '');
  }
  return tableColumns
    .filter((column: ITableColumn) =>
    configTable[recent ? 'recentTableColumns' : 'tableColumns']
    .indexOf(column.name) === -1)
    .map((column: ITableColumn) => column.name);
};
