import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Translate } from 'react-redux-i18n';
import UITableSpecificFilter from './uiTableSpecificFilter';

interface Props {
  columns: any[];
  hiddenColumnNames: string[];
  isVisible?: boolean;
  onCheckboxChange: (name: string, value: boolean) => void;
  onClickOutside: () => void;
  filters: {[key: string]: boolean};
  toggleTableSpecificFilter: (filterType: string, toggled: boolean) => void;
  tableId: string;
}
interface State {
  isVisible: boolean;
  maxHeight: number;
}

export default class UITableFilter extends React.Component<Props, State> {
  wrapperRef: any;
  constructor(props: Props) {
    super(props);
    this.toggleCheckbox = this.toggleCheckbox.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      isVisible: true,
      maxHeight: 100
    };
  }

  componentDidMount() {
    const node = ReactDOM.findDOMNode(this) as Element;
    this.setState({
      ...this.state,
      maxHeight: this._findDockParent(node.parentElement) - 40
    });

    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node: any) {
    this.wrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event: any) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.onClickOutside();
    }
  }

  toggleCheckbox(event: any) {
    const target = event.target;
    const value = target.checked;
    const name = target.name;
    this.props.onCheckboxChange(name, value);
  }

  _findDockParent(ele: HTMLElement | null): number {
    if (ele) {
      if (ele.classList.contains('dock__wrapper')) {
        return ele.clientHeight;
      } else if (ele.classList.contains('analytics-quadrant')) {
        return ele.clientHeight - 40; // usable space is reduced in analytics panel
      } else {
        return this._findDockParent(ele.parentElement);
      }
    } else {
      return 100;
    }
  }

  render() {
    const {
      columns,
      isVisible,
      hiddenColumnNames,
      tableId
    } = this.props;

    const { maxHeight } = this.state;

    const visibilityClass = isVisible ? 'visible' : 'hidden';
    let inputs = [];
    for (let column of columns) {
      if (column.rowVisible) {
        const inputEl = (
          <div className="col-6" key={column.name}>
            <div className="form-group form-check">
              <input
                type="checkbox"
                id={column.name}
                name={column.name}
                className="form-check-input"
                checked={hiddenColumnNames.indexOf(column.name) === -1}
                onChange={this.toggleCheckbox}
              />
              <label className="form-check-label" htmlFor={column.name}>
                <Translate value={column.title} />
              </label>
            </div>
          </div>
        );
        {
          inputs.push(inputEl);
        }
      }
    }

    return (
      <div
        className={`table-visibility-filter ${visibilityClass}`}
        // style={{ maxHeight: `${maxHeight}px` }}
        ref={this.setWrapperRef}
      >
        <div className="row pt-3">{inputs}</div>
        {Object.keys(this.props.filters).length ? (<React.Fragment>
          <span className="diviner m-2" />
            <UITableSpecificFilter
              toggleTableSpecificFilter={this.props.toggleTableSpecificFilter}
              filters={this.props.filters}
              dockId={tableId}
            /></React.Fragment>
          )
          :null}
      </div>
    );
  }
}
