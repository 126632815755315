import { MasterDataId } from '../../main/models/application';
import { IOrder, Restriction } from '../../orders/models/orders';
import { IRequest } from '../../requests/models/request';
import {Expiry} from "./contracts";

export interface SubscribeCombinedOrderbooksRequest {
  instrumentIds: string[];
}

export interface MarketsheetResponse {
    contractMatrixItem: any;
    componentId: string;
}

export interface MarketsheetRequest {
    correlationId: string;
    itemIds: MasterDataId[];
    componentId: string;
}

export interface MarketsheetContractDepthRequest {
  correlationId: string;
  contractDepths: {[contract: string]: number};
  instrumentIds?: MasterDataId[];
  componentId?: string;
}

export interface Orderbooks {
  orderbook: Orderbook[];
}

export interface Orderbook {
  contractId: MasterDataId;
  bidPrices: Price[];
  askPrices: Price[];
  realDepth: number;
}

export interface Price {
  $index: number;
  buy: boolean;
  order: IOrder;
  quote: Quote;
  quoteRequest: IRequest;
  tradable: boolean;
  id: number;
  type: OrderQuoteIndicator;
  price: number;
  quantity: number;
  aon: boolean;
  restriction: Restriction;
  readonly: boolean;
  timestamp: number;
  contractId: MasterDataId;
  traderId: string;
  counterparty: string;
  businessUnitId: string;
  portfolioId: string;
  implied: boolean;
  impliedSources: Price[];
  orderId: string;
}

export enum OrderQuoteIndicator {
  QUOTE,
  ORDER,
  MANUAL_EXECUTION,
  ORDER_ROUTING,
  QUOTE_REQUEST,
  MASS_ORDER_STATE_CHANGE,
  TRADE_EXTERNAL_ORDER_INTERNALLY,
  EXTERNAL_EXECUTION,
  MANUAL_ROUTING_PERMISSION,
  UNSOLICITED_ROUTING_REVOCATION,
  UNSOLICITED_ROUTING_ERROR,
  UNSOLICITED_ROUTING_SUCCESS,
  MARKET_NEUTRAL_UPDATE,
  UNSOLICITED_EXTERNAL_QUANTITY_REDUCED,
  UNSOLICITED_ROUTING_MODIFY_SUCCESS,
  UNSOLICITED_ROUTING_DELETE_SUCCESS,
  ORDER_ROUTING_STATE_CHANGE
}

export interface TrayportOrderId {
  engineID: number;
  orderId: string;
}

// move to separate models for quote
export interface Quote {
  quoteId: number;
  contractId: string;
  userId: string;9
  userName: string;
  userGroupId: string;
  userGroupName: string;
  bidPrc: number;
  askPrc: number;
  bidQty: number;
  askQty: number;
  timestamp: number;
  businessUnitId: string;
  portfolioId: string;
  portfolioClassId: number;
}

export const isIntraday = (periodType: string): boolean => {
  switch (periodType) {
    case 'QUARTER_HOUR':
    case 'HALF_HOUR':
    case 'BLOCK_2_H':
    case 'BLOCK_4_H':
    case 'HOUR': {
      return true;
    }
    default:
      return false;
  }
};

export enum LoadedData {
  CONTRACTS = 2,
  INSTRUMENTS = 3,
  PRODUCTS = 5,
  ALL = 30
}

export interface ContractMatrix {
  [instrumentId: string]: MatrixItem;
}

export interface MatrixItem {
  itemId: MasterDataId; // productId or instrumentId
  name: string;
  intraday: boolean;
  expiryToContract: {[key: string]: MasterDataId};
  expiries: {[key: string]: Expiry[]};
  type: string;
  instrumentId: string; // instrumentId - to track subscribed instrument
  instrumentName: string;
  associatedContracts: {[key: string]: string[]};
}
