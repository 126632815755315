import React, { FC, useState, useEffect } from 'react'
import {
  ARROW_DOWN_KEY,
  ARROW_UP_KEY,
  ENTER_KEY,
  NUMPAD_ADD_KEY,
  NUMPAD_SUBTRACT_KEY,
} from '../../../commonConstants'

export interface NumberSpinnerProps {
  value: number
  step: number
  name: string
  className: string
  size: number
  onChange: (e: any) => void
  autoFocus?: boolean
  disabled?: boolean
  min?: number
  type?: string
}

export const NumberSpinner: FC<NumberSpinnerProps> = (props) => {
  const [value, setValue] = useState<string>(
    isNaN(props.value) ? '0' : props.value + ''
  )

  useEffect(() => {
    setValue(isNaN(props.value) ? '0' : props.value + '')
  }, [props.value])

  const handlePlus = (e: any, sign: number) => {
    if (
      props.disabled ||
      (props.min !== undefined && Number(value) <= props.min && sign < 0)
    ) {
      return
    }

    const newValue =
      props.step === undefined
        ? (Number(value) + sign).toFixed(0)
        : (Number(value) + sign * Math.pow(10, -props.step)).toFixed(props.step)

    props.onChange({
      ...e,
      preventDefault: () => false,
      target: { name: props.name, value: newValue },
    })
  }

  const handleScroll = (e: React.WheelEvent<HTMLInputElement>) => {
    let delta = 0
    if (e.deltaY < 0) {
      delta = +1
    } else if (e.deltaY > 0) {
      delta = -1
    }

    handlePlus(e, delta)
  }

  const handleKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const key = e.key
    if (key === ARROW_UP_KEY || key === NUMPAD_ADD_KEY) {
      handlePlus(e, +1)
    } else if (key === ARROW_DOWN_KEY || key === NUMPAD_SUBTRACT_KEY) {
      handlePlus(e, -1)
    } else if (key === ENTER_KEY) {
      return
    }
  }

  return (
    <div className="number-spinner input-group">
      <input
        {...props}
        value={value}
        onWheel={handleScroll}
        onKeyUp={handleKey}
        autoComplete="off"
        type={props.type || 'text'}
      />
      <div className="spinner-vertical">
        <span className="spinner-item" onClick={(e) => handlePlus(e, +1)}>
          <span className="oi oi-caret-top" />
        </span>
        <span className="spinner-item" onClick={(e) => handlePlus(e, -1)}>
          <span className="oi oi-caret-bottom" />
        </span>
      </div>
    </div>
  )
}
