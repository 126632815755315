import * as React from 'react';
import orderBookstore from '../../../../../orderbook/store/orderbooks';
import store from '../../../../../main/store/store';
import { ColumnEntry } from './TableColumns';
import { ITableColumn } from '../../../models/table';
import { createMasterDataIdString, getOrderbookContracts } from '../../../../../orderbook/selectors/contracts';
import {
  Contract,
  ContractState
} from '../../../../../orderbook/models/contracts';
import { isAnonymizeMarketDataEnabled } from '../../../../../requests/selectors/requests';
import { IMarket } from '../../../models/market';
import { createColumnClass, createColumnLayers, createColumnStyle, getColumns } from '../helper/helper'
import { formatCell } from '../uiOrderbookFormatters';
import { Cell } from '../../../models/market';
import { MasterDataId } from '../../../../../main/models/application';

import {AdditionalExpiryRows} from "./AdditionalExpiryRows";
import {Price} from "../../../../../orderbook/models/orderbooks";


const generateKey = (...args: any[]) => {
  return args.join('-');
};

interface ExpiryRowProps {
  instrumentId: string;
  market: IMarket;
  columns: ITableColumn[];
  askPrices: any;
  bidPrices: any;
  trades: any[]; 
  period: any;
  row: any;
  contractId: MasterDataId;
  onContextMenu: any;
  onClickAction: any;
  onTooltipAction: (e: any, price: Price, visible: boolean) => void;
  expiriesExpanded: any;
  localizeOptions: any;
  colors: any;
  presetDepths: any;
  hasData: boolean;
  compactColumnsEnabled: boolean;
  contract: Contract;
  dockSize: {width: number; height: number, top: number; left: number};
}

interface ExpiryRowState {

}

class ExpiryRowComponent extends React.Component<
  ExpiryRowProps, ExpiryRowState
> {
  ref: any;
  constructor(props: ExpiryRowProps) {
    super(props);

    this.ref = React.createRef();
  }
  render() {
    const {
      instrumentId,
      market,
      askPrices,
      bidPrices,
      trades,
      period,
      row,
      contractId,
      onContextMenu,
      onClickAction,
      expiriesExpanded,
      localizeOptions,
      colors,
      presetDepths,
      compactColumnsEnabled,
      contract
    } = this.props;
       const anonymizeMarketDataEnabled: boolean = isAnonymizeMarketDataEnabled(
      store.getState()
    );
    const isExpanded = expiriesExpanded && row.depth > 1;

    let defaultCell: Cell = {
      className:  `entry`,
      onContextMenu: onContextMenu,
      onClick: (e: any) => {},
      style: { marginBottom: 0 },
      value: '',
      localizeOptions: {
        // this is used as the default. Within formatCell these options may be exchanged for a type specific ones.
        style: 'decimal'
      },
      symbol: ''
    };

    const contextData = {
      contract: contract,
      source: undefined,
      localizeOptions: localizeOptions,
      anonymizeMarketDataEnabled: anonymizeMarketDataEnabled,
      entries: [],
      index: 0
    };

    const columnMap = getColumns(market, compactColumnsEnabled);

    const columnEntries = Object.keys(columnMap).map((colTitle: any) => {
      const col = columnMap[colTitle][0];
      let entry: any = null;
      let additionalEntries = [];
      let columnClass: string = '';
      let columnStyle = { };
      let columnLayers: ((cellValuePresent: boolean) => JSX.Element[]) | null = null;
      
      let isQuoteRequest = false;
      const orders: any = {askPrices: askPrices ? askPrices : [], bidPrices: bidPrices ? bidPrices : []};
      const combinedColumns = col.combine ? columnMap[col.group] : undefined;
      if (col.group === 'askPrices' || col.group === 'bidPrices') {
        const expiryRowCode = orders[col.group];
        if (expiryRowCode && expiryRowCode.length) {
          const price = expiryRowCode[0];
          const readOnly = price.readonly;
          const isPreview = price.order?.orderId === 0;
          const isImplied = price.implied;
          const isRouted = price.order?.routed;

          isQuoteRequest = !!price.quoteRequest;
          entry = expiryRowCode[0];
          additionalEntries = expiryRowCode;
          columnClass = createColumnClass(
            col,
            anonymizeMarketDataEnabled,
          );
          columnLayers = createColumnLayers(
            col,
            anonymizeMarketDataEnabled,
            isQuoteRequest,
            false, // ownOrder,
            readOnly,
            false, // suspended,
            undefined,
            isPreview,
            isImplied,
            isRouted,
            contract ? contract.expiry.type : undefined,
            price.counterparty?.toLowerCase()
          );
          columnStyle = createColumnStyle(
            col,
            anonymizeMarketDataEnabled,
            colors,
            isQuoteRequest,
            false, // ownOrder,
            readOnly,
            undefined,
            isPreview,
            isImplied,
            isRouted,
            price.counterparty?.toLowerCase()
          );
        } else {
          columnClass = createColumnClass(col, false, contract?.expiry.type);
          columnLayers = createColumnLayers(
            col,
            anonymizeMarketDataEnabled,
            false,
            false,
            false,
            false,
            undefined,
            false,
            false,
            false,
            contract ? contract.expiry.type : undefined,
            undefined
          );
        }
      }
      else if (col.group === 'trades') {
        const expiryRowCode = trades ? trades : [];
        const hasValue = expiryRowCode && expiryRowCode.length;
        if (expiryRowCode && expiryRowCode.length) {
          const latestLastPrice = expiryRowCode[0]
          // 0 = price, 1 = timestamp, 2 = cpy
          entry = {execPrice: latestLastPrice[0], timestamp: latestLastPrice[1], date: latestLastPrice[1],  counterparty: latestLastPrice[2]};
          additionalEntries = expiryRowCode.map((v: any[]) => { return {execPrice: v[0], timestamp: v[1], date: v[1], counterparty: v[2]}; });
        }

        columnClass = createColumnClass(
          col,
          hasValue,
          contract?.expiry.type
        );

        columnLayers = createColumnLayers(
          col,
          anonymizeMarketDataEnabled,
          false,
          false,
          false,
          false,
          undefined,
          false,
          false,
          false,
          contract ? contract.expiry.type : undefined,
          undefined);
      }
      const cells = [];
      const columns = combinedColumns ? combinedColumns : [col];
      columns.forEach(column => {
        const cell = formatCell(
          {...defaultCell, 
            value: entry ? entry[column.name] : null}, 
          { ...contextData, 
            column,
            entries: additionalEntries,
            compactColumnsEnabled,
            columns: columns.map(col => col.name)
          }
        );
        cell.localizeOptions = Object.assign(cell.localizeOptions, localizeOptions);
        cells.push(cell);
      });
      

      const contextMenuFn = (e: any, depthIndex: number) => {
        e.preventDefault();
        return onContextMenu(
          e,
          {
            column: col,
            row: row,
            contractId: contractId,
            periodType: period,
            index: depthIndex,
            contract: contract
          },
          !!entry
        );
      };

      const clickActionFn = (e: any, depthIndex: number, empty?: boolean) => {
        e.preventDefault();
        return onClickAction(
          e,
          {
            column: col,
            row: row,
            contractId: contractId,
            periodType: period,
            index: depthIndex,
            contract: contract
          },
          !!entry && !empty
        );
      };

      const key = generateKey(
        instrumentId,
        period,
        row.code,
        col.group,
        col.name
      );

      const presetDepth = presetDepths[row.code];
      let depth = expiriesExpanded ? row.depth : 0;
      let presentedEntries = [...additionalEntries];
      if (presetDepth) {
        if (depth > presetDepth) {
          depth = presetDepth;
          presentedEntries.splice(depth);
        }
      }

      if (isExpanded) {
        return (
            <td
              key={key}
              className={`parentTd expanded ${
                !!contractId ? '' : 'not-interactive'
              }${compactColumnsEnabled ? ' compact' : ''}`}
              style={columnStyle}
              onContextMenu={
                !entry || isQuoteRequest ? e => (!isExpanded ? contextMenuFn(e, 0) : null) : e => null
              }
            >
              <div className="expanded-row-wrapper">
                <AdditionalExpiryRows
                  key={`additional-${createMasterDataIdString(contractId)}-${col.name}`}
                  entries={additionalEntries}
                  depth={depth}
                  column={col}
                  market={market}
                  onContextMenu={(e: any, i: number) => contextMenuFn(e, i)}
                  onClickAction={(e: any, i: number, empty?: boolean) => clickActionFn(e, i, empty)}
                  onTooltipAction={this.props.onTooltipAction}
                  contractId={contractId}
                  localizeOptions={localizeOptions}
                  anonymizeMarketDataEnabled={anonymizeMarketDataEnabled}
                  colors={colors}
                  compactColumnsEnabled={this.props.compactColumnsEnabled}
                />
              </div>
            </td>
        );
      } else {
        const impliedTooltip = (entry?.implied)
            ? (e: any, visible: boolean) => this.props.onTooltipAction(e, entry, visible)
            : null;


        return (
          <td
            key={key}
            className={`parentTd ${columnClass} ${
              !!contractId ? '' : 'not-interactive'
            }${compactColumnsEnabled && col.combine ? ' compact' : ''}`}
            style={columnStyle}
            onContextMenu={
              !entry || isQuoteRequest ? e => contextMenuFn(e, 0) : e => null
            }
          >
            <ColumnEntry
              onContextMenu={
                !!entry ? (e: any) => contextMenuFn(e, 0) : (e: any) => null
              }
              onClick={
                (e: any) => clickActionFn(e, 0)}
              onTooltipAction={impliedTooltip}
              value={entry ? entry[col.name] : null}
              layers={columnLayers}
              combinedColumns={combinedColumns}
              cells={cells}
            /> 
          </td>
        );
      }
  });

    return (
      <tr
        ref={this.ref}
        className={`
          orderbook-expiry-row${!contract || contract.state === ContractState.INACTIVE ? ' inactive' : ''}
          ${isExpanded ? ' expanded-row' : ''}
        `}
      >
        {columnEntries}
      </tr>
    );
  }

  shouldComponentUpdate(nextProps: ExpiryRowProps, nextState: ExpiryRowState) {
    return (
      this.props?.columns?.length !== nextProps?.columns?.length 
      || !areEqualColors(this.props, nextProps) 
      || nextProps.compactColumnsEnabled !== this.props.compactColumnsEnabled
      || this.isVisible(nextProps)
      ) 
      
      && !areEqual(this.props, nextProps);
  }
  
  isVisible(props: ExpiryRowProps) {
    return props.dockSize === undefined || this.ref.current?.offsetTop > props.dockSize.top && this.ref.current?.offsetTop < props.dockSize.top + props.dockSize.height;
  }
}

const isFirstOrderUnchanged = (older: any, newer: any) => {
  if (!!older !== !!newer) {
    return false;
  }
  return (!older && !newer) || (!older.length && !newer.length) || (older.length === newer.length && older[0].price === newer[0].price && older[0].quantity === newer[0].quantity);
};

const isFirstTradeUnchanged = (older: any, newer: any) => {
  if (!!older !== !!newer) {
    return false;
  }
  return  (!older && !newer) || (!older.length && !newer.length) || (older.length === newer.length && older[0] === newer[0]);
};

const isBidGroupVisible = (columns: any[]) => {
  const res = [];
  for (let colIndex = 0; colIndex < columns.length; colIndex++) {
    if (columns[colIndex].group === 'bidPrices') {
      res.push(columns[colIndex]);
    }
  }
  return res;
};

const isAskGroupVisible = (columns: any[]) => {
  const res = [];
  for (let colIndex = 0; colIndex < columns.length; colIndex++) {
    if (columns[colIndex].group === 'askPrices') {
      res.push(columns[colIndex]);
    }
  }
  return res;
};

const isTradeGroupVisible = (columns: any[]) => {
  const res = [];
  for (let colIndex = 0; colIndex < columns.length; colIndex++) {
    if (columns[colIndex].group === 'trades') {
      res.push(columns[colIndex]);
    }
  }
  return res;
};

const areEqualColors = (prevProps, nextProps) => {
  if (!prevProps?.colors || !nextProps?.colors) {
    return false;
  } else {
    return prevProps.colors.colorsVersion === nextProps.colors.colorsVersion
  }
}

function areEqual(prevProps: any, nextProps: any) {
  // do not update when collapsed expandable row data did not change
  if ((!nextProps.expiriesExpanded && !prevProps.expiriesExpanded)  // it's not update because of collapsing/expanding
    && nextProps.columns.length === prevProps.columns.length // columns did not change
    && nextProps.compactColumnsEnabled === prevProps.compactColumnsEnabled 
    && nextProps.dockSize?.width === prevProps.dockSize?.width && nextProps.dockSize?.top === prevProps.dockSize?.top 
    && nextProps.dockSize?.left === prevProps.dockSize?.left && nextProps.dockSize?.height === prevProps.dockSize?.height
  ) {
    if (
      (!isAskGroupVisible(nextProps.columns) 
        || isFirstOrderUnchanged(
          prevProps.askPrices,  
          nextProps.askPrices)
      )
      && (!isBidGroupVisible(nextProps.columns) 
        ||  isFirstOrderUnchanged(
          prevProps.bidPrices,  
          nextProps.bidPrices)
        )
      && (!isTradeGroupVisible(nextProps.columns) 
        ||  isFirstTradeUnchanged(
          prevProps.trades, 
          nextProps.trades)
        )
    ) {
      return true;
    }
  }
  return false;
}

export const ExpiryRow = ExpiryRowComponent;
