import { ITable } from '../../shared/ui/models/table'
import ITrade from '../models/trade';
import { GenericRequest } from '../../main/models/application';

export enum ActionTypes {
  TRADE_SEND = '[Trade] Send',
  TRADE_SEND_SUCCESS = '[Trade] Send Success',
  TRADE_FETCH = '[Trade] Fetch',
  TRADE_FETCH_SUCCESS = '[Trade] Fetch Success',
  TRADE_FETCH_FAILURE = '[Trade] Fetch Failure',
  TRADE_FETCH_COMPLETE = '[Trade] Fetch Complete',
  TRADE_REMOVE_ALL = '[Trade] Remove all',
  OWN_TRADE_FETCH_SUCCESS = '[Own Trade] Fetch Success',
  TRADE_INQUIRE_NOTIFY = '[Trade] Inquiry notification',
  LAST_PRICES_SUCCESS = '[Trade] Last prices',
  SETTLEMENT_PRICES_SUCCESS = '[Trade] Settlement prices',
  TRADE_LIMIT = '[Trade] Limit trades',
  TRADE_REMOVE_TABLE = '[Trade] Remove table'
}

export interface TradeSendAction {
  type: ActionTypes.TRADE_SEND;
  payload: any;
}

export interface TradeSendSuccessAction {
  type: ActionTypes.TRADE_SEND_SUCCESS;
  payload: any;
}

export interface TradeFetchAction {
  type: ActionTypes.TRADE_FETCH;
}

export interface TradeFetchSuccessAction {
  type: ActionTypes.TRADE_FETCH_SUCCESS;
  payload: {[id: string]: ITrade};
  tables?: ITable[];
  replace?: boolean;
  from?: number;
}

export interface OwnTradeFetchSuccessAction {
  type: ActionTypes.OWN_TRADE_FETCH_SUCCESS;
  payload: {[id: string]: ITrade};
  replace?: boolean;
  tables?: ITable[];
  from?: number;
}

export interface TradeFetchFailureAction {
  type: ActionTypes.TRADE_FETCH_FAILURE;
  error: any;
}

export interface TradeFetchCompleteAction {
  type: ActionTypes.TRADE_FETCH_COMPLETE;
}

export interface TradeRemoveAllAction {
  type: ActionTypes.TRADE_REMOVE_ALL;
}

export interface TradeInquireNotifyAction {
  type: ActionTypes.TRADE_INQUIRE_NOTIFY;
  payload: string;
}

export interface LastPricesFetchSuccessAction {
  type: ActionTypes.LAST_PRICES_SUCCESS;
  payload: {[contract: string]: number[]};
}

export interface SettlementPricesFetchSuccessAction {
  type: ActionTypes.SETTLEMENT_PRICES_SUCCESS;
  payload: {[contract: string]: any[]};
}

export interface LimitTradesAction {
  type: ActionTypes.TRADE_LIMIT;
  limit: number;
  days: number;
  own: boolean;
}

export interface TradeRemoveAction {
  type: ActionTypes.TRADE_REMOVE_TABLE;
  tableId: string;
}

export function tradeSend(message: GenericRequest): TradeSendAction {
  return {
    type: ActionTypes.TRADE_SEND,
    payload: message
  };
}

export function tradeSendSuccess(payload: ITrade[]): TradeSendSuccessAction {
  return {
    type: ActionTypes.TRADE_SEND_SUCCESS,
    payload: payload
  };
}

export function tradeFetch(): TradeFetchAction {
  return {
    type: ActionTypes.TRADE_FETCH
  };
}

export function tradeFetchSuccess(message: {[id: string]: ITrade}, tables: ITable[], replace?: boolean, from?: number): TradeFetchSuccessAction {
  return {
    type: ActionTypes.TRADE_FETCH_SUCCESS,
    payload: message,
    replace: replace,
    tables,
    from
  };
}

export function tradeInquireNotify(tableId: string): TradeInquireNotifyAction {
  return {
    type: ActionTypes.TRADE_INQUIRE_NOTIFY,
    payload: tableId
  };
}

export function ownTradeFetchSuccess(message: {[id: string]: ITrade}, tables: ITable[], replace?: boolean): OwnTradeFetchSuccessAction {
  return {
    type: ActionTypes.OWN_TRADE_FETCH_SUCCESS,
    payload: message,
    replace: replace,
    tables
  };
}

export function tradeFetchComplete(): TradeFetchCompleteAction {
  return {
    type: ActionTypes.TRADE_FETCH_COMPLETE
  };
}

export function tradeFetchFailure(error: any): TradeFetchFailureAction {
  return {
    type: ActionTypes.TRADE_FETCH_FAILURE,
    error: error
  };
}

export function removeTrades(): TradeRemoveAllAction {
  return {
    type: ActionTypes.TRADE_REMOVE_ALL
  };
}

export function lastPricesFetchSuccess(lastPrices: {[contract: string]: number[]}): LastPricesFetchSuccessAction {
  return {
    type: ActionTypes.LAST_PRICES_SUCCESS,
    payload: lastPrices
  };
}

export function settlementPricesFetchSuccess(settlementPrices: {[contract: string]: any[]}): SettlementPricesFetchSuccessAction {
  return {
    type: ActionTypes.SETTLEMENT_PRICES_SUCCESS,
    payload: settlementPrices
  };
}

export function setAllTradeLimit(limit: number, days: number): LimitTradesAction {
  return {
    type: ActionTypes.TRADE_LIMIT,
    limit: limit,
    days: days,
    own: false
  };
}

export function setOwnTradeLimit(limit: number, days: number): LimitTradesAction {
  return {
    type: ActionTypes.TRADE_LIMIT,
    limit: limit,
    days: days,
    own: true
  };
}

export function removeTradesTable(tableId: string): TradeRemoveAction {
  return {
    type: ActionTypes.TRADE_REMOVE_TABLE,
    tableId
  };
}

export type Action =
  | TradeSendAction
  | TradeSendSuccessAction
  | TradeFetchAction
  | TradeFetchSuccessAction
  | TradeFetchCompleteAction
  | TradeFetchFailureAction
  | TradeRemoveAllAction
  | OwnTradeFetchSuccessAction
  | TradeInquireNotifyAction
  | LastPricesFetchSuccessAction
  | SettlementPricesFetchSuccessAction
  | LimitTradesAction
  | TradeRemoveAction;
