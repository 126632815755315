import * as React from "react";
import { MeetTableHead } from "./MeetTableHead";
import {
  ITableColumn,
  ITableRow,
  ITableSort,
  ITable,
  ITableSearchTag,
} from "../../ui/models/table";
import { MeetTableBody } from "./MeetTableBody";
import { getLocalizationSettings } from "../../utils/formatters";
import { connect } from "react-redux";
import { State } from "../../../main/reducers/rootReducer";
import { paginatedRequest } from "../../ui/actions/table";
import { MemoTranslate } from "../../i18n/components/memoTranslate";
import { config } from "../../../main/config";

interface MeetTableProps {
  cols: ITableColumn[];
  rows: ITableRow[];
  allRows: ITableRow[];
  pinnedRows: number[];
  pinningAllowed: boolean;
  sorting: ITableSort[];
  disableSorting: boolean;
  hiddenColumnNames: string[];
  dataFormatters: { [key: string]: (value: any, contextData: any) => any };
  selectedRows: number[];
  table: ITable;
  popoverComponent: JSX.Element;
  onSelectionChange: (rowIndex: number, e: any, row: ITableRow) => void;
  onClickCell: (id: string, key: string, row: ITableRow, event: any) => void;
  onContextCell: (id: string, key: string, row: ITableRow, event: any) => void;
  onSortingChange: (sorting: ITableSort[]) => void;
  paginatedDataRequest: (
    page: number,
    sorting: ITableSort[],
    type: string,
    id: string,
    tags: ITableSearchTag[]
  ) => void;
}

interface MeetTableState {
  cols: ITableColumn[];
  unpinnedRows: ITableRow[];
  pinnedRows: ITableRow[];
  allRows: ITableRow[];
  rows: ITableRow[];
  page: number;
}

export class MeetTable extends React.Component<MeetTableProps, MeetTableState> {
  localizeOptions: any;
  constructor(props: MeetTableProps) {
    super(props);
    this.createTableBodyForRows = this.createTableBodyForRows.bind(this);
    this.localizeOptions = getLocalizationSettings();
    this.state = {
      cols: [],
      pinnedRows: [],
      unpinnedRows: [],
      allRows: [],
      page: 1,
      rows: [],
    };
  }

  componentWillMount() {
    this._prepareState(this.props, this.state);
  }

  componentWillReceiveProps(newProps: MeetTableProps, state: MeetTableState) {
    this._prepareState(newProps, state);
  }

  _prepareState(props: MeetTableProps, state: MeetTableState) {
    const { cols, rows, allRows, hiddenColumnNames, pinnedRows } = props;
    const unpinnedRows = [];
    if (pinnedRows) {
      for (let rowIdx = 0; rowIdx < rows.length; rowIdx++) {
        if (pinnedRows.indexOf(rows[rowIdx].$index) === -1) {
          unpinnedRows.push(rows[rowIdx]);
        }
      }
    }

    const pinnedRowElements = [];
    if (rows) {
      for (let rowIdx = 0; rowIdx < rows.length; rowIdx++) {
        if (pinnedRows.indexOf(rows[rowIdx].$index) !== -1) {
          pinnedRowElements.push(rows[rowIdx]);
        }
      }
    }

    this.setState({
      ...state,
      cols: cols.filter((col) => hiddenColumnNames.indexOf(col.name) === -1),
      unpinnedRows: this.prepareRows(unpinnedRows, cols),
      pinnedRows: this.prepareRows(pinnedRowElements, cols),
      allRows: this.prepareRows(allRows, cols),
      rows: rows,
    });
  }

  prepareRows(rows: ITableRow[], cols: ITableColumn[]) {
    const accumulator = [];
    if (rows) {
      for (let rowIdx = 0; rowIdx < rows.length; rowIdx++) {
        const row = rows[rowIdx];
        const rowObject = {
          id: row.id,
          name: "",
          value: "",
          formattedValue: "",
          fullData: row
        }; 
        for (let colIdx = 0; colIdx < cols.length; colIdx++) {
          const colName = cols[colIdx].name;
          rowObject[colName] = row[colName];
        }
        accumulator.push(rowObject);
      }
    }
    return accumulator;
  }

  onSortingChange = (sorting: ITableSort[]) => {
    this.props.onSortingChange(sorting);
    this.setState((prevState) => {
      return {
        ...prevState,
        rows: [],
        page: 1,
      };
    });
    if (this.props.table.externallyPaginated) {
      this.props.paginatedDataRequest(
        0,
        sorting,
        this.props.table.type,
        this.props.table.id,
        this.props.table.tags
      );
    }
  };

  createTableBodyForRows(
    rows: ITableRow[],
    firstTableBody: boolean,
    isPinningTable: boolean
  ) {
    const { cols, page } = this.state;
    const { dataFormatters, selectedRows, table } = this.props;
    if (rows.length === 0 && !isPinningTable) {
      return (
        <tbody className={`scrollable`}>
          <tr>
            <td colSpan={cols.length} className="text-center">
              <MemoTranslate value="table.dataNotAvailable" />
            </td>
          </tr>
        </tbody>
      );
    }
    return (
      <MeetTableBody
        rows={rows}
        cols={cols}
        columnsCount={cols.length - 1} // exclude $index col
        dataFormatters={dataFormatters}
        selectedRows={selectedRows}
        onSelectRow={this.props.onSelectionChange}
        onClickCell={this.props.onClickCell}
        onContextCell={this.props.onContextCell}
        type={table.type}
        popoverComponent={this.props.popoverComponent}
        localizeOptions={this.localizeOptions}
        paginatedScroll={this.paginatedScroll}
        page={page}
        firstTableBody={firstTableBody}
        isPinningTable={isPinningTable}
        allCols={table.columns}
      />
    );
  }

  paginatedScroll = (from: number) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        page: Math.ceil(from / config.tablePageSize),
      };
    });
    if (this.props.table.externallyPaginated) {
      this.props.paginatedDataRequest(
        from,
        this.props.sorting,
        this.props.table.type,
        this.props.table.id,
        this.props.table.tags
      );
    }
  };

  render() {
    const { cols, rows, pinnedRows, unpinnedRows, page, allRows } = this.state;
    const { sorting, dataFormatters, selectedRows, table, pinningAllowed } =
      this.props;

    const colgroup = (
      <colgroup>
        {cols
          .filter((c) => c.name !== "$index")
          .map((col) => (
            <col key={col.name} className={col.name} />
          ))}
      </colgroup>
    );
    return (
      <div className="d-flex flex-column meet-table">
        <div className="table-responsive">
          <table className={`table ${table.type}`}>
            {colgroup}
            <MeetTableHead
              cols={cols}
              sorting={sorting}
              onSelectCol={this.onSortingChange}
              disableSorting={this.props.disableSorting}
            />
            <tbody className="emptyBody">
              {/* <tr>
                <td
                  className="empty-row"
                  style={{ height: '42px' }}
                />
              </tr> */}
            </tbody>
            {pinningAllowed && pinnedRows.length > 0
              ? this.createTableBodyForRows(pinnedRows, true, true)
              : null}
            {pinningAllowed
              ? this.createTableBodyForRows(unpinnedRows, false, false)
              : this.createTableBodyForRows(rows, true, false)}
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: State) => ({});

const mapDispatchToProps = {
  paginatedDataRequest: paginatedRequest,
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(MeetTable);
