import LoginFormData from '../models/login';
import { LogLevel } from '../../shared/logger/models/logger';
import { LogAction } from '../../main/middlewares/logger';
import { LoginRequest } from '../../main/services/stompService';
import { I18n } from 'react-redux-i18n';
import { encryptPassword } from '../../shared/utils/helper/crypto';

export enum ActionTypes {
  AUTHENTICATION_REQUEST    = '[Authentication] AUTHENTICATION_REQUEST',
  AUTHENTICATION_SUCCESS    = '[Authentication] AUTHENTICATION_SUCCESS',
  AUTHENTICATION_FAILURE    = '[Authentication] AUTHENTICATION_FAILURE',
  AUTHENTICATION_LOGOUT     = '[Authentication] AUTHENTICATION_LOGOUT',
  IM_AUTHENTICATION_REQUEST = '[Authentication] INTERNAL MARKET AUTHENTICATION_REQUEST',
  AUTHENTICATION_VERIFY     = '[Authentication] AUTHENTICATION_VERIFY',
  RELOGIN_SUCCESS           = '[Authenticaiton] RELOGIN_SUCCESS',
  LOGIN_PENDING             = '[Authenticaiton] LOGIN_PENDING',
  PENDING_LOGIN_TIMEOUT     = '[Authentication] PENDING_LOGIN_TIMEOUT',
  REFRESH_TOKEN             = '[Authentication] REFRESH_TOKEN',
  LOGIN_DELAY               = '[Authentication] LOGIN_DELAY'
}

export interface AuthenticationRequestAction extends LogAction {
  type: ActionTypes.AUTHENTICATION_REQUEST;
  payload: LoginFormData;
}
export interface AuthenticationSuccessAction extends LogAction {
  type: ActionTypes.AUTHENTICATION_SUCCESS;
  payload: any;
}

export interface AuthenticationFailureAction extends LogAction {
  type: ActionTypes.AUTHENTICATION_FAILURE;
  error: any;
}

export interface LoginDelayAction extends LogAction {
  type: ActionTypes.LOGIN_DELAY;
  loginDelayTime: number;
}

export interface AuthenticationLogoutAction extends LogAction {
  type: ActionTypes.AUTHENTICATION_LOGOUT;
  error: any;
}

export interface IMAuthenticationRequestAction {
  type: ActionTypes.IM_AUTHENTICATION_REQUEST;
  payload: LoginRequest;
}

export interface AuthenticationVerifyAction {
  type: ActionTypes.AUTHENTICATION_VERIFY;
  payload: LoginFormData;
}

export interface ReloginSuccessAction extends LogAction {
  type: ActionTypes.RELOGIN_SUCCESS;
  payload: any;
}

export interface CurrentlyPendingLoginAction extends LogAction {
  type: ActionTypes.LOGIN_PENDING;
}

export interface PendingLoginTimeoutAction extends LogAction {
  type: ActionTypes.PENDING_LOGIN_TIMEOUT;
}

export interface RefreshTokenAction {
  type: ActionTypes.REFRESH_TOKEN;
  payload: any;
}

export function loginRequest(form: LoginFormData): AuthenticationRequestAction {
  return {
    type: ActionTypes.AUTHENTICATION_REQUEST,
    payload: encryptPassword({
      username: form.username,
      password: form.password,
      profile: form.profile,
      token: form.token
    }),
    logMessage: I18n.t('login.log.User is trying to log in', {user: form.username}),
    logLevel: LogLevel.INFO
  };
}

export function loginVerify(form: LoginFormData): AuthenticationVerifyAction {
  return {
    type: ActionTypes.AUTHENTICATION_VERIFY,
    payload: {
        username: form.username,
        password: form.password,
        profile: form.profile,
        token: form.token
    }
  };
}

export function imLoginRequest(loginRequestObject: LoginRequest): IMAuthenticationRequestAction {
  return {
    type: ActionTypes.IM_AUTHENTICATION_REQUEST,
    payload: loginRequestObject
  };
}

export function loginSuccess(user: any): AuthenticationSuccessAction {
  return {
    type: ActionTypes.AUTHENTICATION_SUCCESS,
    payload: user,
    logLevel: LogLevel.INFO,
    logMessage: I18n.t('login.log.success')
  };
}

export function loginFailure(error: any): AuthenticationFailureAction {
  return {
    type: ActionTypes.AUTHENTICATION_FAILURE,
    error: error,
    logLevel: LogLevel.ERROR,
    logMessage: I18n.t('login.log.failure')
  };
}

export function delayLogin(loginDelayTime: number): LoginDelayAction {
  return {
    type: ActionTypes.LOGIN_DELAY,
    loginDelayTime: loginDelayTime,
    logLevel: LogLevel.WARN,
    logMessage: I18n.t('login.log.delay', {delay: loginDelayTime})
  };
}

export function logout(error: any): AuthenticationLogoutAction {
  return {
    type: ActionTypes.AUTHENTICATION_LOGOUT,
    error: error,
    logLevel: LogLevel.INFO,
    logMessage: I18n.t('login.log.logout')
  };
}

export function reloginSuccess(user: string): ReloginSuccessAction {
  return {
    type: ActionTypes.RELOGIN_SUCCESS,
    payload: user,
    logLevel: LogLevel.INFO,
    logMessage: I18n.t('login.log.reloginSuccess')
  };
}

export function currentlyPendingLogin(message: string): CurrentlyPendingLoginAction {
  return {
    type: ActionTypes.LOGIN_PENDING,
    logLevel: LogLevel.INFO,
    logMessage: message
  };
}

export function pendingLoginTimeout(message: string): PendingLoginTimeoutAction {
  return {
    type: ActionTypes.PENDING_LOGIN_TIMEOUT,
    logLevel: LogLevel.WARN,
    logMessage: message
  };
}

export function refreshToken(token: any): RefreshTokenAction {
  return {
    type: ActionTypes.REFRESH_TOKEN,
    payload: token
  };
}

export type Action = AuthenticationRequestAction |
                     AuthenticationSuccessAction |
                     AuthenticationFailureAction |
                     AuthenticationLogoutAction |
                     IMAuthenticationRequestAction |
                     AuthenticationVerifyAction |
                     ReloginSuccessAction |
                     RefreshTokenAction;