import { LogAction } from '../../../main/middlewares/logger';
import { LogLevel } from '../../logger/models/logger';
import { I18n } from 'react-redux-i18n';

export enum ActionTypes {
  LOAD_SETTINGS = '[Design settings] Load UI settings',
  SAVE_CLIENT_SETTINGS = '[Design settings] Save UI settings',
  SAVE_COLOR_SETTINGS = '[Design settings] Save color settings',
  LOAD_VERSION = '[Version] Version loaded'
}

export interface SettingsLoadAction {
  type: ActionTypes.LOAD_SETTINGS;
  payload: any;
}

export interface ClientSettingsSaveAction extends LogAction {
  type: ActionTypes.SAVE_CLIENT_SETTINGS;
  payload: any;
}

export interface ColorSettingsSaveAction extends LogAction {
  type: ActionTypes.SAVE_COLOR_SETTINGS;
  payload: any;
}

export interface VersionInfoLoadAction{
  type: ActionTypes.LOAD_VERSION;
  version: string;
}

export function load(settings: any): SettingsLoadAction {
  return {
    type: ActionTypes.LOAD_SETTINGS,
    payload: settings
  };
}

export function versionInfoLoad(version: any): VersionInfoLoadAction {
  return {
    type: ActionTypes.LOAD_VERSION,
    version: version
  };
}

export function saveColor(settings: any): ColorSettingsSaveAction {
  return {
    type: ActionTypes.SAVE_COLOR_SETTINGS,
    payload: settings,
    logMessage: I18n.t('settings.log.savedColor'),
    logLevel: LogLevel.DEBUG
  };
}

export function saveClient(settings: any): ClientSettingsSaveAction {
  return {
    type: ActionTypes.SAVE_CLIENT_SETTINGS,
    payload: settings,
    logMessage: I18n.t('settings.log.savedUI'),
    logLevel: LogLevel.DEBUG
  };
}

export type Action =
  | SettingsLoadAction
  | ClientSettingsSaveAction
  | ColorSettingsSaveAction
  | VersionInfoLoadAction;
