import { connect } from 'react-redux';
import { State } from '../reducers/rootReducer';
import MainContainer from '../components/MainContainer';
import { getAuthorizedStatus } from '../../authentication/selectors/authetication';
import { getLocale } from '../../shared/i18n/selectors/translations';
import {getConnectedStatus, getTakeoverConfirmDisplayed} from '../../authentication/selectors/connection';
import { sendLogToServer } from '../../shared/logger/actions/logger';
import { sendToServer, load as viewLoad } from '../../dashboard/actions/profile';
import { applicationComplete } from '../../authentication/actions/connection';
import { getActiveViewId } from '../../dashboard/selectors/profile';
import { logout } from 'js/authentication/actions/authentication';
const mapStateToProps = (state: State) => ({
    isAuthorized: getAuthorizedStatus(state),
    isConnected: getConnectedStatus(state),
    locale: getLocale(state),
    showTakeoverConfirm: getTakeoverConfirmDisplayed(state),
    activeViewId: getActiveViewId(state)
});

const mapDispatchToProps = {
    sendLogToServer: sendLogToServer,
    viewLoad: viewLoad,
    applicationComplete: applicationComplete,
    sendProfileToServer: sendToServer,
    logout: logout
};

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);