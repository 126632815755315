import * as React from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import { error } from '../../shared/logger/actions/logger';
import { I18n, Translate } from 'react-redux-i18n';

export interface FileUploadProps {
  onUpload: (binaryString: string) => void;
}

export const FileUpload: React.FC<FileUploadProps> = ({ onUpload }) => {
  const onDrop = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    if (rejectedFiles.length > 0) {
      return error(I18n.t('bulk.invalidFile'));
    }
    if (acceptedFiles.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        const arrayBuffer = reader.result;
        const uint8Array = new Uint8Array(arrayBuffer as ArrayBuffer);
        let binaryString = '';
        uint8Array.forEach((byte) => {
          binaryString += String.fromCharCode(byte);
        });
        return onUpload(binaryString);
      };
      reader.onabort = () => {
        return error(I18n.t('bulk.invalidFile'));
      };
      reader.onerror = () => {
        return error(I18n.t('bulk.invalidFile'));
      };

      reader.readAsArrayBuffer(acceptedFiles[0]);
    }
    return null;
  };

  return (
    <Dropzone onDrop={onDrop}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div
          {...getRootProps()}
          className={'dropzone dropzone--isActive:' + { isDragActive }}
        >
          <input {...getInputProps()} />
          <Translate value={isDragActive ? "bulk.dropFile" : "bulk.dropOrLoadFile"} />
        </div>
      )}
    </Dropzone>
  );
};