import React, { FC, useState } from 'react';
import FavoriteNameForm from '../../../favorite/components/favoriteNameForm';
import { I18n } from 'react-redux-i18n';
import { IFavorite } from '../../../favorite/models/favorite';
import useAutoClose from '../../../autoclose/components/useAutoClose';

interface FavoriteIconProps {
  favoriteName: string;
  isFavorite: boolean;
  onSubmit: (name: string) => void;
  favorites: IFavorite[];
}

const FavoriteIcon: FC<FavoriteIconProps> = ({ favoriteName, isFavorite, onSubmit, favorites }) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const { ref, expanded, triggerVisibility } = useAutoClose<HTMLDivElement>();

  const handleClose = () => {
    triggerVisibility(false);
    document.body.click();
  };

  const popoverComponent = (
    <div style={{display: expanded ? 'block' : 'none'}} className="favorite__form">
      <h3>
        {!!favoriteName
          ? I18n.t('favorite.form.removeTitle')
          : I18n.t('favorite.form.title')
        }
      </h3>
      <FavoriteNameForm
        onSubmit={(name: string) => {
          onSubmit(name);
          handleClose();
        }}
        isFavorite={isFavorite}
        favorites={favorites}
        onCancel={handleClose}
      />
    </div>
  );

  return (
    <div ref={ref}>
      <li
        onMouseEnter={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
      >
        <i
          data-test="oi-star-icon"
          className={`oi oi-star ${isFavorite || isMouseOver ? 'active' : ''}`}
          title={I18n.t(isFavorite ? 'modal.favoriteRemove' : 'modal.favoriteAdd')}
        />
      </li>
      {popoverComponent}
    </div>
  );
};

export default React.memo(FavoriteIcon);