import React from "react";
import { useState} from "react";

/**
 * Adds tooltip functionality to a component
 * - display the tooltip on mouseover after a timeout (default 1s)
 * - if mouse *moves* out of the component, hide the tooltip
 *
 * mouseout can be trigger even if the tooltip is shown in over the component, and it steals a mouse cursor.
 * Therefore, it is checked if mouse was moving before hiding the tooltip
 *
 * @param Component
 */
const tooltipTimeoutMs: number = 1000;

export const withTooltip = (Component: any) => {
    return (props: any) => {
        const {onTooltipAction} = props;
        /** moving: indicates if mouse is moving over the component */
        const [moving, setMoving] = useState(false);
        const [tooltipTimeout, setTooltipTimeout] = useState(null);

        const _onMouseOver = (e) => {
            if (onTooltipAction) {
                setTooltipTimeout(setTimeout(() => {
                    // check again - it can change during timeout
                    if (onTooltipAction) {
                        onTooltipAction(e, true);
                        setMoving(false);
                    }
                }, tooltipTimeoutMs));
            }
        }

        const _onMouseOut = (e) => {
            if (tooltipTimeout) {
                clearTimeout(tooltipTimeout);
            }
            if (moving && onTooltipAction) {
                onTooltipAction(e, false);
            }
        }

        const _onMouseMove = (e) => {
            if (!moving && onTooltipAction) {
                setMoving(true);
            }
        }

        const cancelTooltip = (e) => {
            if (tooltipTimeout) {
                clearTimeout(tooltipTimeout);
            }
            if (onTooltipAction) {
                onTooltipAction(e, false);
                if (moving) {
                    setMoving(false);
                }
            }
        }

        return <Component onMouseOut={_onMouseOut} onMouseMove={_onMouseMove} onMouseOver={_onMouseOver} cancelTooltip={cancelTooltip} {...props} />;
    };
};
