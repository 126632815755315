import * as React from 'react';



const VenueDropdownItem = ({venue, selectedVenues,customId,onChangeVenue}) => {
  return <li
          className="p-2"
          key={`checkbox-venue-${venue}`}
        >
          <input
            type="checkbox"
            className="form-check-input"
            id={`${customId || ''}venue-${venue}`}
            defaultChecked={selectedVenues.indexOf(venue) > -1}
            onChange={(e) =>onChangeVenue(e, venue)}
          />
          <label className="form-check-label" htmlFor={`${customId || ''}venue-${venue}`}>
            {venue}
          </label>
        </li>
};

export default VenueDropdownItem;
