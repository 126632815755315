import * as React from 'react';
import { Contract } from '../../orderbook/models/contracts';
import OrderFormData from '../models/formData';
import { v1 } from 'uuid';
import { MemoTranslate } from '../../shared/i18n/components/memoTranslate';
import { SanityCheck, DeviationMode } from '../models/sanityChecks';
import { checkSanity } from '../helper/sanityChecks';
import { MasterDataId } from 'js/main/models/application';

interface SanityCheckProps {
    contract: Contract;
    prices: {bidPrices: any[], askPrice: any[]};
    lastPrices: any;
    formData: OrderFormData;
    sanityLimits: SanityCheck[];
    subscribe: (contractId: MasterDataId) => void;
    getSanityLimit: (contractId: string) => void;
    unsubscribe: (contractId: MasterDataId) => void;
    onSanityCheck: (activated: boolean, warnigns: string[]) => void;
} 

interface SanityCheckState {
  isSanePrice: boolean;
  isSaneQty: boolean;
  warnings: string[];
}

export default class SanityCheckElement extends React.Component<SanityCheckProps, SanityCheckState> {
    static correlationId: string = v1();
    
    static isSane = (enteredValue: number, orderbookValue: number, sanityCheck: SanityCheck, buy: boolean): boolean => {
      const condition = buy ? enteredValue <= orderbookValue : enteredValue >= orderbookValue;
      if (sanityCheck.priceDeviationMode === DeviationMode.PERCENTAGE) {
        return condition && Math.abs(1 - enteredValue / orderbookValue) < sanityCheck.priceDeviationValue;
      } else {
        return condition && Math.abs(enteredValue - orderbookValue) < sanityCheck.priceDeviationValue;
      }
    }

    static getDerivedStateFromProps(props: SanityCheckProps, state: SanityCheckState) {
      const { formData, prices, contract, sanityLimits, lastPrices } = props;
      const orderbookPrices = {orders: prices, trades: lastPrices};
      const { isSanePrice, isSaneQty, warnings } = checkSanity(formData, orderbookPrices, sanityLimits);
      
      props.onSanityCheck(!isSanePrice || !isSaneQty, warnings);
      
      return {
        isSanePrice,
        isSaneQty,
        warnings
      };
    }

    constructor(props: SanityCheckProps) {
      super(props);
      this.state = {
        isSanePrice: true,
        isSaneQty: true,
        warnings: []
      };
    }

    componentDidMount() {
        this.props.subscribe(this.props.contract.id);
    }

    componentWillUnmount() {
        this.props.unsubscribe(this.props.contract.id);
    }

    createWarnings() {
      if (!this.state.warnings || this.state.warnings.length === 0) {
        return (<MemoTranslate value="sanityChecks.warning"/>);
      } else {
        return (<ul className="sanitycheck-warnings">
          {this.state.warnings.map(warn => (<li key={`sanity-warning-${warn}`}>{warn}</li>))}
         </ul>);
      }
    }

    render() {
      if (this.state.isSanePrice && this.state.isSaneQty) {
        return <React.Fragment />;
      }
      return (
          <div>
            {this.createWarnings()}
          </div>
      );
    }
}