import OrderFormData, { OrderFormMode } from '../models/formData';
import { Restriction, IOrder, ModifyOrderRequest, EnterOrderRequest, CancelOrderRequest } from '../models/orders';
import { Price } from '../../orderbook/models/orderbooks';
import { MasterDataId } from '../../main/models/application';
import { createMasterDataIdString } from '../../orderbook/selectors/contracts';

export function createVolumeMatchOrderFormDataFromPrice(
    prices: Price[], index: number, matchPriceFeedOrdersEnabled: boolean, anonymizeMarketData: boolean, matchOnlyModeEnabled: boolean): OrderFormData | null {
    const price = prices[index];
    // create sum of volume for volume orders
    let volumeQuantity = 0;
    for (let i = 0; i <= index; i++) {
        const currPrice = prices[i];
        const ignorePriceFeedPrice = !matchPriceFeedOrdersEnabled && (!currPrice.order || currPrice.order.readOnly);
        const ignoreNoneOrderType = currPrice.quoteRequest !== null;
        if (currPrice && !ignorePriceFeedPrice && !ignoreNoneOrderType) {
            volumeQuantity += currPrice.quantity;
        }
    }
    return price ?
        createMatchOrderFormData(price.buy, price.contractId, price.price, volumeQuantity, true, 0, matchPriceFeedOrdersEnabled, anonymizeMarketData, matchOnlyModeEnabled) : null;
}

export function createMatchOrderFormDataFromOrder(order: IOrder, matchPriceFeedOrdersEnabled: boolean, anonymizeMarketData: boolean, matchOnlyModeEnabled: boolean): OrderFormData | null {
    return order ? createMatchOrderFormData(
        order.buy, order.contractId, order.limit, order.remainingQuantity, order.readOnly, order.orderId, matchPriceFeedOrdersEnabled, anonymizeMarketData, matchOnlyModeEnabled) : null;
}

export function createMatchOrderFormData(
    isBuy: boolean, contractId: MasterDataId, price: number, quantity: number, readOnly: boolean, orderId: number, matchPriceFeedOrdersEnabled: boolean,
    anonymizeMarketData: boolean, matchOnlyModeEnabled: boolean): OrderFormData {
    return {
        buy: !isBuy,
        contractId: contractId,
        mode: OrderFormMode.MATCH,
        price: price,
        quantity: matchOnlyModeEnabled ? 0 : quantity,
        restriction: readOnly && matchPriceFeedOrdersEnabled ? Restriction.NONE : Restriction.IOC,
        orderId: anonymizeMarketData ? 0 : orderId,
        revisionNo: 0,
        seenOrderToBeModified: {
            buy: !isBuy,
            contractId: contractId,
            mode: OrderFormMode.MATCH,
            limit: price,
            remainingQuantity: matchOnlyModeEnabled ? 0 : quantity,
            restriction: readOnly && matchPriceFeedOrdersEnabled ? Restriction.NONE : Restriction.IOC,
            orderId: anonymizeMarketData ? 0 : orderId,
            revisionNo: 0
        },
        associatedContracts: []
    };
}

export function createEnterOrderFormData(contractId: MasterDataId, isBuy: boolean, price: number, quantity: number, associatedContracts: string[]): OrderFormData {
    return {
        buy: isBuy,
        contractId: contractId,
        mode: OrderFormMode.ENTER,
        price: price ? price : 0,
        quantity: quantity ? quantity : 0,
        restriction: Restriction.NONE,
        orderId: 0,
        revisionNo: 0,
        seenOrderToBeModified: null,
        associatedContracts: associatedContracts
    };
}

export function createEnterOrderFormDataFromPrice(price: Price, contractId: MasterDataId, isBuy: boolean, associatedContracts: string[]): OrderFormData {
    return createEnterOrderFormData(contractId, isBuy, price && !price.quoteRequest ? price.price : 0, price  && !price.quoteRequest ? price.quantity : 0, associatedContracts);
}

export function createEnterOrderFormDataFromOrder(order: IOrder, contractId: MasterDataId, isBuy: boolean): OrderFormData {
    return createEnterOrderFormData(contractId, isBuy, order ? order.limit : 0, order ? order.remainingQuantity : 0, []);
}

export function createDeleteOrderFormDataFromOrder(order: IOrder): OrderFormData | null {
    if (!order) {
        return null;
    }
    return {
        buy: order.buy,
        contractId: order.contractId,
        mode: OrderFormMode.CANCEL,
        price: order.limit,
        quantity: order.remainingQuantity,
        restriction: order.restriction,
        orderId: order.orderId,
        revisionNo: order.revisionNo,
        seenOrderToBeModified: {
            buy: order.buy,
            contractId: order.contractId,
            mode: OrderFormMode.CANCEL,
            limit: order.limit,
            remainingQuantity: order.remainingQuantity,
            restriction: order.restriction,
            orderId: order.orderId,
            revisionNo: order.revisionNo
        },
        associatedContracts: []
    };
}

export function createDeleteOrderFormDataFromPrice(price: Price): OrderFormData | null {
    return price ? createDeleteOrderFormDataFromOrder(price.order) : null;
}

export function createModifyOrderFormDataFromOrder(order: IOrder): OrderFormData | null {
    if (!order) {
        return null;
    }
    return {
        buy: order.buy,
        contractId: order.contractId,
        mode: OrderFormMode.MODIFY,
        price: order.limit,
        quantity: order.remainingQuantity,
        restriction: order.restriction,
        orderId: order.orderId,
        revisionNo: order.revisionNo,
        seenOrderToBeModified: {
            buy: order.buy,
            contractId: order.contractId,
            mode: OrderFormMode.MODIFY,
            limit: order.limit,
            remainingQuantity: order.remainingQuantity,
            restriction: order.restriction,
            orderId: order.orderId,
            revisionNo: order.revisionNo
        },
        associatedContracts: []
    };
}

export function createModifyOrderFormDataFromPrice(price: Price): OrderFormData | null {
    return price ? createModifyOrderFormDataFromOrder(price.order) : null;
}

export function createOrderRequest(formData: OrderFormData, correlationId: string): ModifyOrderRequest | EnterOrderRequest | CancelOrderRequest {
    if (formData.mode === OrderFormMode.ENTER) {
        const request: EnterOrderRequest = {
          correlationId: correlationId,
          limit: formData.price,
          quantity: formData.quantity,
          restriction: formData.restriction,
          contractId: createMasterDataIdString(formData.contractId),
          buy: formData.buy
        };
        return request;

      } else if (formData.mode === OrderFormMode.MODIFY) {
        const request: ModifyOrderRequest = {
          correlationId: correlationId,
          limit: formData.price,
          quantity: formData.quantity,
          restriction: formData.restriction,
          orderId: formData.orderId,
          revisionNo: formData.revisionNo,
          seenOrderToBeModified: formData.seenOrderToBeModified
        };
        return request;

      } else if (formData.mode === OrderFormMode.MATCH) {
        const request: EnterOrderRequest = {
          correlationId: correlationId,
          limit: formData.price,
          quantity: formData.quantity,
          restriction: formData.restriction,
          contractId: createMasterDataIdString(formData.contractId),
          buy: formData.buy
        };
        return request;

      } else {
        const request: CancelOrderRequest = {
          correlationId: correlationId,
          orderId: formData.orderId
        };
        return request;
      
      }
}