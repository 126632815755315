import { MemoTranslate } from 'js/shared/i18n/components/memoTranslate';
import * as React from 'react';



const RadioOption = ({onTypeChanged,customId, type,listType }) => {
  return <div className="form-group form-check">
            <input
            type="radio"
            id={`${customId || ''}list-type-${type}`}
            name={`list-type-${type}`}
            className="form-check-input"
            checked={listType === type}
            onChange={onTypeChanged}
            />

        <label className="form-check-label" htmlFor={`${customId || ''}list-type-${type}`}>
            <MemoTranslate
                value={`instrument.show${type[0].toUpperCase() + type.slice(1)}s`}
            />
        </label>
    </div>
};

export default RadioOption;
