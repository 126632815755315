const decode = (uriEncodedString: string) => {
  return uriEncodedString.replace(/(%[0-9A-Z]{2})+/g, decodeURIComponent);
};

export const getCookie = (cookieName: string): string => {
  const jar: { [key: string]: string } = {};
  const cookies = document.cookie ? document.cookie.split('; ') : [];

  for (const cookie of cookies) {
    let [name, ...valueParts] = cookie.split('=');
    let value = valueParts.join('=');

    if (value.charAt(0) === '"') {
      value = value.slice(1, -1);
    }

    try {
      name = decode(name);
      value = decode(value);

      jar[name] = value;

      if (cookieName === name) {
        break;
      }
    } catch (e) {}
  }

  return jar[cookieName];
};