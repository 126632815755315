import * as React from 'react';
import { connect } from 'react-redux';
import { openPriceAlarmForm } from '../../../priceAlarm/actions/priceAlarms'
import { createPriceAlarmFormData } from '../../../priceAlarm/helper/priceAlarms'
import { PriceAlarmFormData } from '../../../priceAlarm/models/priceAlarms'
import { triggerFilter, triggerSearch, remove } from '../../ui/actions/tab';
import { I18n } from 'react-redux-i18n';
import AddTableIcon from '../../ui/components/actionIcons/AddTableIcon'
import { ComponentType } from '../../ui/models/component';
import DownloadLink from '../../ui/components/table/components/uiTableDownloadLink';
import { getTableForTab } from '../../ui/selectors/ui';
import { ITable, TableAction } from '../../ui/models/table';
import Dragger from '../../dragger/container/Dragger';
import { isProfileModificationAllowed } from '../../../authentication/selectors/authetication';
import { DockScrollState } from '../../dock/models/dock';

export interface TabPaneProps {
  tabId: string;
  dockId: string;
  isGrid?: boolean;
  type: ComponentType;
  title: string;
  isActive: boolean;
  tabIndex: number;
  displayActions: boolean;
  displayFilter: boolean;
  displaySearch: boolean;
  table: ITable;
  profileModificationEnabled: boolean;
  onClick: (tabIndex: number) => void;
  onRemove: (tabIndex: number) => void;
  triggerFilter: (id: string, displayFilter: boolean) => void;
  triggerSearch: (id: string, displaySearch: boolean) => void;
  remove: (id: string, dockId: string, type: ComponentType) => void;
  onDragComplete: (e: any, title: string) => void;
  dockSize: DockScrollState;
  newPriceAlarm: (formData: PriceAlarmFormData) => void;
}

interface TabPaneState {
  parent: HTMLElement;
}

export class TabPane extends React.Component<TabPaneProps, TabPaneState> {
  private timeouts: any[];
  constructor(props: TabPaneProps) {
    super(props);

    this.onFilter = this.onFilter.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onDragComplete = this.onDragComplete.bind(this);
    this.timeouts = [];
    this.state = {
      parent: null
    };
  }

  onFilter(e: any) {
    e.preventDefault();
    const { tabId, displayFilter } = this.props;
    this.props.triggerFilter(tabId, !displayFilter);
  }

  onSearch(e: any) {
    e.preventDefault();
    const { tabId, displaySearch } = this.props;
    this.props.triggerSearch(tabId, !displaySearch);
  }

  onRemove(e: any) {
    e.preventDefault();
    const { tabId, dockId, type } = this.props;
    this.props.remove(tabId, dockId, type);
  }

  onDragComplete(e: any) {
    const { title } = this.props;
    this.props.onDragComplete(e, title);
  }

  componentDidMount(): void {
    const parent = document.querySelector('.dockId-' + this.props.dockId) as HTMLElement;
    this.setState((prevState) => {
      return {...prevState, parent};
    });
  }

  render(): JSX.Element {
    const {
      tabId,
      title,
      tabIndex,
      type,
      onClick,
      onRemove,
      isActive,
      displayActions,
      displayFilter,
      displaySearch,
      table,
      profileModificationEnabled
    } = this.props;
    const dockSize = this.state.parent ? {
      width: this.state.parent.clientWidth,
      height: this.state.parent.clientHeight,
      tabTop: parseInt(('' + this.state.parent.style.top).replace('px', '')),
      tabLeft: parseInt(('' + this.state.parent.style.left).replace('px', '')),
      ...this.props.dockSize }

    : {width: 0, height: 0, top: 0, left: 0, tabTop: 0, tabLeft: 0}
    return (
      <li className={`nav-item ${isActive ? 'active' : 'inactive'}`}>
        <Dragger
          tag="div"
          className="dragger"
          onDragComplete={this.onDragComplete}
        />
        <span
          className="nav-link tabs__link"
          onClick={event => {
            event.preventDefault();
            this.timeouts = [
              ...this.timeouts,
              setTimeout(() => onClick(tabIndex), 1)
            ];
          }}
        >
          <div className="d-flex tab-title flex-direction-row justify-content-between">
            <h3 className="text-nowrap" title={title}>
              {title}
            </h3>
            {displayActions ? (
              <div className="tabs__actions" style={{
                top: dockSize.tabTop - 32 + 'px',
                left:
                  dockSize.tabLeft +
                  dockSize.width -
                  145 +
                  'px'
              }}>
                <ul>
                {table.type == ComponentType.PriceAlarm ? (
                    <AddTableIcon iconName="oi oi-plus"
                                  title={I18n.t('priceAlarm.create')}
                                  onAdd={e => this.props.newPriceAlarm(createPriceAlarmFormData('', '', '', '', 0, ''))}
                    />
                ) : null}
                <li 
                  style={{display: table?.actions.indexOf(TableAction.SEARCH) > -1 ? 'inherit': 'none' }}
                  onClick={this.onSearch}
                 >
                  <i
                    className={`oi oi-magnifying-glass hover-primary ${
                      displaySearch || (table && table.tags.length) ? `active` : `inactive`
                    }`}
                    title={I18n.t('modal.search')}
                  />
                </li>
                <li onClick={e => e.preventDefault()}>
                  <DownloadLink
                    parentId={tabId}
                    componentType={type}
                    name={type}
                  />
                </li>
                <li onClick={this.onFilter}>
                  <i
                    className={`oi oi-wrench hover-primary ${
                      displayFilter ? `active` : `inactive`
                    }`}
                    title={I18n.t('modal.filter')}
                  />
                </li>
                </ul>
              </div>) : null }
          </div>
        </span>
      </li>
    );
  }

  componentWillUnmount() {
    this.timeouts.map(t => clearTimeout(t));
  }
}

const mapDispatchToProps = {
  triggerSearch: triggerSearch,
  triggerFilter: triggerFilter,
  remove: remove,
  newPriceAlarm: openPriceAlarmForm
};

const mapStateToProps = (state: any, props: TabPaneProps) => ({
  table: getTableForTab(state, props.tabId, props.type),
  profileModificationEnabled: isProfileModificationAllowed(state)
});

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(TabPane);
