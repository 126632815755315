import { LogAction } from '../../../main/middlewares/logger';
import { LogLevel } from '../../logger/models/logger';
import { I18n } from 'react-redux-i18n';

export enum ActionTypes {
  SAVE_CORRELATION_ID = '[Messager] Save correlation ID',
  RECEIVE_MESSAGE = '[Messager] Receive message',
  REMOVE_MESSAGE = '[Messager] Remove message',
  RECEIVE_CONCAT_MESSAGE = '[Messager] Receive concat message'

}

export interface MessagerSaveCorrelationIdAction {
  type: ActionTypes.SAVE_CORRELATION_ID;
  payload: string;
}

export interface MessagerRecevieMessageAction extends LogAction {
  type: ActionTypes.RECEIVE_MESSAGE;
  payload: {
    id: string;
    message: string;
    error: boolean;
    translationParams?: {[key: string]: string}
  };
}

export interface MessagerRecevieConcatMessageAction extends LogAction {
  type: ActionTypes.RECEIVE_CONCAT_MESSAGE;
  payload: {
    acks: any[];
  };
}

export interface MessagerRemoveMessageAction {
  type: ActionTypes.REMOVE_MESSAGE;
  payload: any[];
}

export function saveCorrelationId(id: string): MessagerSaveCorrelationIdAction {
  return {
    type: ActionTypes.SAVE_CORRELATION_ID,
    payload: id
  };
}

export function receiveMessage(
  id: string,
  message: any,
  error: boolean,
  isTranslated?: boolean,
  translationParams?: {[key: string]: string}
): MessagerRecevieMessageAction {
  let logMsg = I18n.t('general.log.acknowledgement', { id: id })
  if (message) {
    if (typeof message === 'string') {
      logMsg = isTranslated ? message : I18n.t(message, translationParams);
    } else if (message.hasOwnProperty('message')) {
      logMsg = isTranslated ? message.message : I18n.t(message.message); 
    } else {
      logMsg = message;
    }
  }
  
  return {
    type: ActionTypes.RECEIVE_MESSAGE,
    payload: { id: id, message: message, error: error, translationParams: translationParams },
    logLevel: error ? LogLevel.ERROR : LogLevel.DEBUG,
    logMessage: logMsg
  };
}

export function receiveConcatMessage(
  acks: any[],
  error: boolean
): MessagerRecevieConcatMessageAction {
  let logMsg = I18n.t('general.log.acknowledgement');

  return {
    type: ActionTypes.RECEIVE_CONCAT_MESSAGE,
    payload: { acks: acks },
    logLevel: error ? LogLevel.ERROR : LogLevel.DEBUG,
    logMessage: logMsg
  };
}

export function removeMessages(ids: any[]): MessagerRemoveMessageAction {
  return {
    type: ActionTypes.REMOVE_MESSAGE,
    payload: ids
  };
}

export type Action =
  | MessagerSaveCorrelationIdAction
  | MessagerRecevieMessageAction
  | MessagerRemoveMessageAction
  | MessagerRecevieConcatMessageAction;
