import {
    ActionsObservable,
    combineEpics,
} from 'redux-observable';
import { filter, map, switchMap, flatMap, mergeMap, catchError } from 'rxjs/operators';
import { ActionTypes as BasketActions, invalidSampleBasketFile, finishedDownloadSampleBasketFile } from '../actions/bulkOrders';
import { ActionTypes as OrderActions } from '../../orders/actions/orders';
import BasketService from '../services/bulkOrders';
import * as Rx from 'rxjs';
import * as XLSX from 'xlsx';

const basketService = new BasketService();

export const requestSampleBasketFile: any = (
    actions$: ActionsObservable<any>
) => {
    return actions$.pipe(
        filter(action => action.type === BasketActions.REQUEST_SAMPLE_BASKET_FILE),
        map(action => action.payload),
        mergeMap(() => {
            return basketService.requestSampleBasketFile().pipe(
                map((content: any) => {
                    if (content && content.sampleBasketFile) {
                        // cellStyles only works for Pro compendium of js-xlsx
                        let oFile = XLSX.read(content.sampleBasketFile, { type: 'base64', cellDates: true, cellStyles: true });
                        XLSX.writeFile(oFile, content.sampleBasketFileName);
                        return finishedDownloadSampleBasketFile();
                    } else {
                        return invalidSampleBasketFile();
                    }
                })
            );
        })
    );
};

export const bulkEnter: any = (
    actions$: ActionsObservable<any>
) => {
    return actions$.pipe(
        filter(action => action.type === OrderActions.BULK_ENTER_ORDER),
        map(action => action.payload),
        switchMap((content) => {
            return basketService.sendBulkOrders({orders: content}).pipe(
                catchError((error: any) => { return Rx.empty(); })
            );
        })
    );
};

export const basketEpic = combineEpics(
    requestSampleBasketFile,
    bulkEnter
);