import 'flexlayout-react/style/dark.css'
import {
  Action,
  Actions,
  IJsonModel,
  IJsonTabNode,
  Layout,
  Model,
  TabNode, TabSetNode,
} from 'flexlayout-react'
import { useCallback } from 'react'
import * as React from 'react'
import PriceAlarmModal from '../../priceAlarm/components/priceAlarmModal'
import { setDockVisibility } from '../../shared/dock/actions/dock'
import { updateSubscriptions } from '../../shared/ui/actions/market'

import UIMarketView from '../../shared/ui/components/market/components/uiMarketComponent'
import { Dock, DockType, IDock } from 'js/shared/dock/models/dock'
import { ComponentType } from 'js/shared/ui/models/component'
import { ITableColumn, ITableSearchTag } from 'js/shared/ui/models/table'
import { config } from 'js/main/config'
import { IMarket, Market } from 'js/shared/ui/models/market'
import { DashboardComponent } from '../models/dashboard'
import { createMasterDataIdString } from 'js/orderbook/selectors/contracts'
import { Position } from 'js/shared/utils/models/grid'
import { getMarketEntities, getChartEntites } from 'js/shared/ui/selectors/ui'
import store from 'js/main/store/store'
import { I18n } from 'react-redux-i18n'
import { Chart } from 'js/shared/ui/models/chart'
import UiTradesChartComponent from 'js/shared/ui/components/chart/components/uiTradesChartComponent'
import { ITab, Tab } from '../../shared/ui/models/tab'
import { IFavorite } from '../../shared/favorite/models/favorite'
import UITabView from '../../shared/ui/components/table/components/uiTabComponent'
import { Popup } from '../../shared/modalWindow/components/popup'
import { MarketsheetSelectForDock } from '../../shared/marketsheetSelect/containers/marketsheetSelect'
import {
  NotificationType,
  Notification as NotificationModel,
} from '../../shared/notifications/models/notification'
import OrderSidebarComponent from '../../orders/components/orderSidebar'
import Sidebar from 'js/shared/sidebar/components/Sidebar'
import { RecentActionsComponent } from './recentActions'
import "../styles/flexlayout-customization.css"
const RECENT_ACTIONS_TITLE = {
  'sidebar.logs': 'Logs',
  'sidebar.trades': 'All Trades',
  'sidebar.priceAlarms': 'Price Alarms',
  'sidebar.venues': 'Venues',
}
const initialJson: IJsonModel = {
  global: {
    tabSetEnableSingleTabStretch: true,
    tabEnableFloat: false,
  },
  borders: [],
  layout: {
    type: 'row',
    weight: 100,
    children: [],
  },
}

const DashboardPanel = (props) => {
  const {
    docks,
    enabled,
    dashboardLoading,
    flexLayout,
    removeDock,
    loadProfile,
    dockTitles,
  } = props
  const layoutRef = React.useRef<any>(null)
  const [model, setModel] = React.useState(() => Model.fromJson(initialJson))
  const loading = dashboardLoading || !enabled
  const [visible, setVisible] = React.useState(false)
  const [popupPosition, setPopupPosition] = React.useState(null)
  const [activeDock, setActiveDock] = React.useState('')
  const [activeDockType, setActiveDockType] = React.useState(null)
  const previousDockTitles = usePreviousValue(dockTitles)
  const [hiddenExpiries, setHiddenExpiries] = React.useState([])
  const tabVisibility = React.useRef(null)

  /* Create a new dashboard panel when user sign in with layout that was saved in old version of the app. */
  React.useEffect(() => {
    if (!loading && !flexLayout) {
      loadProfile('emptyView')
    }
  }, [loading, flexLayout])

  function usePreviousValue(value) {
    const ref = React.useRef()
    React.useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  React.useEffect(() => {
    if (previousDockTitles) {
      const changedDocks = Object.keys(dockTitles).reduce((acc, dockId) => {
        if (
          previousDockTitles[dockId] &&
          previousDockTitles[dockId] !== dockTitles[dockId]
        ) {
          acc[dockId] = dockTitles[dockId]
        }
        return acc
      }, {})
      if (Object.keys(changedDocks).length > 0) {
        model.visitNodes((node: any) => {
          if (node._attributes?.config?.dockId in changedDocks) {
            model.doAction(
              Actions.renameTab(
                node.getId(),
                changedDocks[node._attributes.config.dockId]
              )
            )
          }
        })
      }
    }
  }, [dockTitles])

  /* The effect is triggered whenever the `loading`
  variable changes and updates the dashboard panel. */
  React.useEffect(() => {
    if (!loading && flexLayout) {
      // flexLayout is the saved layout of the dashboard panel and has the same structure as the initialJson.
      const newModel = Model.fromJson(flexLayout)
      setModel(newModel)
      if (!tabVisibility.current) {
        tabVisibility.current = createVisibilityMap(newModel)
        Object.keys(tabVisibility.current).forEach(dockId => {
          store.dispatch(setDockVisibility(dockId, tabVisibility.current[dockId]))
        })
      }
    }
  }, [loading, flexLayout])
  //Changes to tab title while the orderbook updates cannot be prevented completely, as this is part of the used 3rd party lib.
  // The best we could achieve is to set the cursor to the end of the current tab title.
  React.useLayoutEffect(() => {
    const inputElement = document.querySelector('.flexlayout__tab_button_textbox') as HTMLInputElement | null;
    if (inputElement && inputElement.selectionStart !== inputElement.selectionEnd) {
      inputElement.selectionStart = inputElement.selectionEnd
      inputElement.focus()
    }
  })

  /**
   * The function `onNewChart` creates a new chart object and calls a function
   * `chartCreate` with the created chart object as an argument.
   */
  const onNewChart = (
    dockId: string,
    title: string,
    instrumentId: string,
    selectedPeriodType?: string,
    contractId?: string,
    groupTypes?: ('bidPrices' | 'askPrices' | 'lastPrices')[]
  ) => {
    const chart: Chart = new Chart(
      dockId,
      title,
      instrumentId,
      instrumentId,
      selectedPeriodType,
      contractId,
      groupTypes
    )

    props.chartCreate(chart)
  }

  /**
   * The function `onNewMarket` creates a new market object and calls an action to
   * add it to the store.
   */
  const onNewMarket = (
    dockId: string,
    title: string,
    type: ComponentType,
    itemId: string,
    instrumentId: string,
    columns?: ITableColumn[],
    hiddenColumnNames?: string[],
    hiddenExpiryKeys?: string[],
    isHeadlinesVisible?: boolean,
    instrumentGroupId?: string,
    expiryRowsLength?: { [key: string]: number }
  ) => {
    let newColumns = undefined
    if (columns) {
      newColumns = []
      const configColumnMap: { [name: string]: ITableColumn } =
        config.ui.market.columns.reduce(
          (map: { [name: string]: ITableColumn }, col: ITableColumn) => {
            return { ...map, [col.group + '-' + col.name]: col }
          },
          {}
        )

      for (let i = 0; i < columns.length; i++) {
        const key = columns[i].group + '-' + columns[i].name
        if (configColumnMap[key]) {
          newColumns.push({
            ...configColumnMap[key],
            rowVisible: columns[i].rowVisible,
          })
        }
      }
    }

    const market: IMarket = new Market(
      dockId,
      title,
      type,
      itemId,
      instrumentId,
      newColumns,
      hiddenColumnNames,
      hiddenExpiryKeys,
      [],
      isHeadlinesVisible,
      undefined,
      undefined,
      instrumentGroupId
    )
    if (expiryRowsLength) {
      market.expiryRowsLength = expiryRowsLength
    }

    props.marketCreate(market)
  }

  /* The `iconFactory` function is used to generate custom icons for the tabs in
  the dashboard panel. */
  const iconFactory = (node: TabNode) => {
    return (
      <>
        <span className={`oi ${node.getIcon()}`}></span>
      </>
    )
  }

  /**
   * The function takes a TabNode as input and returns a React component based on
   * the type of dock associated with the node.
   */
  const factory = (node: TabNode) => {
    const dockIds = node.getConfig().dockIds

    if (dockIds?.length > 0) {
      const result = dockIds.map((dockId) => {
        const dock: IDock = props.docks.find((dock) => dock.id === dockId)
        if (dock) {
          switch (dock.type) {
            case DockType.Market:
            case DockType.MarketIntraday:
            case DockType.ProductMarket:
              return (
                <UIMarketView
                  key={dock.id}
                  dockId={dock.id}
                  dockType={dock.type}
                  onCreateChart={onSingleDragFromSidemenu}
                  onCreateTable={onRecentActionsDrag}
                  onAddMarket={(event, hiddenExpiries) => {
                    setHiddenExpiries(hiddenExpiries)
                    setPopupPosition({ x: event?.clientX, y: Math.min(event?.clientY, screen.height - 590) })
                    setVisible(!visible)
                    setActiveDock(dock.id)
                    setActiveDockType(dock.type)
                  }}
                />
              )

            case DockType.ChartMarket:
              return (
                <UiTradesChartComponent
                  key={dock.id}
                  dockId={dock.id}
                  dockType={dock.type}
                />
              )
            case DockType.Tabs:
              return <UITabView dockId={dock.id} key={dock.id} isGrid />
            default:
              return null
          }
        }
        return null
      })
      return result
    }

    return <div />
  }

  /**
   * The function `onNewDock` creates a new dock object with specified properties
   * and triggers an action to create the dock.
   */
  const onNewDock = (
    type: DockType,
    _position: Position,
    ableToDelete?: boolean,
    autoDragging?: boolean,
    id?: string,
    favoriteName?: string,
    touchScreen?: boolean,
    dockTitle?: string
  ): IDock => {
    // actual dock object
    const newDock: IDock = new Dock(
      type,
      // position and size are irrelevant
      { x: 0, y: 0 },
      { width: 0, height: 0 },

      autoDragging,
      ableToDelete,
      id,
      true,
      favoriteName,
      0,
      undefined,
      touchScreen
    )
    newDock.title = dockTitle

    // action to create a dock
    props.dockCreate(newDock)
    return newDock
  }

  const renderDocType = ({
    component,
    newDock,
    itemId,
    instrumentId,
    expiryRowLengths,
  }) => {
    switch (component.dockType) {
      case DockType.Market:
        onNewMarket(
          newDock.id,
          component.name,
          component.type,
          itemId,
          createMasterDataIdString(instrumentId),
          undefined,
          undefined,
          hiddenExpiries,
          undefined,
          component.args[0].instrument?.instrumentGroupId,
          expiryRowLengths
        )
        break
      case DockType.ProductMarket:
        onNewMarket(
          newDock.id,
          component.name,
          component.type,
          itemId,
          createMasterDataIdString(instrumentId)
        )
        break
      case DockType.ChartMarket:
        onNewChart(
          newDock.id,
          component.name,
          createMasterDataIdString(instrumentId),
          component.args && component.args[1],
          component.args && component.args[2],
          ['lastPrices']
        )
        break
      case DockType.MarketIntraday:
        onNewMarket(
          newDock.id,
          component.name,
          component.type,
          itemId,
          createMasterDataIdString(instrumentId)
        )
        break
      case DockType.Tabs:
        onNewTab(newDock.id, component.name, component.type, true)
        break
      default:
        break
    }
  }

  /**
   * This is called when a single item is dragged from the side menu
   */
  const onSingleDragFromSidemenu = React.useCallback((
    event: React.MouseEvent,
    component: DashboardComponent
  ) => {
    const itemId = component.args && component.args[0].id

    if (!component.args) {
      return
    }

    const instrumentId =
      component && component.type === ComponentType.Product
        ? component.args[0]?.product?.instrumentId
        : component.args[0]?.id

    const newDock = onNewDock(
      component.dockType,
      {
        x: 0,
        y: 0,
      },
      true,
      true,
      undefined,
      undefined,
      false,
      component.name
    )

    if (newDock) {
      renderDocType({
        component,
        newDock,
        itemId,
        instrumentId,
        expiryRowLengths: undefined,
      })
      layoutRef!.current!.addTabWithDragAndDrop(component.name, {
        component: 'chart',
        name: component.name,
        icon:
          component.type.toLowerCase() === 'instrument' ||
          component.type.toLocaleLowerCase() === 'product'
            ? 'oi-list'
            : 'oi-graph',
        contentClassName: 'dockId-' + newDock.id,
        config: {
          dockIds: [newDock.id],
          dockId: newDock.id,
        },
      })
    }
  }, []);

  // Type guard to check if a node is a tab node with config
  const isTabNodeWithConfig = (
    node: any
  ): node is IJsonTabNode & { config: { dockId: string } } => {
    return (
      node &&
      node.type === 'tab' &&
      node.config &&
      typeof node.config.dockId === 'string'
    )
  }

  const onModelChange = (changedModel: Model, action: Action) => {
    const modelJson = changedModel.toJson()
    props.flexLayoutSave(modelJson)

    const newTabVisibility = createVisibilityMap(changedModel)

    // dispatch dock visibility action for changed docks
    const changedDocks = {}
    Object.keys(newTabVisibility).forEach(dockId => {
      if (tabVisibility.current[dockId] !== newTabVisibility[dockId]) {
        store.dispatch(setDockVisibility(dockId, newTabVisibility[dockId]))
        changedDocks[dockId] = newTabVisibility[dockId]
      }
    })
    // dispatch subscription update after all docks got updated visibility attribute
    if (action.type === 'FlexLayout_DeleteTab') {
      const removedDockId = getRemovedDock()
      if (removedDockId) {
        store.dispatch(updateSubscriptions({ ...newTabVisibility, [removedDockId]: false }))
        removeDock(removedDockId)
      }
    } else {
        store.dispatch(updateSubscriptions(newTabVisibility))
    }
    tabVisibility.current = newTabVisibility
  }

  /**
   * creates map of tab nodes:
   * key: dockId
   * value: isVisible based on selected property of the tabset
   *
   * this takes maximized tabset into account - all other tabsets are hidden
   * @param changedModel
   */
  const createVisibilityMap = (changedModel) => {
    const newTabVisibility = {}
    const maximized = changedModel.getMaximizedTabset()
    changedModel.visitNodes(node => {
      if (node.getType() === 'tabset') {
        const isTabsetVisible = maximized ? node.getId() === maximized.getId() : true
        const selectedNode = (node as TabSetNode).getSelectedNode()

        if (selectedNode?.getType() === 'tab') {
          newTabVisibility[(selectedNode as TabNode).getConfig().dockId] = true && isTabsetVisible
          for (const child of node.getChildren()) {
            if (child.getType() === 'tab') {
              if (child.getId() !== selectedNode.getId()) {
                newTabVisibility[(child as TabNode).getConfig().dockId] = false
              }
            }
          }
        }
      }
    })
    return newTabVisibility
  }


  const collectAllDockIds = (m: Model) => {
    const dockIds: string[] = []
    m.visitNodes((node) => {
      const nodeJson = node.toJson()
      if (isTabNodeWithConfig(nodeJson)) {
        dockIds.push(nodeJson.config.dockId)
      }
    })
    return dockIds
  }
  const getRemovedDock = () => {
    const allDockIds = collectAllDockIds(model)

    const removedDockId = docks
      .map((dock) => dock.id)
      .find((id) => !allDockIds.includes(id))
    return removedDockId
  }

  const onNewTab = (
    dockId: string,
    title: string,
    type: ComponentType,
    displayActions: boolean,
    searchTags?: ITableSearchTag[]
  ) => {
    const newTab: ITab = new Tab(dockId, title, type, displayActions, undefined, {
      displayFilter: false,
      displaySearch: false,
      isActive: false,
      ordering: 0,
      tags: searchTags})
    props.tabCreate(newTab)
  }

  const onRecentActionsDrag = useCallback((
    e: any,
    components: RecentActionsComponent[]
  ) => {
    e?.preventDefault()

    const component = components[0];
    const createDock = () => onNewDock(
      DockType.Tabs,
      {
        x: e?.clientX,
        y: e?.clientY,
      },
      true,
      true,
      undefined,
      undefined,
      e?.type === 'touchend'
    )
    const payload = createDock()
    onNewTab(
      payload.id,
      component.title,
      component.type,
      true,
      component.args
        ? component.args[0]?.map((tag, $index) => { return {id: $index, name: tag} as ITableSearchTag})
        : undefined)
    const createTabNode = (dock, tab) => {
      return {
        component: 'chart',
        name: I18n.t(tab.title),
        contentClassName: 'dockId-' + dock.id,
        config: {
          dockIds: [dock.id],
          dockId: dock.id,
        }
      }
    };

    layoutRef!.current!.addTabWithDragAndDrop(
      I18n.t(component.title),
      createTabNode(payload, component),
      (node, event) => {
        // create other tabs from component array as flex layout tabs in the same tabset
        const tabSetId = node.getParent().getId()
        if (components.length > 1) {
          const otherTabs = components.splice(1)
          for (const other of otherTabs) {
            const newDock = createDock()
            onNewTab(
              newDock.id,
              other.title,
              other.type,
              true)
            layoutRef!.current!.addTabToTabSet(
              tabSetId,
              createTabNode(newDock, other)
            )
          }
        }
      }
    )
  }, [layoutRef])

  const renderDocsFromFavorite = ({ favorite, currentEvent }) =>
    setTimeout(() => {
      const dock = onNewDock(
        favorite.entityType,
        {
          x: currentEvent.clientX,
          y: currentEvent.clientY,
        },
        true,
        true,
        undefined,
        favorite.name,
        currentEvent.type === 'touchend',
        favorite.name
      )
      if (dock) {
        for (const entity of favorite.entities as any) {
          if (
            favorite.entityType === DockType.Market ||
            favorite.entityType === DockType.ProductMarket
          ) {
            onNewMarket(
              dock.id,
              entity.instrumentTitle,
              entity.componentType,
              entity.itemId,
              entity.instrumentId,
              entity.columns,
              entity.hiddenColumnNames,
              entity.hiddenExpiryKeys,
              entity.isHeadlinesVisible,
              entity.instrumentGroupId,
              entity.expiryRowsLength
            )
          } else if (favorite.entityType === DockType.MarketIntraday) {
            onNewMarket(
              dock.id,
              entity.instrumentTitle,
              entity.componentType,
              entity.instrumentId,
              entity.instrumentId,
              entity.columns,
              entity.hiddenColumnNames,
              entity.hiddenExpiryKeys,
              entity.isHeadlinesVisible
            )
          } else if (favorite.entityType === DockType.ChartMarket) {
            onNewChart(
              dock.id,
              entity.title,
              entity.instrumentId,
              entity.selectedPeriodType,
              entity.contractId,
              entity.groupTypes
            )
          }
          layoutRef!.current!.addTabWithDragAndDrop(entity.instrumentTitle, {
            component: 'chart',
            name: entity.instrumentTitle,
            contentClassName: 'dockId-' + dock.id,
            config: {
              dockIds: [dock.id],
              dockId: dock.id,
            },
          })
        }
      }
    }, 500)

  const onFavoriteSelectDrag = useCallback((e: any, favorite: IFavorite) => {
    const itemIds =
      favorite.entityType === DockType.MarketIntraday ||
      favorite.entityType === DockType.Market ||
      favorite.entityType === DockType.ProductMarket
        ? getMarketEntities(store.getState()).map((m) => m.itemId)
        : getChartEntites(store.getState())
            .map((c: any) => c.instrumentId)
            .filter((id) => id !== undefined)
    const itemsAlreadyActive = []

    for (const entity of favorite.entities as any) {
      if (itemIds.indexOf(entity.itemId) > -1) {
        itemsAlreadyActive.push(entity.instrumentTitle || entity.title)
      }
    }

    if (
      itemsAlreadyActive.length > 0 &&
      (favorite.entityType === DockType.MarketIntraday ||
        favorite.entityType === DockType.Market ||
        favorite.entityType === DockType.ProductMarket)
    ) {
      props.warn(
        I18n.t(
          favorite.entityType === DockType.ProductMarket
            ? 'error.favouriteProductMarketIsAlreadyActive'
            : 'error.favouriteMarketIsAlreadyActive',
          {
            items: itemsAlreadyActive.join(', '),
          }
        )
      )
    } else {
      e.preventDefault()
      let currentEvent = e
      const mouseMoveListener = (ev: any) => {
        currentEvent = ev
      }
      document.addEventListener('mousemove', mouseMoveListener)

      renderDocsFromFavorite({ favorite, currentEvent })
    }
  }, [])

  const onMarketSelect = (event: any, component: DashboardComponent) => {
    const itemId = component.args && component.args[0].id
    const instrumentId =
      component && component?.type === ComponentType.Product
        ? component?.args[0]?.product?.instrumentId
        : component?.args[0]?.id
    const newDock = onNewDock(
      component.dockType,
      {
        x: 0,
        y: 0,
      },
      true,
      true,
      undefined,
      undefined,
      false
    )
    if (newDock) {
      renderDocType({
        component,
        newDock,
        itemId,
        instrumentId,
        expiryRowLengths: undefined,
      })
      props.connectDocks(newDock.id, activeDock, newDock.type)
      props.createNotification(
        new NotificationModel(
          NotificationType.INFO,
          I18n.t(
            `market.logs.${component.type === ComponentType.Product ? 'addProduct' : 'addInstrument'}`,
            { name: component.name }
          ),
          false
        )
      )
      setVisible(false)
    }
  }

  return (
    <div
      className="home__container h-100 position-relative"
      id="home__container"
      data-test="dashboard-panel-home-container"
    >
      <div className="dashboard-panel">
        <div
          className={`dashboard-panel__container ${
            props.isSidebarLocked ? 'isLocked' : ''
          }`}
        >
          <Layout
            ref={layoutRef}
            model={model}
            factory={(node) => factory(node) as any}
            iconFactory={iconFactory}
            onModelChange={onModelChange}
            icons={{
              popout: null,
            }}
            supportsPopout={false}
          />
        </div>

        <div
          className="modal fade disconnect-fade show"
          role="dialog"
          style={{ display: loading ? 'block' : 'none' }}
        />

        <Sidebar
          key={'sidebar'}
          onTabCreate={props.tabCreate}
          onRecentActionsDrag={onRecentActionsDrag}
          onSingleDragFromSidemenu={onSingleDragFromSidemenu}
          onFavoriteSelectDrag={onFavoriteSelectDrag}
        />
      </div>
      <Popup onVisible={setVisible} visible={visible} position={popupPosition}>
        <MarketsheetSelectForDock
          triggerOnClick={true}
          hideRadioButtons={true}
          customId="addMarket"
          preselectedType={
            activeDockType === DockType.ProductMarket ? 'product' : 'instrument'
          }
          onSelectComponent={onMarketSelect}
          expanded={true}
        />
      </Popup>
      <OrderSidebarComponent />
      <PriceAlarmModal />
    </div>
  )
}

export default DashboardPanel
