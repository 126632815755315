import {MasterDataId} from "../../../../../main/models/application";
import {Cell, IMarket} from "../../../models/market";
import * as React from "react";
import {hashFn} from "../../../../utils/selectors/agrSelector";
import {createMasterDataIdString, getOrderbookContracts} from "../../../../../orderbook/selectors/contracts";
import orderBookstore from "../../../../../orderbook/store/orderbooks";
import { createColumnClass, createColumnLayers, createColumnStyle, getColumns } from '../helper/helper'
import {Restriction} from "../../../../../orders/models/orders";
import {formatCell} from "../uiOrderbookFormatters";
import {ColumnEntry} from "./TableColumns";
import {EmptyRows} from "./EmptyRows";
import {connect} from "react-redux";
import {Price} from "../../../../../orderbook/models/orderbooks";

export interface AdditionalExpiryProps {
    entries: any[];
    column: any;
    depth: number;
    onContextMenu: any;
    onClickAction: any;
    onTooltipAction: (e: any, price: Price, visible: boolean) => void;
    contractId: MasterDataId;
    localizeOptions: any;
    anonymizeMarketDataEnabled: boolean;
    colors: any;
    compactColumnsEnabled: boolean;
    market: IMarket;
}
export interface AdditionalExpiryState {}

class AdditionalExpiryRowsComponent extends React.Component<AdditionalExpiryProps, AdditionalExpiryState> {
    constructor(props: AdditionalExpiryProps) {
        super(props);

        this.state = {
        };
    }

    shouldComponentUpdate(nextProps: AdditionalExpiryProps) {
        if (nextProps.depth !== this.props.depth || hashFn(nextProps.entries) !== hashFn(this.props.entries)) {
            return true;
        }
        if (hashFn(nextProps.colors) !== hashFn(this.props.colors)) {
            return true;
        }
        return false;
    }

    render():  JSX.Element {
        const contract = getOrderbookContracts(orderBookstore.getState())[
            createMasterDataIdString(this.props.contractId)
            ];

        const generalLayers = createColumnLayers(
            this.props.column,
            this.props.anonymizeMarketDataEnabled,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            contract ? contract.expiry.type : undefined,
            undefined
        );

        const rows = this.props.entries.map((entry: Price, index: number) => {
            const entryValue = entry[this.props.column.name];
            // Don't use tradeId, use $index. Two trades may have same tradeId if one is buy and other sell.
            const id = this.props.column.group !== 'trades' ? entry.id : entry.$index;
            const ownOrder =
                this.props.column.group !== 'trades' ? entry.order?.ownOrder : undefined;
            const readOnly =
                this.props.column.group !== 'trades' ? entry.readonly : undefined;
            const suspended =
                this.props.column.group !== 'trades' ? entry.order?.suspended : undefined;
            const isAON =
                this.props.column.group !== 'trades'
                    ? entry.order?.restriction === Restriction.AON
                    : undefined;
            const isPreview = entry.order?.orderId === 0;
            const isQuoteRequest = !!entry.quoteRequest;
            const isImplied = entry.implied;
            const isRouted = entry.order?.routed;
            const counterparty = entry.counterparty;
            const columnClass = createColumnClass(
                this.props.column,
                entryValue,
                contract?.expiry.type
            );
            const columnStyle = createColumnStyle(
                this.props.column,
                this.props.anonymizeMarketDataEnabled,
                this.props.colors,
                isQuoteRequest,
                ownOrder,
                readOnly,
                suspended,
                isPreview,
                isImplied,
                isRouted,
                counterparty?.toLowerCase()
            );
            const columnLayers = createColumnLayers(
                this.props.column,
                this.props.anonymizeMarketDataEnabled,
                isQuoteRequest,
                ownOrder,
                readOnly,
                suspended,
                isAON,
                isPreview,
                isImplied,
                isRouted,
                contract ? contract.expiry.type : undefined,
                counterparty?.toLowerCase()
            );

            const columnMap = getColumns(this.props.market, this.props.compactColumnsEnabled);
            const combinedColumns = this.props.column.combine ? columnMap[this.props.column.group] : undefined;

            let defaultCell: Cell = {
                className:  `entry`,
                onContextMenu: (e: any) => this.props.onContextMenu(e, index),
                onClick: (e: any) => {},
                style: { marginBottom: 0 },
                value: '',
                localizeOptions: {
                    // this is used as the default. Within formatCell these options may be exchanged for a type specific ones.
                    style: 'decimal'
                },
                symbol: ''
            };
            const contextData = {
                contract: contract,
                index: index,
                entries: this.props.entries,
                source: entry.order,
                localizeOptions: this.props.localizeOptions,
                anonymizeMarketDataEnabled: this.props.anonymizeMarketDataEnabled,
                compactColumnsEnabled: this.props.compactColumnsEnabled
            };
            if (this.props.entries?.length - 1 === index) {
                return null;
            }
            const cells = [];
            const columns = combinedColumns ? combinedColumns : [this.props.column];
            columns.forEach(column => {
                const cell = formatCell({
                      ...defaultCell,
                      value: entry[column.name],
                  }, {
                      ...contextData, column: column, columns: columns.map(col => col.name),
                  },
                )
                cell.localizeOptions = Object.assign(cell.localizeOptions, this.props.localizeOptions);

                cells.push(cell);
            });

            const impliedTooltip = isImplied ? (e: any, visible: boolean) => this.props.onTooltipAction(e, entry, visible) : null;

            return (
                <React.Fragment key={`additional-${this.props.column.name}-${createMasterDataIdString(this.props.contractId)}-${index}`}>
                    <tr>
                        <td className={`${columnClass}${this.props.compactColumnsEnabled ? ' compact' : ''}`} style={columnStyle}>
                            <ColumnEntry
                                onContextMenu={(e: any) => this.props.onContextMenu(e, index)}
                                onClick={(e: any) => this.props.onClickAction(e, index)}
                                onTooltipAction={impliedTooltip}
                                value={entry[this.props.column.name]}
                                layers={columnLayers}
                                combinedColumns={combinedColumns}
                                cells={cells}
                            />
                        </td>
                    </tr>
                    {index === this.props.entries.length - 1 && this.props.depth > this.props.entries.length ? (
                        <React.Fragment key={id + '-empty'}>
                            <EmptyRows
                                count={this.props.depth - this.props.entries.length}
                                latestIndex={index}
                                className={createColumnClass(
                                    this.props.column,
                                    false
                                )}
                                onContextMenu={this.props.onContextMenu}
                                onClickAction={this.props.onClickAction}
                                layers={columnLayers}
                                compactColumnsEnabled={this.props.compactColumnsEnabled}
                            />
                        </React.Fragment>
                    ) : null}
                </React.Fragment>
            );
        }).filter((entry: any, index: number, array: any[]) => index < this.props.depth);

        return (
            <table className="inner">
                <tbody>
                {rows.length ? (
                    rows
                ) : (
                    <EmptyRows
                        latestIndex={0}
                        count={this.props.depth}
                        className={createColumnClass(
                            this.props.column,
                            false
                        )}
                        onContextMenu={this.props.onContextMenu}
                        onClickAction={this.props.onClickAction}
                        layers={generalLayers}
                        compactColumnsEnabled={this.props.compactColumnsEnabled}
                    />
                )}
                </tbody>
            </table>
        );
    }
}

const mapStateToProps = (state: AdditionalExpiryState) => ({
});

const mapDispatchToProps = {
};

export const AdditionalExpiryRows = connect<any, any, any>(
    mapStateToProps,
    mapDispatchToProps
)(AdditionalExpiryRowsComponent);
