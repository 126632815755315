import {ActionsObservable, combineEpics, StateObservable} from 'redux-observable';
import * as Rx from 'rxjs';
import OrderbooksService from '../services/orderbooks';
import {State} from '../../main/reducers/rootReducer';
import * as models from '../models/orderbooks';
import {ActionTypes as Authentication} from '../../authentication/actions/authentication';
import {ActionTypes as Connection} from '../../authentication/actions/connection';
import {ActionTypes as Market} from '../../shared/ui/actions/market';
import {ActionTypes as Chart} from '../../shared/ui/actions/chart';
import {ActionTypes as Contracts} from '../../orderbook/actions/contracts';
import {
    ActionTypes,
    contractMatrixLoadFailure,
    contractMatrixLoadSuccess,
    orderbooksLoad,
    orderbooksLoadFailure,
    orderbooksLoadSuccess,
    orderbookUnsubscribed,
    updatePrices
} from '../actions/orderbooks';
import {
    getDocksToChangedInstruments as getDocksWithChangedInstruments,
    getDocksToChangedProducts,
    getOrderbookInstruments,
    getSubscribedDocksToInstruments,
    getSubscribedInstrumentsWithDocks
} from '../selectors/orderbooks';
import {getInstrumentsToUnsubscribe, getProductsToUnsubscribe} from '../../shared/ui/selectors/ui';
import {catchError, filter, map, switchMap, takeUntil} from 'rxjs/operators';
import orderBookStore from '../store/orderbooks';
import {v1} from 'uuid';
import {ComponentType} from '../../shared/ui/models/component';
import {createMasterDataIdString} from '../selectors/contracts';
import {MasterDataId} from "../../main/models/application";
import {getOrderbookProducts} from "../selectors/products";

const orderbooksService = new OrderbooksService();

export const loadOrderbooks: any = (
  actions$: ActionsObservable<any>,
) => {
  return actions$.pipe(
    filter(
      action =>
        action.type === Authentication.AUTHENTICATION_SUCCESS ||
        action.type === Authentication.RELOGIN_SUCCESS
    ),
    switchMap(action => {
      return orderbooksService.inquireOrderbooks().pipe(
        map((content: any) => {
          return orderBookStore.dispatch(
            orderbooksLoadSuccess(content.orderbooks)
          );
        }),
        catchError(error => {
          return Rx.of(orderBookStore.dispatch(orderbooksLoadFailure(error)));
        }),
        takeUntil(
          actions$.pipe(filter(act => act.type === Connection.DISCONNECT  || action.type === Connection.CONNECTION_LOST))
        )
      );
    })
  );
};

export const reloadOrderbooks: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Authentication.RELOGIN_SUCCESS),
    map(action => action.payload),
    switchMap(action => {
      const dockIdsToInstrumentIds = getSubscribedDocksToInstruments(state.value);
      const dockIdsToProductIds = getDocksToChangedProducts(state.value, []); // empty = all products
      const dockToComponentType = getSubscribedInstrumentsWithDocks(state.value).reduce((acc, market) => { acc[market.dockId] = market.type; return acc; }, {});

      return Object.keys(dockIdsToInstrumentIds).filter(dockId => dockToComponentType[dockId] !== ComponentType.Product).map(dockId => {
        const products = orderBookStore.getState().orderbook.products.entities;
        return orderbooksLoad(dockIdsToInstrumentIds[dockId], dockId, dockToComponentType[dockId], v1());
      }).concat(
          Object.keys(dockIdsToProductIds).map(dockId =>
            orderbooksLoad(dockIdsToProductIds[dockId], dockId, dockToComponentType[dockId], v1())
          )
      );
    })
  );
};

export const reloadOrderbooksWithContractStateChange: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(action => action.type === Contracts.CONTRACTS_STATE_CHANGED),
    switchMap(action => {
      const changedInstruments = Object.keys(action.contracts)
          .map(k => createMasterDataIdString(action.contracts[k].product.instrument))
          .filter((val, id, array) => {
            return array.indexOf(val) === id;
          }
      );
      const changedProducts = Object.keys(action.contracts)
          .map(k => createMasterDataIdString(action.contracts[k].product.id))
          .filter((val, id, array) => {
            return array.indexOf(val) === id;
          }
      );
      const dockIdsToInstrumentIds = getDocksWithChangedInstruments(state.value, changedInstruments);
      const dockIdsToProductIds = getDocksToChangedProducts(state.value, changedProducts);
      
      const dockToComponentType = getSubscribedInstrumentsWithDocks(state.value)
          .reduce((acc, market) =>
          {
              acc[market.dockId] = market.type;
              return acc;
          }, {});
      return Object.keys(dockIdsToInstrumentIds).map(dockId =>
        orderbooksLoad(dockIdsToInstrumentIds[dockId], dockId, dockToComponentType[dockId], v1())).concat(
          Object.keys(dockIdsToProductIds).map(dockId =>
            orderbooksLoad(dockIdsToProductIds[dockId], dockId, ComponentType.Product, v1()
        ))
      );
    }),
    catchError(e => { return Rx.of(e); })
  );
};

export const loadContractMatrix: any = (actions$: ActionsObservable<any>) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.LOAD_ORDERBOOKS),
    switchMap(action => {
      if (action.itemType === ComponentType.Instrument || action.itemType === ComponentType.MarketChart || action.itemType === ComponentType.InstrumentIntraday ) {
        orderbooksService.sendInstrumentContractMatrixRequest(<models.MarketsheetRequest> {
          itemIds: action.payload.itemIds,
          componentId: action.payload.dockId,
          correlationId: action.correlationId,
          periodTypes: action.periodTypes
        });
      } else if (action.itemType === ComponentType.Product ) {
        orderbooksService.sendProductContractMatrixRequest(<models.MarketsheetRequest> {
          itemIds: action.payload.itemIds,
          componentId: action.payload.dockId,
          correlationId: action.correlationId,
          periodTypes: action.periodTypes
        });
      }
      return Rx.empty();
    })
  );
};

export const subscribeContractMatrices: any = (
  actions$: ActionsObservable<any>,
) => {
  return actions$.pipe(
    filter(
      action =>
        action.type === Authentication.AUTHENTICATION_SUCCESS ||
        action.type === Authentication.RELOGIN_SUCCESS
    ),
    map(action => action.payload),
    switchMap(() => {
      return orderbooksService.inquireContractMatrixWs().pipe(
        map((content: models.MarketsheetResponse) => {
          orderBookStore.dispatch(contractMatrixLoadSuccess(content));
          return contractMatrixLoadSuccess(content);
        }),
        takeUntil(
          actions$.pipe(filter(action => action.type === Connection.DISCONNECT  || action.type === Connection.CONNECTION_LOST))
        ),
        catchError(error => {
          return Rx.of(
            orderBookStore.dispatch(contractMatrixLoadFailure(error))
          );
        })
      );
    })
  );
};

export const subscribeOrderbooks: any = (
  actions$: ActionsObservable<any>,
) => {
  return actions$.pipe(
    filter(
      action =>
        action.type === Authentication.AUTHENTICATION_SUCCESS ||
        action.type === Authentication.RELOGIN_SUCCESS
    ),
    map(action => action.payload),
    switchMap(() => {
      return orderbooksService.subscribeOrderbooks().pipe(
        map((content: any) => {
          const orderbooks = content.orderbooks
            ? content.orderbooks
            : [content.orderbook];
          return orderBookStore.dispatch(orderbooksLoadSuccess(orderbooks));
        }),
        takeUntil(
          actions$.pipe(filter(action => action.type === Connection.DISCONNECT  || action.type === Connection.CONNECTION_LOST))
        ),
        catchError(error => {
          return Rx.of(orderBookStore.dispatch(orderbooksLoadFailure(error)));
        })
      );
    })
  );
};

export const loadOrderbooksSuccess: any = (
  actions$: ActionsObservable<any>
) => {
  return actions$.pipe(
    filter(action => action.type === ActionTypes.LOAD_ORDERBOOKS_SUCCESS),
    map(action => action.payload),
    switchMap((action: models.Orderbook[]) => {
      const orderbookState = orderBookStore.getState();
      const instrumentIds = action
        .map(orderbook => {
          if (
            orderbookState.orderbook.contracts.entities[createMasterDataIdString(orderbook.contractId)]
          ) {
            return createMasterDataIdString(orderbookState.orderbook.contracts.entities[
                createMasterDataIdString(orderbook.contractId)
            ].instrumentId);
          }
          return '';
        })
        .filter(
          (instrumentId: string, index, self) =>
            instrumentId !== '' && self.indexOf(instrumentId) === index
        );

      return Rx.of(updatePrices(instrumentIds));
    })
  );
};

export const unsubscribeOrderbooks: any = (
  actions$: ActionsObservable<any>,
  state: StateObservable<State>
) => {
  return actions$.pipe(
    filter(
      action => action.type === Market.REMOVE
    ),
    map(action => action.payload),
    switchMap((content: { id: string; dockId: string }) => {

      const instrumentIds = getInstrumentsToUnsubscribe(state.value);
      const productIds = getProductsToUnsubscribe(state.value);

      if (instrumentIds) {
          const instruments = getOrderbookInstruments(orderBookStore.getState());
          const masterDataIds: MasterDataId[] = instrumentIds.map(i => instruments[i]?.id).filter(i => !!i);
          for (let i = 0; i < masterDataIds.length; i++) {
              if (masterDataIds[i]) {
                  orderbooksService.sendUnsubscribeInstrument({ instrumentId: masterDataIds[i], componentId: content.dockId });
              }
          }
      }
      if (productIds) {
          const products = getOrderbookProducts(orderBookStore.getState());
          const masterDataIds: MasterDataId[] = productIds.map(i => products[i]?.id).filter(i => !!i);
          for (let i = 0; i < masterDataIds.length; i++) {
              if (masterDataIds[i]) {
                  orderbooksService.sendUnsubscribeProduct({ productId: masterDataIds[i], componentId: content.dockId });
              }
          }
      }
      orderBookStore.dispatch(orderbookUnsubscribed());
      return Rx.of(orderbookUnsubscribed());
    })
  );
};

export const orderbooksEpic = combineEpics(
  loadOrderbooks,
  subscribeOrderbooks,
  loadContractMatrix,
  subscribeContractMatrices,
  loadOrderbooksSuccess,
  unsubscribeOrderbooks,
  reloadOrderbooks,
  reloadOrderbooksWithContractStateChange
);
