import { State } from '../../main/reducers/rootReducer';
import { createSelector } from 'reselect';
import { ITable } from '../../shared/ui/models/table';
import { IRequest, QuoteRequestState } from '../models/request';
import { sortTable } from '../../shared/utils/helper/sorter';
import { FilterType } from '../../shared/ui/models/tableSpecificFilters';
import orderBookStore from '../../orderbook/store/orderbooks';

const getRequestsState = (state: State) => state.requests;
const getConfigurationState = (state: State) => state.clientConfiguration;

export const isQuoteRequestEnabled = createSelector(
  getConfigurationState,
  s => { 
    return s.quoteRequestsEnabled && s.QUOTE_REQUESTS;
  }

);

export const getClientLogLevel = createSelector(
  getConfigurationState, s => s.clientLogLevel
);

export const isBasketOrderUploadEnabled = createSelector(
  getConfigurationState, s => {
    let enabled = s.basketOrderUploadEnabled;
    return enabled;
  }
);

export const isMatchPriceFeedOrdersEnabled = createSelector(
  getConfigurationState, s => s.matchPriceFeedOrdersEnabled
);

export const isAnonymizeMarketDataEnabled = createSelector(
  getConfigurationState, s => s.anonymizeMarketDataEnabled
);

export const isMatchOnlyModeEnabled = createSelector(
  getConfigurationState, s => {
    return s.matchOnlyModeEnabled;
  }
);

export const isOrderbookPreviewEnabled = createSelector(
  getConfigurationState, s => {
    return s.orderbookPreviewEnabled;
  }
);

export const isPushNotificationsEnabled = createSelector(
  getConfigurationState, s => s.pushNotificationsEnabled
);

export const getInstanceId = createSelector(
    getConfigurationState, s => s.instanceId
);

export const getRequestEntities = createSelector(
  getRequestsState,
  s => s.entities
);
export const getRequestIds = createSelector(getRequestsState, s => s.ids);
export const getAllRequests = createSelector(
  getRequestEntities,
  getRequestIds,
  (entities, ids) => ids.map(id => entities[id])
);

export const getQuoteRequestFormData = createSelector(getRequestsState, s => s.form);

export const isQuoteRequestFormVisible = createSelector(getRequestsState, s => s.form.visible);

export const getSelectedContractId = createSelector(getRequestsState, s => s.form.selectedContractId);

function applyFilters(requests: IRequest[], filters: { [key: string]: boolean }) {
  let filteredRequests = [...requests];
  const activeFilterKeys = Object.keys(filters).filter(k => filters[k]);
  if (activeFilterKeys.indexOf(FilterType.HIDE_INACTIVE_QUOTE_REQUESTS) !== -1) {
    let inactiveStates = [QuoteRequestState.ACCEPTED, QuoteRequestState.REJECTED, QuoteRequestState.TIMEOUT, QuoteRequestState.DELETED];
    filteredRequests = filteredRequests.filter(r => inactiveStates.indexOf(QuoteRequestState[r.state as QuoteRequestState]) === -1);
  }
  return filteredRequests;
}

export const getRequestRows = createSelector(
  [
    getAllRequests,
    (state: State, requestTable: ITable | undefined) => requestTable
  ],
  (requests, requestTable) => {
    if (requestTable) {
      const filteredRequests = applyFilters(requests, requestTable.filters);
      const _requests = filteredRequests.map((order: IRequest) => {
        return requestTable.columns.reduce((result: any, col) => {
          return Object.assign(result, {
            [col.name]: order[col.originalName]
          });
        }, {});
      });

      return sortTable(_requests, requestTable.sorting, requestTable.hiddenColumnNames);
    }
    return [];
  }
);

export const getSelectedContract = createSelector(
  getRequestsState,
  s => {
    const t = orderBookStore.getState().orderbook.contracts.entities;
    return !!t[s.form.selectedContractId]
      ? t[s.form.selectedContractId]
      : { name: 'N/A' };
  }
);
