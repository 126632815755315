import * as React from 'react';
import { Provider, connect } from 'react-redux';
import { RecentActionsComponent } from '../../../../../dashboard/components/recentActions'
import orderBookStore from '../../../../../orderbook/store/orderbooks';
import { State } from '../../../../../main/reducers/rootReducer';
import {
  getMarketsForDock,
  getMarketHiddenExpiries,
  getMarketExpandedExpiries,
  getMarketExpiryRowsLength,
  isMarketHeadlinesVisible,
  getVisibleColumnsCount, getOrderbookDepthLimit
} from '../../../selectors/ui';
import { DashboardComponent } from '../../../../../dashboard/models/dashboard';
import { IMarket } from '../../../models/market';
import {
  getColumnWidth,
  getDockFavoriteName,
  getDockScrollState,
  getExpiryOrderForDock, isDockVisible,
} from '../../../../../dashboard/selectors/dashboard'
import { getIsBorderTableActive, getFontColorsForMarket, getIsSeparateCellsActive } from '../../../../settings/selectors/selector';
import { isMatchPriceFeedOrdersEnabled, isAnonymizeMarketDataEnabled, isMatchOnlyModeEnabled, isQuoteRequestEnabled } from '../../../../../requests/selectors/requests';
import { getUserPermissions, isProfileModificationAllowed, getUser } from '../../../../../authentication/selectors/authetication';
import { getGeneralSettings } from 'js/shared/settings/selectors/settings';
import {getAvailableFavorites} from "../../../../favorite/selectors/favorite";
import {IFavorite} from "../../../../favorite/models/favorite";
import UiBookComponentGrid from './uiBookComponentGrid';
import { DockScrollState } from '../../../../../shared/dock/models/dock';
import { Debouncer } from 'js/shared/utils/components/debounce';
import store from 'js/main/store/store';
import { scrollContent } from 'js/shared/dock/actions/dock';

interface MarketViewProps {
  dockId: string;
  markets: IMarket[];
  hiddenExpiries: string[];
  expandedExpiries: { [key: string]: any };
  expiryRowsLength: { [key: string]: number };
  favoriteName: string;
  isTableBorderActive: boolean;
  isSeparateCells: boolean;
  onCreateChart: (e: any, component: DashboardComponent) => void;
  onCreateTable: (e: any, components: RecentActionsComponent[]) => void;
  matchPriceFeedOrdersEnabled: boolean;
  anonymizeMarketDataEnabled: boolean;
  matchOnlyModeEnabled: boolean;
  colors: { [key: string]: string };
  isHeadlinesVisible: boolean;
  permissions: string[];
  quoteRequestsEnabled: boolean;
  modificationAllowed: boolean;
  user: any;
  columnWidth: string;
  dockScroll: DockScrollState;
  visibleColumnCount: number;
  settings: { [key: string]: any };
  presetDepths: { [expiryCode: string]: number };
  favorites: IFavorite[];
  onAddMarket: (event, hiddenExpiries) => void;
  dockExpiryOrder: string[];
  dockVisible: boolean;
}
interface BookViewState {
  parent: HTMLElement;
}

export class UIMarketView extends React.Component<
  MarketViewProps,
  BookViewState
> {
  debouncer: Debouncer;
  parent: HTMLElement;
  constructor(props: MarketViewProps) {
    super(props);
    this.debouncer = new Debouncer();
    this.state = {
      parent: null
    }
  }

  componentDidMount(): void {
    const parent = document.querySelector('.dockId-' + this.props.dockId) as HTMLElement;
    parent?.addEventListener('scroll', this._handleScroll);
    this.setState((prevState) => {
      return {...prevState, parent};
    });
  }

  componentWillUnmount(): void {
    this.state.parent?.removeEventListener('scroll', this._handleScroll);
  }

  _handleScroll = (e) => {
    const dockSize = this.parent ? {width: this.parent.clientWidth, height: this.parent.clientHeight, ...this.props.dockScroll } : {width: 0, height: 0, top: 0, left: 0} 
   
    this.debouncer.debounce(() => {
      store.dispatch(scrollContent(this.props.dockId, {width: dockSize.width, height: dockSize.height, scrollLeft: e.target?.scrollLeft, scrollTop: e.target?.scrollTop}));
    }, 500);
  };
  
  render() {
    const {
      dockId,
      markets,
      hiddenExpiries,
      expandedExpiries,
      expiryRowsLength,
      favoriteName,
      isTableBorderActive,
      isSeparateCells,
      colors
    } = this.props;

    const dockSize = this.state.parent ? {
      width: this.state.parent.clientWidth, 
      height: this.state.parent.clientHeight, 
      tabTop: parseInt(('' + this.state.parent.style.top).replace('px', '')), 
      tabLeft: parseInt(('' + this.state.parent.style.left).replace('px', '')), 
      ...this.props.dockScroll } 
      
    : {width: 0, height: 0, top: 0, left: 0, tabTop: 0, tabLeft: 0}

    return (
      <Provider store={orderBookStore}>
        <UiBookComponentGrid
          dockId={dockId}
          markets={markets}
          hiddenExpiries={hiddenExpiries}
          favoriteName={favoriteName}
          isHeadlinesVisible={this.props.isHeadlinesVisible}
          modificationAllowed={this.props.modificationAllowed}
          anonymizeMarketDataEnabled={this.props.anonymizeMarketDataEnabled}
          settings={this.props.settings}
          favorites={this.props.favorites}
          expiryRowsLength={expiryRowsLength}
          presetDepths={this.props.presetDepths}
          colors={colors}
          onCreateChart={this.props.onCreateChart}
          onCreateTable={this.props.onCreateTable}
          dockSize={dockSize}
          onAddMarket={(event) => this.props.onAddMarket(event, hiddenExpiries)}
          isTableBorderActive={isTableBorderActive}
          dockExpiryOrder={this.props.dockExpiryOrder}
          dockVisible={this.props.dockVisible}
        />
      </Provider>
    );
  }
}

const mapStateToProps = (state: State, props: MarketViewProps) => ({
  markets: getMarketsForDock(state, props.dockId),
  hiddenExpiries: getMarketHiddenExpiries(state, props.dockId),
  expandedExpiries: getMarketExpandedExpiries(state, props.dockId),
  expiryRowsLength: getMarketExpiryRowsLength(state, props.dockId),
  favoriteName: getDockFavoriteName(state, props.dockId),
  isTableBorderActive: getIsBorderTableActive(state),
  isSeparateCells: getIsSeparateCellsActive(state),
  matchPriceFeedOrdersEnabled: isMatchPriceFeedOrdersEnabled(state),
  anonymizeMarketDataEnabled: isAnonymizeMarketDataEnabled(state),
  matchOnlyModeEnabled: isMatchOnlyModeEnabled(state),
  quoteRequestsEnabled: isQuoteRequestEnabled(state),
  permissions: getUserPermissions(state),
  colors: getFontColorsForMarket(state),
  isHeadlinesVisible: isMarketHeadlinesVisible(state, props.dockId),
  modificationAllowed: isProfileModificationAllowed(state),
  user: getUser(state),
  columnWidth: getColumnWidth(state, props.dockId),
  dockScroll: getDockScrollState(state, props.dockId),
  visibleColumnCount: getVisibleColumnsCount(state, props.dockId),
  settings: getGeneralSettings(state),
  presetDepths: getOrderbookDepthLimit(state, props.dockId),
  favorites: getAvailableFavorites(state),
  dockExpiryOrder: getExpiryOrderForDock(state, props.dockId),
  dockVisible: isDockVisible(state, props.dockId)
});

const mapDispatchToProps = {};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(UIMarketView);
