import { createStore, AnyAction, Store, applyMiddleware } from 'redux';
import { state, OrderbooksState } from '../reducers/combinedReducer';
import { loggerMiddleWare } from '../../main/middlewares/logger';
import { profilerMiddleware } from '../../main/middlewares/profiler';

export function configureStore() {
  const middleware = [loggerMiddleWare];
  // const middleware = [profilerMiddleware];
  // ALWAYS FALSE FOR TEST PURPOSE
  if (false && process.env.NODE_ENV !== 'production') {
    const store = createStore(state, applyMiddleware(...middleware));
    return store;
  } else {
    const store = createStore(state);
    return store;
  }
}

const orderBookStore: Store<OrderbooksState, AnyAction> = configureStore();

export default orderBookStore;
