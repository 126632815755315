import { getAll } from '../../../dashboard/selectors/dashboard'
import { State } from '../../../main/reducers/rootReducer'
import store from '../../../main/store/store'
import orderBookStore from '../../../orderbook/store/orderbooks'
import { createSelector } from 'reselect'
import { ComponentType } from '../models/component'
import { IMarket } from '../models/market'
import { argsSelectorCreator } from '../../utils/selectors/agrSelector'
import { getGeneralSettings } from '../../settings/selectors/selector'
import { createMasterDataIdString } from '../../../orderbook/selectors/contracts'

export const getUIState = (state: State) => {
  return state.ui
}

// Tabs
export const getTabs = createSelector(getUIState, (s) => {
  return s.tabs.entities
})
export const getTabIds = createSelector(getUIState, (s) => s.tabs.ids)
export const getTabsEntites = createSelector(getTabs, getTabIds, (tabs, ids) =>
  ids.map((id) => tabs[id])
)

export const getTabsCountInDocks = createSelector(getTabsEntites, (tabs) =>
  tabs.reduce((docks, tab) => {
    const newState = Object.assign({}, docks, {
      [tab.dockId]: tabs.filter((t) => t.dockId === tab.dockId).length,
    })

    return newState
  }, {})
)

const findTabsInDock = createSelector(
  [getTabsEntites, (state: State, dockId: string) => dockId],
  (tabs, dockId) => tabs.filter((tab) => tab.dockId === dockId)
)

export const getFilteredTabsForDock = argsSelectorCreator(
  [
    findTabsInDock,
    (state: State, dockId: string, filteredTabTypes: ComponentType[]) =>
      filteredTabTypes,
  ],
  (tabs, filteredTabs) =>
    filteredTabs
      ? tabs.filter((tab) => filteredTabs.indexOf(tab.type) !== -1)
      : tabs
)

export const getTabsForDock = argsSelectorCreator(
  [findTabsInDock],
  (tabs) => tabs
)

export const getTabNamesForDock = argsSelectorCreator(
  [getTabsForDock],
  (tabs) => tabs.map((t) => t.title)
)

// Tables
export const getTables = createSelector(getUIState, (s) => s.tables.entities)
export const getTableIds = createSelector(getUIState, (s) => s.tables.ids)
export const getTableEntites = createSelector(
  getTables,
  getTableIds,
  (tables, ids) => ids.map((id) => tables[id])
)

export const getTableIdByParentId = argsSelectorCreator(
  [getTableEntites, (state: State, parentId: string) => parentId],
  (tables, parentId) => {
    const table = tables.find((t) => t.parentId === parentId)
    return table ? table.id : null
  }
)

export const getTableForTab = argsSelectorCreator(
  [
    getTableEntites,
    (state: State, tabId: string, type: ComponentType) => {
      return { tabId: tabId, type: type }
    },
  ],
  (tables, args) =>
    tables.find(
      (table) => table.parentId === args.tabId && table.type === args.type
    )
)

// Markets
export const getMarkets = createSelector(getUIState, (s) => {
  return s.markets.entities
})
export const getMarketIds = createSelector(getUIState, (s) => s.markets.ids)
export const getMarketEntities = createSelector(
  getMarkets,
  getMarketIds,
  (markets, ids) => ids.map((id) => markets[id])
)

const findMarketByInstrumentId = createSelector(
  getMarketEntities,
  (state: State, instrumentId: string) => instrumentId,
  (markets, instrumentId) => markets.find((m) => m.itemId === instrumentId)
)

export const getMarketByInstrumentId = argsSelectorCreator(
  findMarketByInstrumentId,
  (markets) => markets
)

export const getMarketsCountInDocks = createSelector(
  getMarketEntities,
  (markets) =>
    markets.reduce((docks, market) => {
      const newState = Object.assign({}, docks, {
        [market.dockId]: markets.filter((t) => t.dockId === market.dockId)
          .length,
      })

      return newState
    }, {})
)

export const findMarketsInDock = argsSelectorCreator(
  [getMarketEntities, (state: State, dockId: string) => dockId],
  (markets, dockId) => {
    const m = markets
      .filter((market) => market.dockId === dockId)
      .sort((a, b) => a.priority - b.priority)
    return m
  }
)

export const getMarketsForDock = argsSelectorCreator(
  [findMarketsInDock],
  (markets) => markets
)

export const getVisibleColumnsCount = argsSelectorCreator(
  [findMarketsInDock, getGeneralSettings],
  (markets, settings) => {
    return markets.reduce((acc, m) => {
      const compactColumns = settings.compactColumns.value
      if (compactColumns) {
        acc += Object.keys(
          m.columns.reduce((combined, c) => {
            if (m.hiddenColumnNames.indexOf(c.group + '__' + c.name) === -1) {
              if (c.combine) {
                combined[c.group] = combined[c.group]
                  ? [...combined[c.group], c]
                  : [c]
              } else {
                combined[c.group + '__' + c.name] = [c]
              }
            }
            return combined
          }, {})
        ).length
      } else {
        acc = acc + (m.columns.length - m.hiddenColumnNames.length)
      }
      return acc
    }, 0)
  }
)

const findComputedMarketInstrumentIdsForDock = createSelector(
  [findMarketsInDock],
  (markets) =>
    markets.reduce((acc: { [key: string]: string[] }, market: IMarket) => {
      return {
        ...acc,
        marketIds:
          'marketIds' in acc ? [...acc['marketIds'], market.id] : [market.id],
          itemIds:
          'itemIds' in acc
            ? [...acc['itemIds'], market.itemId]
            : [market.itemId],
      }
    }, {})
)

export const getComputedMarketInstrumentIdsForDock = argsSelectorCreator(
  [findComputedMarketInstrumentIdsForDock],
  (ids) => ids
)

export const getMarketInstrumentIds = argsSelectorCreator(
  [getMarketEntities, (state: State, dockId: string) => dockId],
  (markets, dockId) => {
    const _market = markets.find((market) => market.dockId === dockId)
    return _market ? _market.itemId : []
  }
)

export const getDashboardMarketInstruments = argsSelectorCreator(
  getMarketEntities,
  (entities) =>
    entities
      .filter((market) => market.type === ComponentType.Instrument)
      .map((market) => market.itemId)
)

export const getDashboardMarketProducts = argsSelectorCreator(
  getMarketEntities,
  (entities) => {
    return entities
      .filter((market) => market.type === ComponentType.Product)
      .map((market) => market.itemId)
  }
)

export const isMarketHeadlinesVisible = argsSelectorCreator(
  [getMarketsForDock],
  (markets) => (markets && markets[0] ? markets[0].isHeadlinesVisible : true)
)

export const getMarketHiddenExpiries = argsSelectorCreator(
  [getMarketsForDock],
  (markets) =>
    markets.reduce(
      (acc: any, market) => {
        const marketExpiries = market.allExpiryKeys
        const marketHiddenExpiries = market.hiddenExpiryKeys

        const isInLocalHiddenExpiries = (expiry: any) =>
          marketHiddenExpiries.indexOf(expiry) !== -1
        const isInGlobalHiddenExpiries = (expiry: any) =>
          acc.hidden.indexOf(expiry) !== -1
        const isInGlobalVisibleExpiries = (expiry: any) =>
          acc.visible.indexOf(expiry) !== -1

        const visible = marketExpiries.filter((e: any) => {
          return (
            !isInLocalHiddenExpiries(e) &&
            (!isInGlobalHiddenExpiries(e) || acc.hidden.length === 0)
          )
        })

        const hidden = marketHiddenExpiries.filter((e: any) => {
          return !isInGlobalVisibleExpiries(e)
        })

        return {
          ...acc,
          hidden: Array.from(new Set([...[...acc.hidden, ...hidden]])),
          visible: Array.from(new Set([...[...acc.visible, ...visible]])),
        }
      },
      {
        hidden: [],
        visible: [],
      }
    ).hidden
)

const findMarketExpandedExpiries = createSelector(
  [getMarketsForDock],
  (markets) =>
    markets.reduce((acc: any, market) => {
      const expandedExpiries = market.expandedExpiries

      return {
        ...acc,
        ...expandedExpiries,
      }
    }, {})
)

export const getMarketExpandedExpiries = argsSelectorCreator(
  [findMarketExpandedExpiries],
  (expandedExpiries) => expandedExpiries
)

export const getMarketExpiryRowsLength = argsSelectorCreator(
  [getMarketsForDock],
  (markets) =>
    markets.reduce((acc: any, market: IMarket) => {
      return { ...acc, ...market.expiryRowsLength }
    }, {})
)

export const getMarketsToProfileSave = createSelector(
  [getMarketEntities],
  (markets) => markets
)

// Charts
export const getCharts = createSelector(getUIState, (s) => s.charts.entities)
export const getChartIds = createSelector(getUIState, (s) => s.charts.ids)
export const getChartEntites = createSelector(
  getCharts,
  getChartIds,
  (charts, ids) => ids.map((id) => charts[id])
)

export const getChartDataVersion = createSelector(
  getUIState,
  (s) => s.charts.dataVersion
)

export const getChartsCountInDocks = createSelector(getChartEntites, (charts) =>
  charts.reduce((docks, chart) => {
    const newState = Object.assign({}, docks, {
      [chart.dockId]: charts.filter((c) => c && c.dockId === chart.dockId)
        .length,
    })

    return newState
  }, {})
)

const findChartsForDock = argsSelectorCreator(
  [getChartEntites, (state: State, dockId: string) => dockId],
  (charts, dockId) =>
    charts
      .filter((chart) => chart && chart.dockId === dockId)
      .sort((a, b) => a.priority - b.priority)
)

export const getChartsForDock = argsSelectorCreator(
  [findChartsForDock],
  (charts) => charts
)

export const getMarketForChart = createSelector(
  [getMarketEntities, (state: State, marketId: string) => marketId],
  (markets, marketId) => {
    const market = markets.find((m) => m.id === marketId)
    return market ? market : []
  }
)

export const getChartsToProfileSave = createSelector(
  [getChartEntites],
  (charts) => charts
)

export const getDashboardChartInstruments = argsSelectorCreator(
  getChartEntites,
  (entities) =>
    entities
      .map((chart: any) => (chart && chart.itemId ? chart.itemId : null))
      .filter((id) => id !== null)
)

export const getInstrumentsToUnsubscribe = createSelector(getUIState, (s) =>
  s.markets.toUnsubscribeInstruments.concat(s.charts.toUnsubscribe)
)

export const getProductsToUnsubscribe = createSelector(
  getUIState,
  (s) => s.markets.toUnsubscribeProducts
)

// Global
export const getGlobals = createSelector(getUIState, (s) => s.global)

export const getModalPositionClass = createSelector(
  [getGlobals, (state: State, modalId: string) => modalId],
  (globals, modalId) => {
    return globals && globals.modals && globals.modals[modalId]
      ? globals.modals[modalId].positionClass
      : ''
  }
)

/**
 *
 */
export const findMarketPresetDepths = createSelector(
  [
    getMarketEntities,
    (state: State, dockId: string, expiryCode: string) => {
      return { dockId, expiryCode }
    },
  ],
  (markets: IMarket[], params) => {
    const expiries: { [expiryCode: string]: number } = markets
      // filter markets in dock
      .filter((market: IMarket) => market.dockId === params.dockId)
      // join their depth preferences
      .reduce((acc: { [expiryCode: string]: number }, market: IMarket) => {
        return { ...acc, ...market.depths }
      }, {})
    return expiries
  }
)

export const getContractDepths = createSelector(
  [getUIState, (state: State, dockId: string) => dockId],
  (uiState, dockId) => {
    const markets: IMarket[] = Object.keys(uiState.markets.entities)
      .map((key) => uiState.markets.entities[key])
      .filter((m) => m.dockId === dockId)
    if (markets.length === 0) {
      return {}
    }
    return getDepthsForMarket(markets[0])
  }
)

const getDepthsForMarket = (market: IMarket) => {
  const depths = market.depths
  const expiryRowsLength = market.expiryRowsLength
  const expandedExpiries = Object.keys(market.expandedExpiries)
  const hiddenExpiryKeys = market.hiddenExpiryKeys
  const matrixInDock =
    orderBookStore.getState().orderbook.contractMatrixes.entities[market.dockId]
  if (!matrixInDock) {
    return {}
  }

  const contractMatrix = Object.keys(matrixInDock).map(
    (key) => matrixInDock[key]
  )
  const contractDepths: { [contract: string]: number } = {}

  for (let i = 0; i < contractMatrix.length; i++) {
    const expiries = Object.keys(contractMatrix[i].expiries)
      .map((key) => contractMatrix[i].expiries[key].map(expiry => { return {...expiry, typeWithSpread: key}}))
      .reduce((acc, array) => acc.concat(array), [])
    const rowCounter = { ...expiryRowsLength }

    for (let expiryIndex = 0; expiryIndex < expiries.length; expiryIndex++) {
      const expiry = expiries[expiryIndex]

      const contract = createMasterDataIdString(
        contractMatrix[i].expiryToContract[expiry.code]
      )

      let allowAdd = hiddenExpiryKeys.indexOf(expiry.typeWithSpread) < 0
      if (
        rowCounter[expiry.typeWithSpread] !== undefined &&
        rowCounter[expiry.typeWithSpread] > 0
      ) {
        rowCounter[expiry.typeWithSpread] = rowCounter[expiry.typeWithSpread] - 1
      } else if (rowCounter[expiry.typeWithSpread] !== undefined) {
        allowAdd = false
      }

      if (allowAdd) {
        let depth = 1
        if (expandedExpiries && expandedExpiries.indexOf(expiry.code) > -1) {
          if (
            depths &&
            depths[expiry.code] &&
            depths[expiry.code] < Infinity
          ) {
            depth = depths[expiry.code]
          } else {
            depth = 0 // expanded
          }
        }
        contractDepths[contract] = depth
      } else {
        contractDepths[contract] = -1
      }
    }
  }
  return contractDepths
}

// hidden dock has all depths set to -1
const getDepthsForHiddenDock = (dockId: string) => {
  const matrixInDock =
    orderBookStore.getState().orderbook.contractMatrixes.entities[dockId]

  if (!matrixInDock) {
    return {}
  }
  const contractMatrix = Object.keys(matrixInDock).map(
    (key) => matrixInDock[key]
  )
  const contractDepths: { [contract: string]: number } = {}

  for (let i = 0; i < contractMatrix.length; i++) {
    const expiries = Object.keys(contractMatrix[i].expiries)
      .map((key) => contractMatrix[i].expiries[key].map(expiry => { return {...expiry, typeWithSpread: key}}))
      .reduce((acc, array) => acc.concat(array), [])

    for (let expiryIndex = 0; expiryIndex < expiries.length; expiryIndex++) {
      const expiry = expiries[expiryIndex]

      const contract = createMasterDataIdString(
        contractMatrix[i].expiryToContract[expiry.code]
      )
      contractDepths[contract] = -1
    }
  }
  return contractDepths
}

// Returns contract depths for docks which IDs are the keys of tabVisibility map
// can be called after visibility of docks changes (flexlayout tabs actions)
export const getContractDepthForDocks = (tabVisibility: {[dockId: string]: boolean}) => {
  const depths = {}

  for (let dockId in tabVisibility) {
    mergeDepths(dockId, tabVisibility[dockId], depths)
  }
  removeInvisibleContracts(depths)
  return depths;
}

// Returns contract depths for all docks in the dashboard. Dock visibility is determined from hidden attribute of each dock
// Can be called after loading dashboard when visibility is assigned to all of the docks.
export const getContractDepthForAllDocks = (state: State) => {
  const depths = {}
  const docks = getAll(state)
  for (const dock of docks) {
    mergeDepths(dock.id, !dock.hidden, depths)
  }
  removeInvisibleContracts(depths)
  return depths;
}

function removeInvisibleContracts(depths) {
  const contractIds = Object.keys(depths)
  for (const contractId of contractIds) {
    if (depths[contractId] === -1) {
      delete depths[contractId]
    }
  }
}

function mergeDepths(dockId: string, visible: boolean, depths: {}) {
  const markets = getMarketsForDock(store.getState(), dockId)
  if (markets.length > 0) {
    const marketDepths = visible ? getDepthsForMarket(markets[0]) : getDepthsForHiddenDock(dockId)
    for (const contractId in marketDepths) {
      if (depths[contractId] === undefined || (depths[contractId] !== 0 && marketDepths[contractId] > depths[contractId])) {
        depths[contractId] = marketDepths[contractId]
      }
    }
  }
}

export const getOrderbookDepthLimit = argsSelectorCreator(
  [
    findMarketPresetDepths,
    (state: State, dockId: string, expiryCode: string) => {
      return { dockId, expiryCode }
    },
  ],
  (depths) => {
    return depths
  }
)

export const getChartThemes = createSelector(getUIState, (s) => {
  return s.charts.themes
})

export const getChartDrawings = createSelector(getUIState, (s) => {
  return s.charts.drawings
})

export const getChartViews = createSelector(getUIState, (s) => {
  return s.charts.views
})
