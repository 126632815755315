import { createMemoryHistory } from 'history';
import store from '../store/store';
import { LOCATION_CHANGE } from '../../authentication/reducers/location';

const history = createMemoryHistory();

history.listen((location) => {
  store.dispatch({
    type: LOCATION_CHANGE,
    payload: { location },
  });
});

export default function locationMiddleware(store) {
  return function(next) {
    return function(action) {
      const returnValue = next(action);

      return returnValue;
    };
  };
}