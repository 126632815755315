import { LogAction } from '../../../main/middlewares/logger';
import { I18n } from 'react-redux-i18n';
import { LogLevel } from '../../logger/models/logger';
import { Venue } from '../models/venue';

export enum ActionTypes {
  LOAD = '[Venues] Load venues',
  SUCCESS = '[Venues] Load venues success'
}

export interface LoadVenuesAction extends LogAction {
  type: ActionTypes.LOAD;
}

export interface LoadVenuesSuccesAction extends LogAction {
  type: ActionTypes.SUCCESS;
  payload: Venue[];
}

export function loadVenues(): LoadVenuesAction {
  return {
    type: ActionTypes.LOAD,
    logLevel: LogLevel.DEBUG,
    logMessage: I18n.t('venues.log.load', {
      
    })
  };
}

export function successLoadVenues(venues: Venue[]): LoadVenuesSuccesAction {
  return {
    type: ActionTypes.SUCCESS,
    payload: venues,
    logLevel: LogLevel.DEBUG,
    logMessage: I18n.t('venues.log.success')
  };
}

export type Action =
  LoadVenuesAction |
  LoadVenuesSuccesAction
;