import * as React from 'react'
import { connect } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { setModalPosition } from '../../ui/actions/global'
import { State } from '../../../main/reducers/rootReducer'
import { getModalPositionClass } from '../../ui/selectors/ui'
import { Popover, Overlay } from 'react-bootstrap'
import { ESCAPE_KEY } from '../../../commonConstants'

export interface KeyboardShortcut {
  key: string // Text, for example 'Escape', 'F1', 'Tab', 'S' ...
  ctrl?: boolean
  alt?: boolean
  shift?: boolean
  meta?: boolean // Windows or equivalent key
  handler: () => void
}

interface ModalWindowProperties {
  id: string // Id globally unique for each modal
  title: string
  isOpen: boolean

  onModalClose: () => void
  keyboardShortcuts?: KeyboardShortcut[] // This can be used to override some default handlers like 'Ctlr+S', but for example not 'Ctrl+W'.
  positionClass: string
  setModalPosition: (modalId: string, positionClass: string) => void
  children: React.ReactElement
}

interface ModalWindowState {
  positionOverlayOpen: boolean
  positionOverlayTarget: any
}

class ModalWindow extends React.Component<
  ModalWindowProperties,
  ModalWindowState
> {
  private static modalWindows: ModalWindow[] = []
  private ref: any

  private positionButtonRef: (target: any) => void

  static globalHandleKeyDown(event: any) {
    ModalWindow.modalWindows.forEach((modalWindow) => {
      if (modalWindow.props.isOpen) {
        modalWindow.handleKeyDown(event)
      }
    })
  }

  constructor(props: ModalWindowProperties) {
    super(props)

    this.ref = React.createRef()

    this.ref = React.createRef();

    this.positionButtonRef = (target: any) =>
      this.setState((state) => ({
        ...state,
        positionOverlayTarget: target,
      }))
    this.state = {
      positionOverlayOpen: false,
      positionOverlayTarget: null,
    }
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handlePositionClick = this.handlePositionClick.bind(this)
    this.handleShowPositionPopover = this.handleShowPositionPopover.bind(this)
  }

  componentDidMount() {
    if (ModalWindow.modalWindows.length === 0) {
      window.addEventListener('keydown', ModalWindow.globalHandleKeyDown)
    }
    ModalWindow.modalWindows.push(this)
  }

  componentWillUnmount() {
    ModalWindow.modalWindows = ModalWindow.modalWindows.filter(
      (that) => this !== that
    )
    if (ModalWindow.modalWindows.length === 0) {
      window.removeEventListener('keydown', ModalWindow.globalHandleKeyDown)
    }
  }

  handleKeyDown(event: any) {
    for (const shortcut of this.props.keyboardShortcuts || []) {
      if (
        shortcut.key.toUpperCase() === event.key.toUpperCase() &&
        !!shortcut.ctrl === event.ctrlKey &&
        !!shortcut.alt === event.altKey &&
        !!shortcut.shift === event.shiftKey &&
        !!shortcut.meta === event.metaKey
      ) {
        shortcut.handler()
        event.preventDefault()
        return
      }
    }

    // Default shortcut - 'Escape' to close the modal.
    if (event.key === ESCAPE_KEY) {
      this.handleClose()
    }
  }

  handleClose() {
    this.handleShowPositionPopover(false)
    this.props.onModalClose()
  }

  handlePositionClick(positionClass: string) {
    this.props.setModalPosition(this.props.id, positionClass)
  }

  handleShowPositionPopover(show: boolean) {
    this.setState((state) => ({
      ...state,
      positionOverlayOpen: show,
    }))
  }

  render() {
    const positionButton = (positionClass: string, iconClass: string) => (
      <button onClick={() => this.handlePositionClick(positionClass)}>
        <i
          className={
            'oi oi-' +
            iconClass +
            (this.props.positionClass === positionClass ? ' active' : '')
          }
        />
      </button>
    )

    const positionPopover = (
      <Overlay
        show={this.state.positionOverlayOpen}
        target={this.state.positionOverlayTarget}
        placement="bottom"
        container={this.ref.current}
        containerPadding={20}
      >
        <Popover
          id={'popover-positioned-bottom-' + this.props.id}
          className="show position-picker"
        >
          <div className="modal-header">
            <Translate value="modal.positionTitle" tag={'h3'} />
            <div>
              <button
                type="button"
                onClick={() => this.handleShowPositionPopover(false)}
                aria-label={I18n.t('modal.close')}
                title={I18n.t('modal.close')}
              >
                <i className="oi oi-x" />
              </button>
            </div>
          </div>

          <div className="modal-body">
            <div>
              {positionButton(
                'position-left position-top',
                'arrow-circle-left rotate-45'
              )}
              {positionButton('position-top', 'arrow-circle-top')}
              {positionButton(
                'position-right position-top',
                'arrow-circle-top rotate-45'
              )}
            </div>
            <div>
              {positionButton('position-left', 'arrow-circle-left')}
              {positionButton('', 'target')}
              {positionButton('position-right', 'arrow-circle-right')}
            </div>
            <div>
              {positionButton(
                'position-left position-bottom',
                'arrow-circle-bottom rotate-45'
              )}
              {positionButton('position-bottom', 'arrow-circle-bottom')}
              {positionButton(
                'position-right position-bottom',
                'arrow-circle-right rotate-45'
              )}
            </div>
          </div>
        </Popover>
      </Overlay>
    )

    return this.props.isOpen ? (
      <div
        className={
          'modal meet-modal ' +
          this.props.positionClass +
          ' modal-' +
          this.props.id
        }
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <Translate
                value={this.props.title}
                tag={'h3'}
                className="modal-title"
              />

              <div className="modal-header__actions" ref={this.ref}>
                <button
                  ref={this.positionButtonRef}
                  className="change-position"
                  aria-label={I18n.t('modal.position')}
                  title={I18n.t('modal.position')}
                  onClick={() =>
                    this.handleShowPositionPopover(
                      !this.state.positionOverlayOpen
                    )
                  }
                >
                  <i className="oi oi-move" />
                </button>

                <button
                  onClick={() => this.handleClose()}
                  data-dismiss="modal"
                  aria-label={I18n.t('modal.close')}
                  title={I18n.t('modal.close')}
                >
                  <i className="oi oi-x" />
                </button>

                {positionPopover}
              </div>
            </div>
            <div className="modal-body">{this.props.children}</div>
            <button
              className="btn btn-cancellation w150 modal-close general-button"
              data-dismiss="modal"
              onClick={this.handleClose}
            >
              {I18n.t('modal.cancel')}
            </button>
          </div>
        </div>
      </div>
    ) : null
  }
}

const mapStateToProps = (state: State, props: ModalWindowProperties) => ({
  positionClass: getModalPositionClass(state, props.id),
})

const mapDispatchToProps = {
  setModalPosition: setModalPosition,
}

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(ModalWindow)
