import { createStore, applyMiddleware, compose, AnyAction, Store } from 'redux';

import { createEpicMiddleware } from 'redux-observable';

import { state, State } from '../reducers/rootReducer';
import rootEpic from '../epics/rootEpics';
import { loadState, saveState } from '../services/localStorage';
import { getConnectedStatus } from '../../authentication/selectors/connection';
import { connectionStart } from '../../authentication/actions/connection';
import { getAuthorizedStatus } from '../../authentication/selectors/authetication';
import {
  loggerMiddleWare,
  serverLoggingMiddleware
} from '../middlewares/logger';
import { correlationMiddleware } from '../middlewares/correlationMiddleware';
import { viewMiddleware } from '../middlewares/viewMiddleware';
import locationMiddleware from '../middlewares/locationMiddleware';
import { config } from '../config';
import { profilerMiddleware } from '../middlewares/profiler';
import history from '../history'


const persistedState: State = loadState();

export function configureStore(preloadedState?: any) {
  const epicMiddleware = createEpicMiddleware();

  let middleware = [];
  if (process.env.NODE_ENV !== 'production') {
    middleware = [
      locationMiddleware,
      viewMiddleware,
      correlationMiddleware,
      epicMiddleware,
      // loggerMiddleWare, // uncomment to display debug information - actions with payloads and current store state
      // profilerMiddleware, // uncomment to enable action timers in profiler output
      serverLoggingMiddleware
    ];
  } else {
    middleware = [
      locationMiddleware,
      viewMiddleware,
      correlationMiddleware,
      epicMiddleware,
      serverLoggingMiddleware
    ];
  }

  const appstore = createStore(
    state,
    preloadedState,
    applyMiddleware(...middleware)
  );
  epicMiddleware.run(rootEpic);
  return appstore;
}
/**
 * Setup main store object
 */
/*
export const store: Store<State> = configureStore(persistedState);
  state,
  persistedState,
  applyMiddleware(...middleware)
);
*/
export const store: Store<State, AnyAction> = configureStore(persistedState);

/**
 * Listen for store change and save persisted state
 */
store.subscribe(() => {
  saveState({
    auth: store.getState().auth,
    connection: store.getState().connection
  });
});

if (
  getAuthorizedStatus(store.getState()) &&
  getConnectedStatus(store.getState())
) {
  // refresh logic - login data are preserved and connection/authentication logic is triggered immediately
  if (!config.ssoEnabled) {
    store.dispatch(connectionStart(store.getState().auth.loginData));
  }
}

export default store;