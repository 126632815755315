export enum ComponentType {
    Order = 'Order',
    Trade = 'Trade',
    Owntrade = 'Owntrade',
    Request = 'Request',
    Instrument = 'Instrument',
    InstrumentIntraday = 'InstrumentIntraday',
    Product = 'Product',
    MarketChart = 'MarketChart',
    Log = 'Log',
    BulkOrder = 'BulkOrder',
    BulkError = 'BulkError',
    TradeReport = 'TradeReport',
    PriceAlarm = 'PriceAlarm',
    Venues ='Venues',
    SettlementPrices = 'SettlementPrices',
}

export interface IComponent {
    id: string;
    title: string;
    type: ComponentType;
}
