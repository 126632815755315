import ModalWindow from 'js/shared/modalWindow/components/modalWindow'
import React from 'react'
import { ProfileForm } from './profileForm'

export const NewProfileModal = ({
  viewNames,
  isModalOpen,
  onModalClose,
  onFormSubmit,
}) => {
  return (
    <ModalWindow
      id="profile"
      onModalClose={() => onModalClose()}
      isOpen={isModalOpen}
      title={'profile.modal.title'}
    >
      <ProfileForm onSubmit={onFormSubmit} viewNames={viewNames} />
    </ModalWindow>
  )
}
