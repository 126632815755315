import { IDock, Pinning } from '../models/dock';
import { Position, Size } from '../../utils/models/grid';
import { DashboardMeta } from '../../../dashboard/models/dashboard';

export enum ActionTypes {
  CREATE = '[Dock] Create',
  LOAD = '[Dock] Load',
  REMOVE = '[Dock] Remove',
  ACTIVE = '[Dock] Active',
  INACTIVE = '[Dock] Inactive',
  COLLIDE_START = '[Dock] Start collide',
  COLLIDE_END = '[Dock] End collide',
  PIN_START = '[Dock] Pin start',
  PIN_END = '[Dock] Pin end',
  TOGGLE_FAVORITE = '[Dock] Save/Remove as favorite',
  SET_BLOCK_WIDTH = '[Dock] Set block width',
  SCROLL_CONTENT = '[Dock] Scroll content',
  SET_EXPIRY_ORDER = '[Dock] Set expiry order',
  SET_VISIBILITY = '[Dock] Set visibility'
}

export interface DockCreateAction {
  type: ActionTypes.CREATE;
  payload: IDock;
}

export interface DockLoadAction {
  type: ActionTypes.LOAD;
  payload: { docks: IDock[], meta: DashboardMeta };
}

export interface DockRemoveAction {
  type: ActionTypes.REMOVE;
  payload: string;
}

export interface DockActiveAction {
  type: ActionTypes.ACTIVE;
  payload: { id: string; isBeingDragged: boolean; };
}

export interface DockInactiveAction {
  type: ActionTypes.INACTIVE;
  payload: { id: string; position: Position; size: Size; };
}

export interface DockCollideStartAction {
  type: ActionTypes.COLLIDE_START;
  payload: { id: string, isRemoving: boolean };
}

export interface DockCollideEndAction {
  type: ActionTypes.COLLIDE_END;
  payload: string;
}

export interface DockPinStartAction {
  type: ActionTypes.PIN_START;
  payload: { id: string; pinning: Pinning };
}

export interface DockPinEndAction {
  type: ActionTypes.PIN_END;
  payload: string;
}

export interface DockToggleFavoriteAction {
  type: ActionTypes.TOGGLE_FAVORITE;
  payload: { id: string, favoriteName: string };
}

export interface DockToggleFavoriteAction {
  type: ActionTypes.TOGGLE_FAVORITE;
  payload: { id: string, favoriteName: string };
}

export interface SetBlockWidthAction {
  type: ActionTypes.SET_BLOCK_WIDTH;
  dockId: string;
  blockWidth: string;
}

export interface ScrollContentAction {
  type: ActionTypes.SCROLL_CONTENT;
  dockId: string;
  height: number;
  width: number;
  top: number;
  left: number;
}

export interface SetExpiryOrderAction {
  type: ActionTypes.SET_EXPIRY_ORDER;
  payload: {
    dockId: string;
    expiryKeys: string[];
  }
}

export interface SetDockVisibilityAction {
  type: ActionTypes.SET_VISIBILITY;
  payload: {
    dockId: string;
    visible: boolean;
  }
}

export function create(dock: IDock): DockCreateAction {
  return {
    type: ActionTypes.CREATE,
    payload: dock
  };
}

export function load(docks: IDock[], meta: DashboardMeta): DockLoadAction {
  return {
    type: ActionTypes.LOAD,
    payload: { docks, meta }
  };
}

export function remove(dockId: string): DockRemoveAction {
  return {
    type: ActionTypes.REMOVE,
    payload: dockId
  };
}

export function active(dockId: string, isDragged: boolean): DockActiveAction {
  return {
    type: ActionTypes.ACTIVE,
    payload: {id: dockId, isBeingDragged: isDragged}
  };
}

export function inactive(
  id: string,
  position: Position,
  size: Size
): DockInactiveAction {
  return {
    type: ActionTypes.INACTIVE,
    payload: { id, position, size }
  };
}

export function collideStart(id: string, isRemoving: boolean): DockCollideStartAction {
  return {
    type: ActionTypes.COLLIDE_START,
    payload: { id, isRemoving }
  };
}

export function collideEnd(id: string): DockCollideEndAction {
  return {
    type: ActionTypes.COLLIDE_END,
    payload: id
  };
}

export function pinStart(id: string, pinning: Pinning): DockPinStartAction {
  return {
    type: ActionTypes.PIN_START,
    payload: { id, pinning }
  };
}

export function pinEnd(id: string): DockPinEndAction {
  return {
    type: ActionTypes.PIN_END,
    payload: id
  };
}

export function toggleFavorite(
  id: string,
  favoriteName: string
): DockToggleFavoriteAction {
  return {
    type: ActionTypes.TOGGLE_FAVORITE,
    payload: { id, favoriteName }
  };
}

export function setBlockWidth(dockId: string, blockWidth: string): SetBlockWidthAction {
  return {
    type: ActionTypes.SET_BLOCK_WIDTH,
    dockId: dockId,
    blockWidth: blockWidth
  };
}

export function scrollContent(dockId: string, scrollInfo: {width: number; height: number; scrollTop: number; scrollLeft: number}): ScrollContentAction {
  return {
    type: ActionTypes.SCROLL_CONTENT,
    dockId: dockId,
    width: scrollInfo?.width,
    height: scrollInfo?.height,
    top: scrollInfo?.scrollTop,
    left: scrollInfo?.scrollLeft
  };
}

export function setDockExpiryKeysOrder(
  dockId: string,
  expiryKeys: string[]
): SetExpiryOrderAction {
  return {
    type: ActionTypes.SET_EXPIRY_ORDER,
    payload: { dockId, expiryKeys }
  };
}

export function setDockVisibility(
  dockId: string,
  visible: boolean
): SetDockVisibilityAction {
  return {
    type: ActionTypes.SET_VISIBILITY,
    payload: { dockId, visible }
  };
}

export type Action =
  | DockCreateAction
  | DockLoadAction
  | DockRemoveAction
  | DockActiveAction
  | DockInactiveAction
  | DockCollideStartAction
  | DockCollideEndAction
  | DockPinStartAction
  | DockPinEndAction
  | DockToggleFavoriteAction
  | SetBlockWidthAction
  | ScrollContentAction
  | SetExpiryOrderAction
  | SetDockVisibilityAction;
