export interface Venue {
  [key: string]: any;
  venue: string;
  status: VenueStatus;
}

export interface VenuesResponse {
  venues: Venue[];
}

export enum VenueStatus {
  CONNECTING = 'Connecting', 
  CONNECTED = 'Connected', 
  DISCONNECTED = 'Disconnected'
}
