import { Venue } from '../models/venue';
import { Action, ActionTypes } from '../actions/venues';

export interface State {
  venues: Venue[]
}

export const initialState: State = {
  venues: [],
};

export function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case ActionTypes.SUCCESS: {
      const _venues = action.payload.map((v, idx) => { return {...v, $index: idx}; });
      return {
        ...state,
        venues: _venues
      };
    }

    default: return state;
  }
}