interface Credentials {
  username: string;
  password: string;
  profile: string;
  token: string;
  code?: string;
  ssoToken?: string;
}

/**
 * encrypt password in credentials object
 * Using same process as backend crypto service: SHA-512 of string password+username, result in base64
 * @param credentials
 */
export function encryptPassword(credentials: Credentials): Credentials {
  const isTestEnv = process?.env?.NODE_ENV === 'test';
  let data;
  let returnObj = {...credentials};

  if (isTestEnv) {
    // Node.js environment for Jest tests
    (() => {
      const crypto = require('crypto');
      data = Buffer.from(credentials.password + credentials.username, 'utf-8');
      const hash = crypto.createHash('sha512');
      hash.update(data);
      returnObj.password = hash.digest('base64');
    })();
  } else {
    // Browser environment
    const encoder = new TextEncoder();
    data = encoder.encode(credentials.password + credentials.username);
    window.crypto.subtle.digest('SHA-512', data)
      .then(hash => {
        returnObj.password = btoa(String.fromCharCode.apply(null, new Uint8Array(hash)));
      })
      .catch(error => {
        const errorMsg = 'Error in encryption';

        console.error(errorMsg, error);
        throw new Error(errorMsg);
      });
  }

  return returnObj;
}