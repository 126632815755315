import * as React from 'react';
import {CSSProperties, useEffect, useRef, useState} from "react";
import {createPortal} from "react-dom";

interface MarketFilterIconProps {
  iconName: string;
  title: string;
  component: React.JSX.Element;
}

function MarketFilterIconComponent(props: MarketFilterIconProps) {
    const {iconName, title, component} = props;
    const ref = useRef(null);
    const iconRef = useRef(null);
    const [isMouseOver, setIsMouseOver] = useState(false);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (visible) {
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('touchstart', handleClickOutside);
        }
    }, [])

    const setVisibility = (isVisible) => {
        if (isVisible) {
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('touchstart', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        }
        setVisible(isVisible);
    }

    const handleClickOutside = (e) => {
        setVisible((currentVisible) => {
            if (
                currentVisible &&
                !ref?.current?.contains(e.target) &&
                !iconRef?.current?.contains(e.target)
            ) {
                setVisibility(false);
                return false;
            }
            return currentVisible;
        });
    }

    const style: CSSProperties = {
        display: visible ? 'block' : 'none',
    }

    return (
        <div ref={iconRef}>
            <li
                onMouseEnter={() => setIsMouseOver(true)}
                onMouseLeave={() => setIsMouseOver(false)}
                onClick={e => setVisibility(!visible)}
            >
                <i
                    data-test={`${iconName}-icon`}
                    className={`oi ${iconName} ${isMouseOver ? `active` : ``}`}
                    title={title}
                />
            </li>
            {createPortal(<div style={style} ref={ref}
                 className={`market-filter-popover`}>{component}</div>, document.body)}
        </div>
    );
}
export const MarketFilterIcon = MarketFilterIconComponent;