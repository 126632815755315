import { IDashboardQuadrant } from '../models/quadrants'
import { ActionTypes, Action } from '../actions/quadrants'
import { IQuadrant } from '../../analyticsPanel/models/analyticsPanel'
import { updateQuardrantsSizes as updateQuadrantsSizes } from '../../analyticsPanel/helper/helper'
import * as Profile from '../../dashboard/actions/profile'

export interface State {
  locked: boolean
  height: number
  active: boolean
  axises: {
    x: number
    y: number
  }
  quadrants: {
    ids: number[]
    entities: { [key: string]: IDashboardQuadrant }
  }
  layout: number[]
  emptyView: boolean
}

export const initialState: State = {
  locked: false,
  height: 0,
  active: false,
  axises: {
    x: 50,
    y: 50,
  },
  quadrants: {
    ids: [1, 2, 3, 4],
    entities: [1, 2, 3, 4].reduce((acc, q) => {
      return {
        ...acc,
        [q + '']: {
          size: {
            width: 50,
            height: 50,
          },
          id: q,
          isColliding: false,
          active: true,
          collidingAllowed: false,
        },
      }
    }, {}),
  },
  layout: [1, 2, 3, 4],
  emptyView: true,
}

export function reducer(
  state: State = initialState,
  action: Action | Profile.Action
) {
  switch (action.type) {
    case ActionTypes.LOAD: {
      if (!action.payload) {
        return { ...initialState, active: state.active }
      }
      const { axises, quadrants, locked, height, active, layout, emptyView } =
        action.payload
      const updatedQuadrants = updateQuadrantsSizes(
        quadrants.entities,
        axises.x,
        axises.y,
        layout === undefined ? [] : layout
      )

      return {
        ...state,
        locked: locked,
        height: locked ? height : 0,
        axises: axises,
        active: action.emptyView ? state.active : active,
        quadrants: {
          ids: quadrants.ids,
          entities: Object.keys(quadrants.entities).reduce((acc, id) => {
            return {
              ...acc,
              [id + '']: {
                ...quadrants.entities[id + ''],
                size: updatedQuadrants[id + '']
                  ? updatedQuadrants[id + ''].size
                  : { width: 0, height: 0 },
              },
            }
          }, {}),
        },
        layout: emptyView ? state.layout : layout,
      }
    }

    case ActionTypes.QUADRANT_RESIZE: {
      const { quadrants, axisX, axisY } = action.payload
      return {
        ...state,
        axises: {
          x: axisX,
          y: axisY,
        },
        quadrants: {
          ...state.quadrants,
          ids: quadrants.map((q) => q.id),
          entities: quadrants.reduce(
            (acc: { [key: string]: IQuadrant }, q: IQuadrant) => {
              return {
                ...acc,
                [q.id + '']: {
                  ...state.quadrants.entities[q.id],
                  id: q.id,
                  size: q.size,
                },
              }
            },
            {}
          ),
        },
      }
    }
    case ActionTypes.QUADRANT_COLLIDE_START: {
      const { quadrantBoxId, activeDock } = action.payload
      const id = parseInt(quadrantBoxId.replace('quadrant-box', ''))
      if (state.quadrants.ids.indexOf(id) > -1) {
        const collidingAllowed =
          !state.quadrants.entities[id].dock ||
          state.quadrants.entities[id].dock.type === activeDock?.type
        const entities = {
          ...state.quadrants.entities,
          [id + '']: {
            ...state.quadrants.entities[id],
            isColliding: true,
            collidingAllowed,
          },
        }
        return {
          ...state,
          quadrants: {
            ...state.quadrants,
            entities: entities,
          },
        }
      }
      return {
        ...state,
      }
    }
    case ActionTypes.QUADRANT_COLLIDE_STOP: {
      const { quadrantBoxId } = action.payload
      const id = parseInt(quadrantBoxId.replace('quadrant-box', ''))
      if (state.quadrants.ids.indexOf(id) > -1) {
        const entities = {
          ...state.quadrants.entities,
          [id + '']: { ...state.quadrants.entities[id], isColliding: false },
        }
        return {
          ...state,
          quadrants: {
            ...state.quadrants,
            entities: entities,
          },
        }
      }
      return {
        ...state,
      }
    }

    case ActionTypes.QUADRANT_ADD_DOCK: {
      const { quadrantId, dock } = action.payload
      if (state.quadrants.ids.indexOf(quadrantId) > -1) {
        if (state.quadrants.entities[quadrantId].dock) {
          return { ...state } // do not replace existing dock with the new one. Epic will emit action to connect them.
        }
        const entities = {
          ...state.quadrants.entities,
          [quadrantId + '']: {
            ...state.quadrants.entities[quadrantId],
            isColliding: false,
            dock: { ...dock, disableRendering: false },
          },
        }
        return {
          ...state,
          quadrants: {
            ...state.quadrants,
            entities: entities,
          },
        }
      }
      return {
        ...state,
      }
    }

    case ActionTypes.QUADRANT_REMOVE_DOCK: {
      const { dockId } = action.payload
      for (let i = 0; i < state.quadrants.ids.length; i++) {
        const quad = { ...state.quadrants.entities[state.quadrants.ids[i]] }
        if (quad.dock && quad.dock.id === dockId) {
          quad.dock = undefined
          quad.isColliding = false
          const entities = { ...state.quadrants.entities, [quad.id + '']: quad }
          return {
            ...state,
            quadrants: {
              ...state.quadrants,
              entities: entities,
            },
          }
        }
      }
      return {
        ...state,
      }
    }

    case ActionTypes.QUADRANT_TOGGLE: {
      const { quadrantIds } = action.payload
      const entities = { ...state.quadrants.entities }

      state.quadrants.ids.forEach(
        (id) => (entities[id].active = quadrantIds.indexOf(id) > -1)
      )
      const updatedQuadrants = updateQuadrantsSizes(
        entities,
        state.axises.x,
        state.axises.y,
        quadrantIds
      )
      return {
        ...state,
        layout: quadrantIds,
        quadrants: {
          ...state.quadrants,
          entities: updatedQuadrants,
        },
        active: quadrantIds.length > 0,
      }
    }

    case ActionTypes.QUADRANT_GRID_TOGGLE: {
      const { quadrantIds } = action.payload
      const entities = { ...state.quadrants.entities }

      let updatedQuadrants
      if (quadrantIds) {
        state.quadrants.ids.forEach(
          (id) => (entities[id].active = quadrantIds.indexOf(id) > -1)
        )
        updatedQuadrants = updateQuadrantsSizes(
          entities,
          state.axises.x,
          state.axises.y,
          quadrantIds
        )
      } else {
        updatedQuadrants = entities
      }
      for (let i = 0; i < state.quadrants.ids.length; i++) {
        const quad = { ...updatedQuadrants[state.quadrants.ids[i] + ''] }
        quad.dock = undefined
        quad.isColliding = false
        entities[quad.id + ''] = quad
      }
      return {
        ...state,
        active: !state.active,
        layout: quadrantIds,
        quadrants: {
          ...state.quadrants,
          entities: updatedQuadrants,
        },
      }
    }

    case Profile.ActionTypes.LOAD: {
      return {
        ...state,
        active: action.grid,
      }
    }

    default: {
      return {
        ...state,
      }
    }
  }
}
