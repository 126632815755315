import { DockType } from "js/shared/dock/models/dock";
import { IFavorite } from "../models/favorite";

export function isBlocked(favorite: IFavorite, activeMarketInstruments: string[], activeMarketProducts: string[], activeChartInstruments: string[]): boolean {
  if (favorite.entityType === DockType.Market || favorite.entityType === DockType.MarketIntraday) {
    for (let entity of favorite.entities) {
      if (activeMarketInstruments.indexOf(entity.instrumentId || '') > -1) {
        return true;
      }
    }
  } else if (favorite.entityType === DockType.ProductMarket) {
    for (let i = 0; i < favorite.entities.length; i++) {
      let entity = favorite.entities[i];
      if (activeMarketProducts.indexOf(entity.itemId || '') > -1) {
        return true;
      }
    }
  } else {
    for (let entity of favorite.entities) {
      if (activeChartInstruments.indexOf(entity.instrumentId || '') > -1) {
        return true;
      }
    }
  }
  return false;
}