import React, { useState } from 'react'
import { connect } from 'react-redux'
import { v1 } from 'uuid'
import { save, load, remove, toggleVisibility } from '../actions/profile'
import {
  getAvailableViews,
  getActiveViewId,
  getVisibleViewIds,
} from '../selectors/profile'
import ModalWindow from 'js/shared/modalWindow/components/modalWindow'
import {
  isProfileModificationAllowed,
  isProfileAdmin,
} from 'js/authentication/selectors/authetication'
import { MemoTranslate } from 'js/shared/i18n/components/memoTranslate'
import { getQuadrantGridActive } from '../selectors/quadrantPanel'

import { ProfileList } from './profileList'
import { ProfileSelectableList } from './profileSelectableList'
import { NewProfileModal } from './newProfileModal'
import { ProfileChangeIcons } from './profileIcons'

const lastViewId = 'lastview'
const emptyViewId = 'emptyView'

const ProfileViews = ({
  views,
  activeViewId,
  visibleViewIds,
  viewSave,
  viewLoad,
  viewRemove,
  viewChangeVisibility,
  modificationAllowed,
  isProfileAdmin,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isMoreOpen, setIsMoreOpen] = useState(false)

  const handleFormSubmit = (viewName) => {
    setIsModalOpen(false)

    const existingView = views.find((v) => v.name === viewName)

    const view = {
      viewId: existingView ? existingView.viewId : v1(),
      name: viewName,
      defaultView: true,
      data: {},
      jsonString: '',
    }
    viewSave(view)
  }

  const handleViewLoad = (view) => {
    viewLoad(view)
  }

  const lastDashboardActive = activeViewId === lastViewId
  const profileListViews = views.filter(
    (v) => visibleViewIds.indexOf(v.viewId) !== -1
  )
  const lastViewPosition = profileListViews
    .map((view) => view.viewId)
    .indexOf(lastViewId)
  if (lastViewPosition > -1) {
    const lastView = profileListViews.splice(lastViewPosition, 1)
    profileListViews.push(lastView[0])
  }

  const currentDashboardSaved = profileListViews.find(
    (v) => v.viewId === activeViewId
  )
    ? true
    : false

  return (
    <div className="sidebar__profile-views">
      <div className="subtitle d-flex justify-content-between">
        <div>
          <MemoTranslate value="profile.views.title" tag={'h2'} />
        </div>
        {modificationAllowed ? (
          <ProfileChangeIcons
            currentDashboardSaved={currentDashboardSaved}
            saveDashboard={() => setIsModalOpen(true)}
            lastDashboardActive={lastDashboardActive}
            viewLoad={handleViewLoad}
            lastViewId={lastViewId}
            emptyViewId={emptyViewId}
          />
        ) : null}
      </div>
      {isModalOpen ? (
        <NewProfileModal
          viewNames={views.map((v) => v.name)}
          onModalClose={() => setIsModalOpen(false)}
          isModalOpen={isModalOpen}
          onFormSubmit={handleFormSubmit}
        />
      ) : null}
      <ModalWindow
        id="profile-views"
        onModalClose={() => setIsMoreOpen(false)}
        isOpen={isMoreOpen}
        title="profile.views.title"
      >
        <ProfileSelectableList
          key="view-list-modal"
          views={views}
          visibleViewIds={visibleViewIds}
          onClose={() => setIsMoreOpen(false)}
          onViewRemove={viewRemove}
          onViewChangeVisibility={viewChangeVisibility}
          onViewLoad={viewLoad}
          activeViewId={activeViewId}
          profileAdmin={isProfileAdmin}
        />
      </ModalWindow>
      <div>
        <ProfileList
          key="view-list"
          activeViewId={activeViewId}
          views={profileListViews}
          onItemClick={(itemId) => viewLoad(itemId)}
          openMore={() => setIsMoreOpen(true)}
          modificationAllowed={modificationAllowed}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  views: getAvailableViews(state),
  activeViewId: getActiveViewId(state),
  visibleViewIds: getVisibleViewIds(state),
  modificationAllowed: isProfileModificationAllowed(state),
  isProfileAdmin: isProfileAdmin(state),
  isGridQuadrantsActive: getQuadrantGridActive(state),
})

const mapDispatchToProps = {
  viewSave: save,
  viewLoad: load,
  viewRemove: remove,
  viewChangeVisibility: toggleVisibility,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileViews)
