import { I18n } from 'react-redux-i18n';
import { createMasterDataIdString, getOrderbookContractByMasterDataId, getOrderbookContracts } from '../../orderbook/selectors/contracts';
import { EnterOrderRequest, ModifyOrderRequest } from '../../orders/models/orders';
import orderBookStore from '../../orderbook/store/orderbooks';
import OrderFormData from '../../orders/models/formData';
import { QuoteRequestFormData } from '../../requests/models/request';
import { Contract } from '../../orderbook/models/contracts';
import { PriceAlarmFormData } from '../../priceAlarm/models/priceAlarms';
import { MasterDataId } from '../../main/models/application';

const getPrecision = (x: any): number => {
    x = parseFloat(x) + '';
    var scale = x.indexOf('.');
    if (scale === -1) {
        return 0;
    }
    return x.length - scale - 1;
};

/**
 * display the correct number of decimals including quantity steps (if qty steps is 100, only one decimal is possible)
 * @param configuredDecimals
 * @param decimalStep
 */
export const getMaxDecimalsIncludingStepSizes = function (configuredDecimals: number, decimalStep: number) {
    let maxDecimals = configuredDecimals;
    if (decimalStep && decimalStep > 0) {
        maxDecimals = getPrecision(decimalStep / Math.pow(10, configuredDecimals));
    }
    return maxDecimals;
};

/**
 * Format number using thousand and decimal separator, limit/add decimal places according to maximumFractionDigits
 * @param value
 * @param localizeOptions
 */
export const formatNumber = function(value: number, localizeOptions: any): string {
    // example 12345.0678; decimal separator .; thousand separator ,; maximumFractionDigits 3
    const sign = value < 0 ? '-' : '';
    value = value < 0 ? -value : value;
    const wholePart = ('' + Math.trunc(value)); // Return full integer -> "12345"
    let result: string = '';
    const thousandSep = '\\' + localizeOptions.thousandSeparator;
    for (let i = wholePart.length - 1; i >= 0; i--) {
        result = wholePart[i] + (result.length >= 3 && result.replace(
            new RegExp(thousandSep, 'g'), '').length % 3 === 0
            ? localizeOptions.thousandSeparator : '') + result;
    }
    // -> "12,345"
    if (!localizeOptions.maximumFractionDigits) {
        return result;
    }
    result = result + localizeOptions.decimalSeparator; // "12,345."

    let maxDecimals = getMaxDecimalsIncludingStepSizes(localizeOptions.maximumFractionDigits, localizeOptions.decimalStep);
    const decimalPart = '' + Math.round((value - Math.trunc(value)) * Math.floor(Math.exp(Math.log(10) * maxDecimals))); // "68"
    let index = decimalPart.length - 1;
    let decimalPartResult = '';

    for (let i = maxDecimals - 1; i >= 0; i--) {
        if (index < 0) {
            decimalPartResult = '0' + decimalPartResult;
            continue;
        }
        decimalPartResult = decimalPart[index--] + decimalPartResult;
    }
    // -> "068"
    return sign + result + decimalPartResult; // 12,345.068
};
/**
 * Extract thousand and decimal separator from I18n localized number
 */
export const getLocalizationSettings = function(): any {
    const referenceNumber: string = I18n.l(12345.678, {style: 'decimal'});
    const referenceDate: string = I18n.l(new Date('3000-11-22').getTime(), {dateFormat: 'date.long'})
        .replace('3000', 'yyyy')
        .replace('00', 'yy')
        .replace('11', 'MM')
        .replace('22', 'dd');
    const thousandSeparator = referenceNumber[referenceNumber.indexOf('2') + 1];
    const decimalSeparator = referenceNumber[referenceNumber.indexOf('5') + 1];
    return {
      thousandSeparator: isNaN(parseInt(thousandSeparator, 10)) ? thousandSeparator : '',
      decimalSeparator: isNaN(parseInt(decimalSeparator, 10)) ? decimalSeparator : '.',
      dateFormat: referenceDate
    };
};
/**
 * format quantity and price to string that is shown in marketsheets (kW->MW conversion, decimal and thousand separatos)
 * !!! only values for log message should be formatted, request values must stay in raw form without formatting !!!
 * @param contractId
 * @param payload
 */
export const formatOrderRequestValues = function(contractId: MasterDataId, payload: EnterOrderRequest | ModifyOrderRequest) {
    const contract = getOrderbookContractByMasterDataId(orderBookStore.getState(), contractId);
    const localizeOptions = getLocalizationSettings();
    const quantity = contract ? payload.quantity / Math.floor(Math.exp(Math.log(10) * contract.qtyDecimals)) : payload.quantity;
    const price = payload.limit;
    let qtyUnit = contract.qtyUnit;
    // special case, this conversion is also used in order entry and orderbook
    if (qtyUnit?.trim().toLowerCase() === 'kw' && contract.qtyDecimals === 3) {
        qtyUnit = 'MW';
    }
    return {
        price: formatNumber(price, {...localizeOptions, maximumFractionDigits: contract.priceDecimals}),
        quantity: formatNumber(quantity, {...localizeOptions, maximumFractionDigits: contract.qtyDecimals, decimalStep: contract.qtyStepSize}),
        qtyUnit: !!qtyUnit && contract.qtyUnit !== 'NONE' ? qtyUnit : ''
    };
};

export function formatFormData(formData: OrderFormData, toDecimal: boolean, contractId: MasterDataId, contracts: {[id: string]: Contract}): OrderFormData & {associatedContractObjects: Contract[]} {
    const contract: Contract = contracts[createMasterDataIdString(contractId)];
    let formattedData: OrderFormData & {associatedContractObjects: Contract[]} = { ...formData, contractId: contract?.id, associatedContractObjects: [] };
    if (contract) {
        formattedData.price = formatPrice(formattedData.price, contract.priceDecimals);
        formattedData.quantity = formatQuantity(toDecimal, formattedData.quantity, contract.qtyDecimals, contract.qtyStepSize);
    }
    setAssociatedContracts(formattedData, contracts);
    return formattedData;
}

export function formatQuoteRequestFormData(formData: QuoteRequestFormData, toDecimal: boolean, quantityDecimals: any, stepSizes: any, contracts: {[id: string]: Contract}): QuoteRequestFormData {
    let formattedData: QuoteRequestFormData & {associatedContractObjects: Contract[]} = { ...formData,  associatedContractObjects: [] };

    formattedData.quantity = formatQuantity(toDecimal, formattedData.quantity, quantityDecimals, stepSizes);
    setAssociatedContracts(formattedData, contracts);
    return formattedData;
}

export function formatPriceAlarmFormData(formData: PriceAlarmFormData): PriceAlarmFormData & {contract: Contract} {
    const contract = getOrderbookContracts(orderBookStore.getState())[formData.contractId];

    return {...formData, contract };
}

function setAssociatedContracts(formData: any, contracts: {[id: string]: Contract}) {
    if (!!formData.associatedContracts && formData.associatedContracts.length > 0) {
        formData.associatedContractObjects = formData.associatedContracts.map((c: string) => contracts[c]).filter((c: Contract) => !!c);
        if (!!formData.associatedContractObjects && formData.associatedContractObjects.length > 0 && formData.associatedContracts.indexOf(formData.contractId) === -1) {
            formData.contract = formData.associatedContractObjects[0];
            formData.contractId = formData.associatedContractObjects[0].id;
        }
    }
}

/**
 * Formats quantity values to be in same unit (for display purpose in mEET) and back to values used internally by IM.
 *
 * @param formData
 * @param toDecimal true - format for display purposes (to decimal), false - format back to normal representation
 */
export function formatQuantity(toDecimal: boolean, quantity: number, qtyDecimals: any, stepSizes: any): any {
    return toDecimal ? formatQuantityToDecimalWithQtySteps(quantity, qtyDecimals, stepSizes) : formatQuantityToInt(quantity, qtyDecimals, stepSizes);     // fixed floating point inaccuracy
}

export function formatPrice(price: any, priceDecimals: any): any {
    // at runtime the parameter price will be handed over as a string, no matter, what we define as the type here. Therefore we need to convert it into a number.
    return parseFloat(price).toFixed(priceDecimals);
}

export function formatQuantityToDecimalWithQtySteps(quantity: any, qtyDecimals: any, stepSizes: any) {
    let fixedDecimals = getMaxDecimalsIncludingStepSizes(qtyDecimals, stepSizes);
    let decimals = Math.max(qtyDecimals, getPrecision(stepSizes / Math.pow(10, qtyDecimals)));
    let floatingInaccurate: any = quantity / Math.floor(Math.exp(Math.log(10) * decimals));
    return parseFloat(floatingInaccurate).toFixed(fixedDecimals);
}

export function formatQuantityToDecimal(quantity: any, qtyDecimals: any) {
    let floatingInaccurate: any = quantity / Math.floor(Math.exp(Math.log(10) * qtyDecimals));
    return parseFloat(floatingInaccurate).toFixed(qtyDecimals);
}

export function formatQuantityToInt(quantity: any, qtyDecimals: any, stepSizes: any) {
    let decimals = Math.max(qtyDecimals, getPrecision(stepSizes / Math.pow(10, qtyDecimals)));
    let floatingInaccurate: any = quantity * Math.floor(Math.exp(Math.log(10) * decimals));
    return parseFloat(floatingInaccurate).toFixed(0);
}

export const getFormattedTime = (seconds) => {
    if (seconds < 0) {
      return '00:00';
    }
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const sec = seconds % 60;
    return (
      (hours === 0 ? '' : (hours < 10 ? '0' : '') + hours + ':') +
      ((minutes < 10 ? '0' : '') + minutes) +
      ':' +
      ((sec < 10 ? '0' : '') + (sec % 60))
    );
};