import { State } from '../../../main/reducers/rootReducer';
import { createSelector } from 'reselect';
import { argsSelectorCreator } from '../../utils/selectors/agrSelector';
import { ITable } from '../../ui/models/table';
import { Venue } from '../models/venue';
import { sortTable } from '../../utils/helper/sorter';

const getVenueState = (state: State) => state.venues;

export const getAllVenues = createSelector(
  getVenueState,
  s => s.venues
);

export const getVenueTableRows = argsSelectorCreator(
  [getAllVenues, (state: State, venueTable: ITable | undefined) => venueTable],
  (venues, venuesTable) => {
    if (venuesTable) {
      const _venues = venues.map((venue: Venue) => {
      
        return venuesTable.columns.reduce((result: any, col) => {
          return Object.assign(result, {
            [col.name]: venue[col.originalName]
          });
        }, { id: venue.id });
      });
      return sortTable(_venues, venuesTable.sorting, venuesTable.hiddenColumnNames, venuesTable.cmpFunctions);
    }
    return [];
  }
);