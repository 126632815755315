import React, { useState } from 'react'
import { TranslateViewName } from '../utils/profile_views'
import { MemoTranslate } from 'js/shared/i18n/components/memoTranslate'
import { I18n } from 'react-redux-i18n'
import { config } from 'js/main/config'

export const ProfileSelectableList = ({
  views,
  visibleViewIds,
  activeViewId,
  profileAdmin,
  onClose,
  onViewRemove,
  onViewChangeVisibility,
  onViewLoad,
}) => {
  const [items, setItems] = useState(
    views.map((view) => ({
      viewId: view.viewId,
      name: view.name,
      visibleBefore: visibleViewIds.indexOf(view.viewId) !== -1,
      visibleAfter: visibleViewIds.indexOf(view.viewId) !== -1,
      deleteAfter: false,
    }))
  )

  const handleSave = () => {
    items.forEach((item) => {
      if (item.deleteAfter) {
        onViewRemove(item.viewId, item.name)
      } else if (item.visibleBefore !== item.visibleAfter) {
        onViewChangeVisibility(item.viewId, item.visibleAfter)
      }
    })
    onClose()
  }

  const handleToggleVisible = (viewId) => {
    setItems((prevState) =>
      prevState.map((item) =>
        item.viewId === viewId
          ? {
              ...item,
              visibleAfter: !item.visibleAfter,
            }
          : item
      )
    )
  }

  const handleDelete = (viewId) => {
    setItems((prevState) =>
      prevState.map((item) =>
        item.viewId === viewId
          ? {
              ...item,
              deleteAfter: true,
            }
          : item
      )
    )
  }

  const handleOpen = (viewId) => {
    onViewLoad(viewId)
  }

  const profileTableBody = items
    .filter((item) => !item.deleteAfter)
    .map((item) => (
      <tr key={item.viewId}>
        <td onClick={() => handleOpen(item.viewId)}>
          <div className="select-item">
            <div className="left-container">
              <span className="oi oi-list" />
              <span className="dashboard-name">
                <label className="dashboard-name">
                  {TranslateViewName(item.name)}
                </label>{' '}
              </span>
            </div>
            <span
              className={`oi oi-star ${
                item.viewId === activeViewId ? `active` : ``
              }`}
            />
          </div>
        </td>
        <td className="text-center">
          <input
            type="checkbox"
            id={item.viewId}
            checked={item.visibleAfter}
            disabled={
              !item.visibleAfter &&
              !profileAdmin &&
              items.filter((i) => i.visibleAfter && !i.deleteAfter).length >=
                config.sidebarDashboardsListLimit
            }
            onChange={() => handleToggleVisible(item.viewId)}
          />
          <label htmlFor={item.viewId}>&nbsp;</label>
        </td>
        <td className="text-center">
          {item.viewId === 'emptyView' ? (
            <MemoTranslate value="profile.modal.deleteUnsupported" />
          ) : (
            <i
              className="oi oi-trash ml-auto failed"
              onClick={() => handleDelete(item.viewId)}
              title={I18n.t('profile.modal.delete')}
            />
          )}
        </td>
      </tr>
    ))

  return (
    <div className="sidebar__profile-views__borderless-list">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>
              <MemoTranslate value="profile.modal.name" />
            </th>
            <th className="text-center">
              <MemoTranslate value="profile.modal.listed" />
            </th>
            <th className="text-center">
              <MemoTranslate value="profile.modal.delete" />
            </th>
          </tr>
        </thead>
        <tbody>{profileTableBody}</tbody>
      </table>
      <div className="d-flex">
        <button
          className="btn btn-approval w150 ml-auto"
          onClick={() => handleSave()}
        >
          <MemoTranslate value="profile.modal.save" />
        </button>
      </div>
    </div>
  )
}
