import React from "react";
import {config} from "../config";
import {I18n} from "react-redux-i18n";
import {FallbackProps} from "react-error-boundary";
import {NotificationMessage} from "../../authentication/components/LoginForm";

export default function fallbackRender({resetErrorBoundary}: FallbackProps) {
    return (
        <div className="login-container row justify-content-center h-100">
            <div className="login-form center-block">
                <NotificationMessage
                    message={I18n.t('error.profileError')}
                    level={'danger'}
                />
                <div className="d-flex justify-content-center mt-auto w-100">
                    <a href={config.subfolder + '/'}><input
                       type="button"
                       className="btn btn-primary w150 login-button"
                       onClick={resetErrorBoundary}
                       value={I18n.t('profile.reset')}
                    /></a>
                </div>
            </div>
        </div>
    );
}