import { getOrderbookProductById } from '../../../orderbook/selectors/products'
import { Action, ActionTypes } from '../actions/chart';
import { IChart, Chart } from '../models/chart';
import * as OrderbookActions from '../../../orderbook/actions/orderbooks';
import * as ContractActions from '../../../orderbook/actions/contracts';
import * as TradeActions from '../../../trade/actions/trade';
import {
  createMasterDataIdString,
  getOrderbookContractById,
  getOrderbookContracts,
} from '../../../orderbook/selectors/contracts'
import orderBookStore from '../../../orderbook/store/orderbooks';
import { v1 } from 'uuid';
import { convertSyntheticToConcrete, getSyntheticContractIdFromContract } from '../components/chart/helper/helper'
import { Contract, Product } from '../../../orderbook/models/contracts'

export interface State {
  ids: string[];
  entities: { [id: string]: Chart };
  toUnsubscribe: string[];
  dataVersion: string;
  themes: any;
  drawings: {[contractName: string]: any};
  views: any;
}

export const initialState: State = {
  ids: [],
  entities: {},
  toUnsubscribe: [],
  dataVersion: v1(),
  themes: undefined,
  drawings: {},
  views: {}
};

export function reducer(
  state: State = initialState,
  action: Action | OrderbookActions.Action | TradeActions.Action | ContractActions.Action
) {
  switch (action.type) {
    case ActionTypes.LOAD: {
      const charts = action.payload;
      const newChartEntities = charts.reduce(
        (entities: { [id: string]: IChart }, chart: IChart) => {
          const chartComplete = chart as Chart;
          if (chartComplete?.syntheticContractId) {
            const contract = convertSyntheticToConcrete(chartComplete.syntheticContractId)
            chartComplete.contractId = contract?.idString
          }
          return Object.assign(entities, {
            [chart.id]: chartComplete
          });
        },
        {}
      );

      return {
        ...state,
        ids: Object.keys(newChartEntities),
        entities: newChartEntities
      };
    }

    case ActionTypes.CREATE: {
      const chart: Chart = action.payload;
      const contract: Contract = getOrderbookContractById(orderBookStore.getState(), chart.contractId)
      let synId = getSyntheticContractIdFromContract(contract);
      return {
        ...state,
        ids: [...state.ids, chart.id],
        entities: {
          ...state.entities,
          [chart.id]: {...chart, syntheticContractId: synId}
        }
      };
    }

    case ActionTypes.MOVE: {
      const { ids, toDockId } = action.payload;
      const toDockMarkets = state.ids
      .map(id => state.entities[id])
      .filter(market => market.dockId === toDockId);
      let lowestPrio = Math.max(...toDockMarkets.map(m => m.priority));
      const newState = ids.reduce(
        (entities: { [id: string]: IChart }, id: string) => {
          lowestPrio++;
          return Object.assign(entities, {
            [id]: Object.assign({}, state.entities[id], {
              dockId: toDockId,
              priority: lowestPrio
            })
          });
        },
        {}
      );

      return {
        ...state,
        entities: {
          ...state.entities,
          ...newState
        }
      };
    }

    case ActionTypes.REMOVE: {
      const { id } = action.payload;
      const newChartIds = state.ids.filter(chartId => chartId !== id);
      let removed: any = { ...state.entities[id] };
      let instrumentId = '';
      if (removed.itemId) {
        
        instrumentId = removed.itemId;
      } else if (removed.contractId) {
        const contract = getOrderbookContracts(orderBookStore.getState())[
          removed.contractId
        ];
        if (!!contract) {
          instrumentId = createMasterDataIdString(contract.instrumentId);
        }
      }
      const newChartEntities = newChartIds.reduce(
        (entities: { [id: string]: IChart }, chartId: string) => {
          return Object.assign(entities, {
            [chartId]: state.entities[chartId]
          });
        },
        {}
      );

      return {
        ...state,
        ids: newChartIds,
        entities: newChartEntities,
        toUnsubscribe: instrumentId
          ? state.toUnsubscribe.concat([instrumentId])
          : state.toUnsubscribe
      };
    }

    case ActionTypes.SET_PERIOD_TYPE: {
      const { id, periodType } = action.payload;
      const updateEntities = Object.assign({}, state.entities, {
        [id]: Object.assign({}, state.entities[id], {
          selectedPeriodType: periodType
        })
      });
      return {
        ...state,
        entities: {
          ...state.entities,
          ...updateEntities
        },
        dataVersion: v1()
      };
    }

    case ActionTypes.SET_CONTRACT: {
      const { id, contract, } = action.payload;
      if (!contract) {
        return {...state};
      }

      let synId = getSyntheticContractIdFromContract(contract);
      const updateEntities = Object.assign({}, state.entities, {
        [id]: Object.assign({}, state.entities[id], {
          contractId: createMasterDataIdString(contract.id),
          itemId: createMasterDataIdString(contract.instrumentId),
          instrumentId: createMasterDataIdString(contract.instrumentId),
          selectedPeriodType: contract.expiry?.type,
          title: contract.nameWithVenue,
          syntheticContractId: synId
        })
      });
      return {
        ...state,
        entities: {
          ...state.entities,
          ...updateEntities
        },
        dataVersion: v1()
      };
    }

    case ActionTypes.SET_CONTRACT_ID: {
      const { id, contractId, ohlcPeriod } = action.payload;
      const contract: Contract = getOrderbookContractById(orderBookStore.getState(), contractId);
      if (!contract) {
        return {...state};
      }
      let synId = getSyntheticContractIdFromContract(contract)
      const updateEntities = Object.assign({}, state.entities, {
        [id]: Object.assign({}, state.entities[id], {
          contractId: contractId,
          ohlcPeriod: ohlcPeriod ? ohlcPeriod : state.entities[id].ohlcPeriod,
          title: contract?.nameWithVenue || contractId,
          syntheticContractId: synId
        })
      });
      return {
        ...state,
        entities: {
          ...state.entities,
          ...updateEntities
        },
        dataVersion: v1()
      };
    }

    case ActionTypes.SET_GROUP_TYPES: {
      const { id, groupTypes } = action.payload;
      const updateEntities = Object.assign({}, state.entities, {
        [id]: Object.assign({}, state.entities[id], {
          groupTypes: groupTypes
        })
      });
      return {
        ...state,
        entities: {
          ...state.entities,
          ...updateEntities
        },
        dataVersion: v1()
      };
    }


    case ActionTypes.REMOVE: {
      const { id } = action.payload;
      let copy = Object.assign({}, state);
      delete copy.entities[id];
      return copy;
    }

    case ActionTypes.SET_VIEWS: {
      const { views } = action.payload;
      
      return {
        ...state,
        views: views,
        dataVersion: v1()
      };
    }

    case ActionTypes.SET_LAYOUT: {
      const { id, layout } = action.payload;
      const updateEntities = Object.assign({}, state.entities, {
        [id]: Object.assign({}, state.entities[id], {
         layout: layout
        })
      });
      return {
        ...state,
        entities: {
          ...state.entities,
          ...updateEntities
        },
        dataVersion: v1()
      };
    }

    case ActionTypes.SET_THEMES: {
      const { themes } = action.payload;
      return {
        ...state,
        themes: themes
      };
    }

    case ActionTypes.SET_CURRENT_THEME: {
      const { id, theme } = action.payload;
      const updateEntities = Object.assign({}, state.entities, {
        [id]: Object.assign({}, state.entities[id], {
         currentTheme: theme
        })
      });
      return {
        ...state,
        entities: {
          ...state.entities,
          ...updateEntities
        },
        dataVersion: v1()
      };
    }

    case ActionTypes.SET_DRAWINGS: {
      const { contractName, drawings } = action.payload;
      const updatedDrawings = Object.assign({}, state.drawings, {
        [contractName]: drawings
      });
      return {
        ...state,
        drawings: updatedDrawings,
        dataVersion: v1()
      };
    }

    case ActionTypes.RESTORE_DRAWINGS: {
      const { drawings } = action.payload;
      
      return {
        ...state,
        drawings: drawings,
        dataVersion: v1()
      };
    }

    default:
      return state;
  }
}
