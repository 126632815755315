import { createMemoryHistory } from 'history';
import { config } from './config';

const history = createMemoryHistory();
// history object at the beggining did not contain correct location
// this will initialize it with pathname from window.location
history.replace(window.location.pathname);

export function historyReplace(path: string) {
    window.history.replaceState({},null,config.subfolder + path);
    history.replace(config.subfolder + path);
}

export default history;

// TODO Uncomment these lines when: all class-based components, using history are refactored to functional components and react-router-dom is updated > 5.1.2. Then, replace all history.push and history.replace with useHistory hook. Then, 'history' package must be removed from package.json.
// import { useHistory } from 'react-router-dom';
// import { config } from './config';
//
// export function historyReplace(path: string) {
//   const history = useHistory();
//   history.replace(config.subfolder + path);
// }