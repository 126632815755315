import { v1 } from 'uuid'
import * as Settings from '../actions/settings';
import { config } from '../../../main/config';

export interface State {
  colors: { [key: string]: { [x: string]: string } };
  general: { [key: string]: { [x: string]: any } };
  sidebar: { locked: boolean };
  colorsVersion: string;
}

export const initialState: State = {
  colors: config.branding.colors,
  general: config.settings.general,
  sidebar: {
    locked: false
  },
  colorsVersion: v1()
};

export function reducer(state: State = initialState, action: Settings.Action) {
  switch (action.type) {
    case Settings.ActionTypes.SAVE_CLIENT_SETTINGS:
    case Settings.ActionTypes.SAVE_COLOR_SETTINGS:
    case Settings.ActionTypes.LOAD_SETTINGS: {
      let currentColors = Object.assign({}, state.colors);
      let currentGeneral = Object.assign({}, state.general);

      const newColors = action.payload.colors ? action.payload.colors : state.colors;
      const newGeneral = action.payload.general ? action.payload.general : state.general;
      const newSidebar = action.payload.sidebar ? action.payload.sidebar : state.sidebar;

      for (let key of Object.keys(newColors)) {
        currentColors[key] = newColors[key];
      }
      for (let key of Object.keys(newGeneral)) {
        currentGeneral[key] = newGeneral[key];
      }

      return { 
        ...state, 
        colors: currentColors,
        general: currentGeneral,
        sidebar: newSidebar,
        colorsVersion: v1()
      };
    }

    default:
      return state;
  }
}
