import * as Rx from 'rxjs';
import { StompService, StompClient } from '../../main/services/stompService';
import {
  SaveDashboardViewRequest,
  LoadDashboardViewRequest,
  RemoveDashboardViewRequest
} from '../models/profile';
import RestService from '../../main/services/restService';

export default class ProfileService extends StompService {
  private restService: RestService;

  constructor() {
    super(StompClient);
    this.restService = new RestService();
  }

  public saveViewWs(message: SaveDashboardViewRequest): Rx.Observable<any> {
    return Rx.of(
      this.sendMessage('/app/view', JSON.stringify(message))
    );
  }

  public saveView(message: SaveDashboardViewRequest): Rx.Observable<any> {
    return this.restService.restPost('/data/view/save', message);
  }

  public saveViewSync(message: SaveDashboardViewRequest) {
    this.restService.syncRestPost('/data/view/save', message, (data) => {});
  }

  public loadView(message: LoadDashboardViewRequest): Rx.Observable<any> {
    return Rx.of(
      this.sendMessage('/app/view/load', JSON.stringify(message))
    );
  }

  public removeView(message: RemoveDashboardViewRequest): Rx.Observable<any> {
    return Rx.of(
      this.sendMessage('/app/view/delete', JSON.stringify(message))
    );
  }

  public subscribeView(): Rx.Observable<any> {
    return this.subscribe('/user/topic/view');
  }
}
