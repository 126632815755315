import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Component, createRef } from 'react'

interface AutoCloseProps {
  locked?: boolean;
}

interface AutoCloseState {
  expanded: boolean;
}

export default function withAutoClose(
  WrappedComponent:any
) {
  return class WithAutoClose extends Component<
    AutoCloseProps & any,
    AutoCloseState
  > {
    private wrapperRef: any = createRef();
    constructor(props: any) {
      super(props);
      this.state = {
        expanded: false
      };

      this.handleClickOutside = this.handleClickOutside.bind(this);
      this.handleTriggerVisibility = this.handleTriggerVisibility.bind(this);
      this._checkAndSetLock = this._checkAndSetLock.bind(this);
    }

    componentDidMount() {
      this._checkAndSetLock(this.props);
      document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillReceiveProps(newProps: any) {
      this._checkAndSetLock(newProps);
    }

    componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleTriggerVisibility(visible: boolean) {
      this.setState({ expanded: visible });
    }

    _checkAndSetLock(props: any) {
      if (props.locked) {
        this.setState({ expanded: true });
        return true;
      } else {
        return false;
      }
    }

    handleClickOutside(event: any) {
      const { expanded } = this.state;
      if (!this._checkAndSetLock(this.props)) {
        if (
          expanded &&
          this.wrapperRef &&
          !this.wrapperRef.current?.contains(event.target)
        ) {
          this.setState({ expanded: false });
        } else if (
          !expanded &&
          this.wrapperRef &&
          this.wrapperRef.current?.contains(event.target)
        ) {
          this.setState({ expanded: true });
        }
      }
    }

    render() {
      return (
        //And a div outside to pass the ref, because WrappedComponent could also be functional component
        <div ref={this.wrapperRef}>
          <WrappedComponent
            {...this.props}
            {...this.state}
            triggerVisibility={this.handleTriggerVisibility}
          />
        </div>
      );
    }
  };
}
