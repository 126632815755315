import { IQuadrant } from '../../analyticsPanel/models/analyticsPanel';
import { State } from '../reducers/quadrants';
import { LogAction } from '../../main/middlewares/logger';
import { I18n } from 'react-redux-i18n';
import { LogLevel } from '../../shared/logger/models/logger';
import { IDashboardQuadrant } from '../models/quadrants';
import { IDock } from '../../shared/dock/models/dock';
import { config } from '../../main/config';

export enum ActionTypes {
  LOAD = '[Quadrant] Load',
  RESET = '[Quadrant] Reset',
  SET_QUADRANT = '[Quadrant] Set quadrant',
  UPDATE_QUADRANT = '[Quadrant] Update quadrant',
  REMOVE_QUADRANT = '[Quadrant] Remove quadrant',
  QUADRANT_RESIZE = '[Quadrant] Quadrants resize',
  TRIGGER_PANEL_LOCK = '[Quadrant] Trigger panel lock',
  QUADRANT_COLLIDE_START = '[Quadrant] Collide start',
  QUADRANT_COLLIDE_STOP = '[Quadrant] Collide stop',
  QUADRANT_ADD_DOCK = '[Quadrant] Add dock',
  QUADRANT_REMOVE_DOCK = '[Quadrant] Remove dock',
  QUADRANT_GRID_TOGGLE = '[Quadrant] Toggle quadrant grid',
  QUADRANT_TOGGLE = '[Quadrant] Toggle quadrant mode'
}

export interface DashboardQuadrantsLoadAction extends LogAction {
  type: ActionTypes.LOAD;
  payload: State;
  emptyView: boolean;
}

export interface DashboardQuadrantsResetAction extends LogAction {
  type: ActionTypes.RESET;
}

export interface DashboardSetQuadrantAction extends LogAction {
  type: ActionTypes.SET_QUADRANT;
  payload: IQuadrant;
}

export interface DashboardUpdateQuadrantAction extends LogAction {
  type: ActionTypes.UPDATE_QUADRANT;
  payload: IDashboardQuadrant;
}

export interface DashboardRemoveQuadrantAction {
  type: ActionTypes.REMOVE_QUADRANT;
  payload: number;
}

export interface DashboardQuadrantsResizeAction {
  type: ActionTypes.QUADRANT_RESIZE;
  payload: { quadrants: IQuadrant[]; axisX: number; axisY: number };
}

export interface DashboardTriggerPanelLockAction {
  type: ActionTypes.TRIGGER_PANEL_LOCK;
  payload: { locked: boolean; height: number };
}

export interface QuadrantCollideStartAction {
  type: ActionTypes.QUADRANT_COLLIDE_START;
  payload: { quadrantBoxId: string; activeDock: IDock };
}

export interface QuadrantCollideStopAction {
  type: ActionTypes.QUADRANT_COLLIDE_STOP;
  payload: { quadrantBoxId: string };
}

export interface QuadrantAddDockAction {
  type: ActionTypes.QUADRANT_ADD_DOCK;
  payload: { quadrantId: number, dock: IDock };
}

export interface QuadrantRemoveDockAction {
  type: ActionTypes.QUADRANT_REMOVE_DOCK;
  payload: { dockId: string };
}

export interface QuadrantToggleAction {
  type: ActionTypes.QUADRANT_GRID_TOGGLE;
  payload: {quadrantIds: number[]};
}

export interface QuadrantToggleSingleAction {
  type: ActionTypes.QUADRANT_TOGGLE;
  payload: {quadrantIds: number[]};
}

export function resetDashboard(): DashboardQuadrantsResetAction {
  return {
    type: ActionTypes.RESET,
    logMessage: I18n.t('dashboard.log.reset'),
    logLevel: LogLevel.DEBUG
  };
}

export function loadDashboardQuadrants(payload: State, emptyView: boolean): DashboardQuadrantsLoadAction {
  return {
    type: ActionTypes.LOAD,
    payload: {...payload, active: config.gridLayoutEnabled ? payload.active : false},
    emptyView: emptyView,
    logMessage: I18n.t('dashboard.log.load'),
    logLevel: LogLevel.DEBUG
  };
}

export function setDashboardQuadrant(quadrant: IQuadrant): DashboardSetQuadrantAction {
  return {
    type: ActionTypes.SET_QUADRANT,
    payload: quadrant,
    logMessage: I18n.t('dashboard.log.set', {quadrant: quadrant.id + ' - ' + quadrant.components.map(c => I18n.t(`recentAction.${c}`)).join(', ')}),
    logLevel: LogLevel.DEBUG
  };
}

export function updateDashboardQuadrant(
  quadrant: IQuadrant
): DashboardUpdateQuadrantAction {
  return {
    type: ActionTypes.UPDATE_QUADRANT,
    payload: quadrant,
    logMessage: I18n.t('dashboard.log.update', {quadrant: quadrant.id + ' - ' + quadrant.components.map(c => I18n.t(`recentAction.${c}`)).join(', ')}),
    logLevel: LogLevel.DEBUG
  };
}

export function removeDashboardQuadrant(
  quadrantId: number
): DashboardRemoveQuadrantAction {
  return {
    type: ActionTypes.REMOVE_QUADRANT,
    payload: quadrantId
  };
}

export function dashboardQuadrantsResize(
  quadrants: IQuadrant[],
  axisX: number,
  axisY: number
): DashboardQuadrantsResizeAction {
  return {
    type: ActionTypes.QUADRANT_RESIZE,
    payload: { quadrants, axisX, axisY }
  };
}

export function triggerQuadrantPanelLock(
  locked: boolean,
  height: number
): DashboardTriggerPanelLockAction {
  return {
    type: ActionTypes.TRIGGER_PANEL_LOCK,
    payload: { locked, height }
  };
}

export function quadrantCollideStart(id: string, activeDock: IDock): QuadrantCollideStartAction {
  return {
    type: ActionTypes.QUADRANT_COLLIDE_START,
    payload: { quadrantBoxId: id, activeDock }
  };
}

export function quadrantCollideStop(id: string): QuadrantCollideStopAction {
  return {
    type: ActionTypes.QUADRANT_COLLIDE_STOP,
    payload: { quadrantBoxId: id }
  };
}

export function addDockToQuadrant(id: number, dock: IDock): QuadrantAddDockAction {
  return {
    type: ActionTypes.QUADRANT_ADD_DOCK,
    payload: { quadrantId: id, dock: dock }
  };
}

export function removeDockFromQuadrant(id: string): QuadrantRemoveDockAction {
  return {
    type: ActionTypes.QUADRANT_REMOVE_DOCK,
    payload: { dockId: id }
  };
}

export function toggleQuadrantGrid(quadrantIds?: number[]): QuadrantToggleAction {
  return {
    type: ActionTypes.QUADRANT_GRID_TOGGLE,
    payload: {quadrantIds}
  };
}

export function toggleQuadrant(quadrantIds: number[]): QuadrantToggleSingleAction {
  return {
    type: ActionTypes.QUADRANT_TOGGLE,
    payload: {quadrantIds}
  };
}

export type Action =
  | DashboardQuadrantsLoadAction
  | DashboardQuadrantsResetAction
  | DashboardUpdateQuadrantAction
  | DashboardRemoveQuadrantAction
  | DashboardSetQuadrantAction
  | DashboardQuadrantsResizeAction
  | DashboardTriggerPanelLockAction
  | QuadrantCollideStopAction
  | QuadrantCollideStartAction
  | QuadrantAddDockAction
  | QuadrantRemoveDockAction
  | QuadrantToggleAction
  | QuadrantToggleSingleAction;
