import * as Rx from 'rxjs';
import { config } from '../config';
import { getSSOAccessToken } from '../../authentication/selectors/connection';
import connectionStore from '../../authentication/store/connection';
import { getCookie } from '../../shared/utils/helper/cookie';

export default class RestService {

    public restFetch(url: string) {
        return Rx.Observable.create(
            (observer: Rx.Observer<any>) => {
                const accessToken = getSSOAccessToken(connectionStore.getState());
                fetch(config.serverURL + url + '?t=' + new Date().getTime(), {
                    method: 'GET',
                    mode: 'cors',
                    credentials: 'include',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + accessToken
                    }
                }).then((response: Response) => {
                    if (response.status !== 200) {
                        observer.error(response.statusText);
                    }
                    response
                        .text()
                        .then((data: any) => {
                            if (!data) {
                                observer.error('No json input');
                            }
                            try {
                                const parsed = JSON.parse(data);
                                observer.next(parsed);
                            } catch (e) {
                                observer.error(e);
                            }
                        })
                        .catch(error => observer.error(error));
                });
            }
        );
    }
    /**
     *
     * @param url request url without server host and context path
     * @param request
     */
    public restPost(url: string, request: any): Rx.Observable<any> {
        const xsrf = getCookie('XSRF-TOKEN');
        const accessToken = getSSOAccessToken(connectionStore.getState());
        return new Rx.Observable(
            (observer: Rx.Observer<any>) => {
                fetch(config.serverURL + url + '?t=' + new Date().getTime(), {
                    method: 'POST',
                    mode: 'cors',
                    credentials: 'include',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'X-XSRF-TOKEN': xsrf,
                        Authorization: 'Bearer ' + accessToken
                    },
                    body: JSON.stringify(request)
                }).then((response: Response) => {
                    if (!response.ok) {
                        observer.error({ status: response.status, text: response.statusText });
                    } else {
                        response
                          .text()
                          .then((data: any) => {
                              if (!data) {
                                  observer.error('No json input');
                              }
                              observer.next(JSON.parse(data));
                          })
                          .catch(error => {
                                observer.error(error)
                            }
                          );
                    }
                })
                    .catch(error => observer.error(error));
            }
        );
    }

/**
     * rest post using fetch with keepalive option
     * @param url request url without server host and context path
     * @param request
     */
    public syncRestPost(url: string, request: any, callback: (res) => void) {
        const xsrf = getCookie('XSRF-TOKEN');
        const accessToken = getSSOAccessToken(connectionStore.getState());

        fetch(config.serverURL + url + '?t=' + new Date().getTime(), {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            keepalive: true,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': xsrf,
                Authorization: 'Bearer ' + accessToken
            },
            body: JSON.stringify(request)
        }).then((response: Response) => {
            response
                .text()
                .then((data: any) => {
                    callback(data);
                })
            }
        );
    }


    public restPostOctetStreamRequest(url: string, request: any, csvName: string): Rx.Observable<any> {
        const xsrf = getCookie('XSRF-TOKEN');
        const accessToken = getSSOAccessToken(connectionStore.getState());
        return Rx.Observable.create(
            (observer: Rx.Observer<any>) => {
                fetch(config.serverURL + url + '?t=' + new Date().getTime(), {
                    method: 'POST',
                    mode: 'cors',
                    credentials: 'include',
                    headers: {
                        Accept: 'application/octet-stream',
                        'Content-Type': 'application/json',
                        'X-XSRF-TOKEN': xsrf,
                        Authorization: 'Bearer ' + accessToken
                    },
                    body: JSON.stringify(request)
                }).then((response: Response) => {
                    if (response.status !== 200) {
                        observer.error('error.noDataToBeDownloaded');
                    } else {
                        response.blob().then(blob => {
                            const link = document.createElement('a');
                            const browserUrl = URL.createObjectURL(blob);
                            link.href = browserUrl;
                            link.download = `${csvName}.csv`;
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        });
                        observer.next(csvName);
                    }
                })
                    .catch(error => observer.error(error));
            }
        );
    }
}
