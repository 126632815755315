export const getPathIfNotFindNatively = (e: any) => {
    var path = [];
    var node = e.target;
    while (node && node !== document.body) {
      path.push(node);
      node = node.parentNode;
    }
    return path;
};

export const pullDockHigher = (eventPath: any) => {
  const dockTarget = eventPath.find((el: any) =>
    el.className && el.className.split(' ').includes('react-resizable')
  );
  if (!dockTarget) {
    return;
  }
  const dockWrapperTarget = dockTarget.closest('.dock__wrapper');
  if (dockWrapperTarget) {
    (dockWrapperTarget as HTMLElement).style.zIndex = '3';
  }
};

export const pushDockLower = () => {
    let element = document.getElementById('market-context-menu');
    if (element) {
      let parent = element.closest('.dock__wrapper');
      if (parent) {
        (parent as HTMLElement).style.zIndex = '1';
      }
    }
};

export const findRootElement = (ele: Element | Text | null, targetClass: string): Element | null => {
  if (!ele) {
    return null;
  }

  if (
    ele instanceof Element &&
    ele.className.search(targetClass) !== -1
  ) {
    return ele;
  }
  return findRootElement(ele.parentElement, targetClass);
};

export const findRootElementMultipleClasses = (ele: HTMLElement | Text | null, targetClasses: string[]): HTMLElement | null => {
  if (!ele) {
    return null;
  }

  if (
    ele instanceof Element &&
    containsClass(ele.className, targetClasses)
  ) {
    return ele;
  }
  return findRootElementMultipleClasses(ele.parentElement, targetClasses);
};

const containsClass = (className: string, targetClasses: string[]) => {
  for (let i = 0; i < targetClasses.length; i++) {
    if (className.search(targetClasses[i]) !== -1) {
      return true;
    }
  }
  return false;
}