import UITable from '../../ui/components/table/components/uiTableComponent';
import { getTableForTab } from '../../ui/selectors/ui';
import { ComponentType } from '../../ui/models/component';
import { State } from '../../../main/reducers/rootReducer';
import {
  triggerColumnNames,
  triggerHeadlines,
  sort,
  triggerSearchTags,
  pinRows,
  unpinRows
} from '../../ui/actions/table';
import { triggerFilter } from '../../ui/actions/tab';
import { connect } from 'react-redux';
import { getVenueTableRows } from '../selectors/venues';

const mapStateToProps = (state: State, props: any) => ({
  table: getTableForTab(state, props.parentId, ComponentType.Venues),
  rows: getVenueTableRows(
    state,
    getTableForTab(state, props.parentId, ComponentType.Venues)
  )
});

const mapDispatchToProps = {
  sort: sort,
  triggerColumnNames: triggerColumnNames,
  triggerHeadlines: triggerHeadlines,
  triggerFilter: triggerFilter,
  triggerSearchTags: triggerSearchTags,
  pinRows: pinRows,
  unpinRows: unpinRows
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(UITable);