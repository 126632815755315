import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { EnterOrderRequest, Restriction } from '../../orders/models/orders';
import { FileUpload } from './FileUpload';
import {
  getUploadedBulkOrders,
  isParsingCurrentlyInProgess,
  getUploadErrors,
  getFirstSubmitTime
} from '../selectors/bulkOrders';
import { State } from '../../main/reducers/rootReducer';
import {
  submitBulkOrders,
  parseBinaryStringFromFile,
  basketOrderSuccess,
  basketOrderFailure,
  selectBulkOrder,
  clearBasketOrders,
  requestSampleBasketFile,
  basketOrderBulkSuccess,
  basketOrderBulkFailure
} from '../actions/bulkOrders';
import { v1 } from 'uuid';
import { ITable } from '../../shared/ui/models/table';
import { BulkTable } from '../models/BulkTable';
import { ComponentType } from '../../shared/ui/models/component';
import BasketErrorsTable from '../containers/BasketErrorsTable';
import BasketOrdersTable from '../containers/BasketOrdersTable';
import { orderEnter, bulkEnterOrders } from '../../orders/actions/orders';
import Messenger from '../../shared/messenger/components/messenger';
import { BasketOrder, BasketOrderStatus } from '../models/model';
import ModalWindow from '../../shared/modalWindow/components/modalWindow';
import { Tab, ITab } from '../../shared/ui/models/tab';
import { create } from '../../shared/ui/actions/table';
import { create as createTab } from '../../shared/ui/actions/tab';

export interface BulkProps {
  modalVisible: boolean;
  closeModal: () => void;
  sendParseRequest: (file: any, correlationId: string) => void;
  enterBulkOrders: (orders: any[]) => void;
  onClickCell: (id: string, key: string) => void;
  createTable: (tables: ITable[]) => void;
  enterOrder: (request: EnterOrderRequest) => void;
  bulkEnterOrder: (request: EnterOrderRequest[]) => void;
  handleSuccess: (message: any) => void;
  handleFailure: (message: any) => void;
  handleBulkSuccess: (message: any) => void;
  handleBulkFailure: (message: any) => void;
  clearBasket: () => void;
  fakeTabCreate: (tab: ITab) => void;
  displayFilter: boolean;
  bulkOrders: BasketOrder[];
  errors: { [row: number]: string[] };
  parsingRunning: boolean;
  firstSubmitTime: number;
  downloadSample: () => void;
}

interface ConfirmationBlockProps {
  onSuccess: () => void;
  timestamp: number;
}

const ConfirmationBlock: FC<ConfirmationBlockProps> = ({ onSuccess, timestamp }) => {
  const text = I18n.t('bulk.resubmitQuestion', { time: new Date(timestamp) });

  return (
    <div className="confirmation-block">
      <div>
        <p>{text}</p>
      </div>
      <div>
        <div className="modal-footer">
          <button
            className="btn btn-success ml-2 w150"
            onClick={onSuccess}
            autoFocus
          >
            <Translate value="bulk.resubmit" />
          </button>
        </div>
      </div>
    </div>
  );
};

export const BulkOrderUploadModalComponent: FC<BulkProps> = (props) => {
  const [confirmationPopupVisible, setConfirmationPopupVisible] = useState(false);
  const [displayFilter, setDisplayFilter] = useState(false);
  const { bulkOrders } = props;
  const correlationIds = bulkOrders.map(order => order.correlationId);

  useEffect(() => {
    const newTab = new Tab(
      'bulkModal',
      ComponentType.BulkOrder,
      ComponentType.BulkOrder,
      false,
      'modal-' + ComponentType.BulkOrder,
      {
        displayFilter: displayFilter,
        displaySearch: false,
        isActive: true,
        ordering: 0
      }
    );
    props.fakeTabCreate(newTab);
  }, []);

  useEffect(() => {
    let tables = [];
    tables.push(
      new BulkTable(ComponentType.BulkOrder, ComponentType.BulkOrder)
    );
    props.createTable(tables);
  }, []);

  const hideModal = () => {
    props.closeModal();
    props.clearBasket();
  };

  const createComponent = (component: any, type: ComponentType) => {
    return React.createElement(component, {
      key: 'modal-' + type,
      parentId: 'modal-' + type,
      ...{...props, displayFilter}
    });
  };

  const enterBulkOrders = () => {
    let orders = props.bulkOrders;
    let notYetEnteredOrders = orders.filter(
      order =>
        order.status !== BasketOrderStatus.SUCCESS &&
        order.status !== BasketOrderStatus.PENDING
    );
    props.enterBulkOrders(notYetEnteredOrders);
    const enterOrderRequests = notYetEnteredOrders.map(order => {
      const request: EnterOrderRequest = {
        correlationId: order.correlationId,
        limit: order.limit,
        quantity: order.quantity,
        restriction: Restriction.NONE,
        contractId: order.contract,
        buy: order.buy
      };
      return request;
    });
    props.bulkEnterOrder(enterOrderRequests);
  };

  const handleOrderSuccess = (messages: any[]) => {
    props.handleBulkSuccess(messages);
  }

  const handleOrderFailure = (messages: any[]) => {
    props.handleBulkFailure(messages);
  }

  const forceSubmit = () => {
    enterBulkOrders();
    setConfirmationPopupVisible(false);
  };

  const handleSubmitClicked = () => {
    if (props.firstSubmitTime > -1) {
      setConfirmationPopupVisible(true);
    } else {
      enterBulkOrders();
    }
  };

  const clearBasket = () => {
    setConfirmationPopupVisible(false);
    props.clearBasket();
  };

  const popoverComponent = (
    <ModalWindow
      id="bulk-order-upload"
      onModalClose={() => setConfirmationPopupVisible(false)}
      isOpen={confirmationPopupVisible}
      title="bulk.resubmit"
    >
      <ConfirmationBlock
        onSuccess={forceSubmit}
        timestamp={props.firstSubmitTime}
      />
    </ModalWindow>
  );

  return (
    <ModalWindow
      id="bulk"
      onModalClose={hideModal}
      isOpen={props.modalVisible}
      title={I18n.t('bulk.title')}
    >
      <React.Fragment>
        <div style={{ display: 'none' }}>
          <Messenger
            messageIds={correlationIds}
            onSuccessMessages={(messages: any) => {
              handleOrderSuccess(messages);
            }}
            onErrorMessages={(messages: any) => {
              handleOrderFailure(messages);
            }}
          />
        </div>
        <div className="d-flex align-items-end">
          <FileUpload
            onUpload={(binary: any) => {
              props.sendParseRequest(binary, v1());
            }}
          />
        </div>

        <div className={Object.keys(props.errors).length ? 'bulktable-half' : 'bulktable-full'}>
          <div className="scrollable">
            {createComponent(
              BasketOrdersTable,
              ComponentType.BulkOrder
            )}
          </div>
        </div>
        <div className="bulktable-errors uitable">
          <div className="scrollable meet-table">
            {createComponent(
              BasketErrorsTable,
              ComponentType.BulkError
            )}
          </div>
        </div>
        <div className="modal-footer">
          <button
            className="btn btn-secondary w150"
            onClick={props.downloadSample}
          >
            <Translate value="bulk.downloadSample" />
          </button>
          <button
            className="btn btn-secondary w150"
            onClick={clearBasket}
            disabled={bulkOrders.length === 0}
          >
            <Translate value="bulk.clear" />
          </button>
          <button
            className="btn btn-approval w150"
            onClick={handleSubmitClicked}
            disabled={confirmationPopupVisible || bulkOrders.length === 0}
          >
            <Translate value="bulk.submit" />
          </button>
        </div>
        {confirmationPopupVisible ? popoverComponent : null}
      </React.Fragment>
    </ModalWindow>
  );
};

const mapStateToProps = (s: State) => ({
  bulkOrders: getUploadedBulkOrders(s),
  parsingRunning: isParsingCurrentlyInProgess(s),
  errors: getUploadErrors(s),
  firstSubmitTime: getFirstSubmitTime(s)
});

const mapDispatchToProps = {
  enterBulkOrders: submitBulkOrders,
  onClickCell: selectBulkOrder,
  sendParseRequest: parseBinaryStringFromFile,
  createTable: create,
  enterOrder: orderEnter,
  bulkEnterOrder: bulkEnterOrders,
  handleSuccess: basketOrderSuccess,
  handleFailure: basketOrderFailure,
  handleBulkSuccess: basketOrderBulkSuccess,
  handleBulkFailure: basketOrderBulkFailure,
  clearBasket: clearBasketOrders,
  fakeTabCreate: createTab,
  downloadSample: requestSampleBasketFile
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(BulkOrderUploadModalComponent);