import { IComponent, ComponentType } from './component';
import { ITableColumn } from './table';
import { config } from '../../../main/config';
import { v1 } from 'uuid';

export enum MarketActionType {
  NEW_ORDER = 'NEW_ORDER',
  MATCH_ORDER = 'MATCH_ORDER',
  MODIFY_ORDER = 'MODIFY_ORDER',
  CANCEL_ORDER = 'CANCEL_ORDER',
  NEW_GRAPH = 'NEW_GRAPH',
  NEW_QUOTE_REQUEST = 'NEW_QUOTE_REQUEST',
  ANSWER_QUOTE_REQUEST = 'ANSWER_QUOTE_REQUEST',
  CANCEL_QUOTE_REQUEST = 'CANCEL_QUOTE_REQUEST',
  REJECT_QUOTE_REQUEST = 'REJECT_QUOTE_REQUEST',
  ACCEPT_QUOTE_REQUEST = 'ACCEPT_QUOTE_REQUEST',
  NEW_PRICE_ALARM = 'NEW_PRICE_ALARM',
  MODIFY_PRICE_ALARM = 'MODIFY_PRICE_ALARM',
  CANCEL_PRICE_ALARM = 'CANCEL_PRICE_ALARM',
  SHOW_TRADES = 'SHOW_TRADES'
}

export interface IMarket extends IComponent {
  dockId: string;
  itemId: string;
  instrumentId: string;
  priority: number;

  columns: ITableColumn[];
  hiddenColumnNames: string[];
  visibleColumnNames: string[];
  hiddenExpiryKeys: string[];
  expandedExpiries: { [key: string]: any };
  allExpiryKeys: string[];
  expiryRowsLength: {
    [key: string]: number;
  };
  isHeadlinesVisible: boolean;
  depths: {[expiryCode: string]: number };
  instType: string;
  customColumnOrder: string[];
  customWidths: {[colId: string]: number};
}

export class Market implements IMarket {
  id: string;
  dockId: string;
  priority: number = 1;
  title: string;
  type: ComponentType;
  itemId: string;
  instrumentId: string;
  columns: ITableColumn[];
  hiddenColumnNames: string[];
  visibleColumnNames: string[];
  hiddenExpiryKeys: any[];
  expandedExpiries: { [key: string]: any };
  allExpiryKeys: string[];
  expiryRowsLength: {
    [key: string]: number;
  };
  isHeadlinesVisible: boolean;
  depths:  {[expiryCode: string]: number };
  instType: string;
  customColumnOrder: string[];
  customWidths: {[colId: string]: number};
  constructor(
    dockId: string,
    title: string,
    type: ComponentType,
    itemId: string,
    instrumentId: string,
    columns?: ITableColumn[],
    hiddenColumnNames?: string[],
    hiddenExpiryKeys?: string[],
    expandedExpiries?: { [key: string]: any },
    isHeadlinesVisible?: boolean,
    allExpiryKeys?: string[],
    depths?: {[expiryCode: string]: number; },
    instType?: string
  ) {
    this.id = v1();
    this.dockId = dockId;
    this.title = title;
    this.type = type;
    this.itemId = itemId;
    this.instrumentId = instrumentId;
    this.columns = columns ? columns : config.ui.market.columns;
    if (this.customColumnOrder) {
      this.columns.sort((c1, c2) =>
        this.customColumnOrder.indexOf(`${c1.group}__${c1.name}`) - this.customColumnOrder.indexOf(`${c2.group}__${c2.name}`)
      )
    }
    this.hiddenColumnNames = hiddenColumnNames
      ? hiddenColumnNames
      : [
          'bidPrices__counterparty',
          'askPrices__counterparty',
          'trades__execPrice',
          'trades__counterparty',
          'trades__timestamp',
          'trades__date',
          'settlement__setlPrice',
          'settlement__counterparty'
        ];
    this.hiddenExpiryKeys = hiddenExpiryKeys ? hiddenExpiryKeys : [];
    this.allExpiryKeys = allExpiryKeys ? allExpiryKeys : [];
    this.expandedExpiries = expandedExpiries ? expandedExpiries : [];
    this.expiryRowsLength = {};
    this.isHeadlinesVisible = isHeadlinesVisible ? isHeadlinesVisible : true;
    this.depths = depths ? depths : {};
    this.instType = instType;
  }
}

export interface Cell {
  value: any;
  style: { [attribute: string]: any };
  onContextMenu: any;
  onClick: any;
  className: string;
  localizeOptions: { [attribute: string]: any };
  symbol?: string;
  decorator?: JSX.Element;
}
