import * as React from 'react'
import { connect } from 'react-redux'
import VenueTable from '../containers/venueTable'
import { getAllVenues } from '../selectors/venues'
import { State } from 'js/main/reducers/rootReducer'
import { Venue } from '../models/venue'
import { VenueFormatter } from '../../ui/components/table/tableFormatters'

interface VenueProps {
  venues: Venue[]
  parentId: string
}

export function VenueComponent(props: VenueProps) {
  const { venues } = props

  if (props.parentId.startsWith('recent-')) {
    return (
      <>
        <VenueTable {...props} />
      </>
    )
  } else {
    return (
      <ul className="ui-status-light">
        {venues.map((v) => (
          <li key={`${v.venue}-status`}>
            <span>
              <VenueFormatter value={v.status} />
            </span>
            {v.venue}
          </li>
        ))}
      </ul>
    )
  }
}

const mapStateToProps = (state: State) => ({
  venues: getAllVenues(state),
})

const mapDispatchToProps = {}

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(VenueComponent)
